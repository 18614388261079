import React, { MouseEventHandler, useEffect } from 'react';
import ForgotPasswordForm, {
	ForgotPasswordFormType,
} from '@dr-pam/common-components/Components/Auth/ForgotPasswordForm';
import Center from '@dr-pam/common-components/Components/Misc/Center';
import { Link, useNavigate } from 'react-router-dom';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import useGoBack from '@dr-pam/common-components/Hooks/useGoBack';
import { useAuthService } from '@dr-pam/common-components/Components/Auth/AuthProvider';
import { BrandingType } from '../graphql/graphql';

export type ForgotPasswordPageProps = {
	className?: string;
};

export default function ForgotPasswordPage(props: ForgotPasswordPageProps) {
	const { className } = props;

	const navigate = useNavigate();

	const authService = useAuthService();
	const { user, isLoading } = useAuthenticatedUser();

	useEffect(() => {
		if (user && !isLoading) {
			navigate('/', { replace: true });
		}
	}, [isLoading, navigate, user]);

	const onForgotPasswordRequested = async (form: ForgotPasswordFormType) => {
		await authService.requestPasswordReset(form.email, BrandingType.Ndc);
	};

	const { goBack } = useGoBack('/login');

	const handleBackClicked: MouseEventHandler<HTMLAnchorElement> = (e) => {
		e.preventDefault();
		goBack();
	};

	return (
		<Center className={`ForgotPasswordPage dark-bg ${className ?? ''}`}>
			{/* <Logo className="logo" /> */}
			<h1>Forgot password</h1>
			<ForgotPasswordForm onSubmit={onForgotPasswordRequested} />
			<p className="additional-options tac">
				<Link to="/login" onClick={handleBackClicked}>
					Back to login
				</Link>
			</p>
		</Center>
	);
}
