import React from 'react';

export type LogoProps = {
	className?: string;
};

export default function Logo(props: LogoProps) {
	const { className } = props;

	return (
		<div className={`Logo ${className ?? ''}`}>
			<img src="/images/logo-icon.png" />
			<p className="main">
				the ndc <br></br>institute
			</p>
			<p className="subtitle">home of possums</p>
		</div>
	);
}
