import React, { useEffect } from 'react';
import ResetPasswordForm, { ResetPasswordFormType } from '@dr-pam/common-components/Components/Auth/ResetPasswordForm';
import Center from '@dr-pam/common-components/Components/Misc/Center';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import { useAuthService } from '@dr-pam/common-components/Components/Auth/AuthProvider';

export type ResetPasswordPageProps = {
	className?: string;
};

export default function ResetPasswordPage(props: ResetPasswordPageProps) {
	const { className } = props;

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const params = {
		email: searchParams.get('email'),
		token: searchParams.get('token'),
	};

	const authService = useAuthService();
	const { user, isLoading } = useAuthenticatedUser();

	useEffect(() => {
		if (user && !isLoading) {
			navigate('/', { replace: true });
		}
	}, [isLoading, navigate, user]);

	const onResetPasswordRequested = async (form: ResetPasswordFormType) => {
		if (!params.email || !params.token) {
			throw new Error('This reset link is invalid or has expired.');
		}

		await authService.completePasswordReset(params.email, params.token, form.password);
	};

	return (
		<Center className={`ResetPasswordPage dark-bg ${className ?? ''}`}>
			<h1>Reset password</h1>
			<p>You&apos;re nearly there! Please provide a new password to use for your account</p>
			<ResetPasswordForm onSubmit={onResetPasswordRequested} />
		</Center>
	);
}
