import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	IconLogout,
	IconChevronLeft,
	IconHourglassLow,
	IconFileStack,
	IconLayoutGridAdd,
	IconUserCircle,
	IconMenu2,
	IconX,
	IconHome,
} from '@tabler/icons-react';
import { Link, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { useProgrammeProvider } from '../programme/ProgrammeProvider';
import Loading from '@dr-pam/common-components/Components/Misc/Loading';
import ProgrammeDropDown from './ProgrammeDropDown';
import { useRuntimeConfigProvider } from '@dr-pam/common-components/Config/RuntimeConfigProvider';
import RuntimeConfig from '../../config/RuntimeConfig';
import { PurchasedProgramme } from '@dr-pam/common-components/Models/NdcProgramme';
import { routes } from '../App';
import Logo from '../layout/Logo';
import LocalStorageUtil from '@dr-pam/common-components/Utils/LocalStorageUtil';

// If you want to change these numbers, also update the following variables:
//     `$navbar-vertical-breakpoint`  in  `src/styles/components/NavBar.scss`
//     `$breakpoint-tablet`           in  `src/styles/abstracts/responsive.scss`
const MOBILE_BREAKPOINT_VERTICAL = '630px'; // This should match $navbar-vertical-breakpoint
const MOBILE_BREAKPOINT_HORIZONTAL = '768px'; // This should match $navbar-horizontal-breakpoint

const SMALL_DESKTOP_BREAKPOINT_HORIZONTAL = '1000px';

export type NavBarProps = {
	className?: string;
};

export default function NavBar(props: NavBarProps) {
	const { className } = props;
	const [showNavbar, setShowNavbar] = useState(true);
	const [isWithinHorizontalMobileBreakpoint, setIsWithinHorizontalMobileBreakpoint] = useState(false);
	const [isWithinVerticalMobileBreakpoint, setIsWithinVerticalMobileBreakpoint] = useState(false);
	const [isWithinSmallDesktopBreakpoint, setIsWithinSmallDesktopBreakpoint] = useState(false);

	const { config } = useRuntimeConfigProvider<RuntimeConfig>();

	const location = useLocation();
	const matchedRoutes = matchRoutes(routes, location) ?? [];
	const currentRoute = matchedRoutes.length ? matchedRoutes[0] : null;

	const navigate = useNavigate();
	const programmeProvider = useProgrammeProvider();

	const isMobile = useMemo(
		() => isWithinHorizontalMobileBreakpoint || isWithinVerticalMobileBreakpoint,
		[isWithinHorizontalMobileBreakpoint, isWithinVerticalMobileBreakpoint],
	);

	const closeNavBar = useCallback(() => {
		setShowNavbar(false);
		document.body.classList.add('menu-closed');
		document.body.classList.remove('menu-open');
		if (!isMobile) {
			LocalStorageUtil.setItem('menu', 'closed');
		}
	}, [isMobile]);

	const openNavBar = useCallback(() => {
		setShowNavbar(true);
		document.body.classList.remove('menu-closed');
		document.body.classList.add('menu-open');
		if (!isMobile) {
			LocalStorageUtil.setItem('menu', 'open');
		}
	}, [isMobile]);

	const toggleNavBar = useCallback(() => {
		if (showNavbar) {
			closeNavBar();
		} else {
			openNavBar();
		}
	}, [closeNavBar, openNavBar, showNavbar]);

	useEffect(() => {
		const horizontalCallback = (event: MediaQueryListEvent) => {
			setIsWithinHorizontalMobileBreakpoint(event.matches);
		};
		const verticalCallback = (event: MediaQueryListEvent) => {
			setIsWithinVerticalMobileBreakpoint(event.matches);
		};
		const smallDesktopCallback = (event: MediaQueryListEvent) => {
			setIsWithinSmallDesktopBreakpoint(event.matches);
		};

		const horizontalMediaQuery = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT_HORIZONTAL})`);
		const verticalMediaQuery = window.matchMedia(`(max-height: ${MOBILE_BREAKPOINT_VERTICAL})`);
		const smallDesktopMediaQuery = window.matchMedia(`(max-width: ${SMALL_DESKTOP_BREAKPOINT_HORIZONTAL})`);

		horizontalMediaQuery.addEventListener('change', horizontalCallback);
		verticalMediaQuery.addEventListener('change', verticalCallback);
		smallDesktopMediaQuery.addEventListener('change', smallDesktopCallback);

		setIsWithinHorizontalMobileBreakpoint(horizontalMediaQuery.matches);
		setIsWithinVerticalMobileBreakpoint(verticalMediaQuery.matches);
		setIsWithinSmallDesktopBreakpoint(smallDesktopMediaQuery.matches);

		return () => {
			horizontalMediaQuery.removeEventListener('change', horizontalCallback);
			verticalMediaQuery.removeEventListener('change', verticalCallback);
			smallDesktopMediaQuery.removeEventListener('change', smallDesktopCallback);
		};
	}, [closeNavBar]);

	useEffect(() => {
		if (currentRoute?.pathname.startsWith('/programme/')) {
			const programmeId = currentRoute.params.programmeId ?? currentRoute.params.id;
			const programme = programmeProvider.programmes?.find((x) => x.id === programmeId);
			if (programme) {
				programmeProvider.setSelectedProgramme(programme);
			}
		}
	}, [currentRoute, programmeProvider]);

	useEffect(() => {
		if (LocalStorageUtil.getItem('menu') === 'closed') {
			closeNavBar();
		} else {
			openNavBar();
		}
	}, [closeNavBar, openNavBar]);

	useEffect(() => {
		if (isMobile || isWithinSmallDesktopBreakpoint) {
			closeNavBar();
		}
	}, [closeNavBar, isMobile, isWithinSmallDesktopBreakpoint]);

	const handleProgrammeChanged = (programme?: PurchasedProgramme) => {
		programmeProvider.setSelectedProgramme(programme);
		if (programme) {
			navigate(`/programme/${programme.id}`);
			if (isMobile) {
				closeNavBar();
			}
		}
	};

	const handleMenuClicked = (e: React.MouseEvent) => {
		const target = e.target as HTMLElement;
		if (isMobile && (target.tagName === 'A' || target.closest('a'))) {
			closeNavBar();
		}
	};

	let courseLinks = null;

	if (programmeProvider.isLoading) {
		courseLinks = (
			<div>
				<Loading className="secondary" />
			</div>
		);
	} else if (programmeProvider.programmes) {
		if (programmeProvider.programmes.length === 1) {
			const programme = programmeProvider.programmes[0];
			courseLinks = (
				<Link to={`/programme/${programme.id}`}>
					<IconHome stroke={2} />
					<span>{programme.name}</span>
				</Link>
			);
		} else if (programmeProvider.programmes.length > 1) {
			const filteredProgrammes = programmeProvider.programmes
				.filter((x) => !x.isHidden)
				.sort((a, b) => a.name.localeCompare(b.name));
			courseLinks = (
				<ProgrammeDropDown
					programmes={filteredProgrammes}
					value={programmeProvider.selectedProgramme}
					onChange={handleProgrammeChanged}
				/>
			);
		}
	}

	return (
		<div className={`NavBar dark-bg fg0 fs0 ${className ?? ''}`} onClick={handleMenuClicked}>
			<Link to="/" className="logo-container">
				<Logo />
			</Link>
			<div className="navbar-content">
				{courseLinks}
				<div className="divider-element"></div>
				<ul>
					{programmeProvider.programmes?.find((x) => x.id === config?.resourceHubProgrammeId) ? (
						<li>
							<Link to="/resource-hub">
								<IconFileStack stroke={2} />
								<span>Resource hub</span>
							</Link>
						</li>
					) : null}
					<li>
						<Link to="/activity-log">
							<IconHourglassLow stroke={2} />
							<span>Activity log</span>
						</Link>
					</li>
				</ul>
				<div className="divider-element"></div>
				<Link to="/">
					<IconLayoutGridAdd stroke={2} />
					<span>NDC courses</span>
				</Link>
				<div className="spacer-element"></div>
				<Link to="/profile">
					<IconUserCircle stroke={2} />
					<span>Your account</span>
				</Link>
				<Link to="/logout" title="Logout" className="flex">
					<IconLogout stroke={2} />
					<span>Logout</span>
				</Link>

				<div className="divider-element"></div>
			</div>
			<div className="menu-collapse-toggle" onClick={toggleNavBar}>
				<IconChevronLeft stroke={2} className="desktop-only" />
				<IconMenu2 stroke={2} className="hide-on-desktop show-when-collapsed" />
				<span className="hide-on-desktop show-when-expanded">Close menu</span>
				<IconX stroke={2} className="hide-on-desktop show-when-expanded" />
				<span className="desktop-only show-when-expanded">Collapse menu</span>
				<span className="desktop-only show-when-collapsed">Expand menu</span>
			</div>
		</div>
	);
}
