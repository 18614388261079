import React, { ReactNode, Suspense, useMemo, useState } from 'react';
import NavBar from '../navigation/NavBar';
import RequireAuthenticated from '@dr-pam/common-components/Components/Auth/RequireAuthenticated';
import { Navigate, Outlet, matchRoutes, useLocation } from 'react-router-dom';
import Center from '@dr-pam/common-components/Components/Misc/Center';
import Loading from '@dr-pam/common-components/Components/Misc/Loading';
import { PageRouteObject } from '../App';
import { IconX } from '@tabler/icons-react';

export type PageWrapperProps = {
	className?: string;
	routes?: () => PageRouteObject[];
	children?: ReactNode;
};

export default function PageWrapper(props: PageWrapperProps) {
	const { className, routes, children } = props;

	const location = useLocation();

	const [isMobileWarningDismissed, setIsMobileWarningDismissed] = useState(false);

	const matched = useMemo(() => matchRoutes(routes?.() ?? [], location) ?? [], [location, routes]);

	const additionalClassName = useMemo(() => {
		if (matched.length > 0) {
			const matchedRoute = matched[matched.length - 1];

			return matchedRoute.route.className ?? '';
		}
		return '';
	}, [matched]);

	const onDismissMobileWarningClicked = () => {
		setIsMobileWarningDismissed(true);
	};

	return (
		<RequireAuthenticated
			loadingChildren={LoadingState}
			notAuthenticatedChildren={<Navigate to="/login" state={{ redirectTo: location.pathname }} />}
		>
			<div className={`PageWrapper w100 h100 flex ${className ?? ''} ${additionalClassName}`}>
				<NavBar />
				<div className="PageContent light-bg fg1 fs1">
					<Suspense fallback={LoadingState}>
						{children}
						<Outlet />
					</Suspense>
				</div>
				{!isMobileWarningDismissed && (
					<div className="mobile-warning">
						<p>
							Support for mobile devices is coming soon. Until then, please use a desktop computer for the
							best experience.
						</p>

						<IconX style={{ width: '2rem', height: '2rem' }} onClick={onDismissMobileWarningClicked} />
					</div>
				)}
			</div>
		</RequireAuthenticated>
	);
}

const LoadingState = (
	<Center>
		<Loading size="large">
			<p>Please wait...</p>
		</Loading>
	</Center>
);
