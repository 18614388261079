import React, { useEffect, useState } from 'react';
import { PurchasedProgramme } from '@dr-pam/common-components/Models/NdcProgramme';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useClickOutside } from '@mantine/hooks';

export type ProgrammeDropDownProps = {
	className?: string;
	programmes: PurchasedProgramme[];
	value?: PurchasedProgramme;
	onChange?: (programme?: PurchasedProgramme) => void;
};

export default function ProgrammeDropDown(props: ProgrammeDropDownProps) {
	const { className, programmes, onChange } = props;

	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState<PurchasedProgramme | undefined>(props.value);

	const ref = useClickOutside(() => setIsOpen(false));

	useEffect(() => {
		setSelected(props.value);
	}, [props.value]);

	const handleOptionClicked = (programme?: PurchasedProgramme) => {
		setSelected(programme);
		onChange?.(programme);
		setIsOpen(false);
	};

	const emptyValue = 'Select a course';

	return (
		<div ref={ref} className={`ProgrammeDropDown ${className ?? ''}`}>
			<div className="value" onClick={() => setIsOpen(!isOpen)}>
				<span>{selected ? selected.name : emptyValue}</span>
				{isOpen ? <IconChevronUp /> : <IconChevronDown />}
			</div>
			<div className="options" style={{ display: isOpen ? undefined : 'none' }}>
				<div className="option empty" onClick={() => handleOptionClicked(undefined)}>
					{emptyValue}
				</div>
				{programmes.map((programme) => (
					<div
						key={programme.id}
						className={`option ${selected?.id === programme.id ? 'selected' : ''}`}
						onClick={() => handleOptionClicked(programme)}
					>
						{programme.name}
					</div>
				))}
			</div>
		</div>
	);
}
