import React, {
	Dispatch,
	ReactNode,
	SetStateAction,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { PurchasedProgramme } from '@dr-pam/common-components/Models/NdcProgramme';
import { useProgrammeService } from '../../services/ProgrammeService';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';

export type ProgrammeProviderValue = {
	isLoading: boolean;
	selectedProgramme: PurchasedProgramme | undefined;
	setSelectedProgramme: Dispatch<SetStateAction<PurchasedProgramme | undefined>>;
	programmes: PurchasedProgramme[] | undefined;
	setProgrammes: Dispatch<SetStateAction<PurchasedProgramme[] | undefined>>;
	fetchProgrammes: () => Promise<void>;
};

const ProgrameContext = createContext<ProgrammeProviderValue>({
	isLoading: false,
	selectedProgramme: undefined,
	setSelectedProgramme: () => {},
	programmes: undefined,
	setProgrammes: () => {},
	fetchProgrammes: () => Promise.resolve(),
});

export type ProgrammeProviderProps = {
	children?: ReactNode;
};

export default function ProgrammeProvider(props: ProgrammeProviderProps) {
	const { children } = props;

	const { user } = useAuthenticatedUser();
	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const programmeService = useProgrammeService();

	const isFetching = useRef<boolean>(false);

	const [selectedProgramme, setSelectedProgramme] = useState<PurchasedProgramme | undefined>(undefined);
	const [programmes, setProgrammes] = useState<PurchasedProgramme[] | undefined>(undefined);

	const fetchProgrammes = useCallback(async () => {
		if (isFetching.current) {
			return;
		}
		const loader = addLoader();
		try {
			isFetching.current = true;
			const request = programmeService.getPurchasedProgrammes();
			const programmes = await request.response;
			programmes.sort((a, b) => a.sortOrder - b.sortOrder);
			setProgrammes(programmes);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to fetch programmes');
		} finally {
			removeLoader(loader);
			isFetching.current = false;
		}
	}, [addLoader, programmeService, removeLoader]);

	useEffect(() => {
		if (user && !programmes) {
			fetchProgrammes();
		}
	}, [fetchProgrammes, programmes, user]);

	const value: ProgrammeProviderValue = {
		isLoading,
		programmes,
		setProgrammes,
		selectedProgramme,
		setSelectedProgramme,
		fetchProgrammes,
	};

	return <ProgrameContext.Provider value={value}>{children}</ProgrameContext.Provider>;
}

export function useProgrammeProvider() {
	const context = useContext(ProgrameContext);

	if (!context) {
		throw new Error('useProgrammeProvider must be used within a ProgrammeProvider');
	}

	return context;
}
