/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export enum AcceptedCurrency {
  Aud = 'AUD'
}

export type AffectedRowsOutput = {
  count: Scalars['Int']['output'];
};

export type AggregateArticle = {
  _count?: Maybe<ArticleCountAggregate>;
  _max?: Maybe<ArticleMaxAggregate>;
  _min?: Maybe<ArticleMinAggregate>;
};

export type AggregateArticleCategory = {
  _avg?: Maybe<ArticleCategoryAvgAggregate>;
  _count?: Maybe<ArticleCategoryCountAggregate>;
  _max?: Maybe<ArticleCategoryMaxAggregate>;
  _min?: Maybe<ArticleCategoryMinAggregate>;
  _sum?: Maybe<ArticleCategorySumAggregate>;
};

export type AggregateCategory = {
  _avg?: Maybe<CategoryAvgAggregate>;
  _count?: Maybe<CategoryCountAggregate>;
  _max?: Maybe<CategoryMaxAggregate>;
  _min?: Maybe<CategoryMinAggregate>;
  _sum?: Maybe<CategorySumAggregate>;
};

export type AggregateCategoryPrerequisite = {
  _count?: Maybe<CategoryPrerequisiteCountAggregate>;
  _max?: Maybe<CategoryPrerequisiteMaxAggregate>;
  _min?: Maybe<CategoryPrerequisiteMinAggregate>;
};

export type AggregateContent = {
  _count?: Maybe<ContentCountAggregate>;
  _max?: Maybe<ContentMaxAggregate>;
  _min?: Maybe<ContentMinAggregate>;
};

export type AggregateEvent = {
  _avg?: Maybe<EventAvgAggregate>;
  _count?: Maybe<EventCountAggregate>;
  _max?: Maybe<EventMaxAggregate>;
  _min?: Maybe<EventMinAggregate>;
  _sum?: Maybe<EventSumAggregate>;
};

export type AggregateEventAttendance = {
  _avg?: Maybe<EventAttendanceAvgAggregate>;
  _count?: Maybe<EventAttendanceCountAggregate>;
  _max?: Maybe<EventAttendanceMaxAggregate>;
  _min?: Maybe<EventAttendanceMinAggregate>;
  _sum?: Maybe<EventAttendanceSumAggregate>;
};

export type AggregateEventCategory = {
  _avg?: Maybe<EventCategoryAvgAggregate>;
  _count?: Maybe<EventCategoryCountAggregate>;
  _max?: Maybe<EventCategoryMaxAggregate>;
  _min?: Maybe<EventCategoryMinAggregate>;
  _sum?: Maybe<EventCategorySumAggregate>;
};

export type AggregateEventOccurrence = {
  _avg?: Maybe<EventOccurrenceAvgAggregate>;
  _count?: Maybe<EventOccurrenceCountAggregate>;
  _max?: Maybe<EventOccurrenceMaxAggregate>;
  _min?: Maybe<EventOccurrenceMinAggregate>;
  _sum?: Maybe<EventOccurrenceSumAggregate>;
};

export type AggregateEventRegistration = {
  _count?: Maybe<EventRegistrationCountAggregate>;
  _max?: Maybe<EventRegistrationMaxAggregate>;
  _min?: Maybe<EventRegistrationMinAggregate>;
};

export type AggregateGuest = {
  _avg?: Maybe<GuestAvgAggregate>;
  _count?: Maybe<GuestCountAggregate>;
  _max?: Maybe<GuestMaxAggregate>;
  _min?: Maybe<GuestMinAggregate>;
  _sum?: Maybe<GuestSumAggregate>;
};

export type AggregatePayment = {
  _avg?: Maybe<PaymentAvgAggregate>;
  _count?: Maybe<PaymentCountAggregate>;
  _max?: Maybe<PaymentMaxAggregate>;
  _min?: Maybe<PaymentMinAggregate>;
  _sum?: Maybe<PaymentSumAggregate>;
};

export type AggregatePaymentItem = {
  _count?: Maybe<PaymentItemCountAggregate>;
  _max?: Maybe<PaymentItemMaxAggregate>;
  _min?: Maybe<PaymentItemMinAggregate>;
};

export type AggregateProduct = {
  _count?: Maybe<ProductCountAggregate>;
  _max?: Maybe<ProductMaxAggregate>;
  _min?: Maybe<ProductMinAggregate>;
};

export type AggregateProductProgramme = {
  _count?: Maybe<ProductProgrammeCountAggregate>;
  _max?: Maybe<ProductProgrammeMaxAggregate>;
  _min?: Maybe<ProductProgrammeMinAggregate>;
};

export type AggregateProductProgrammePrerequisite = {
  _count?: Maybe<ProductProgrammePrerequisiteCountAggregate>;
  _max?: Maybe<ProductProgrammePrerequisiteMaxAggregate>;
  _min?: Maybe<ProductProgrammePrerequisiteMinAggregate>;
};

export type AggregateProductSubscriptionType = {
  _avg?: Maybe<ProductSubscriptionTypeAvgAggregate>;
  _count?: Maybe<ProductSubscriptionTypeCountAggregate>;
  _max?: Maybe<ProductSubscriptionTypeMaxAggregate>;
  _min?: Maybe<ProductSubscriptionTypeMinAggregate>;
  _sum?: Maybe<ProductSubscriptionTypeSumAggregate>;
};

export type AggregateProgramme = {
  _avg?: Maybe<ProgrammeAvgAggregate>;
  _count?: Maybe<ProgrammeCountAggregate>;
  _max?: Maybe<ProgrammeMaxAggregate>;
  _min?: Maybe<ProgrammeMinAggregate>;
  _sum?: Maybe<ProgrammeSumAggregate>;
};

export type AggregateProgrammeProgression = {
  _avg?: Maybe<ProgrammeProgressionAvgAggregate>;
  _count?: Maybe<ProgrammeProgressionCountAggregate>;
  _max?: Maybe<ProgrammeProgressionMaxAggregate>;
  _min?: Maybe<ProgrammeProgressionMinAggregate>;
  _sum?: Maybe<ProgrammeProgressionSumAggregate>;
};

export type AggregateQuiz = {
  _count?: Maybe<QuizCountAggregate>;
  _max?: Maybe<QuizMaxAggregate>;
  _min?: Maybe<QuizMinAggregate>;
};

export type AggregateQuizCategory = {
  _avg?: Maybe<QuizCategoryAvgAggregate>;
  _count?: Maybe<QuizCategoryCountAggregate>;
  _max?: Maybe<QuizCategoryMaxAggregate>;
  _min?: Maybe<QuizCategoryMinAggregate>;
  _sum?: Maybe<QuizCategorySumAggregate>;
};

export type AggregateResource = {
  _count?: Maybe<ResourceCountAggregate>;
  _max?: Maybe<ResourceMaxAggregate>;
  _min?: Maybe<ResourceMinAggregate>;
};

export type AggregateResourceCategory = {
  _avg?: Maybe<ResourceCategoryAvgAggregate>;
  _count?: Maybe<ResourceCategoryCountAggregate>;
  _max?: Maybe<ResourceCategoryMaxAggregate>;
  _min?: Maybe<ResourceCategoryMinAggregate>;
  _sum?: Maybe<ResourceCategorySumAggregate>;
};

export type AggregateResourceType = {
  _count?: Maybe<ResourceTypeCountAggregate>;
  _max?: Maybe<ResourceTypeMaxAggregate>;
  _min?: Maybe<ResourceTypeMinAggregate>;
};

export type AggregateStripeEvent = {
  _count?: Maybe<StripeEventCountAggregate>;
  _max?: Maybe<StripeEventMaxAggregate>;
  _min?: Maybe<StripeEventMinAggregate>;
};

export type AggregateStripePaymentIntent = {
  _avg?: Maybe<StripePaymentIntentAvgAggregate>;
  _count?: Maybe<StripePaymentIntentCountAggregate>;
  _max?: Maybe<StripePaymentIntentMaxAggregate>;
  _min?: Maybe<StripePaymentIntentMinAggregate>;
  _sum?: Maybe<StripePaymentIntentSumAggregate>;
};

export type AggregateUser = {
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
};

export type AggregateUserArticle = {
  _count?: Maybe<UserArticleCountAggregate>;
  _max?: Maybe<UserArticleMaxAggregate>;
  _min?: Maybe<UserArticleMinAggregate>;
};

export type AggregateUserManualActivity = {
  _avg?: Maybe<UserManualActivityAvgAggregate>;
  _count?: Maybe<UserManualActivityCountAggregate>;
  _max?: Maybe<UserManualActivityMaxAggregate>;
  _min?: Maybe<UserManualActivityMinAggregate>;
  _sum?: Maybe<UserManualActivitySumAggregate>;
};

export type AggregateUserQuiz = {
  _avg?: Maybe<UserQuizAvgAggregate>;
  _count?: Maybe<UserQuizCountAggregate>;
  _max?: Maybe<UserQuizMaxAggregate>;
  _min?: Maybe<UserQuizMinAggregate>;
  _sum?: Maybe<UserQuizSumAggregate>;
};

export type AggregateUserResource = {
  _count?: Maybe<UserResourceCountAggregate>;
  _max?: Maybe<UserResourceMaxAggregate>;
  _min?: Maybe<UserResourceMinAggregate>;
};

export type AggregateUserSubscription = {
  _count?: Maybe<UserSubscriptionCountAggregate>;
  _max?: Maybe<UserSubscriptionMaxAggregate>;
  _min?: Maybe<UserSubscriptionMinAggregate>;
};

export type Article = {
  _count?: Maybe<ArticleCount>;
  articleCategories: Array<ArticleCategory>;
  content?: Maybe<Content>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  readBy: Array<UserArticle>;
  resourceTypeId?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type?: Maybe<ResourceType>;
};


export type ArticleArticleCategoriesArgs = {
  cursor?: InputMaybe<ArticleCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArticleCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArticleCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleCategoryWhereInput>;
};


export type ArticleContentArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type ArticleReadByArgs = {
  cursor?: InputMaybe<UserArticleWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserArticleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserArticleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserArticleWhereInput>;
};


export type ArticleTypeArgs = {
  where?: InputMaybe<ResourceTypeWhereInput>;
};

export type ArticleCategory = {
  article: Article;
  articleId: Scalars['String']['output'];
  category: Category;
  categoryId: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isPinned: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type ArticleCategoryArticleCategoryCompoundUniqueInput = {
  articleId: Scalars['String']['input'];
  categoryId: Scalars['String']['input'];
};

export type ArticleCategoryAvgAggregate = {
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

export type ArticleCategoryAvgOrderByAggregateInput = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type ArticleCategoryCountAggregate = {
  _all: Scalars['Int']['output'];
  articleId: Scalars['Int']['output'];
  categoryId: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isPinned: Scalars['Int']['output'];
  isRequired: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type ArticleCategoryCountOrderByAggregateInput = {
  articleId?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ArticleCategoryCreateInput = {
  article: ArticleCreateNestedOneWithoutArticleCategoriesInput;
  category: CategoryCreateNestedOneWithoutCategoryArticlesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ArticleCategoryCreateManyArticleInput = {
  categoryId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ArticleCategoryCreateManyArticleInputEnvelope = {
  data: Array<ArticleCategoryCreateManyArticleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ArticleCategoryCreateManyCategoryInput = {
  articleId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ArticleCategoryCreateManyCategoryInputEnvelope = {
  data: Array<ArticleCategoryCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ArticleCategoryCreateManyInput = {
  articleId: Scalars['String']['input'];
  categoryId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ArticleCategoryCreateNestedManyWithoutArticleInput = {
  connect?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ArticleCategoryCreateOrConnectWithoutArticleInput>>;
  create?: InputMaybe<Array<ArticleCategoryCreateWithoutArticleInput>>;
  createMany?: InputMaybe<ArticleCategoryCreateManyArticleInputEnvelope>;
};

export type ArticleCategoryCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ArticleCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<ArticleCategoryCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<ArticleCategoryCreateManyCategoryInputEnvelope>;
};

export type ArticleCategoryCreateOrConnectWithoutArticleInput = {
  create: ArticleCategoryCreateWithoutArticleInput;
  where: ArticleCategoryWhereUniqueInput;
};

export type ArticleCategoryCreateOrConnectWithoutCategoryInput = {
  create: ArticleCategoryCreateWithoutCategoryInput;
  where: ArticleCategoryWhereUniqueInput;
};

export type ArticleCategoryCreateWithoutArticleInput = {
  category: CategoryCreateNestedOneWithoutCategoryArticlesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ArticleCategoryCreateWithoutCategoryInput = {
  article: ArticleCreateNestedOneWithoutArticleCategoriesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ArticleCategoryGroupBy = {
  _avg?: Maybe<ArticleCategoryAvgAggregate>;
  _count?: Maybe<ArticleCategoryCountAggregate>;
  _max?: Maybe<ArticleCategoryMaxAggregate>;
  _min?: Maybe<ArticleCategoryMinAggregate>;
  _sum?: Maybe<ArticleCategorySumAggregate>;
  articleId: Scalars['String']['output'];
  categoryId: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isPinned: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type ArticleCategoryListRelationFilter = {
  every?: InputMaybe<ArticleCategoryWhereInput>;
  none?: InputMaybe<ArticleCategoryWhereInput>;
  some?: InputMaybe<ArticleCategoryWhereInput>;
};

export type ArticleCategoryMaxAggregate = {
  articleId?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type ArticleCategoryMaxOrderByAggregateInput = {
  articleId?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ArticleCategoryMinAggregate = {
  articleId?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type ArticleCategoryMinOrderByAggregateInput = {
  articleId?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ArticleCategoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ArticleCategoryOrderByWithAggregationInput = {
  _avg?: InputMaybe<ArticleCategoryAvgOrderByAggregateInput>;
  _count?: InputMaybe<ArticleCategoryCountOrderByAggregateInput>;
  _max?: InputMaybe<ArticleCategoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<ArticleCategoryMinOrderByAggregateInput>;
  _sum?: InputMaybe<ArticleCategorySumOrderByAggregateInput>;
  articleId?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ArticleCategoryOrderByWithRelationInput = {
  article?: InputMaybe<ArticleOrderByWithRelationInput>;
  articleId?: InputMaybe<SortOrder>;
  category?: InputMaybe<CategoryOrderByWithRelationInput>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export enum ArticleCategoryScalarFieldEnum {
  ArticleId = 'articleId',
  CategoryId = 'categoryId',
  Created = 'created',
  Id = 'id',
  IsPinned = 'isPinned',
  IsRequired = 'isRequired',
  Modified = 'modified',
  SortOrder = 'sortOrder'
}

export type ArticleCategoryScalarWhereInput = {
  AND?: InputMaybe<Array<ArticleCategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<ArticleCategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<ArticleCategoryScalarWhereInput>>;
  articleId?: InputMaybe<StringFilter>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isPinned?: InputMaybe<BoolFilter>;
  isRequired?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  sortOrder?: InputMaybe<IntFilter>;
};

export type ArticleCategoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ArticleCategoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ArticleCategoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ArticleCategoryScalarWhereWithAggregatesInput>>;
  articleId?: InputMaybe<StringWithAggregatesFilter>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPinned?: InputMaybe<BoolWithAggregatesFilter>;
  isRequired?: InputMaybe<BoolWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  sortOrder?: InputMaybe<IntWithAggregatesFilter>;
};

export type ArticleCategorySumAggregate = {
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type ArticleCategorySumOrderByAggregateInput = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type ArticleCategoryUpdateInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutArticleCategoriesNestedInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutCategoryArticlesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ArticleCategoryUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ArticleCategoryUpdateManyWithWhereWithoutArticleInput = {
  data: ArticleCategoryUpdateManyMutationInput;
  where: ArticleCategoryScalarWhereInput;
};

export type ArticleCategoryUpdateManyWithWhereWithoutCategoryInput = {
  data: ArticleCategoryUpdateManyMutationInput;
  where: ArticleCategoryScalarWhereInput;
};

export type ArticleCategoryUpdateManyWithoutArticleNestedInput = {
  connect?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ArticleCategoryCreateOrConnectWithoutArticleInput>>;
  create?: InputMaybe<Array<ArticleCategoryCreateWithoutArticleInput>>;
  createMany?: InputMaybe<ArticleCategoryCreateManyArticleInputEnvelope>;
  delete?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ArticleCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<ArticleCategoryUpdateWithWhereUniqueWithoutArticleInput>>;
  updateMany?: InputMaybe<Array<ArticleCategoryUpdateManyWithWhereWithoutArticleInput>>;
  upsert?: InputMaybe<Array<ArticleCategoryUpsertWithWhereUniqueWithoutArticleInput>>;
};

export type ArticleCategoryUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ArticleCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<ArticleCategoryCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<ArticleCategoryCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ArticleCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<ArticleCategoryUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<ArticleCategoryUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<ArticleCategoryUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type ArticleCategoryUpdateWithWhereUniqueWithoutArticleInput = {
  data: ArticleCategoryUpdateWithoutArticleInput;
  where: ArticleCategoryWhereUniqueInput;
};

export type ArticleCategoryUpdateWithWhereUniqueWithoutCategoryInput = {
  data: ArticleCategoryUpdateWithoutCategoryInput;
  where: ArticleCategoryWhereUniqueInput;
};

export type ArticleCategoryUpdateWithoutArticleInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutCategoryArticlesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ArticleCategoryUpdateWithoutCategoryInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutArticleCategoriesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ArticleCategoryUpsertWithWhereUniqueWithoutArticleInput = {
  create: ArticleCategoryCreateWithoutArticleInput;
  update: ArticleCategoryUpdateWithoutArticleInput;
  where: ArticleCategoryWhereUniqueInput;
};

export type ArticleCategoryUpsertWithWhereUniqueWithoutCategoryInput = {
  create: ArticleCategoryCreateWithoutCategoryInput;
  update: ArticleCategoryUpdateWithoutCategoryInput;
  where: ArticleCategoryWhereUniqueInput;
};

export type ArticleCategoryWhereInput = {
  AND?: InputMaybe<Array<ArticleCategoryWhereInput>>;
  NOT?: InputMaybe<Array<ArticleCategoryWhereInput>>;
  OR?: InputMaybe<Array<ArticleCategoryWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  articleId?: InputMaybe<StringFilter>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isPinned?: InputMaybe<BoolFilter>;
  isRequired?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  sortOrder?: InputMaybe<IntFilter>;
};

export type ArticleCategoryWhereUniqueInput = {
  AND?: InputMaybe<Array<ArticleCategoryWhereInput>>;
  NOT?: InputMaybe<Array<ArticleCategoryWhereInput>>;
  OR?: InputMaybe<Array<ArticleCategoryWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  articleCategory?: InputMaybe<ArticleCategoryArticleCategoryCompoundUniqueInput>;
  articleId?: InputMaybe<StringFilter>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<BoolFilter>;
  isRequired?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  sortOrder?: InputMaybe<IntFilter>;
};

export type ArticleCount = {
  articleCategories: Scalars['Int']['output'];
  readBy: Scalars['Int']['output'];
};


export type ArticleCountArticleCategoriesArgs = {
  where?: InputMaybe<ArticleCategoryWhereInput>;
};


export type ArticleCountReadByArgs = {
  where?: InputMaybe<UserArticleWhereInput>;
};

export type ArticleCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isPublished: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  resourceTypeId: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
};

export type ArticleCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceTypeId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type ArticleCreateInput = {
  articleCategories?: InputMaybe<ArticleCategoryCreateNestedManyWithoutArticleInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutArticleInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  readBy?: InputMaybe<UserArticleCreateNestedManyWithoutArticleInput>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type?: InputMaybe<ResourceTypeCreateNestedOneWithoutArticlesInput>;
};

export type ArticleCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  resourceTypeId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type ArticleCreateManyTypeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type ArticleCreateManyTypeInputEnvelope = {
  data: Array<ArticleCreateManyTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ArticleCreateNestedManyWithoutTypeInput = {
  connect?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ArticleCreateOrConnectWithoutTypeInput>>;
  create?: InputMaybe<Array<ArticleCreateWithoutTypeInput>>;
  createMany?: InputMaybe<ArticleCreateManyTypeInputEnvelope>;
};

export type ArticleCreateNestedOneWithoutArticleCategoriesInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutArticleCategoriesInput>;
  create?: InputMaybe<ArticleCreateWithoutArticleCategoriesInput>;
};

export type ArticleCreateNestedOneWithoutContentInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutContentInput>;
  create?: InputMaybe<ArticleCreateWithoutContentInput>;
};

export type ArticleCreateNestedOneWithoutReadByInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutReadByInput>;
  create?: InputMaybe<ArticleCreateWithoutReadByInput>;
};

export type ArticleCreateOrConnectWithoutArticleCategoriesInput = {
  create: ArticleCreateWithoutArticleCategoriesInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleCreateOrConnectWithoutContentInput = {
  create: ArticleCreateWithoutContentInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleCreateOrConnectWithoutReadByInput = {
  create: ArticleCreateWithoutReadByInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleCreateOrConnectWithoutTypeInput = {
  create: ArticleCreateWithoutTypeInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleCreateWithoutArticleCategoriesInput = {
  content?: InputMaybe<ContentCreateNestedOneWithoutArticleInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  readBy?: InputMaybe<UserArticleCreateNestedManyWithoutArticleInput>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type?: InputMaybe<ResourceTypeCreateNestedOneWithoutArticlesInput>;
};

export type ArticleCreateWithoutContentInput = {
  articleCategories?: InputMaybe<ArticleCategoryCreateNestedManyWithoutArticleInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  readBy?: InputMaybe<UserArticleCreateNestedManyWithoutArticleInput>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type?: InputMaybe<ResourceTypeCreateNestedOneWithoutArticlesInput>;
};

export type ArticleCreateWithoutReadByInput = {
  articleCategories?: InputMaybe<ArticleCategoryCreateNestedManyWithoutArticleInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutArticleInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type?: InputMaybe<ResourceTypeCreateNestedOneWithoutArticlesInput>;
};

export type ArticleCreateWithoutTypeInput = {
  articleCategories?: InputMaybe<ArticleCategoryCreateNestedManyWithoutArticleInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutArticleInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  readBy?: InputMaybe<UserArticleCreateNestedManyWithoutArticleInput>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type ArticleGroupBy = {
  _count?: Maybe<ArticleCountAggregate>;
  _max?: Maybe<ArticleMaxAggregate>;
  _min?: Maybe<ArticleMinAggregate>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  resourceTypeId?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ArticleListRelationFilter = {
  every?: InputMaybe<ArticleWhereInput>;
  none?: InputMaybe<ArticleWhereInput>;
  some?: InputMaybe<ArticleWhereInput>;
};

export type ArticleMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  resourceTypeId?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ArticleMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceTypeId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type ArticleMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  resourceTypeId?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ArticleMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceTypeId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type ArticleNullableRelationFilter = {
  is?: InputMaybe<ArticleWhereInput>;
  isNot?: InputMaybe<ArticleWhereInput>;
};

export type ArticleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ArticleOrderByWithAggregationInput = {
  _count?: InputMaybe<ArticleCountOrderByAggregateInput>;
  _max?: InputMaybe<ArticleMaxOrderByAggregateInput>;
  _min?: InputMaybe<ArticleMinOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceTypeId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type ArticleOrderByWithRelationInput = {
  articleCategories?: InputMaybe<ArticleCategoryOrderByRelationAggregateInput>;
  content?: InputMaybe<ContentOrderByWithRelationInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  readBy?: InputMaybe<UserArticleOrderByRelationAggregateInput>;
  resourceTypeId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<ResourceTypeOrderByWithRelationInput>;
};

export type ArticleRelationFilter = {
  is?: InputMaybe<ArticleWhereInput>;
  isNot?: InputMaybe<ArticleWhereInput>;
};

export enum ArticleScalarFieldEnum {
  Created = 'created',
  Id = 'id',
  IsPublished = 'isPublished',
  Modified = 'modified',
  ResourceTypeId = 'resourceTypeId',
  Slug = 'slug',
  Title = 'title'
}

export type ArticleScalarWhereInput = {
  AND?: InputMaybe<Array<ArticleScalarWhereInput>>;
  NOT?: InputMaybe<Array<ArticleScalarWhereInput>>;
  OR?: InputMaybe<Array<ArticleScalarWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isPublished?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  resourceTypeId?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type ArticleScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ArticleScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ArticleScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ArticleScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPublished?: InputMaybe<BoolWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  resourceTypeId?: InputMaybe<StringNullableWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
};

export type ArticleUpdateInput = {
  articleCategories?: InputMaybe<ArticleCategoryUpdateManyWithoutArticleNestedInput>;
  content?: InputMaybe<ContentUpdateOneWithoutArticleNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  readBy?: InputMaybe<UserArticleUpdateManyWithoutArticleNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<ResourceTypeUpdateOneWithoutArticlesNestedInput>;
};

export type ArticleUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ArticleUpdateManyWithWhereWithoutTypeInput = {
  data: ArticleUpdateManyMutationInput;
  where: ArticleScalarWhereInput;
};

export type ArticleUpdateManyWithoutTypeNestedInput = {
  connect?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ArticleCreateOrConnectWithoutTypeInput>>;
  create?: InputMaybe<Array<ArticleCreateWithoutTypeInput>>;
  createMany?: InputMaybe<ArticleCreateManyTypeInputEnvelope>;
  delete?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ArticleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  set?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  update?: InputMaybe<Array<ArticleUpdateWithWhereUniqueWithoutTypeInput>>;
  updateMany?: InputMaybe<Array<ArticleUpdateManyWithWhereWithoutTypeInput>>;
  upsert?: InputMaybe<Array<ArticleUpsertWithWhereUniqueWithoutTypeInput>>;
};

export type ArticleUpdateOneRequiredWithoutArticleCategoriesNestedInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutArticleCategoriesInput>;
  create?: InputMaybe<ArticleCreateWithoutArticleCategoriesInput>;
  update?: InputMaybe<ArticleUpdateToOneWithWhereWithoutArticleCategoriesInput>;
  upsert?: InputMaybe<ArticleUpsertWithoutArticleCategoriesInput>;
};

export type ArticleUpdateOneRequiredWithoutReadByNestedInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutReadByInput>;
  create?: InputMaybe<ArticleCreateWithoutReadByInput>;
  update?: InputMaybe<ArticleUpdateToOneWithWhereWithoutReadByInput>;
  upsert?: InputMaybe<ArticleUpsertWithoutReadByInput>;
};

export type ArticleUpdateOneWithoutContentNestedInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutContentInput>;
  create?: InputMaybe<ArticleCreateWithoutContentInput>;
  delete?: InputMaybe<ArticleWhereInput>;
  disconnect?: InputMaybe<ArticleWhereInput>;
  update?: InputMaybe<ArticleUpdateToOneWithWhereWithoutContentInput>;
  upsert?: InputMaybe<ArticleUpsertWithoutContentInput>;
};

export type ArticleUpdateToOneWithWhereWithoutArticleCategoriesInput = {
  data: ArticleUpdateWithoutArticleCategoriesInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpdateToOneWithWhereWithoutContentInput = {
  data: ArticleUpdateWithoutContentInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpdateToOneWithWhereWithoutReadByInput = {
  data: ArticleUpdateWithoutReadByInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpdateWithWhereUniqueWithoutTypeInput = {
  data: ArticleUpdateWithoutTypeInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleUpdateWithoutArticleCategoriesInput = {
  content?: InputMaybe<ContentUpdateOneWithoutArticleNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  readBy?: InputMaybe<UserArticleUpdateManyWithoutArticleNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<ResourceTypeUpdateOneWithoutArticlesNestedInput>;
};

export type ArticleUpdateWithoutContentInput = {
  articleCategories?: InputMaybe<ArticleCategoryUpdateManyWithoutArticleNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  readBy?: InputMaybe<UserArticleUpdateManyWithoutArticleNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<ResourceTypeUpdateOneWithoutArticlesNestedInput>;
};

export type ArticleUpdateWithoutReadByInput = {
  articleCategories?: InputMaybe<ArticleCategoryUpdateManyWithoutArticleNestedInput>;
  content?: InputMaybe<ContentUpdateOneWithoutArticleNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<ResourceTypeUpdateOneWithoutArticlesNestedInput>;
};

export type ArticleUpdateWithoutTypeInput = {
  articleCategories?: InputMaybe<ArticleCategoryUpdateManyWithoutArticleNestedInput>;
  content?: InputMaybe<ContentUpdateOneWithoutArticleNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  readBy?: InputMaybe<UserArticleUpdateManyWithoutArticleNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ArticleUpsertWithWhereUniqueWithoutTypeInput = {
  create: ArticleCreateWithoutTypeInput;
  update: ArticleUpdateWithoutTypeInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleUpsertWithoutArticleCategoriesInput = {
  create: ArticleCreateWithoutArticleCategoriesInput;
  update: ArticleUpdateWithoutArticleCategoriesInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpsertWithoutContentInput = {
  create: ArticleCreateWithoutContentInput;
  update: ArticleUpdateWithoutContentInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpsertWithoutReadByInput = {
  create: ArticleCreateWithoutReadByInput;
  update: ArticleUpdateWithoutReadByInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleWhereInput = {
  AND?: InputMaybe<Array<ArticleWhereInput>>;
  NOT?: InputMaybe<Array<ArticleWhereInput>>;
  OR?: InputMaybe<Array<ArticleWhereInput>>;
  articleCategories?: InputMaybe<ArticleCategoryListRelationFilter>;
  content?: InputMaybe<ContentNullableRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isPublished?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  readBy?: InputMaybe<UserArticleListRelationFilter>;
  resourceTypeId?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<ResourceTypeNullableRelationFilter>;
};

export type ArticleWhereUniqueInput = {
  AND?: InputMaybe<Array<ArticleWhereInput>>;
  NOT?: InputMaybe<Array<ArticleWhereInput>>;
  OR?: InputMaybe<Array<ArticleWhereInput>>;
  articleCategories?: InputMaybe<ArticleCategoryListRelationFilter>;
  content?: InputMaybe<ContentNullableRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  readBy?: InputMaybe<UserArticleListRelationFilter>;
  resourceTypeId?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<ResourceTypeNullableRelationFilter>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export enum BrandingType {
  Bsb = 'BSB',
  DrPam = 'DR_PAM',
  Ndc = 'NDC',
  Psp = 'PSP',
  Sby = 'SBY'
}

export type Category = {
  _count?: Maybe<CategoryCount>;
  categoryArticles: Array<ArticleCategory>;
  categoryEvents: Array<EventCategory>;
  categoryQuizzes: Array<QuizCategory>;
  categoryResources: Array<ResourceCategory>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  name: Scalars['String']['output'];
  parentCategory?: Maybe<Category>;
  parentId?: Maybe<Scalars['String']['output']>;
  prerequisiteFor: Array<CategoryPrerequisite>;
  prerequisites: Array<CategoryPrerequisite>;
  programme: Programme;
  programmeId: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  subCategories: Array<Category>;
  userQuizzes: Array<UserQuiz>;
};


export type CategoryCategoryArticlesArgs = {
  cursor?: InputMaybe<ArticleCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArticleCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArticleCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleCategoryWhereInput>;
};


export type CategoryCategoryEventsArgs = {
  cursor?: InputMaybe<EventCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type CategoryCategoryQuizzesArgs = {
  cursor?: InputMaybe<QuizCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuizCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuizCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuizCategoryWhereInput>;
};


export type CategoryCategoryResourcesArgs = {
  cursor?: InputMaybe<ResourceCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResourceCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResourceCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceCategoryWhereInput>;
};


export type CategoryParentCategoryArgs = {
  where?: InputMaybe<CategoryWhereInput>;
};


export type CategoryPrerequisiteForArgs = {
  cursor?: InputMaybe<CategoryPrerequisiteWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryPrerequisiteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryPrerequisiteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryPrerequisiteWhereInput>;
};


export type CategoryPrerequisitesArgs = {
  cursor?: InputMaybe<CategoryPrerequisiteWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryPrerequisiteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryPrerequisiteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryPrerequisiteWhereInput>;
};


export type CategorySubCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type CategoryUserQuizzesArgs = {
  cursor?: InputMaybe<UserQuizWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserQuizScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserQuizOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserQuizWhereInput>;
};

export type CategoryAvgAggregate = {
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

export type CategoryAvgOrderByAggregateInput = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type CategoryCount = {
  categoryArticles: Scalars['Int']['output'];
  categoryEvents: Scalars['Int']['output'];
  categoryQuizzes: Scalars['Int']['output'];
  categoryResources: Scalars['Int']['output'];
  prerequisiteFor: Scalars['Int']['output'];
  prerequisites: Scalars['Int']['output'];
  subCategories: Scalars['Int']['output'];
  userQuizzes: Scalars['Int']['output'];
};


export type CategoryCountCategoryArticlesArgs = {
  where?: InputMaybe<ArticleCategoryWhereInput>;
};


export type CategoryCountCategoryEventsArgs = {
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type CategoryCountCategoryQuizzesArgs = {
  where?: InputMaybe<QuizCategoryWhereInput>;
};


export type CategoryCountCategoryResourcesArgs = {
  where?: InputMaybe<ResourceCategoryWhereInput>;
};


export type CategoryCountPrerequisiteForArgs = {
  where?: InputMaybe<CategoryPrerequisiteWhereInput>;
};


export type CategoryCountPrerequisitesArgs = {
  where?: InputMaybe<CategoryPrerequisiteWhereInput>;
};


export type CategoryCountSubCategoriesArgs = {
  where?: InputMaybe<CategoryWhereInput>;
};


export type CategoryCountUserQuizzesArgs = {
  where?: InputMaybe<UserQuizWhereInput>;
};

export type CategoryCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  parentId: Scalars['Int']['output'];
  programmeId: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type CategoryCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type CategoryCreateInput = {
  categoryArticles?: InputMaybe<ArticleCategoryCreateNestedManyWithoutCategoryInput>;
  categoryEvents?: InputMaybe<EventCategoryCreateNestedManyWithoutCategoryInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryCreateNestedManyWithoutCategoryInput>;
  categoryResources?: InputMaybe<ResourceCategoryCreateNestedManyWithoutCategoryInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutPrerequisiteInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutCategoryInput>;
  programme: ProgrammeCreateNestedOneWithoutCategoriesInput;
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
  userQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
  programmeId: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryCreateManyParentCategoryInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  programmeId: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryCreateManyParentCategoryInputEnvelope = {
  data: Array<CategoryCreateManyParentCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategoryCreateManyProgrammeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryCreateManyProgrammeInputEnvelope = {
  data: Array<CategoryCreateManyProgrammeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategoryCreateNestedManyWithoutParentCategoryInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutParentCategoryInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutParentCategoryInput>>;
  createMany?: InputMaybe<CategoryCreateManyParentCategoryInputEnvelope>;
};

export type CategoryCreateNestedManyWithoutProgrammeInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutProgrammeInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutProgrammeInput>>;
  createMany?: InputMaybe<CategoryCreateManyProgrammeInputEnvelope>;
};

export type CategoryCreateNestedOneWithoutCategoryArticlesInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutCategoryArticlesInput>;
  create?: InputMaybe<CategoryCreateWithoutCategoryArticlesInput>;
};

export type CategoryCreateNestedOneWithoutCategoryEventsInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutCategoryEventsInput>;
  create?: InputMaybe<CategoryCreateWithoutCategoryEventsInput>;
};

export type CategoryCreateNestedOneWithoutCategoryQuizzesInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutCategoryQuizzesInput>;
  create?: InputMaybe<CategoryCreateWithoutCategoryQuizzesInput>;
};

export type CategoryCreateNestedOneWithoutCategoryResourcesInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutCategoryResourcesInput>;
  create?: InputMaybe<CategoryCreateWithoutCategoryResourcesInput>;
};

export type CategoryCreateNestedOneWithoutPrerequisiteForInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutPrerequisiteForInput>;
  create?: InputMaybe<CategoryCreateWithoutPrerequisiteForInput>;
};

export type CategoryCreateNestedOneWithoutPrerequisitesInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutPrerequisitesInput>;
  create?: InputMaybe<CategoryCreateWithoutPrerequisitesInput>;
};

export type CategoryCreateNestedOneWithoutSubCategoriesInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutSubCategoriesInput>;
  create?: InputMaybe<CategoryCreateWithoutSubCategoriesInput>;
};

export type CategoryCreateNestedOneWithoutUserQuizzesInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutUserQuizzesInput>;
  create?: InputMaybe<CategoryCreateWithoutUserQuizzesInput>;
};

export type CategoryCreateOrConnectWithoutCategoryArticlesInput = {
  create: CategoryCreateWithoutCategoryArticlesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutCategoryEventsInput = {
  create: CategoryCreateWithoutCategoryEventsInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutCategoryQuizzesInput = {
  create: CategoryCreateWithoutCategoryQuizzesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutCategoryResourcesInput = {
  create: CategoryCreateWithoutCategoryResourcesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutParentCategoryInput = {
  create: CategoryCreateWithoutParentCategoryInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutPrerequisiteForInput = {
  create: CategoryCreateWithoutPrerequisiteForInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutPrerequisitesInput = {
  create: CategoryCreateWithoutPrerequisitesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutProgrammeInput = {
  create: CategoryCreateWithoutProgrammeInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutSubCategoriesInput = {
  create: CategoryCreateWithoutSubCategoriesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutUserQuizzesInput = {
  create: CategoryCreateWithoutUserQuizzesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateWithoutCategoryArticlesInput = {
  categoryEvents?: InputMaybe<EventCategoryCreateNestedManyWithoutCategoryInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryCreateNestedManyWithoutCategoryInput>;
  categoryResources?: InputMaybe<ResourceCategoryCreateNestedManyWithoutCategoryInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutPrerequisiteInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutCategoryInput>;
  programme: ProgrammeCreateNestedOneWithoutCategoriesInput;
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
  userQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateWithoutCategoryEventsInput = {
  categoryArticles?: InputMaybe<ArticleCategoryCreateNestedManyWithoutCategoryInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryCreateNestedManyWithoutCategoryInput>;
  categoryResources?: InputMaybe<ResourceCategoryCreateNestedManyWithoutCategoryInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutPrerequisiteInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutCategoryInput>;
  programme: ProgrammeCreateNestedOneWithoutCategoriesInput;
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
  userQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateWithoutCategoryQuizzesInput = {
  categoryArticles?: InputMaybe<ArticleCategoryCreateNestedManyWithoutCategoryInput>;
  categoryEvents?: InputMaybe<EventCategoryCreateNestedManyWithoutCategoryInput>;
  categoryResources?: InputMaybe<ResourceCategoryCreateNestedManyWithoutCategoryInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutPrerequisiteInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutCategoryInput>;
  programme: ProgrammeCreateNestedOneWithoutCategoriesInput;
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
  userQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateWithoutCategoryResourcesInput = {
  categoryArticles?: InputMaybe<ArticleCategoryCreateNestedManyWithoutCategoryInput>;
  categoryEvents?: InputMaybe<EventCategoryCreateNestedManyWithoutCategoryInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryCreateNestedManyWithoutCategoryInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutPrerequisiteInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutCategoryInput>;
  programme: ProgrammeCreateNestedOneWithoutCategoriesInput;
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
  userQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateWithoutParentCategoryInput = {
  categoryArticles?: InputMaybe<ArticleCategoryCreateNestedManyWithoutCategoryInput>;
  categoryEvents?: InputMaybe<EventCategoryCreateNestedManyWithoutCategoryInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryCreateNestedManyWithoutCategoryInput>;
  categoryResources?: InputMaybe<ResourceCategoryCreateNestedManyWithoutCategoryInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutPrerequisiteInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutCategoryInput>;
  programme: ProgrammeCreateNestedOneWithoutCategoriesInput;
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
  userQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateWithoutPrerequisiteForInput = {
  categoryArticles?: InputMaybe<ArticleCategoryCreateNestedManyWithoutCategoryInput>;
  categoryEvents?: InputMaybe<EventCategoryCreateNestedManyWithoutCategoryInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryCreateNestedManyWithoutCategoryInput>;
  categoryResources?: InputMaybe<ResourceCategoryCreateNestedManyWithoutCategoryInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutCategoryInput>;
  programme: ProgrammeCreateNestedOneWithoutCategoriesInput;
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
  userQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateWithoutPrerequisitesInput = {
  categoryArticles?: InputMaybe<ArticleCategoryCreateNestedManyWithoutCategoryInput>;
  categoryEvents?: InputMaybe<EventCategoryCreateNestedManyWithoutCategoryInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryCreateNestedManyWithoutCategoryInput>;
  categoryResources?: InputMaybe<ResourceCategoryCreateNestedManyWithoutCategoryInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutPrerequisiteInput>;
  programme: ProgrammeCreateNestedOneWithoutCategoriesInput;
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
  userQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateWithoutProgrammeInput = {
  categoryArticles?: InputMaybe<ArticleCategoryCreateNestedManyWithoutCategoryInput>;
  categoryEvents?: InputMaybe<EventCategoryCreateNestedManyWithoutCategoryInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryCreateNestedManyWithoutCategoryInput>;
  categoryResources?: InputMaybe<ResourceCategoryCreateNestedManyWithoutCategoryInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutPrerequisiteInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutCategoryInput>;
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
  userQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateWithoutSubCategoriesInput = {
  categoryArticles?: InputMaybe<ArticleCategoryCreateNestedManyWithoutCategoryInput>;
  categoryEvents?: InputMaybe<EventCategoryCreateNestedManyWithoutCategoryInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryCreateNestedManyWithoutCategoryInput>;
  categoryResources?: InputMaybe<ResourceCategoryCreateNestedManyWithoutCategoryInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutPrerequisiteInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutCategoryInput>;
  programme: ProgrammeCreateNestedOneWithoutCategoriesInput;
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  userQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateWithoutUserQuizzesInput = {
  categoryArticles?: InputMaybe<ArticleCategoryCreateNestedManyWithoutCategoryInput>;
  categoryEvents?: InputMaybe<EventCategoryCreateNestedManyWithoutCategoryInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryCreateNestedManyWithoutCategoryInput>;
  categoryResources?: InputMaybe<ResourceCategoryCreateNestedManyWithoutCategoryInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutPrerequisiteInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteCreateNestedManyWithoutCategoryInput>;
  programme: ProgrammeCreateNestedOneWithoutCategoriesInput;
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
};

export type CategoryGroupBy = {
  _avg?: Maybe<CategoryAvgAggregate>;
  _count?: Maybe<CategoryCountAggregate>;
  _max?: Maybe<CategoryMaxAggregate>;
  _min?: Maybe<CategoryMinAggregate>;
  _sum?: Maybe<CategorySumAggregate>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  programmeId: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type CategoryListRelationFilter = {
  every?: InputMaybe<CategoryWhereInput>;
  none?: InputMaybe<CategoryWhereInput>;
  some?: InputMaybe<CategoryWhereInput>;
};

export type CategoryMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  programmeId?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type CategoryMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type CategoryMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  programmeId?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type CategoryMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type CategoryNullableRelationFilter = {
  is?: InputMaybe<CategoryWhereInput>;
  isNot?: InputMaybe<CategoryWhereInput>;
};

export type CategoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CategoryOrderByWithAggregationInput = {
  _avg?: InputMaybe<CategoryAvgOrderByAggregateInput>;
  _count?: InputMaybe<CategoryCountOrderByAggregateInput>;
  _max?: InputMaybe<CategoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<CategoryMinOrderByAggregateInput>;
  _sum?: InputMaybe<CategorySumOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrderInput>;
  programmeId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type CategoryOrderByWithRelationInput = {
  categoryArticles?: InputMaybe<ArticleCategoryOrderByRelationAggregateInput>;
  categoryEvents?: InputMaybe<EventCategoryOrderByRelationAggregateInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryOrderByRelationAggregateInput>;
  categoryResources?: InputMaybe<ResourceCategoryOrderByRelationAggregateInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentCategory?: InputMaybe<CategoryOrderByWithRelationInput>;
  parentId?: InputMaybe<SortOrderInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteOrderByRelationAggregateInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteOrderByRelationAggregateInput>;
  programme?: InputMaybe<ProgrammeOrderByWithRelationInput>;
  programmeId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
  subCategories?: InputMaybe<CategoryOrderByRelationAggregateInput>;
  userQuizzes?: InputMaybe<UserQuizOrderByRelationAggregateInput>;
};

export type CategoryPrerequisite = {
  category: Category;
  categoryId: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  prerequisite: Category;
  prerequisiteId: Scalars['String']['output'];
};

export type CategoryPrerequisiteCategoryPrerequisiteCompoundUniqueInput = {
  categoryId: Scalars['String']['input'];
  prerequisiteId: Scalars['String']['input'];
};

export type CategoryPrerequisiteCountAggregate = {
  _all: Scalars['Int']['output'];
  categoryId: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  prerequisiteId: Scalars['Int']['output'];
};

export type CategoryPrerequisiteCountOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  prerequisiteId?: InputMaybe<SortOrder>;
};

export type CategoryPrerequisiteCreateInput = {
  category: CategoryCreateNestedOneWithoutPrerequisitesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  prerequisite: CategoryCreateNestedOneWithoutPrerequisiteForInput;
};

export type CategoryPrerequisiteCreateManyCategoryInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  prerequisiteId: Scalars['String']['input'];
};

export type CategoryPrerequisiteCreateManyCategoryInputEnvelope = {
  data: Array<CategoryPrerequisiteCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategoryPrerequisiteCreateManyInput = {
  categoryId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  prerequisiteId: Scalars['String']['input'];
};

export type CategoryPrerequisiteCreateManyPrerequisiteInput = {
  categoryId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CategoryPrerequisiteCreateManyPrerequisiteInputEnvelope = {
  data: Array<CategoryPrerequisiteCreateManyPrerequisiteInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategoryPrerequisiteCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<CategoryPrerequisiteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryPrerequisiteCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<CategoryPrerequisiteCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<CategoryPrerequisiteCreateManyCategoryInputEnvelope>;
};

export type CategoryPrerequisiteCreateNestedManyWithoutPrerequisiteInput = {
  connect?: InputMaybe<Array<CategoryPrerequisiteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryPrerequisiteCreateOrConnectWithoutPrerequisiteInput>>;
  create?: InputMaybe<Array<CategoryPrerequisiteCreateWithoutPrerequisiteInput>>;
  createMany?: InputMaybe<CategoryPrerequisiteCreateManyPrerequisiteInputEnvelope>;
};

export type CategoryPrerequisiteCreateOrConnectWithoutCategoryInput = {
  create: CategoryPrerequisiteCreateWithoutCategoryInput;
  where: CategoryPrerequisiteWhereUniqueInput;
};

export type CategoryPrerequisiteCreateOrConnectWithoutPrerequisiteInput = {
  create: CategoryPrerequisiteCreateWithoutPrerequisiteInput;
  where: CategoryPrerequisiteWhereUniqueInput;
};

export type CategoryPrerequisiteCreateWithoutCategoryInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  prerequisite: CategoryCreateNestedOneWithoutPrerequisiteForInput;
};

export type CategoryPrerequisiteCreateWithoutPrerequisiteInput = {
  category: CategoryCreateNestedOneWithoutPrerequisitesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CategoryPrerequisiteGroupBy = {
  _count?: Maybe<CategoryPrerequisiteCountAggregate>;
  _max?: Maybe<CategoryPrerequisiteMaxAggregate>;
  _min?: Maybe<CategoryPrerequisiteMinAggregate>;
  categoryId: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  prerequisiteId: Scalars['String']['output'];
};

export type CategoryPrerequisiteListRelationFilter = {
  every?: InputMaybe<CategoryPrerequisiteWhereInput>;
  none?: InputMaybe<CategoryPrerequisiteWhereInput>;
  some?: InputMaybe<CategoryPrerequisiteWhereInput>;
};

export type CategoryPrerequisiteMaxAggregate = {
  categoryId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  prerequisiteId?: Maybe<Scalars['String']['output']>;
};

export type CategoryPrerequisiteMaxOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  prerequisiteId?: InputMaybe<SortOrder>;
};

export type CategoryPrerequisiteMinAggregate = {
  categoryId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  prerequisiteId?: Maybe<Scalars['String']['output']>;
};

export type CategoryPrerequisiteMinOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  prerequisiteId?: InputMaybe<SortOrder>;
};

export type CategoryPrerequisiteOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CategoryPrerequisiteOrderByWithAggregationInput = {
  _count?: InputMaybe<CategoryPrerequisiteCountOrderByAggregateInput>;
  _max?: InputMaybe<CategoryPrerequisiteMaxOrderByAggregateInput>;
  _min?: InputMaybe<CategoryPrerequisiteMinOrderByAggregateInput>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  prerequisiteId?: InputMaybe<SortOrder>;
};

export type CategoryPrerequisiteOrderByWithRelationInput = {
  category?: InputMaybe<CategoryOrderByWithRelationInput>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  prerequisite?: InputMaybe<CategoryOrderByWithRelationInput>;
  prerequisiteId?: InputMaybe<SortOrder>;
};

export enum CategoryPrerequisiteScalarFieldEnum {
  CategoryId = 'categoryId',
  Created = 'created',
  Id = 'id',
  Modified = 'modified',
  PrerequisiteId = 'prerequisiteId'
}

export type CategoryPrerequisiteScalarWhereInput = {
  AND?: InputMaybe<Array<CategoryPrerequisiteScalarWhereInput>>;
  NOT?: InputMaybe<Array<CategoryPrerequisiteScalarWhereInput>>;
  OR?: InputMaybe<Array<CategoryPrerequisiteScalarWhereInput>>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  prerequisiteId?: InputMaybe<StringFilter>;
};

export type CategoryPrerequisiteScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CategoryPrerequisiteScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CategoryPrerequisiteScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CategoryPrerequisiteScalarWhereWithAggregatesInput>>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  prerequisiteId?: InputMaybe<StringWithAggregatesFilter>;
};

export type CategoryPrerequisiteUpdateInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutPrerequisitesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  prerequisite?: InputMaybe<CategoryUpdateOneRequiredWithoutPrerequisiteForNestedInput>;
};

export type CategoryPrerequisiteUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryPrerequisiteUpdateManyWithWhereWithoutCategoryInput = {
  data: CategoryPrerequisiteUpdateManyMutationInput;
  where: CategoryPrerequisiteScalarWhereInput;
};

export type CategoryPrerequisiteUpdateManyWithWhereWithoutPrerequisiteInput = {
  data: CategoryPrerequisiteUpdateManyMutationInput;
  where: CategoryPrerequisiteScalarWhereInput;
};

export type CategoryPrerequisiteUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<CategoryPrerequisiteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryPrerequisiteCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<CategoryPrerequisiteCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<CategoryPrerequisiteCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<CategoryPrerequisiteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategoryPrerequisiteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategoryPrerequisiteWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoryPrerequisiteWhereUniqueInput>>;
  update?: InputMaybe<Array<CategoryPrerequisiteUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<CategoryPrerequisiteUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<CategoryPrerequisiteUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type CategoryPrerequisiteUpdateManyWithoutPrerequisiteNestedInput = {
  connect?: InputMaybe<Array<CategoryPrerequisiteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryPrerequisiteCreateOrConnectWithoutPrerequisiteInput>>;
  create?: InputMaybe<Array<CategoryPrerequisiteCreateWithoutPrerequisiteInput>>;
  createMany?: InputMaybe<CategoryPrerequisiteCreateManyPrerequisiteInputEnvelope>;
  delete?: InputMaybe<Array<CategoryPrerequisiteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategoryPrerequisiteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategoryPrerequisiteWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoryPrerequisiteWhereUniqueInput>>;
  update?: InputMaybe<Array<CategoryPrerequisiteUpdateWithWhereUniqueWithoutPrerequisiteInput>>;
  updateMany?: InputMaybe<Array<CategoryPrerequisiteUpdateManyWithWhereWithoutPrerequisiteInput>>;
  upsert?: InputMaybe<Array<CategoryPrerequisiteUpsertWithWhereUniqueWithoutPrerequisiteInput>>;
};

export type CategoryPrerequisiteUpdateWithWhereUniqueWithoutCategoryInput = {
  data: CategoryPrerequisiteUpdateWithoutCategoryInput;
  where: CategoryPrerequisiteWhereUniqueInput;
};

export type CategoryPrerequisiteUpdateWithWhereUniqueWithoutPrerequisiteInput = {
  data: CategoryPrerequisiteUpdateWithoutPrerequisiteInput;
  where: CategoryPrerequisiteWhereUniqueInput;
};

export type CategoryPrerequisiteUpdateWithoutCategoryInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  prerequisite?: InputMaybe<CategoryUpdateOneRequiredWithoutPrerequisiteForNestedInput>;
};

export type CategoryPrerequisiteUpdateWithoutPrerequisiteInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutPrerequisitesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryPrerequisiteUpsertWithWhereUniqueWithoutCategoryInput = {
  create: CategoryPrerequisiteCreateWithoutCategoryInput;
  update: CategoryPrerequisiteUpdateWithoutCategoryInput;
  where: CategoryPrerequisiteWhereUniqueInput;
};

export type CategoryPrerequisiteUpsertWithWhereUniqueWithoutPrerequisiteInput = {
  create: CategoryPrerequisiteCreateWithoutPrerequisiteInput;
  update: CategoryPrerequisiteUpdateWithoutPrerequisiteInput;
  where: CategoryPrerequisiteWhereUniqueInput;
};

export type CategoryPrerequisiteWhereInput = {
  AND?: InputMaybe<Array<CategoryPrerequisiteWhereInput>>;
  NOT?: InputMaybe<Array<CategoryPrerequisiteWhereInput>>;
  OR?: InputMaybe<Array<CategoryPrerequisiteWhereInput>>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  prerequisite?: InputMaybe<CategoryRelationFilter>;
  prerequisiteId?: InputMaybe<StringFilter>;
};

export type CategoryPrerequisiteWhereUniqueInput = {
  AND?: InputMaybe<Array<CategoryPrerequisiteWhereInput>>;
  NOT?: InputMaybe<Array<CategoryPrerequisiteWhereInput>>;
  OR?: InputMaybe<Array<CategoryPrerequisiteWhereInput>>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  categoryPrerequisite?: InputMaybe<CategoryPrerequisiteCategoryPrerequisiteCompoundUniqueInput>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  prerequisite?: InputMaybe<CategoryRelationFilter>;
  prerequisiteId?: InputMaybe<StringFilter>;
};

export type CategoryProductCategorySlugCompoundUniqueInput = {
  parentId: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type CategoryRelationFilter = {
  is?: InputMaybe<CategoryWhereInput>;
  isNot?: InputMaybe<CategoryWhereInput>;
};

export enum CategoryScalarFieldEnum {
  Created = 'created',
  Id = 'id',
  Modified = 'modified',
  Name = 'name',
  ParentId = 'parentId',
  ProgrammeId = 'programmeId',
  Slug = 'slug',
  SortOrder = 'sortOrder'
}

export type CategoryScalarWhereInput = {
  AND?: InputMaybe<Array<CategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<CategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<CategoryScalarWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  parentId?: InputMaybe<StringNullableFilter>;
  programmeId?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  sortOrder?: InputMaybe<IntFilter>;
};

export type CategoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  parentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  programmeId?: InputMaybe<StringWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  sortOrder?: InputMaybe<IntWithAggregatesFilter>;
};

export type CategorySumAggregate = {
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type CategorySumOrderByAggregateInput = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type CategoryUpdateInput = {
  categoryArticles?: InputMaybe<ArticleCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryEvents?: InputMaybe<EventCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryResources?: InputMaybe<ResourceCategoryUpdateManyWithoutCategoryNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutPrerequisiteNestedInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutCategoryNestedInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
  userQuizzes?: InputMaybe<UserQuizUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CategoryUpdateManyWithWhereWithoutParentCategoryInput = {
  data: CategoryUpdateManyMutationInput;
  where: CategoryScalarWhereInput;
};

export type CategoryUpdateManyWithWhereWithoutProgrammeInput = {
  data: CategoryUpdateManyMutationInput;
  where: CategoryScalarWhereInput;
};

export type CategoryUpdateManyWithoutParentCategoryNestedInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutParentCategoryInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutParentCategoryInput>>;
  createMany?: InputMaybe<CategoryCreateManyParentCategoryInputEnvelope>;
  delete?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<CategoryUpdateWithWhereUniqueWithoutParentCategoryInput>>;
  updateMany?: InputMaybe<Array<CategoryUpdateManyWithWhereWithoutParentCategoryInput>>;
  upsert?: InputMaybe<Array<CategoryUpsertWithWhereUniqueWithoutParentCategoryInput>>;
};

export type CategoryUpdateManyWithoutProgrammeNestedInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutProgrammeInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutProgrammeInput>>;
  createMany?: InputMaybe<CategoryCreateManyProgrammeInputEnvelope>;
  delete?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<CategoryUpdateWithWhereUniqueWithoutProgrammeInput>>;
  updateMany?: InputMaybe<Array<CategoryUpdateManyWithWhereWithoutProgrammeInput>>;
  upsert?: InputMaybe<Array<CategoryUpsertWithWhereUniqueWithoutProgrammeInput>>;
};

export type CategoryUpdateOneRequiredWithoutCategoryArticlesNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutCategoryArticlesInput>;
  create?: InputMaybe<CategoryCreateWithoutCategoryArticlesInput>;
  update?: InputMaybe<CategoryUpdateToOneWithWhereWithoutCategoryArticlesInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutCategoryArticlesInput>;
};

export type CategoryUpdateOneRequiredWithoutCategoryEventsNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutCategoryEventsInput>;
  create?: InputMaybe<CategoryCreateWithoutCategoryEventsInput>;
  update?: InputMaybe<CategoryUpdateToOneWithWhereWithoutCategoryEventsInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutCategoryEventsInput>;
};

export type CategoryUpdateOneRequiredWithoutCategoryQuizzesNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutCategoryQuizzesInput>;
  create?: InputMaybe<CategoryCreateWithoutCategoryQuizzesInput>;
  update?: InputMaybe<CategoryUpdateToOneWithWhereWithoutCategoryQuizzesInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutCategoryQuizzesInput>;
};

export type CategoryUpdateOneRequiredWithoutCategoryResourcesNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutCategoryResourcesInput>;
  create?: InputMaybe<CategoryCreateWithoutCategoryResourcesInput>;
  update?: InputMaybe<CategoryUpdateToOneWithWhereWithoutCategoryResourcesInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutCategoryResourcesInput>;
};

export type CategoryUpdateOneRequiredWithoutPrerequisiteForNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutPrerequisiteForInput>;
  create?: InputMaybe<CategoryCreateWithoutPrerequisiteForInput>;
  update?: InputMaybe<CategoryUpdateToOneWithWhereWithoutPrerequisiteForInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutPrerequisiteForInput>;
};

export type CategoryUpdateOneRequiredWithoutPrerequisitesNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutPrerequisitesInput>;
  create?: InputMaybe<CategoryCreateWithoutPrerequisitesInput>;
  update?: InputMaybe<CategoryUpdateToOneWithWhereWithoutPrerequisitesInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutPrerequisitesInput>;
};

export type CategoryUpdateOneRequiredWithoutUserQuizzesNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutUserQuizzesInput>;
  create?: InputMaybe<CategoryCreateWithoutUserQuizzesInput>;
  update?: InputMaybe<CategoryUpdateToOneWithWhereWithoutUserQuizzesInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutUserQuizzesInput>;
};

export type CategoryUpdateOneWithoutSubCategoriesNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutSubCategoriesInput>;
  create?: InputMaybe<CategoryCreateWithoutSubCategoriesInput>;
  delete?: InputMaybe<CategoryWhereInput>;
  disconnect?: InputMaybe<CategoryWhereInput>;
  update?: InputMaybe<CategoryUpdateToOneWithWhereWithoutSubCategoriesInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutSubCategoriesInput>;
};

export type CategoryUpdateToOneWithWhereWithoutCategoryArticlesInput = {
  data: CategoryUpdateWithoutCategoryArticlesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpdateToOneWithWhereWithoutCategoryEventsInput = {
  data: CategoryUpdateWithoutCategoryEventsInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpdateToOneWithWhereWithoutCategoryQuizzesInput = {
  data: CategoryUpdateWithoutCategoryQuizzesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpdateToOneWithWhereWithoutCategoryResourcesInput = {
  data: CategoryUpdateWithoutCategoryResourcesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpdateToOneWithWhereWithoutPrerequisiteForInput = {
  data: CategoryUpdateWithoutPrerequisiteForInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpdateToOneWithWhereWithoutPrerequisitesInput = {
  data: CategoryUpdateWithoutPrerequisitesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpdateToOneWithWhereWithoutSubCategoriesInput = {
  data: CategoryUpdateWithoutSubCategoriesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpdateToOneWithWhereWithoutUserQuizzesInput = {
  data: CategoryUpdateWithoutUserQuizzesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpdateWithWhereUniqueWithoutParentCategoryInput = {
  data: CategoryUpdateWithoutParentCategoryInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpdateWithWhereUniqueWithoutProgrammeInput = {
  data: CategoryUpdateWithoutProgrammeInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpdateWithoutCategoryArticlesInput = {
  categoryEvents?: InputMaybe<EventCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryResources?: InputMaybe<ResourceCategoryUpdateManyWithoutCategoryNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutPrerequisiteNestedInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutCategoryNestedInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
  userQuizzes?: InputMaybe<UserQuizUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpdateWithoutCategoryEventsInput = {
  categoryArticles?: InputMaybe<ArticleCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryResources?: InputMaybe<ResourceCategoryUpdateManyWithoutCategoryNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutPrerequisiteNestedInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutCategoryNestedInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
  userQuizzes?: InputMaybe<UserQuizUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpdateWithoutCategoryQuizzesInput = {
  categoryArticles?: InputMaybe<ArticleCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryEvents?: InputMaybe<EventCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryResources?: InputMaybe<ResourceCategoryUpdateManyWithoutCategoryNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutPrerequisiteNestedInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutCategoryNestedInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
  userQuizzes?: InputMaybe<UserQuizUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpdateWithoutCategoryResourcesInput = {
  categoryArticles?: InputMaybe<ArticleCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryEvents?: InputMaybe<EventCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryUpdateManyWithoutCategoryNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutPrerequisiteNestedInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutCategoryNestedInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
  userQuizzes?: InputMaybe<UserQuizUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpdateWithoutParentCategoryInput = {
  categoryArticles?: InputMaybe<ArticleCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryEvents?: InputMaybe<EventCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryResources?: InputMaybe<ResourceCategoryUpdateManyWithoutCategoryNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutPrerequisiteNestedInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutCategoryNestedInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
  userQuizzes?: InputMaybe<UserQuizUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpdateWithoutPrerequisiteForInput = {
  categoryArticles?: InputMaybe<ArticleCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryEvents?: InputMaybe<EventCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryResources?: InputMaybe<ResourceCategoryUpdateManyWithoutCategoryNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutCategoryNestedInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
  userQuizzes?: InputMaybe<UserQuizUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpdateWithoutPrerequisitesInput = {
  categoryArticles?: InputMaybe<ArticleCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryEvents?: InputMaybe<EventCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryResources?: InputMaybe<ResourceCategoryUpdateManyWithoutCategoryNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutPrerequisiteNestedInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
  userQuizzes?: InputMaybe<UserQuizUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpdateWithoutProgrammeInput = {
  categoryArticles?: InputMaybe<ArticleCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryEvents?: InputMaybe<EventCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryResources?: InputMaybe<ResourceCategoryUpdateManyWithoutCategoryNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutPrerequisiteNestedInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutCategoryNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
  userQuizzes?: InputMaybe<UserQuizUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpdateWithoutSubCategoriesInput = {
  categoryArticles?: InputMaybe<ArticleCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryEvents?: InputMaybe<EventCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryResources?: InputMaybe<ResourceCategoryUpdateManyWithoutCategoryNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutPrerequisiteNestedInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutCategoryNestedInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  userQuizzes?: InputMaybe<UserQuizUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpdateWithoutUserQuizzesInput = {
  categoryArticles?: InputMaybe<ArticleCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryEvents?: InputMaybe<EventCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryQuizzes?: InputMaybe<QuizCategoryUpdateManyWithoutCategoryNestedInput>;
  categoryResources?: InputMaybe<ResourceCategoryUpdateManyWithoutCategoryNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutPrerequisiteNestedInput>;
  prerequisites?: InputMaybe<CategoryPrerequisiteUpdateManyWithoutCategoryNestedInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
};

export type CategoryUpsertWithWhereUniqueWithoutParentCategoryInput = {
  create: CategoryCreateWithoutParentCategoryInput;
  update: CategoryUpdateWithoutParentCategoryInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpsertWithWhereUniqueWithoutProgrammeInput = {
  create: CategoryCreateWithoutProgrammeInput;
  update: CategoryUpdateWithoutProgrammeInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpsertWithoutCategoryArticlesInput = {
  create: CategoryCreateWithoutCategoryArticlesInput;
  update: CategoryUpdateWithoutCategoryArticlesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpsertWithoutCategoryEventsInput = {
  create: CategoryCreateWithoutCategoryEventsInput;
  update: CategoryUpdateWithoutCategoryEventsInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpsertWithoutCategoryQuizzesInput = {
  create: CategoryCreateWithoutCategoryQuizzesInput;
  update: CategoryUpdateWithoutCategoryQuizzesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpsertWithoutCategoryResourcesInput = {
  create: CategoryCreateWithoutCategoryResourcesInput;
  update: CategoryUpdateWithoutCategoryResourcesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpsertWithoutPrerequisiteForInput = {
  create: CategoryCreateWithoutPrerequisiteForInput;
  update: CategoryUpdateWithoutPrerequisiteForInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpsertWithoutPrerequisitesInput = {
  create: CategoryCreateWithoutPrerequisitesInput;
  update: CategoryUpdateWithoutPrerequisitesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpsertWithoutSubCategoriesInput = {
  create: CategoryCreateWithoutSubCategoriesInput;
  update: CategoryUpdateWithoutSubCategoriesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpsertWithoutUserQuizzesInput = {
  create: CategoryCreateWithoutUserQuizzesInput;
  update: CategoryUpdateWithoutUserQuizzesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryWhereInput = {
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  NOT?: InputMaybe<Array<CategoryWhereInput>>;
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  categoryArticles?: InputMaybe<ArticleCategoryListRelationFilter>;
  categoryEvents?: InputMaybe<EventCategoryListRelationFilter>;
  categoryQuizzes?: InputMaybe<QuizCategoryListRelationFilter>;
  categoryResources?: InputMaybe<ResourceCategoryListRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  parentCategory?: InputMaybe<CategoryNullableRelationFilter>;
  parentId?: InputMaybe<StringNullableFilter>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteListRelationFilter>;
  prerequisites?: InputMaybe<CategoryPrerequisiteListRelationFilter>;
  programme?: InputMaybe<ProgrammeRelationFilter>;
  programmeId?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  sortOrder?: InputMaybe<IntFilter>;
  subCategories?: InputMaybe<CategoryListRelationFilter>;
  userQuizzes?: InputMaybe<UserQuizListRelationFilter>;
};

export type CategoryWhereUniqueInput = {
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  NOT?: InputMaybe<Array<CategoryWhereInput>>;
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  categoryArticles?: InputMaybe<ArticleCategoryListRelationFilter>;
  categoryEvents?: InputMaybe<EventCategoryListRelationFilter>;
  categoryQuizzes?: InputMaybe<QuizCategoryListRelationFilter>;
  categoryResources?: InputMaybe<ResourceCategoryListRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  parentCategory?: InputMaybe<CategoryNullableRelationFilter>;
  parentId?: InputMaybe<StringNullableFilter>;
  prerequisiteFor?: InputMaybe<CategoryPrerequisiteListRelationFilter>;
  prerequisites?: InputMaybe<CategoryPrerequisiteListRelationFilter>;
  productCategorySlug?: InputMaybe<CategoryProductCategorySlugCompoundUniqueInput>;
  programme?: InputMaybe<ProgrammeRelationFilter>;
  programmeId?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  sortOrder?: InputMaybe<IntFilter>;
  subCategories?: InputMaybe<CategoryListRelationFilter>;
  userQuizzes?: InputMaybe<UserQuizListRelationFilter>;
};

export type Content = {
  article?: Maybe<Article>;
  articleId?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  markdown: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  publishedFrontmatter?: Maybe<Scalars['JSON']['output']>;
  publishedHtml?: Maybe<Scalars['String']['output']>;
  publishedMarkdown?: Maybe<Scalars['String']['output']>;
  quiz?: Maybe<Quiz>;
  quizId?: Maybe<Scalars['String']['output']>;
};


export type ContentArticleArgs = {
  where?: InputMaybe<ArticleWhereInput>;
};


export type ContentQuizArgs = {
  where?: InputMaybe<QuizWhereInput>;
};

export type ContentCountAggregate = {
  _all: Scalars['Int']['output'];
  articleId: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  markdown: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  publishedFrontmatter: Scalars['Int']['output'];
  publishedHtml: Scalars['Int']['output'];
  publishedMarkdown: Scalars['Int']['output'];
  quizId: Scalars['Int']['output'];
};

export type ContentCountOrderByAggregateInput = {
  articleId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  markdown?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  publishedFrontmatter?: InputMaybe<SortOrder>;
  publishedHtml?: InputMaybe<SortOrder>;
  publishedMarkdown?: InputMaybe<SortOrder>;
  quizId?: InputMaybe<SortOrder>;
};

export type ContentCreateInput = {
  article?: InputMaybe<ArticleCreateNestedOneWithoutContentInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  markdown: Scalars['String']['input'];
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  publishedFrontmatter?: InputMaybe<Scalars['JSON']['input']>;
  publishedHtml?: InputMaybe<Scalars['String']['input']>;
  publishedMarkdown?: InputMaybe<Scalars['String']['input']>;
  quiz?: InputMaybe<QuizCreateNestedOneWithoutContentInput>;
};

export type ContentCreateManyInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  markdown: Scalars['String']['input'];
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  publishedFrontmatter?: InputMaybe<Scalars['JSON']['input']>;
  publishedHtml?: InputMaybe<Scalars['String']['input']>;
  publishedMarkdown?: InputMaybe<Scalars['String']['input']>;
  quizId?: InputMaybe<Scalars['String']['input']>;
};

export type ContentCreateNestedOneWithoutArticleInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutArticleInput>;
  create?: InputMaybe<ContentCreateWithoutArticleInput>;
};

export type ContentCreateNestedOneWithoutQuizInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutQuizInput>;
  create?: InputMaybe<ContentCreateWithoutQuizInput>;
};

export type ContentCreateOrConnectWithoutArticleInput = {
  create: ContentCreateWithoutArticleInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateOrConnectWithoutQuizInput = {
  create: ContentCreateWithoutQuizInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateWithoutArticleInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  markdown: Scalars['String']['input'];
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  publishedFrontmatter?: InputMaybe<Scalars['JSON']['input']>;
  publishedHtml?: InputMaybe<Scalars['String']['input']>;
  publishedMarkdown?: InputMaybe<Scalars['String']['input']>;
  quiz?: InputMaybe<QuizCreateNestedOneWithoutContentInput>;
};

export type ContentCreateWithoutQuizInput = {
  article?: InputMaybe<ArticleCreateNestedOneWithoutContentInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  markdown: Scalars['String']['input'];
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  publishedFrontmatter?: InputMaybe<Scalars['JSON']['input']>;
  publishedHtml?: InputMaybe<Scalars['String']['input']>;
  publishedMarkdown?: InputMaybe<Scalars['String']['input']>;
};

export type ContentGroupBy = {
  _count?: Maybe<ContentCountAggregate>;
  _max?: Maybe<ContentMaxAggregate>;
  _min?: Maybe<ContentMinAggregate>;
  articleId?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  markdown: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  publishedFrontmatter?: Maybe<Scalars['JSON']['output']>;
  publishedHtml?: Maybe<Scalars['String']['output']>;
  publishedMarkdown?: Maybe<Scalars['String']['output']>;
  quizId?: Maybe<Scalars['String']['output']>;
};

export type ContentMaxAggregate = {
  articleId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  markdown?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  publishedHtml?: Maybe<Scalars['String']['output']>;
  publishedMarkdown?: Maybe<Scalars['String']['output']>;
  quizId?: Maybe<Scalars['String']['output']>;
};

export type ContentMaxOrderByAggregateInput = {
  articleId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  markdown?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  publishedHtml?: InputMaybe<SortOrder>;
  publishedMarkdown?: InputMaybe<SortOrder>;
  quizId?: InputMaybe<SortOrder>;
};

export type ContentMinAggregate = {
  articleId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  markdown?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  publishedHtml?: Maybe<Scalars['String']['output']>;
  publishedMarkdown?: Maybe<Scalars['String']['output']>;
  quizId?: Maybe<Scalars['String']['output']>;
};

export type ContentMinOrderByAggregateInput = {
  articleId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  markdown?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  publishedHtml?: InputMaybe<SortOrder>;
  publishedMarkdown?: InputMaybe<SortOrder>;
  quizId?: InputMaybe<SortOrder>;
};

export type ContentNullableRelationFilter = {
  is?: InputMaybe<ContentWhereInput>;
  isNot?: InputMaybe<ContentWhereInput>;
};

export type ContentOrderByWithAggregationInput = {
  _count?: InputMaybe<ContentCountOrderByAggregateInput>;
  _max?: InputMaybe<ContentMaxOrderByAggregateInput>;
  _min?: InputMaybe<ContentMinOrderByAggregateInput>;
  articleId?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  markdown?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  publishedFrontmatter?: InputMaybe<SortOrderInput>;
  publishedHtml?: InputMaybe<SortOrderInput>;
  publishedMarkdown?: InputMaybe<SortOrderInput>;
  quizId?: InputMaybe<SortOrderInput>;
};

export type ContentOrderByWithRelationInput = {
  article?: InputMaybe<ArticleOrderByWithRelationInput>;
  articleId?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  markdown?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  publishedFrontmatter?: InputMaybe<SortOrderInput>;
  publishedHtml?: InputMaybe<SortOrderInput>;
  publishedMarkdown?: InputMaybe<SortOrderInput>;
  quiz?: InputMaybe<QuizOrderByWithRelationInput>;
  quizId?: InputMaybe<SortOrderInput>;
};

export enum ContentScalarFieldEnum {
  ArticleId = 'articleId',
  Created = 'created',
  Id = 'id',
  Markdown = 'markdown',
  Modified = 'modified',
  PublishedFrontmatter = 'publishedFrontmatter',
  PublishedHtml = 'publishedHtml',
  PublishedMarkdown = 'publishedMarkdown',
  QuizId = 'quizId'
}

export type ContentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ContentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ContentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ContentScalarWhereWithAggregatesInput>>;
  articleId?: InputMaybe<StringNullableWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  markdown?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  publishedFrontmatter?: InputMaybe<JsonNullableWithAggregatesFilter>;
  publishedHtml?: InputMaybe<StringNullableWithAggregatesFilter>;
  publishedMarkdown?: InputMaybe<StringNullableWithAggregatesFilter>;
  quizId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type ContentUpdateInput = {
  article?: InputMaybe<ArticleUpdateOneWithoutContentNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  markdown?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publishedFrontmatter?: InputMaybe<Scalars['JSON']['input']>;
  publishedHtml?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publishedMarkdown?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  quiz?: InputMaybe<QuizUpdateOneWithoutContentNestedInput>;
};

export type ContentUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  markdown?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publishedFrontmatter?: InputMaybe<Scalars['JSON']['input']>;
  publishedHtml?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publishedMarkdown?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpdateOneWithoutArticleNestedInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutArticleInput>;
  create?: InputMaybe<ContentCreateWithoutArticleInput>;
  delete?: InputMaybe<ContentWhereInput>;
  disconnect?: InputMaybe<ContentWhereInput>;
  update?: InputMaybe<ContentUpdateToOneWithWhereWithoutArticleInput>;
  upsert?: InputMaybe<ContentUpsertWithoutArticleInput>;
};

export type ContentUpdateOneWithoutQuizNestedInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutQuizInput>;
  create?: InputMaybe<ContentCreateWithoutQuizInput>;
  delete?: InputMaybe<ContentWhereInput>;
  disconnect?: InputMaybe<ContentWhereInput>;
  update?: InputMaybe<ContentUpdateToOneWithWhereWithoutQuizInput>;
  upsert?: InputMaybe<ContentUpsertWithoutQuizInput>;
};

export type ContentUpdateToOneWithWhereWithoutArticleInput = {
  data: ContentUpdateWithoutArticleInput;
  where?: InputMaybe<ContentWhereInput>;
};

export type ContentUpdateToOneWithWhereWithoutQuizInput = {
  data: ContentUpdateWithoutQuizInput;
  where?: InputMaybe<ContentWhereInput>;
};

export type ContentUpdateWithoutArticleInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  markdown?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publishedFrontmatter?: InputMaybe<Scalars['JSON']['input']>;
  publishedHtml?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publishedMarkdown?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  quiz?: InputMaybe<QuizUpdateOneWithoutContentNestedInput>;
};

export type ContentUpdateWithoutQuizInput = {
  article?: InputMaybe<ArticleUpdateOneWithoutContentNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  markdown?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publishedFrontmatter?: InputMaybe<Scalars['JSON']['input']>;
  publishedHtml?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publishedMarkdown?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpsertWithoutArticleInput = {
  create: ContentCreateWithoutArticleInput;
  update: ContentUpdateWithoutArticleInput;
  where?: InputMaybe<ContentWhereInput>;
};

export type ContentUpsertWithoutQuizInput = {
  create: ContentCreateWithoutQuizInput;
  update: ContentUpdateWithoutQuizInput;
  where?: InputMaybe<ContentWhereInput>;
};

export type ContentWhereInput = {
  AND?: InputMaybe<Array<ContentWhereInput>>;
  NOT?: InputMaybe<Array<ContentWhereInput>>;
  OR?: InputMaybe<Array<ContentWhereInput>>;
  article?: InputMaybe<ArticleNullableRelationFilter>;
  articleId?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  markdown?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  publishedFrontmatter?: InputMaybe<JsonNullableFilter>;
  publishedHtml?: InputMaybe<StringNullableFilter>;
  publishedMarkdown?: InputMaybe<StringNullableFilter>;
  quiz?: InputMaybe<QuizNullableRelationFilter>;
  quizId?: InputMaybe<StringNullableFilter>;
};

export type ContentWhereUniqueInput = {
  AND?: InputMaybe<Array<ContentWhereInput>>;
  NOT?: InputMaybe<Array<ContentWhereInput>>;
  OR?: InputMaybe<Array<ContentWhereInput>>;
  article?: InputMaybe<ArticleNullableRelationFilter>;
  articleId?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  markdown?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  publishedFrontmatter?: InputMaybe<JsonNullableFilter>;
  publishedHtml?: InputMaybe<StringNullableFilter>;
  publishedMarkdown?: InputMaybe<StringNullableFilter>;
  quiz?: InputMaybe<QuizNullableRelationFilter>;
  quizId?: InputMaybe<Scalars['String']['input']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type EnumAcceptedCurrencyFieldUpdateOperationsInput = {
  set?: InputMaybe<AcceptedCurrency>;
};

export type EnumAcceptedCurrencyFilter = {
  equals?: InputMaybe<AcceptedCurrency>;
  in?: InputMaybe<Array<AcceptedCurrency>>;
  not?: InputMaybe<NestedEnumAcceptedCurrencyFilter>;
  notIn?: InputMaybe<Array<AcceptedCurrency>>;
};

export type EnumAcceptedCurrencyWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAcceptedCurrencyFilter>;
  _min?: InputMaybe<NestedEnumAcceptedCurrencyFilter>;
  equals?: InputMaybe<AcceptedCurrency>;
  in?: InputMaybe<Array<AcceptedCurrency>>;
  not?: InputMaybe<NestedEnumAcceptedCurrencyWithAggregatesFilter>;
  notIn?: InputMaybe<Array<AcceptedCurrency>>;
};

export type EnumBrandingTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<BrandingType>;
};

export type EnumBrandingTypeFilter = {
  equals?: InputMaybe<BrandingType>;
  in?: InputMaybe<Array<BrandingType>>;
  not?: InputMaybe<NestedEnumBrandingTypeFilter>;
  notIn?: InputMaybe<Array<BrandingType>>;
};

export type EnumBrandingTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumBrandingTypeFilter>;
  _min?: InputMaybe<NestedEnumBrandingTypeFilter>;
  equals?: InputMaybe<BrandingType>;
  in?: InputMaybe<Array<BrandingType>>;
  not?: InputMaybe<NestedEnumBrandingTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<BrandingType>>;
};

export type EnumLearningStyleFieldUpdateOperationsInput = {
  set?: InputMaybe<LearningStyle>;
};

export type EnumLearningStyleFilter = {
  equals?: InputMaybe<LearningStyle>;
  in?: InputMaybe<Array<LearningStyle>>;
  not?: InputMaybe<NestedEnumLearningStyleFilter>;
  notIn?: InputMaybe<Array<LearningStyle>>;
};

export type EnumLearningStyleWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumLearningStyleFilter>;
  _min?: InputMaybe<NestedEnumLearningStyleFilter>;
  equals?: InputMaybe<LearningStyle>;
  in?: InputMaybe<Array<LearningStyle>>;
  not?: InputMaybe<NestedEnumLearningStyleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<LearningStyle>>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<Role>;
};

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumRoleWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRoleFilter>;
  _min?: InputMaybe<NestedEnumRoleFilter>;
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumStripePaymentIntentStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<StripePaymentIntentStatus>;
};

export type EnumStripePaymentIntentStatusFilter = {
  equals?: InputMaybe<StripePaymentIntentStatus>;
  in?: InputMaybe<Array<StripePaymentIntentStatus>>;
  not?: InputMaybe<NestedEnumStripePaymentIntentStatusFilter>;
  notIn?: InputMaybe<Array<StripePaymentIntentStatus>>;
};

export type EnumStripePaymentIntentStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStripePaymentIntentStatusFilter>;
  _min?: InputMaybe<NestedEnumStripePaymentIntentStatusFilter>;
  equals?: InputMaybe<StripePaymentIntentStatus>;
  in?: InputMaybe<Array<StripePaymentIntentStatus>>;
  not?: InputMaybe<NestedEnumStripePaymentIntentStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StripePaymentIntentStatus>>;
};

export type Event = {
  _count?: Maybe<EventCount>;
  created: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  eventCategories: Array<EventCategory>;
  eventOccurrences: Array<EventOccurrence>;
  id: Scalars['String']['output'];
  maxRegistrations?: Maybe<Scalars['Int']['output']>;
  modified: Scalars['DateTimeISO']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


export type EventEventCategoriesArgs = {
  cursor?: InputMaybe<EventCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type EventEventOccurrencesArgs = {
  cursor?: InputMaybe<EventOccurrenceWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventOccurrenceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventOccurrenceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventOccurrenceWhereInput>;
};

export type EventAttendance = {
  contributions: Scalars['JSON']['output'];
  contributionsMade: Scalars['Int']['output'];
  created: Scalars['DateTimeISO']['output'];
  eventOccurrence?: Maybe<EventOccurrence>;
  eventOccurrenceId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};


export type EventAttendanceEventOccurrenceArgs = {
  where?: InputMaybe<EventOccurrenceWhereInput>;
};

export type EventAttendanceAvgAggregate = {
  contributionsMade?: Maybe<Scalars['Float']['output']>;
};

export type EventAttendanceAvgOrderByAggregateInput = {
  contributionsMade?: InputMaybe<SortOrder>;
};

export type EventAttendanceCountAggregate = {
  _all: Scalars['Int']['output'];
  contributions: Scalars['Int']['output'];
  contributionsMade: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  eventOccurrenceId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type EventAttendanceCountOrderByAggregateInput = {
  contributions?: InputMaybe<SortOrder>;
  contributionsMade?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  eventOccurrenceId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type EventAttendanceCreateInput = {
  contributions: Scalars['JSON']['input'];
  contributionsMade?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventOccurrence?: InputMaybe<EventOccurrenceCreateNestedOneWithoutEventAttendeesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  user: UserCreateNestedOneWithoutEventAttendancesInput;
};

export type EventAttendanceCreateManyEventOccurrenceInput = {
  contributions: Scalars['JSON']['input'];
  contributionsMade?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  userId: Scalars['String']['input'];
};

export type EventAttendanceCreateManyEventOccurrenceInputEnvelope = {
  data: Array<EventAttendanceCreateManyEventOccurrenceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventAttendanceCreateManyInput = {
  contributions: Scalars['JSON']['input'];
  contributionsMade?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventOccurrenceId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  userId: Scalars['String']['input'];
};

export type EventAttendanceCreateManyUserInput = {
  contributions: Scalars['JSON']['input'];
  contributionsMade?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventOccurrenceId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type EventAttendanceCreateManyUserInputEnvelope = {
  data: Array<EventAttendanceCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventAttendanceCreateNestedManyWithoutEventOccurrenceInput = {
  connect?: InputMaybe<Array<EventAttendanceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventAttendanceCreateOrConnectWithoutEventOccurrenceInput>>;
  create?: InputMaybe<Array<EventAttendanceCreateWithoutEventOccurrenceInput>>;
  createMany?: InputMaybe<EventAttendanceCreateManyEventOccurrenceInputEnvelope>;
};

export type EventAttendanceCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<EventAttendanceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventAttendanceCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<EventAttendanceCreateWithoutUserInput>>;
  createMany?: InputMaybe<EventAttendanceCreateManyUserInputEnvelope>;
};

export type EventAttendanceCreateOrConnectWithoutEventOccurrenceInput = {
  create: EventAttendanceCreateWithoutEventOccurrenceInput;
  where: EventAttendanceWhereUniqueInput;
};

export type EventAttendanceCreateOrConnectWithoutUserInput = {
  create: EventAttendanceCreateWithoutUserInput;
  where: EventAttendanceWhereUniqueInput;
};

export type EventAttendanceCreateWithoutEventOccurrenceInput = {
  contributions: Scalars['JSON']['input'];
  contributionsMade?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  user: UserCreateNestedOneWithoutEventAttendancesInput;
};

export type EventAttendanceCreateWithoutUserInput = {
  contributions: Scalars['JSON']['input'];
  contributionsMade?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventOccurrence?: InputMaybe<EventOccurrenceCreateNestedOneWithoutEventAttendeesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type EventAttendanceGroupBy = {
  _avg?: Maybe<EventAttendanceAvgAggregate>;
  _count?: Maybe<EventAttendanceCountAggregate>;
  _max?: Maybe<EventAttendanceMaxAggregate>;
  _min?: Maybe<EventAttendanceMinAggregate>;
  _sum?: Maybe<EventAttendanceSumAggregate>;
  contributions: Scalars['JSON']['output'];
  contributionsMade: Scalars['Int']['output'];
  created: Scalars['DateTimeISO']['output'];
  eventOccurrenceId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type EventAttendanceListRelationFilter = {
  every?: InputMaybe<EventAttendanceWhereInput>;
  none?: InputMaybe<EventAttendanceWhereInput>;
  some?: InputMaybe<EventAttendanceWhereInput>;
};

export type EventAttendanceMaxAggregate = {
  contributionsMade?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  eventOccurrenceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type EventAttendanceMaxOrderByAggregateInput = {
  contributionsMade?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  eventOccurrenceId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type EventAttendanceMinAggregate = {
  contributionsMade?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  eventOccurrenceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type EventAttendanceMinOrderByAggregateInput = {
  contributionsMade?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  eventOccurrenceId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type EventAttendanceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EventAttendanceOrderByWithAggregationInput = {
  _avg?: InputMaybe<EventAttendanceAvgOrderByAggregateInput>;
  _count?: InputMaybe<EventAttendanceCountOrderByAggregateInput>;
  _max?: InputMaybe<EventAttendanceMaxOrderByAggregateInput>;
  _min?: InputMaybe<EventAttendanceMinOrderByAggregateInput>;
  _sum?: InputMaybe<EventAttendanceSumOrderByAggregateInput>;
  contributions?: InputMaybe<SortOrder>;
  contributionsMade?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  eventOccurrenceId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type EventAttendanceOrderByWithRelationInput = {
  contributions?: InputMaybe<SortOrder>;
  contributionsMade?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  eventOccurrence?: InputMaybe<EventOccurrenceOrderByWithRelationInput>;
  eventOccurrenceId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum EventAttendanceScalarFieldEnum {
  Contributions = 'contributions',
  ContributionsMade = 'contributionsMade',
  Created = 'created',
  EventOccurrenceId = 'eventOccurrenceId',
  Id = 'id',
  Modified = 'modified',
  UserId = 'userId'
}

export type EventAttendanceScalarWhereInput = {
  AND?: InputMaybe<Array<EventAttendanceScalarWhereInput>>;
  NOT?: InputMaybe<Array<EventAttendanceScalarWhereInput>>;
  OR?: InputMaybe<Array<EventAttendanceScalarWhereInput>>;
  contributions?: InputMaybe<JsonFilter>;
  contributionsMade?: InputMaybe<IntFilter>;
  created?: InputMaybe<DateTimeFilter>;
  eventOccurrenceId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type EventAttendanceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EventAttendanceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EventAttendanceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EventAttendanceScalarWhereWithAggregatesInput>>;
  contributions?: InputMaybe<JsonWithAggregatesFilter>;
  contributionsMade?: InputMaybe<IntWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  eventOccurrenceId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type EventAttendanceSumAggregate = {
  contributionsMade?: Maybe<Scalars['Int']['output']>;
};

export type EventAttendanceSumOrderByAggregateInput = {
  contributionsMade?: InputMaybe<SortOrder>;
};

export type EventAttendanceUpdateInput = {
  contributions?: InputMaybe<Scalars['JSON']['input']>;
  contributionsMade?: InputMaybe<IntFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventOccurrence?: InputMaybe<EventOccurrenceUpdateOneWithoutEventAttendeesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutEventAttendancesNestedInput>;
};

export type EventAttendanceUpdateManyMutationInput = {
  contributions?: InputMaybe<Scalars['JSON']['input']>;
  contributionsMade?: InputMaybe<IntFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventAttendanceUpdateManyWithWhereWithoutEventOccurrenceInput = {
  data: EventAttendanceUpdateManyMutationInput;
  where: EventAttendanceScalarWhereInput;
};

export type EventAttendanceUpdateManyWithWhereWithoutUserInput = {
  data: EventAttendanceUpdateManyMutationInput;
  where: EventAttendanceScalarWhereInput;
};

export type EventAttendanceUpdateManyWithoutEventOccurrenceNestedInput = {
  connect?: InputMaybe<Array<EventAttendanceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventAttendanceCreateOrConnectWithoutEventOccurrenceInput>>;
  create?: InputMaybe<Array<EventAttendanceCreateWithoutEventOccurrenceInput>>;
  createMany?: InputMaybe<EventAttendanceCreateManyEventOccurrenceInputEnvelope>;
  delete?: InputMaybe<Array<EventAttendanceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventAttendanceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventAttendanceWhereUniqueInput>>;
  set?: InputMaybe<Array<EventAttendanceWhereUniqueInput>>;
  update?: InputMaybe<Array<EventAttendanceUpdateWithWhereUniqueWithoutEventOccurrenceInput>>;
  updateMany?: InputMaybe<Array<EventAttendanceUpdateManyWithWhereWithoutEventOccurrenceInput>>;
  upsert?: InputMaybe<Array<EventAttendanceUpsertWithWhereUniqueWithoutEventOccurrenceInput>>;
};

export type EventAttendanceUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<EventAttendanceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventAttendanceCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<EventAttendanceCreateWithoutUserInput>>;
  createMany?: InputMaybe<EventAttendanceCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<EventAttendanceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventAttendanceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventAttendanceWhereUniqueInput>>;
  set?: InputMaybe<Array<EventAttendanceWhereUniqueInput>>;
  update?: InputMaybe<Array<EventAttendanceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<EventAttendanceUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<EventAttendanceUpsertWithWhereUniqueWithoutUserInput>>;
};

export type EventAttendanceUpdateWithWhereUniqueWithoutEventOccurrenceInput = {
  data: EventAttendanceUpdateWithoutEventOccurrenceInput;
  where: EventAttendanceWhereUniqueInput;
};

export type EventAttendanceUpdateWithWhereUniqueWithoutUserInput = {
  data: EventAttendanceUpdateWithoutUserInput;
  where: EventAttendanceWhereUniqueInput;
};

export type EventAttendanceUpdateWithoutEventOccurrenceInput = {
  contributions?: InputMaybe<Scalars['JSON']['input']>;
  contributionsMade?: InputMaybe<IntFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutEventAttendancesNestedInput>;
};

export type EventAttendanceUpdateWithoutUserInput = {
  contributions?: InputMaybe<Scalars['JSON']['input']>;
  contributionsMade?: InputMaybe<IntFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventOccurrence?: InputMaybe<EventOccurrenceUpdateOneWithoutEventAttendeesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventAttendanceUpsertWithWhereUniqueWithoutEventOccurrenceInput = {
  create: EventAttendanceCreateWithoutEventOccurrenceInput;
  update: EventAttendanceUpdateWithoutEventOccurrenceInput;
  where: EventAttendanceWhereUniqueInput;
};

export type EventAttendanceUpsertWithWhereUniqueWithoutUserInput = {
  create: EventAttendanceCreateWithoutUserInput;
  update: EventAttendanceUpdateWithoutUserInput;
  where: EventAttendanceWhereUniqueInput;
};

export type EventAttendanceWhereInput = {
  AND?: InputMaybe<Array<EventAttendanceWhereInput>>;
  NOT?: InputMaybe<Array<EventAttendanceWhereInput>>;
  OR?: InputMaybe<Array<EventAttendanceWhereInput>>;
  contributions?: InputMaybe<JsonFilter>;
  contributionsMade?: InputMaybe<IntFilter>;
  created?: InputMaybe<DateTimeFilter>;
  eventOccurrence?: InputMaybe<EventOccurrenceNullableRelationFilter>;
  eventOccurrenceId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type EventAttendanceWhereUniqueInput = {
  AND?: InputMaybe<Array<EventAttendanceWhereInput>>;
  NOT?: InputMaybe<Array<EventAttendanceWhereInput>>;
  OR?: InputMaybe<Array<EventAttendanceWhereInput>>;
  contributions?: InputMaybe<JsonFilter>;
  contributionsMade?: InputMaybe<IntFilter>;
  created?: InputMaybe<DateTimeFilter>;
  eventOccurrence?: InputMaybe<EventOccurrenceNullableRelationFilter>;
  eventOccurrenceId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type EventAvgAggregate = {
  maxRegistrations?: Maybe<Scalars['Float']['output']>;
};

export type EventAvgOrderByAggregateInput = {
  maxRegistrations?: InputMaybe<SortOrder>;
};

export type EventCategory = {
  category: Category;
  categoryId: Scalars['String']['output'];
  contributionName?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPinned: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  minContributions?: Maybe<Scalars['Int']['output']>;
  minHours?: Maybe<Scalars['Float']['output']>;
  modified: Scalars['DateTimeISO']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type EventCategoryAvgAggregate = {
  minContributions?: Maybe<Scalars['Float']['output']>;
  minHours?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

export type EventCategoryAvgOrderByAggregateInput = {
  minContributions?: InputMaybe<SortOrder>;
  minHours?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type EventCategoryCountAggregate = {
  _all: Scalars['Int']['output'];
  categoryId: Scalars['Int']['output'];
  contributionName: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  eventId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isPinned: Scalars['Int']['output'];
  isRequired: Scalars['Int']['output'];
  minContributions: Scalars['Int']['output'];
  minHours: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type EventCategoryCountOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  contributionName?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  minContributions?: InputMaybe<SortOrder>;
  minHours?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type EventCategoryCreateInput = {
  category: CategoryCreateNestedOneWithoutCategoryEventsInput;
  contributionName?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  event: EventCreateNestedOneWithoutEventCategoriesInput;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  minContributions?: InputMaybe<Scalars['Int']['input']>;
  minHours?: InputMaybe<Scalars['Float']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type EventCategoryCreateManyCategoryInput = {
  contributionName?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  minContributions?: InputMaybe<Scalars['Int']['input']>;
  minHours?: InputMaybe<Scalars['Float']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type EventCategoryCreateManyCategoryInputEnvelope = {
  data: Array<EventCategoryCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventCategoryCreateManyEventInput = {
  categoryId: Scalars['String']['input'];
  contributionName?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  minContributions?: InputMaybe<Scalars['Int']['input']>;
  minHours?: InputMaybe<Scalars['Float']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type EventCategoryCreateManyEventInputEnvelope = {
  data: Array<EventCategoryCreateManyEventInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventCategoryCreateManyInput = {
  categoryId: Scalars['String']['input'];
  contributionName?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  minContributions?: InputMaybe<Scalars['Int']['input']>;
  minHours?: InputMaybe<Scalars['Float']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type EventCategoryCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<EventCategoryCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<EventCategoryCreateManyCategoryInputEnvelope>;
};

export type EventCategoryCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCategoryCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<EventCategoryCreateWithoutEventInput>>;
  createMany?: InputMaybe<EventCategoryCreateManyEventInputEnvelope>;
};

export type EventCategoryCreateOrConnectWithoutCategoryInput = {
  create: EventCategoryCreateWithoutCategoryInput;
  where: EventCategoryWhereUniqueInput;
};

export type EventCategoryCreateOrConnectWithoutEventInput = {
  create: EventCategoryCreateWithoutEventInput;
  where: EventCategoryWhereUniqueInput;
};

export type EventCategoryCreateWithoutCategoryInput = {
  contributionName?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  event: EventCreateNestedOneWithoutEventCategoriesInput;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  minContributions?: InputMaybe<Scalars['Int']['input']>;
  minHours?: InputMaybe<Scalars['Float']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type EventCategoryCreateWithoutEventInput = {
  category: CategoryCreateNestedOneWithoutCategoryEventsInput;
  contributionName?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  minContributions?: InputMaybe<Scalars['Int']['input']>;
  minHours?: InputMaybe<Scalars['Float']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type EventCategoryEventCategoryCompoundUniqueInput = {
  categoryId: Scalars['String']['input'];
  eventId: Scalars['String']['input'];
};

export type EventCategoryGroupBy = {
  _avg?: Maybe<EventCategoryAvgAggregate>;
  _count?: Maybe<EventCategoryCountAggregate>;
  _max?: Maybe<EventCategoryMaxAggregate>;
  _min?: Maybe<EventCategoryMinAggregate>;
  _sum?: Maybe<EventCategorySumAggregate>;
  categoryId: Scalars['String']['output'];
  contributionName?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPinned: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  minContributions?: Maybe<Scalars['Int']['output']>;
  minHours?: Maybe<Scalars['Float']['output']>;
  modified: Scalars['DateTimeISO']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type EventCategoryListRelationFilter = {
  every?: InputMaybe<EventCategoryWhereInput>;
  none?: InputMaybe<EventCategoryWhereInput>;
  some?: InputMaybe<EventCategoryWhereInput>;
};

export type EventCategoryMaxAggregate = {
  categoryId?: Maybe<Scalars['String']['output']>;
  contributionName?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  minContributions?: Maybe<Scalars['Int']['output']>;
  minHours?: Maybe<Scalars['Float']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type EventCategoryMaxOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  contributionName?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  minContributions?: InputMaybe<SortOrder>;
  minHours?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type EventCategoryMinAggregate = {
  categoryId?: Maybe<Scalars['String']['output']>;
  contributionName?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  minContributions?: Maybe<Scalars['Int']['output']>;
  minHours?: Maybe<Scalars['Float']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type EventCategoryMinOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  contributionName?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  minContributions?: InputMaybe<SortOrder>;
  minHours?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type EventCategoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EventCategoryOrderByWithAggregationInput = {
  _avg?: InputMaybe<EventCategoryAvgOrderByAggregateInput>;
  _count?: InputMaybe<EventCategoryCountOrderByAggregateInput>;
  _max?: InputMaybe<EventCategoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<EventCategoryMinOrderByAggregateInput>;
  _sum?: InputMaybe<EventCategorySumOrderByAggregateInput>;
  categoryId?: InputMaybe<SortOrder>;
  contributionName?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  minContributions?: InputMaybe<SortOrderInput>;
  minHours?: InputMaybe<SortOrderInput>;
  modified?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type EventCategoryOrderByWithRelationInput = {
  category?: InputMaybe<CategoryOrderByWithRelationInput>;
  categoryId?: InputMaybe<SortOrder>;
  contributionName?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  event?: InputMaybe<EventOrderByWithRelationInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  minContributions?: InputMaybe<SortOrderInput>;
  minHours?: InputMaybe<SortOrderInput>;
  modified?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export enum EventCategoryScalarFieldEnum {
  CategoryId = 'categoryId',
  ContributionName = 'contributionName',
  Created = 'created',
  EventId = 'eventId',
  Id = 'id',
  IsPinned = 'isPinned',
  IsRequired = 'isRequired',
  MinContributions = 'minContributions',
  MinHours = 'minHours',
  Modified = 'modified',
  SortOrder = 'sortOrder'
}

export type EventCategoryScalarWhereInput = {
  AND?: InputMaybe<Array<EventCategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<EventCategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<EventCategoryScalarWhereInput>>;
  categoryId?: InputMaybe<StringFilter>;
  contributionName?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPinned?: InputMaybe<BoolFilter>;
  isRequired?: InputMaybe<BoolFilter>;
  minContributions?: InputMaybe<IntNullableFilter>;
  minHours?: InputMaybe<FloatNullableFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  sortOrder?: InputMaybe<IntFilter>;
};

export type EventCategoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EventCategoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EventCategoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EventCategoryScalarWhereWithAggregatesInput>>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  contributionName?: InputMaybe<StringNullableWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  eventId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPinned?: InputMaybe<BoolWithAggregatesFilter>;
  isRequired?: InputMaybe<BoolWithAggregatesFilter>;
  minContributions?: InputMaybe<IntNullableWithAggregatesFilter>;
  minHours?: InputMaybe<FloatNullableWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  sortOrder?: InputMaybe<IntWithAggregatesFilter>;
};

export type EventCategorySumAggregate = {
  minContributions?: Maybe<Scalars['Int']['output']>;
  minHours?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type EventCategorySumOrderByAggregateInput = {
  minContributions?: InputMaybe<SortOrder>;
  minHours?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type EventCategoryUpdateInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutCategoryEventsNestedInput>;
  contributionName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutEventCategoriesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  minContributions?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minHours?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type EventCategoryUpdateManyMutationInput = {
  contributionName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  minContributions?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minHours?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type EventCategoryUpdateManyWithWhereWithoutCategoryInput = {
  data: EventCategoryUpdateManyMutationInput;
  where: EventCategoryScalarWhereInput;
};

export type EventCategoryUpdateManyWithWhereWithoutEventInput = {
  data: EventCategoryUpdateManyMutationInput;
  where: EventCategoryScalarWhereInput;
};

export type EventCategoryUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<EventCategoryCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<EventCategoryCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<EventCategoryUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<EventCategoryUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<EventCategoryUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type EventCategoryUpdateManyWithoutEventNestedInput = {
  connect?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCategoryCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<EventCategoryCreateWithoutEventInput>>;
  createMany?: InputMaybe<EventCategoryCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<EventCategoryUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<EventCategoryUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<EventCategoryUpsertWithWhereUniqueWithoutEventInput>>;
};

export type EventCategoryUpdateWithWhereUniqueWithoutCategoryInput = {
  data: EventCategoryUpdateWithoutCategoryInput;
  where: EventCategoryWhereUniqueInput;
};

export type EventCategoryUpdateWithWhereUniqueWithoutEventInput = {
  data: EventCategoryUpdateWithoutEventInput;
  where: EventCategoryWhereUniqueInput;
};

export type EventCategoryUpdateWithoutCategoryInput = {
  contributionName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutEventCategoriesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  minContributions?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minHours?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type EventCategoryUpdateWithoutEventInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutCategoryEventsNestedInput>;
  contributionName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  minContributions?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minHours?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type EventCategoryUpsertWithWhereUniqueWithoutCategoryInput = {
  create: EventCategoryCreateWithoutCategoryInput;
  update: EventCategoryUpdateWithoutCategoryInput;
  where: EventCategoryWhereUniqueInput;
};

export type EventCategoryUpsertWithWhereUniqueWithoutEventInput = {
  create: EventCategoryCreateWithoutEventInput;
  update: EventCategoryUpdateWithoutEventInput;
  where: EventCategoryWhereUniqueInput;
};

export type EventCategoryWhereInput = {
  AND?: InputMaybe<Array<EventCategoryWhereInput>>;
  NOT?: InputMaybe<Array<EventCategoryWhereInput>>;
  OR?: InputMaybe<Array<EventCategoryWhereInput>>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  contributionName?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPinned?: InputMaybe<BoolFilter>;
  isRequired?: InputMaybe<BoolFilter>;
  minContributions?: InputMaybe<IntNullableFilter>;
  minHours?: InputMaybe<FloatNullableFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  sortOrder?: InputMaybe<IntFilter>;
};

export type EventCategoryWhereUniqueInput = {
  AND?: InputMaybe<Array<EventCategoryWhereInput>>;
  NOT?: InputMaybe<Array<EventCategoryWhereInput>>;
  OR?: InputMaybe<Array<EventCategoryWhereInput>>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  contributionName?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  event?: InputMaybe<EventRelationFilter>;
  eventCategory?: InputMaybe<EventCategoryEventCategoryCompoundUniqueInput>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<BoolFilter>;
  isRequired?: InputMaybe<BoolFilter>;
  minContributions?: InputMaybe<IntNullableFilter>;
  minHours?: InputMaybe<FloatNullableFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  sortOrder?: InputMaybe<IntFilter>;
};

export type EventCount = {
  eventCategories: Scalars['Int']['output'];
  eventOccurrences: Scalars['Int']['output'];
};


export type EventCountEventCategoriesArgs = {
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type EventCountEventOccurrencesArgs = {
  where?: InputMaybe<EventOccurrenceWhereInput>;
};

export type EventCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  maxRegistrations: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
};

export type EventCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxRegistrations?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type EventCreateInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  eventCategories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventInput>;
  eventOccurrences?: InputMaybe<EventOccurrenceCreateNestedManyWithoutEventInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxRegistrations?: InputMaybe<Scalars['Int']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type EventCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  maxRegistrations?: InputMaybe<Scalars['Int']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type EventCreateNestedOneWithoutEventCategoriesInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutEventCategoriesInput>;
  create?: InputMaybe<EventCreateWithoutEventCategoriesInput>;
};

export type EventCreateNestedOneWithoutEventOccurrencesInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutEventOccurrencesInput>;
  create?: InputMaybe<EventCreateWithoutEventOccurrencesInput>;
};

export type EventCreateOrConnectWithoutEventCategoriesInput = {
  create: EventCreateWithoutEventCategoriesInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutEventOccurrencesInput = {
  create: EventCreateWithoutEventOccurrencesInput;
  where: EventWhereUniqueInput;
};

export type EventCreateWithoutEventCategoriesInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  eventOccurrences?: InputMaybe<EventOccurrenceCreateNestedManyWithoutEventInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxRegistrations?: InputMaybe<Scalars['Int']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type EventCreateWithoutEventOccurrencesInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  eventCategories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxRegistrations?: InputMaybe<Scalars['Int']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type EventGroupBy = {
  _avg?: Maybe<EventAvgAggregate>;
  _count?: Maybe<EventCountAggregate>;
  _max?: Maybe<EventMaxAggregate>;
  _min?: Maybe<EventMinAggregate>;
  _sum?: Maybe<EventSumAggregate>;
  created: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  maxRegistrations?: Maybe<Scalars['Int']['output']>;
  modified: Scalars['DateTimeISO']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type EventMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxRegistrations?: Maybe<Scalars['Int']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type EventMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxRegistrations?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type EventMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxRegistrations?: Maybe<Scalars['Int']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type EventMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxRegistrations?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type EventOccurrence = {
  _count?: Maybe<EventOccurrenceCount>;
  created: Scalars['DateTimeISO']['output'];
  end?: Maybe<Scalars['DateTimeISO']['output']>;
  event: Event;
  eventAttendees: Array<EventAttendance>;
  eventId: Scalars['String']['output'];
  eventRegistrations: Array<EventRegistration>;
  id: Scalars['String']['output'];
  maxRegistrations?: Maybe<Scalars['Int']['output']>;
  modified: Scalars['DateTimeISO']['output'];
  start: Scalars['DateTimeISO']['output'];
};


export type EventOccurrenceEventAttendeesArgs = {
  cursor?: InputMaybe<EventAttendanceWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventAttendanceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventAttendanceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventAttendanceWhereInput>;
};


export type EventOccurrenceEventRegistrationsArgs = {
  cursor?: InputMaybe<EventRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventRegistrationWhereInput>;
};

export type EventOccurrenceAvgAggregate = {
  maxRegistrations?: Maybe<Scalars['Float']['output']>;
};

export type EventOccurrenceAvgOrderByAggregateInput = {
  maxRegistrations?: InputMaybe<SortOrder>;
};

export type EventOccurrenceCount = {
  eventAttendees: Scalars['Int']['output'];
  eventRegistrations: Scalars['Int']['output'];
};


export type EventOccurrenceCountEventAttendeesArgs = {
  where?: InputMaybe<EventAttendanceWhereInput>;
};


export type EventOccurrenceCountEventRegistrationsArgs = {
  where?: InputMaybe<EventRegistrationWhereInput>;
};

export type EventOccurrenceCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  end: Scalars['Int']['output'];
  eventId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  maxRegistrations: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type EventOccurrenceCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxRegistrations?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
};

export type EventOccurrenceCreateInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  end?: InputMaybe<Scalars['DateTimeISO']['input']>;
  event: EventCreateNestedOneWithoutEventOccurrencesInput;
  eventAttendees?: InputMaybe<EventAttendanceCreateNestedManyWithoutEventOccurrenceInput>;
  eventRegistrations?: InputMaybe<EventRegistrationCreateNestedManyWithoutEventOccurrenceInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxRegistrations?: InputMaybe<Scalars['Int']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  start: Scalars['DateTimeISO']['input'];
};

export type EventOccurrenceCreateManyEventInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  end?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxRegistrations?: InputMaybe<Scalars['Int']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  start: Scalars['DateTimeISO']['input'];
};

export type EventOccurrenceCreateManyEventInputEnvelope = {
  data: Array<EventOccurrenceCreateManyEventInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventOccurrenceCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  end?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  maxRegistrations?: InputMaybe<Scalars['Int']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  start: Scalars['DateTimeISO']['input'];
};

export type EventOccurrenceCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<EventOccurrenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventOccurrenceCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<EventOccurrenceCreateWithoutEventInput>>;
  createMany?: InputMaybe<EventOccurrenceCreateManyEventInputEnvelope>;
};

export type EventOccurrenceCreateNestedOneWithoutEventAttendeesInput = {
  connect?: InputMaybe<EventOccurrenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventOccurrenceCreateOrConnectWithoutEventAttendeesInput>;
  create?: InputMaybe<EventOccurrenceCreateWithoutEventAttendeesInput>;
};

export type EventOccurrenceCreateNestedOneWithoutEventRegistrationsInput = {
  connect?: InputMaybe<EventOccurrenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventOccurrenceCreateOrConnectWithoutEventRegistrationsInput>;
  create?: InputMaybe<EventOccurrenceCreateWithoutEventRegistrationsInput>;
};

export type EventOccurrenceCreateOrConnectWithoutEventAttendeesInput = {
  create: EventOccurrenceCreateWithoutEventAttendeesInput;
  where: EventOccurrenceWhereUniqueInput;
};

export type EventOccurrenceCreateOrConnectWithoutEventInput = {
  create: EventOccurrenceCreateWithoutEventInput;
  where: EventOccurrenceWhereUniqueInput;
};

export type EventOccurrenceCreateOrConnectWithoutEventRegistrationsInput = {
  create: EventOccurrenceCreateWithoutEventRegistrationsInput;
  where: EventOccurrenceWhereUniqueInput;
};

export type EventOccurrenceCreateWithoutEventAttendeesInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  end?: InputMaybe<Scalars['DateTimeISO']['input']>;
  event: EventCreateNestedOneWithoutEventOccurrencesInput;
  eventRegistrations?: InputMaybe<EventRegistrationCreateNestedManyWithoutEventOccurrenceInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxRegistrations?: InputMaybe<Scalars['Int']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  start: Scalars['DateTimeISO']['input'];
};

export type EventOccurrenceCreateWithoutEventInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  end?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventAttendees?: InputMaybe<EventAttendanceCreateNestedManyWithoutEventOccurrenceInput>;
  eventRegistrations?: InputMaybe<EventRegistrationCreateNestedManyWithoutEventOccurrenceInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxRegistrations?: InputMaybe<Scalars['Int']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  start: Scalars['DateTimeISO']['input'];
};

export type EventOccurrenceCreateWithoutEventRegistrationsInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  end?: InputMaybe<Scalars['DateTimeISO']['input']>;
  event: EventCreateNestedOneWithoutEventOccurrencesInput;
  eventAttendees?: InputMaybe<EventAttendanceCreateNestedManyWithoutEventOccurrenceInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxRegistrations?: InputMaybe<Scalars['Int']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  start: Scalars['DateTimeISO']['input'];
};

export type EventOccurrenceGroupBy = {
  _avg?: Maybe<EventOccurrenceAvgAggregate>;
  _count?: Maybe<EventOccurrenceCountAggregate>;
  _max?: Maybe<EventOccurrenceMaxAggregate>;
  _min?: Maybe<EventOccurrenceMinAggregate>;
  _sum?: Maybe<EventOccurrenceSumAggregate>;
  created: Scalars['DateTimeISO']['output'];
  end?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  maxRegistrations?: Maybe<Scalars['Int']['output']>;
  modified: Scalars['DateTimeISO']['output'];
  start: Scalars['DateTimeISO']['output'];
};

export type EventOccurrenceListRelationFilter = {
  every?: InputMaybe<EventOccurrenceWhereInput>;
  none?: InputMaybe<EventOccurrenceWhereInput>;
  some?: InputMaybe<EventOccurrenceWhereInput>;
};

export type EventOccurrenceMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  end?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxRegistrations?: Maybe<Scalars['Int']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  start?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type EventOccurrenceMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxRegistrations?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
};

export type EventOccurrenceMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  end?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxRegistrations?: Maybe<Scalars['Int']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  start?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type EventOccurrenceMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxRegistrations?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
};

export type EventOccurrenceNullableRelationFilter = {
  is?: InputMaybe<EventOccurrenceWhereInput>;
  isNot?: InputMaybe<EventOccurrenceWhereInput>;
};

export type EventOccurrenceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EventOccurrenceOrderByWithAggregationInput = {
  _avg?: InputMaybe<EventOccurrenceAvgOrderByAggregateInput>;
  _count?: InputMaybe<EventOccurrenceCountOrderByAggregateInput>;
  _max?: InputMaybe<EventOccurrenceMaxOrderByAggregateInput>;
  _min?: InputMaybe<EventOccurrenceMinOrderByAggregateInput>;
  _sum?: InputMaybe<EventOccurrenceSumOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrderInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxRegistrations?: InputMaybe<SortOrderInput>;
  modified?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
};

export type EventOccurrenceOrderByWithRelationInput = {
  created?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrderInput>;
  event?: InputMaybe<EventOrderByWithRelationInput>;
  eventAttendees?: InputMaybe<EventAttendanceOrderByRelationAggregateInput>;
  eventId?: InputMaybe<SortOrder>;
  eventRegistrations?: InputMaybe<EventRegistrationOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  maxRegistrations?: InputMaybe<SortOrderInput>;
  modified?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
};

export enum EventOccurrenceScalarFieldEnum {
  Created = 'created',
  End = 'end',
  EventId = 'eventId',
  Id = 'id',
  MaxRegistrations = 'maxRegistrations',
  Modified = 'modified',
  Start = 'start'
}

export type EventOccurrenceScalarWhereInput = {
  AND?: InputMaybe<Array<EventOccurrenceScalarWhereInput>>;
  NOT?: InputMaybe<Array<EventOccurrenceScalarWhereInput>>;
  OR?: InputMaybe<Array<EventOccurrenceScalarWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  maxRegistrations?: InputMaybe<IntNullableFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export type EventOccurrenceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EventOccurrenceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EventOccurrenceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EventOccurrenceScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  end?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  eventId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  maxRegistrations?: InputMaybe<IntNullableWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  start?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type EventOccurrenceSumAggregate = {
  maxRegistrations?: Maybe<Scalars['Int']['output']>;
};

export type EventOccurrenceSumOrderByAggregateInput = {
  maxRegistrations?: InputMaybe<SortOrder>;
};

export type EventOccurrenceUpdateInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutEventOccurrencesNestedInput>;
  eventAttendees?: InputMaybe<EventAttendanceUpdateManyWithoutEventOccurrenceNestedInput>;
  eventRegistrations?: InputMaybe<EventRegistrationUpdateManyWithoutEventOccurrenceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxRegistrations?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventOccurrenceUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxRegistrations?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventOccurrenceUpdateManyWithWhereWithoutEventInput = {
  data: EventOccurrenceUpdateManyMutationInput;
  where: EventOccurrenceScalarWhereInput;
};

export type EventOccurrenceUpdateManyWithoutEventNestedInput = {
  connect?: InputMaybe<Array<EventOccurrenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventOccurrenceCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<EventOccurrenceCreateWithoutEventInput>>;
  createMany?: InputMaybe<EventOccurrenceCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<EventOccurrenceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventOccurrenceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventOccurrenceWhereUniqueInput>>;
  set?: InputMaybe<Array<EventOccurrenceWhereUniqueInput>>;
  update?: InputMaybe<Array<EventOccurrenceUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<EventOccurrenceUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<EventOccurrenceUpsertWithWhereUniqueWithoutEventInput>>;
};

export type EventOccurrenceUpdateOneWithoutEventAttendeesNestedInput = {
  connect?: InputMaybe<EventOccurrenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventOccurrenceCreateOrConnectWithoutEventAttendeesInput>;
  create?: InputMaybe<EventOccurrenceCreateWithoutEventAttendeesInput>;
  delete?: InputMaybe<EventOccurrenceWhereInput>;
  disconnect?: InputMaybe<EventOccurrenceWhereInput>;
  update?: InputMaybe<EventOccurrenceUpdateToOneWithWhereWithoutEventAttendeesInput>;
  upsert?: InputMaybe<EventOccurrenceUpsertWithoutEventAttendeesInput>;
};

export type EventOccurrenceUpdateOneWithoutEventRegistrationsNestedInput = {
  connect?: InputMaybe<EventOccurrenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventOccurrenceCreateOrConnectWithoutEventRegistrationsInput>;
  create?: InputMaybe<EventOccurrenceCreateWithoutEventRegistrationsInput>;
  delete?: InputMaybe<EventOccurrenceWhereInput>;
  disconnect?: InputMaybe<EventOccurrenceWhereInput>;
  update?: InputMaybe<EventOccurrenceUpdateToOneWithWhereWithoutEventRegistrationsInput>;
  upsert?: InputMaybe<EventOccurrenceUpsertWithoutEventRegistrationsInput>;
};

export type EventOccurrenceUpdateToOneWithWhereWithoutEventAttendeesInput = {
  data: EventOccurrenceUpdateWithoutEventAttendeesInput;
  where?: InputMaybe<EventOccurrenceWhereInput>;
};

export type EventOccurrenceUpdateToOneWithWhereWithoutEventRegistrationsInput = {
  data: EventOccurrenceUpdateWithoutEventRegistrationsInput;
  where?: InputMaybe<EventOccurrenceWhereInput>;
};

export type EventOccurrenceUpdateWithWhereUniqueWithoutEventInput = {
  data: EventOccurrenceUpdateWithoutEventInput;
  where: EventOccurrenceWhereUniqueInput;
};

export type EventOccurrenceUpdateWithoutEventAttendeesInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutEventOccurrencesNestedInput>;
  eventRegistrations?: InputMaybe<EventRegistrationUpdateManyWithoutEventOccurrenceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxRegistrations?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventOccurrenceUpdateWithoutEventInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventAttendees?: InputMaybe<EventAttendanceUpdateManyWithoutEventOccurrenceNestedInput>;
  eventRegistrations?: InputMaybe<EventRegistrationUpdateManyWithoutEventOccurrenceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxRegistrations?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventOccurrenceUpdateWithoutEventRegistrationsInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutEventOccurrencesNestedInput>;
  eventAttendees?: InputMaybe<EventAttendanceUpdateManyWithoutEventOccurrenceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxRegistrations?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventOccurrenceUpsertWithWhereUniqueWithoutEventInput = {
  create: EventOccurrenceCreateWithoutEventInput;
  update: EventOccurrenceUpdateWithoutEventInput;
  where: EventOccurrenceWhereUniqueInput;
};

export type EventOccurrenceUpsertWithoutEventAttendeesInput = {
  create: EventOccurrenceCreateWithoutEventAttendeesInput;
  update: EventOccurrenceUpdateWithoutEventAttendeesInput;
  where?: InputMaybe<EventOccurrenceWhereInput>;
};

export type EventOccurrenceUpsertWithoutEventRegistrationsInput = {
  create: EventOccurrenceCreateWithoutEventRegistrationsInput;
  update: EventOccurrenceUpdateWithoutEventRegistrationsInput;
  where?: InputMaybe<EventOccurrenceWhereInput>;
};

export type EventOccurrenceWhereInput = {
  AND?: InputMaybe<Array<EventOccurrenceWhereInput>>;
  NOT?: InputMaybe<Array<EventOccurrenceWhereInput>>;
  OR?: InputMaybe<Array<EventOccurrenceWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  event?: InputMaybe<EventRelationFilter>;
  eventAttendees?: InputMaybe<EventAttendanceListRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  eventRegistrations?: InputMaybe<EventRegistrationListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  maxRegistrations?: InputMaybe<IntNullableFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export type EventOccurrenceWhereUniqueInput = {
  AND?: InputMaybe<Array<EventOccurrenceWhereInput>>;
  NOT?: InputMaybe<Array<EventOccurrenceWhereInput>>;
  OR?: InputMaybe<Array<EventOccurrenceWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  event?: InputMaybe<EventRelationFilter>;
  eventAttendees?: InputMaybe<EventAttendanceListRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  eventRegistrations?: InputMaybe<EventRegistrationListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxRegistrations?: InputMaybe<IntNullableFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export type EventOrderByWithAggregationInput = {
  _avg?: InputMaybe<EventAvgOrderByAggregateInput>;
  _count?: InputMaybe<EventCountOrderByAggregateInput>;
  _max?: InputMaybe<EventMaxOrderByAggregateInput>;
  _min?: InputMaybe<EventMinOrderByAggregateInput>;
  _sum?: InputMaybe<EventSumOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxRegistrations?: InputMaybe<SortOrderInput>;
  modified?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type EventOrderByWithRelationInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  eventCategories?: InputMaybe<EventCategoryOrderByRelationAggregateInput>;
  eventOccurrences?: InputMaybe<EventOccurrenceOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  maxRegistrations?: InputMaybe<SortOrderInput>;
  modified?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type EventRegistration = {
  created: Scalars['DateTimeISO']['output'];
  eventOccurrence?: Maybe<EventOccurrence>;
  eventOccurrenceId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};


export type EventRegistrationEventOccurrenceArgs = {
  where?: InputMaybe<EventOccurrenceWhereInput>;
};

export type EventRegistrationCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  eventOccurrenceId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type EventRegistrationCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  eventOccurrenceId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type EventRegistrationCreateInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventOccurrence?: InputMaybe<EventOccurrenceCreateNestedOneWithoutEventRegistrationsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  user: UserCreateNestedOneWithoutEventRegistrationsInput;
};

export type EventRegistrationCreateManyEventOccurrenceInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  userId: Scalars['String']['input'];
};

export type EventRegistrationCreateManyEventOccurrenceInputEnvelope = {
  data: Array<EventRegistrationCreateManyEventOccurrenceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventRegistrationCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventOccurrenceId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  userId: Scalars['String']['input'];
};

export type EventRegistrationCreateManyUserInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventOccurrenceId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type EventRegistrationCreateManyUserInputEnvelope = {
  data: Array<EventRegistrationCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventRegistrationCreateNestedManyWithoutEventOccurrenceInput = {
  connect?: InputMaybe<Array<EventRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventRegistrationCreateOrConnectWithoutEventOccurrenceInput>>;
  create?: InputMaybe<Array<EventRegistrationCreateWithoutEventOccurrenceInput>>;
  createMany?: InputMaybe<EventRegistrationCreateManyEventOccurrenceInputEnvelope>;
};

export type EventRegistrationCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<EventRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventRegistrationCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<EventRegistrationCreateWithoutUserInput>>;
  createMany?: InputMaybe<EventRegistrationCreateManyUserInputEnvelope>;
};

export type EventRegistrationCreateOrConnectWithoutEventOccurrenceInput = {
  create: EventRegistrationCreateWithoutEventOccurrenceInput;
  where: EventRegistrationWhereUniqueInput;
};

export type EventRegistrationCreateOrConnectWithoutUserInput = {
  create: EventRegistrationCreateWithoutUserInput;
  where: EventRegistrationWhereUniqueInput;
};

export type EventRegistrationCreateWithoutEventOccurrenceInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  user: UserCreateNestedOneWithoutEventRegistrationsInput;
};

export type EventRegistrationCreateWithoutUserInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventOccurrence?: InputMaybe<EventOccurrenceCreateNestedOneWithoutEventRegistrationsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type EventRegistrationGroupBy = {
  _count?: Maybe<EventRegistrationCountAggregate>;
  _max?: Maybe<EventRegistrationMaxAggregate>;
  _min?: Maybe<EventRegistrationMinAggregate>;
  created: Scalars['DateTimeISO']['output'];
  eventOccurrenceId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type EventRegistrationListRelationFilter = {
  every?: InputMaybe<EventRegistrationWhereInput>;
  none?: InputMaybe<EventRegistrationWhereInput>;
  some?: InputMaybe<EventRegistrationWhereInput>;
};

export type EventRegistrationMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  eventOccurrenceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type EventRegistrationMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  eventOccurrenceId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type EventRegistrationMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  eventOccurrenceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type EventRegistrationMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  eventOccurrenceId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type EventRegistrationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EventRegistrationOrderByWithAggregationInput = {
  _count?: InputMaybe<EventRegistrationCountOrderByAggregateInput>;
  _max?: InputMaybe<EventRegistrationMaxOrderByAggregateInput>;
  _min?: InputMaybe<EventRegistrationMinOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  eventOccurrenceId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type EventRegistrationOrderByWithRelationInput = {
  created?: InputMaybe<SortOrder>;
  eventOccurrence?: InputMaybe<EventOccurrenceOrderByWithRelationInput>;
  eventOccurrenceId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum EventRegistrationScalarFieldEnum {
  Created = 'created',
  EventOccurrenceId = 'eventOccurrenceId',
  Id = 'id',
  Modified = 'modified',
  UserId = 'userId'
}

export type EventRegistrationScalarWhereInput = {
  AND?: InputMaybe<Array<EventRegistrationScalarWhereInput>>;
  NOT?: InputMaybe<Array<EventRegistrationScalarWhereInput>>;
  OR?: InputMaybe<Array<EventRegistrationScalarWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  eventOccurrenceId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type EventRegistrationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EventRegistrationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EventRegistrationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EventRegistrationScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  eventOccurrenceId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type EventRegistrationUpdateInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventOccurrence?: InputMaybe<EventOccurrenceUpdateOneWithoutEventRegistrationsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutEventRegistrationsNestedInput>;
};

export type EventRegistrationUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventRegistrationUpdateManyWithWhereWithoutEventOccurrenceInput = {
  data: EventRegistrationUpdateManyMutationInput;
  where: EventRegistrationScalarWhereInput;
};

export type EventRegistrationUpdateManyWithWhereWithoutUserInput = {
  data: EventRegistrationUpdateManyMutationInput;
  where: EventRegistrationScalarWhereInput;
};

export type EventRegistrationUpdateManyWithoutEventOccurrenceNestedInput = {
  connect?: InputMaybe<Array<EventRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventRegistrationCreateOrConnectWithoutEventOccurrenceInput>>;
  create?: InputMaybe<Array<EventRegistrationCreateWithoutEventOccurrenceInput>>;
  createMany?: InputMaybe<EventRegistrationCreateManyEventOccurrenceInputEnvelope>;
  delete?: InputMaybe<Array<EventRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<EventRegistrationWhereUniqueInput>>;
  update?: InputMaybe<Array<EventRegistrationUpdateWithWhereUniqueWithoutEventOccurrenceInput>>;
  updateMany?: InputMaybe<Array<EventRegistrationUpdateManyWithWhereWithoutEventOccurrenceInput>>;
  upsert?: InputMaybe<Array<EventRegistrationUpsertWithWhereUniqueWithoutEventOccurrenceInput>>;
};

export type EventRegistrationUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<EventRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventRegistrationCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<EventRegistrationCreateWithoutUserInput>>;
  createMany?: InputMaybe<EventRegistrationCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<EventRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<EventRegistrationWhereUniqueInput>>;
  update?: InputMaybe<Array<EventRegistrationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<EventRegistrationUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<EventRegistrationUpsertWithWhereUniqueWithoutUserInput>>;
};

export type EventRegistrationUpdateWithWhereUniqueWithoutEventOccurrenceInput = {
  data: EventRegistrationUpdateWithoutEventOccurrenceInput;
  where: EventRegistrationWhereUniqueInput;
};

export type EventRegistrationUpdateWithWhereUniqueWithoutUserInput = {
  data: EventRegistrationUpdateWithoutUserInput;
  where: EventRegistrationWhereUniqueInput;
};

export type EventRegistrationUpdateWithoutEventOccurrenceInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutEventRegistrationsNestedInput>;
};

export type EventRegistrationUpdateWithoutUserInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventOccurrence?: InputMaybe<EventOccurrenceUpdateOneWithoutEventRegistrationsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventRegistrationUpsertWithWhereUniqueWithoutEventOccurrenceInput = {
  create: EventRegistrationCreateWithoutEventOccurrenceInput;
  update: EventRegistrationUpdateWithoutEventOccurrenceInput;
  where: EventRegistrationWhereUniqueInput;
};

export type EventRegistrationUpsertWithWhereUniqueWithoutUserInput = {
  create: EventRegistrationCreateWithoutUserInput;
  update: EventRegistrationUpdateWithoutUserInput;
  where: EventRegistrationWhereUniqueInput;
};

export type EventRegistrationWhereInput = {
  AND?: InputMaybe<Array<EventRegistrationWhereInput>>;
  NOT?: InputMaybe<Array<EventRegistrationWhereInput>>;
  OR?: InputMaybe<Array<EventRegistrationWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  eventOccurrence?: InputMaybe<EventOccurrenceNullableRelationFilter>;
  eventOccurrenceId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type EventRegistrationWhereUniqueInput = {
  AND?: InputMaybe<Array<EventRegistrationWhereInput>>;
  NOT?: InputMaybe<Array<EventRegistrationWhereInput>>;
  OR?: InputMaybe<Array<EventRegistrationWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  eventOccurrence?: InputMaybe<EventOccurrenceNullableRelationFilter>;
  eventOccurrenceId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type EventRelationFilter = {
  is?: InputMaybe<EventWhereInput>;
  isNot?: InputMaybe<EventWhereInput>;
};

export enum EventScalarFieldEnum {
  Created = 'created',
  Description = 'description',
  Id = 'id',
  MaxRegistrations = 'maxRegistrations',
  Modified = 'modified',
  Slug = 'slug',
  Title = 'title'
}

export type EventScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EventScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EventScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EventScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  maxRegistrations?: InputMaybe<IntNullableWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
};

export type EventSumAggregate = {
  maxRegistrations?: Maybe<Scalars['Int']['output']>;
};

export type EventSumOrderByAggregateInput = {
  maxRegistrations?: InputMaybe<SortOrder>;
};

export type EventUpdateInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventCategories?: InputMaybe<EventCategoryUpdateManyWithoutEventNestedInput>;
  eventOccurrences?: InputMaybe<EventOccurrenceUpdateManyWithoutEventNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxRegistrations?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EventUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxRegistrations?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EventUpdateOneRequiredWithoutEventCategoriesNestedInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutEventCategoriesInput>;
  create?: InputMaybe<EventCreateWithoutEventCategoriesInput>;
  update?: InputMaybe<EventUpdateToOneWithWhereWithoutEventCategoriesInput>;
  upsert?: InputMaybe<EventUpsertWithoutEventCategoriesInput>;
};

export type EventUpdateOneRequiredWithoutEventOccurrencesNestedInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutEventOccurrencesInput>;
  create?: InputMaybe<EventCreateWithoutEventOccurrencesInput>;
  update?: InputMaybe<EventUpdateToOneWithWhereWithoutEventOccurrencesInput>;
  upsert?: InputMaybe<EventUpsertWithoutEventOccurrencesInput>;
};

export type EventUpdateToOneWithWhereWithoutEventCategoriesInput = {
  data: EventUpdateWithoutEventCategoriesInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpdateToOneWithWhereWithoutEventOccurrencesInput = {
  data: EventUpdateWithoutEventOccurrencesInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpdateWithoutEventCategoriesInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventOccurrences?: InputMaybe<EventOccurrenceUpdateManyWithoutEventNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxRegistrations?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutEventOccurrencesInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventCategories?: InputMaybe<EventCategoryUpdateManyWithoutEventNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxRegistrations?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EventUpsertWithoutEventCategoriesInput = {
  create: EventCreateWithoutEventCategoriesInput;
  update: EventUpdateWithoutEventCategoriesInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpsertWithoutEventOccurrencesInput = {
  create: EventCreateWithoutEventOccurrencesInput;
  update: EventUpdateWithoutEventOccurrencesInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventWhereInput = {
  AND?: InputMaybe<Array<EventWhereInput>>;
  NOT?: InputMaybe<Array<EventWhereInput>>;
  OR?: InputMaybe<Array<EventWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  eventCategories?: InputMaybe<EventCategoryListRelationFilter>;
  eventOccurrences?: InputMaybe<EventOccurrenceListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  maxRegistrations?: InputMaybe<IntNullableFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type EventWhereUniqueInput = {
  AND?: InputMaybe<Array<EventWhereInput>>;
  NOT?: InputMaybe<Array<EventWhereInput>>;
  OR?: InputMaybe<Array<EventWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  eventCategories?: InputMaybe<EventCategoryListRelationFilter>;
  eventOccurrences?: InputMaybe<EventOccurrenceListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxRegistrations?: InputMaybe<IntNullableFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<StringFilter>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type Guest = {
  created: Scalars['DateTimeISO']['output'];
  fingerprintId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  visitCount: Scalars['Int']['output'];
};

export type GuestAvgAggregate = {
  visitCount?: Maybe<Scalars['Float']['output']>;
};

export type GuestAvgOrderByAggregateInput = {
  visitCount?: InputMaybe<SortOrder>;
};

export type GuestCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  fingerprintId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  visitCount: Scalars['Int']['output'];
};

export type GuestCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  fingerprintId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  visitCount?: InputMaybe<SortOrder>;
};

export type GuestCreateInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  fingerprintId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  visitCount?: InputMaybe<Scalars['Int']['input']>;
};

export type GuestCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  fingerprintId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  visitCount?: InputMaybe<Scalars['Int']['input']>;
};

export type GuestGroupBy = {
  _avg?: Maybe<GuestAvgAggregate>;
  _count?: Maybe<GuestCountAggregate>;
  _max?: Maybe<GuestMaxAggregate>;
  _min?: Maybe<GuestMinAggregate>;
  _sum?: Maybe<GuestSumAggregate>;
  created: Scalars['DateTimeISO']['output'];
  fingerprintId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  visitCount: Scalars['Int']['output'];
};

export type GuestMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  fingerprintId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  visitCount?: Maybe<Scalars['Int']['output']>;
};

export type GuestMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  fingerprintId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  visitCount?: InputMaybe<SortOrder>;
};

export type GuestMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  fingerprintId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  visitCount?: Maybe<Scalars['Int']['output']>;
};

export type GuestMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  fingerprintId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  visitCount?: InputMaybe<SortOrder>;
};

export type GuestOrderByWithAggregationInput = {
  _avg?: InputMaybe<GuestAvgOrderByAggregateInput>;
  _count?: InputMaybe<GuestCountOrderByAggregateInput>;
  _max?: InputMaybe<GuestMaxOrderByAggregateInput>;
  _min?: InputMaybe<GuestMinOrderByAggregateInput>;
  _sum?: InputMaybe<GuestSumOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  fingerprintId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  visitCount?: InputMaybe<SortOrder>;
};

export type GuestOrderByWithRelationInput = {
  created?: InputMaybe<SortOrder>;
  fingerprintId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  visitCount?: InputMaybe<SortOrder>;
};

export enum GuestScalarFieldEnum {
  Created = 'created',
  FingerprintId = 'fingerprintId',
  Id = 'id',
  Modified = 'modified',
  VisitCount = 'visitCount'
}

export type GuestScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<GuestScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<GuestScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<GuestScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  fingerprintId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  visitCount?: InputMaybe<IntWithAggregatesFilter>;
};

export type GuestSumAggregate = {
  visitCount?: Maybe<Scalars['Int']['output']>;
};

export type GuestSumOrderByAggregateInput = {
  visitCount?: InputMaybe<SortOrder>;
};

export type GuestUpdateInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fingerprintId?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visitCount?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type GuestUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fingerprintId?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visitCount?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type GuestWhereInput = {
  AND?: InputMaybe<Array<GuestWhereInput>>;
  NOT?: InputMaybe<Array<GuestWhereInput>>;
  OR?: InputMaybe<Array<GuestWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  fingerprintId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  visitCount?: InputMaybe<IntFilter>;
};

export type GuestWhereUniqueInput = {
  AND?: InputMaybe<Array<GuestWhereInput>>;
  NOT?: InputMaybe<Array<GuestWhereInput>>;
  OR?: InputMaybe<Array<GuestWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  fingerprintId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  visitCount?: InputMaybe<IntFilter>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type JsonNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedJsonNullableFilter>;
  _min?: InputMaybe<NestedJsonNullableFilter>;
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type JsonWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedJsonFilter>;
  _min?: InputMaybe<NestedJsonFilter>;
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum LearningStyle {
  FlexibleSequential = 'FLEXIBLE_SEQUENTIAL',
  RigidSequential = 'RIGID_SEQUENTIAL',
  Unstructured = 'UNSTRUCTURED'
}

export type Mutation = {
  createManyArticle: AffectedRowsOutput;
  createManyArticleCategory: AffectedRowsOutput;
  createManyCategory: AffectedRowsOutput;
  createManyCategoryPrerequisite: AffectedRowsOutput;
  createManyContent: AffectedRowsOutput;
  createManyEvent: AffectedRowsOutput;
  createManyEventAttendance: AffectedRowsOutput;
  createManyEventCategory: AffectedRowsOutput;
  createManyEventOccurrence: AffectedRowsOutput;
  createManyEventRegistration: AffectedRowsOutput;
  createManyGuest: AffectedRowsOutput;
  createManyPayment: AffectedRowsOutput;
  createManyPaymentItem: AffectedRowsOutput;
  createManyProduct: AffectedRowsOutput;
  createManyProductProgramme: AffectedRowsOutput;
  createManyProductProgrammePrerequisite: AffectedRowsOutput;
  createManyProductSubscriptionType: AffectedRowsOutput;
  createManyProgramme: AffectedRowsOutput;
  createManyProgrammeProgression: AffectedRowsOutput;
  createManyQuiz: AffectedRowsOutput;
  createManyQuizCategory: AffectedRowsOutput;
  createManyResource: AffectedRowsOutput;
  createManyResourceCategory: AffectedRowsOutput;
  createManyResourceType: AffectedRowsOutput;
  createManyStripeEvent: AffectedRowsOutput;
  createManyStripePaymentIntent: AffectedRowsOutput;
  createManyUser: AffectedRowsOutput;
  createManyUserArticle: AffectedRowsOutput;
  createManyUserManualActivity: AffectedRowsOutput;
  createManyUserQuiz: AffectedRowsOutput;
  createManyUserResource: AffectedRowsOutput;
  createManyUserSubscription: AffectedRowsOutput;
  createOneArticle: Article;
  createOneArticleCategory: ArticleCategory;
  createOneCategory: Category;
  createOneCategoryPrerequisite: CategoryPrerequisite;
  createOneContent: Content;
  createOneEvent: Event;
  createOneEventAttendance: EventAttendance;
  createOneEventCategory: EventCategory;
  createOneEventOccurrence: EventOccurrence;
  createOneEventRegistration: EventRegistration;
  createOneGuest: Guest;
  createOnePayment: Payment;
  createOnePaymentItem: PaymentItem;
  createOneProduct: Product;
  createOneProductProgramme: ProductProgramme;
  createOneProductProgrammePrerequisite: ProductProgrammePrerequisite;
  createOneProductSubscriptionType: ProductSubscriptionType;
  createOneProgramme: Programme;
  createOneProgrammeProgression: ProgrammeProgression;
  createOneQuiz: Quiz;
  createOneQuizCategory: QuizCategory;
  createOneResource: Resource;
  createOneResourceCategory: ResourceCategory;
  createOneResourceType: ResourceType;
  createOneStripeEvent: StripeEvent;
  createOneStripePaymentIntent: StripePaymentIntent;
  createOneUser: User;
  createOneUserArticle: UserArticle;
  createOneUserManualActivity: UserManualActivity;
  createOneUserQuiz: UserQuiz;
  createOneUserResource: UserResource;
  createOneUserSubscription: UserSubscription;
  deleteManyArticle: AffectedRowsOutput;
  deleteManyArticleCategory: AffectedRowsOutput;
  deleteManyCategory: AffectedRowsOutput;
  deleteManyCategoryPrerequisite: AffectedRowsOutput;
  deleteManyContent: AffectedRowsOutput;
  deleteManyEvent: AffectedRowsOutput;
  deleteManyEventAttendance: AffectedRowsOutput;
  deleteManyEventCategory: AffectedRowsOutput;
  deleteManyEventOccurrence: AffectedRowsOutput;
  deleteManyEventRegistration: AffectedRowsOutput;
  deleteManyGuest: AffectedRowsOutput;
  deleteManyPayment: AffectedRowsOutput;
  deleteManyPaymentItem: AffectedRowsOutput;
  deleteManyProduct: AffectedRowsOutput;
  deleteManyProductProgramme: AffectedRowsOutput;
  deleteManyProductProgrammePrerequisite: AffectedRowsOutput;
  deleteManyProductSubscriptionType: AffectedRowsOutput;
  deleteManyProgramme: AffectedRowsOutput;
  deleteManyProgrammeProgression: AffectedRowsOutput;
  deleteManyQuiz: AffectedRowsOutput;
  deleteManyQuizCategory: AffectedRowsOutput;
  deleteManyResource: AffectedRowsOutput;
  deleteManyResourceCategory: AffectedRowsOutput;
  deleteManyResourceType: AffectedRowsOutput;
  deleteManyStripeEvent: AffectedRowsOutput;
  deleteManyStripePaymentIntent: AffectedRowsOutput;
  deleteManyUser: AffectedRowsOutput;
  deleteManyUserArticle: AffectedRowsOutput;
  deleteManyUserManualActivity: AffectedRowsOutput;
  deleteManyUserQuiz: AffectedRowsOutput;
  deleteManyUserResource: AffectedRowsOutput;
  deleteManyUserSubscription: AffectedRowsOutput;
  deleteOneArticle?: Maybe<Article>;
  deleteOneArticleCategory?: Maybe<ArticleCategory>;
  deleteOneCategory?: Maybe<Category>;
  deleteOneCategoryPrerequisite?: Maybe<CategoryPrerequisite>;
  deleteOneContent?: Maybe<Content>;
  deleteOneEvent?: Maybe<Event>;
  deleteOneEventAttendance?: Maybe<EventAttendance>;
  deleteOneEventCategory?: Maybe<EventCategory>;
  deleteOneEventOccurrence?: Maybe<EventOccurrence>;
  deleteOneEventRegistration?: Maybe<EventRegistration>;
  deleteOneGuest?: Maybe<Guest>;
  deleteOnePayment?: Maybe<Payment>;
  deleteOnePaymentItem?: Maybe<PaymentItem>;
  deleteOneProduct?: Maybe<Product>;
  deleteOneProductProgramme?: Maybe<ProductProgramme>;
  deleteOneProductProgrammePrerequisite?: Maybe<ProductProgrammePrerequisite>;
  deleteOneProductSubscriptionType?: Maybe<ProductSubscriptionType>;
  deleteOneProgramme?: Maybe<Programme>;
  deleteOneProgrammeProgression?: Maybe<ProgrammeProgression>;
  deleteOneQuiz?: Maybe<Quiz>;
  deleteOneQuizCategory?: Maybe<QuizCategory>;
  deleteOneResource?: Maybe<Resource>;
  deleteOneResourceCategory?: Maybe<ResourceCategory>;
  deleteOneResourceType?: Maybe<ResourceType>;
  deleteOneStripeEvent?: Maybe<StripeEvent>;
  deleteOneStripePaymentIntent?: Maybe<StripePaymentIntent>;
  deleteOneUser?: Maybe<User>;
  deleteOneUserArticle?: Maybe<UserArticle>;
  deleteOneUserManualActivity?: Maybe<UserManualActivity>;
  deleteOneUserQuiz?: Maybe<UserQuiz>;
  deleteOneUserResource?: Maybe<UserResource>;
  deleteOneUserSubscription?: Maybe<UserSubscription>;
  updateManyArticle: AffectedRowsOutput;
  updateManyArticleCategory: AffectedRowsOutput;
  updateManyCategory: AffectedRowsOutput;
  updateManyCategoryPrerequisite: AffectedRowsOutput;
  updateManyContent: AffectedRowsOutput;
  updateManyEvent: AffectedRowsOutput;
  updateManyEventAttendance: AffectedRowsOutput;
  updateManyEventCategory: AffectedRowsOutput;
  updateManyEventOccurrence: AffectedRowsOutput;
  updateManyEventRegistration: AffectedRowsOutput;
  updateManyGuest: AffectedRowsOutput;
  updateManyPayment: AffectedRowsOutput;
  updateManyPaymentItem: AffectedRowsOutput;
  updateManyProduct: AffectedRowsOutput;
  updateManyProductProgramme: AffectedRowsOutput;
  updateManyProductProgrammePrerequisite: AffectedRowsOutput;
  updateManyProductSubscriptionType: AffectedRowsOutput;
  updateManyProgramme: AffectedRowsOutput;
  updateManyProgrammeProgression: AffectedRowsOutput;
  updateManyQuiz: AffectedRowsOutput;
  updateManyQuizCategory: AffectedRowsOutput;
  updateManyResource: AffectedRowsOutput;
  updateManyResourceCategory: AffectedRowsOutput;
  updateManyResourceType: AffectedRowsOutput;
  updateManyStripeEvent: AffectedRowsOutput;
  updateManyStripePaymentIntent: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  updateManyUserArticle: AffectedRowsOutput;
  updateManyUserManualActivity: AffectedRowsOutput;
  updateManyUserQuiz: AffectedRowsOutput;
  updateManyUserResource: AffectedRowsOutput;
  updateManyUserSubscription: AffectedRowsOutput;
  updateOneArticle?: Maybe<Article>;
  updateOneArticleCategory?: Maybe<ArticleCategory>;
  updateOneCategory?: Maybe<Category>;
  updateOneCategoryPrerequisite?: Maybe<CategoryPrerequisite>;
  updateOneContent?: Maybe<Content>;
  updateOneEvent?: Maybe<Event>;
  updateOneEventAttendance?: Maybe<EventAttendance>;
  updateOneEventCategory?: Maybe<EventCategory>;
  updateOneEventOccurrence?: Maybe<EventOccurrence>;
  updateOneEventRegistration?: Maybe<EventRegistration>;
  updateOneGuest?: Maybe<Guest>;
  updateOnePayment?: Maybe<Payment>;
  updateOnePaymentItem?: Maybe<PaymentItem>;
  updateOneProduct?: Maybe<Product>;
  updateOneProductProgramme?: Maybe<ProductProgramme>;
  updateOneProductProgrammePrerequisite?: Maybe<ProductProgrammePrerequisite>;
  updateOneProductSubscriptionType?: Maybe<ProductSubscriptionType>;
  updateOneProgramme?: Maybe<Programme>;
  updateOneProgrammeProgression?: Maybe<ProgrammeProgression>;
  updateOneQuiz?: Maybe<Quiz>;
  updateOneQuizCategory?: Maybe<QuizCategory>;
  updateOneResource?: Maybe<Resource>;
  updateOneResourceCategory?: Maybe<ResourceCategory>;
  updateOneResourceType?: Maybe<ResourceType>;
  updateOneStripeEvent?: Maybe<StripeEvent>;
  updateOneStripePaymentIntent?: Maybe<StripePaymentIntent>;
  updateOneUser?: Maybe<User>;
  updateOneUserArticle?: Maybe<UserArticle>;
  updateOneUserManualActivity?: Maybe<UserManualActivity>;
  updateOneUserQuiz?: Maybe<UserQuiz>;
  updateOneUserResource?: Maybe<UserResource>;
  updateOneUserSubscription?: Maybe<UserSubscription>;
  upsertOneArticle: Article;
  upsertOneArticleCategory: ArticleCategory;
  upsertOneCategory: Category;
  upsertOneCategoryPrerequisite: CategoryPrerequisite;
  upsertOneContent: Content;
  upsertOneEvent: Event;
  upsertOneEventAttendance: EventAttendance;
  upsertOneEventCategory: EventCategory;
  upsertOneEventOccurrence: EventOccurrence;
  upsertOneEventRegistration: EventRegistration;
  upsertOneGuest: Guest;
  upsertOnePayment: Payment;
  upsertOnePaymentItem: PaymentItem;
  upsertOneProduct: Product;
  upsertOneProductProgramme: ProductProgramme;
  upsertOneProductProgrammePrerequisite: ProductProgrammePrerequisite;
  upsertOneProductSubscriptionType: ProductSubscriptionType;
  upsertOneProgramme: Programme;
  upsertOneProgrammeProgression: ProgrammeProgression;
  upsertOneQuiz: Quiz;
  upsertOneQuizCategory: QuizCategory;
  upsertOneResource: Resource;
  upsertOneResourceCategory: ResourceCategory;
  upsertOneResourceType: ResourceType;
  upsertOneStripeEvent: StripeEvent;
  upsertOneStripePaymentIntent: StripePaymentIntent;
  upsertOneUser: User;
  upsertOneUserArticle: UserArticle;
  upsertOneUserManualActivity: UserManualActivity;
  upsertOneUserQuiz: UserQuiz;
  upsertOneUserResource: UserResource;
  upsertOneUserSubscription: UserSubscription;
};


export type MutationCreateManyArticleArgs = {
  data: Array<ArticleCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyArticleCategoryArgs = {
  data: Array<ArticleCategoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCategoryArgs = {
  data: Array<CategoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCategoryPrerequisiteArgs = {
  data: Array<CategoryPrerequisiteCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyContentArgs = {
  data: Array<ContentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyEventArgs = {
  data: Array<EventCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyEventAttendanceArgs = {
  data: Array<EventAttendanceCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyEventCategoryArgs = {
  data: Array<EventCategoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyEventOccurrenceArgs = {
  data: Array<EventOccurrenceCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyEventRegistrationArgs = {
  data: Array<EventRegistrationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyGuestArgs = {
  data: Array<GuestCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyPaymentArgs = {
  data: Array<PaymentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyPaymentItemArgs = {
  data: Array<PaymentItemCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyProductArgs = {
  data: Array<ProductCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyProductProgrammeArgs = {
  data: Array<ProductProgrammeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyProductProgrammePrerequisiteArgs = {
  data: Array<ProductProgrammePrerequisiteCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyProductSubscriptionTypeArgs = {
  data: Array<ProductSubscriptionTypeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyProgrammeArgs = {
  data: Array<ProgrammeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyProgrammeProgressionArgs = {
  data: Array<ProgrammeProgressionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyQuizArgs = {
  data: Array<QuizCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyQuizCategoryArgs = {
  data: Array<QuizCategoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyResourceArgs = {
  data: Array<ResourceCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyResourceCategoryArgs = {
  data: Array<ResourceCategoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyResourceTypeArgs = {
  data: Array<ResourceTypeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyStripeEventArgs = {
  data: Array<StripeEventCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyStripePaymentIntentArgs = {
  data: Array<StripePaymentIntentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserArticleArgs = {
  data: Array<UserArticleCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserManualActivityArgs = {
  data: Array<UserManualActivityCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserQuizArgs = {
  data: Array<UserQuizCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserResourceArgs = {
  data: Array<UserResourceCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserSubscriptionArgs = {
  data: Array<UserSubscriptionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateOneArticleArgs = {
  data: ArticleCreateInput;
};


export type MutationCreateOneArticleCategoryArgs = {
  data: ArticleCategoryCreateInput;
};


export type MutationCreateOneCategoryArgs = {
  data: CategoryCreateInput;
};


export type MutationCreateOneCategoryPrerequisiteArgs = {
  data: CategoryPrerequisiteCreateInput;
};


export type MutationCreateOneContentArgs = {
  data: ContentCreateInput;
};


export type MutationCreateOneEventArgs = {
  data: EventCreateInput;
};


export type MutationCreateOneEventAttendanceArgs = {
  data: EventAttendanceCreateInput;
};


export type MutationCreateOneEventCategoryArgs = {
  data: EventCategoryCreateInput;
};


export type MutationCreateOneEventOccurrenceArgs = {
  data: EventOccurrenceCreateInput;
};


export type MutationCreateOneEventRegistrationArgs = {
  data: EventRegistrationCreateInput;
};


export type MutationCreateOneGuestArgs = {
  data: GuestCreateInput;
};


export type MutationCreateOnePaymentArgs = {
  data: PaymentCreateInput;
};


export type MutationCreateOnePaymentItemArgs = {
  data: PaymentItemCreateInput;
};


export type MutationCreateOneProductArgs = {
  data: ProductCreateInput;
};


export type MutationCreateOneProductProgrammeArgs = {
  data: ProductProgrammeCreateInput;
};


export type MutationCreateOneProductProgrammePrerequisiteArgs = {
  data: ProductProgrammePrerequisiteCreateInput;
};


export type MutationCreateOneProductSubscriptionTypeArgs = {
  data: ProductSubscriptionTypeCreateInput;
};


export type MutationCreateOneProgrammeArgs = {
  data: ProgrammeCreateInput;
};


export type MutationCreateOneProgrammeProgressionArgs = {
  data: ProgrammeProgressionCreateInput;
};


export type MutationCreateOneQuizArgs = {
  data: QuizCreateInput;
};


export type MutationCreateOneQuizCategoryArgs = {
  data: QuizCategoryCreateInput;
};


export type MutationCreateOneResourceArgs = {
  data: ResourceCreateInput;
};


export type MutationCreateOneResourceCategoryArgs = {
  data: ResourceCategoryCreateInput;
};


export type MutationCreateOneResourceTypeArgs = {
  data: ResourceTypeCreateInput;
};


export type MutationCreateOneStripeEventArgs = {
  data: StripeEventCreateInput;
};


export type MutationCreateOneStripePaymentIntentArgs = {
  data: StripePaymentIntentCreateInput;
};


export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateOneUserArticleArgs = {
  data: UserArticleCreateInput;
};


export type MutationCreateOneUserManualActivityArgs = {
  data: UserManualActivityCreateInput;
};


export type MutationCreateOneUserQuizArgs = {
  data: UserQuizCreateInput;
};


export type MutationCreateOneUserResourceArgs = {
  data: UserResourceCreateInput;
};


export type MutationCreateOneUserSubscriptionArgs = {
  data: UserSubscriptionCreateInput;
};


export type MutationDeleteManyArticleArgs = {
  where?: InputMaybe<ArticleWhereInput>;
};


export type MutationDeleteManyArticleCategoryArgs = {
  where?: InputMaybe<ArticleCategoryWhereInput>;
};


export type MutationDeleteManyCategoryArgs = {
  where?: InputMaybe<CategoryWhereInput>;
};


export type MutationDeleteManyCategoryPrerequisiteArgs = {
  where?: InputMaybe<CategoryPrerequisiteWhereInput>;
};


export type MutationDeleteManyContentArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationDeleteManyEventArgs = {
  where?: InputMaybe<EventWhereInput>;
};


export type MutationDeleteManyEventAttendanceArgs = {
  where?: InputMaybe<EventAttendanceWhereInput>;
};


export type MutationDeleteManyEventCategoryArgs = {
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type MutationDeleteManyEventOccurrenceArgs = {
  where?: InputMaybe<EventOccurrenceWhereInput>;
};


export type MutationDeleteManyEventRegistrationArgs = {
  where?: InputMaybe<EventRegistrationWhereInput>;
};


export type MutationDeleteManyGuestArgs = {
  where?: InputMaybe<GuestWhereInput>;
};


export type MutationDeleteManyPaymentArgs = {
  where?: InputMaybe<PaymentWhereInput>;
};


export type MutationDeleteManyPaymentItemArgs = {
  where?: InputMaybe<PaymentItemWhereInput>;
};


export type MutationDeleteManyProductArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type MutationDeleteManyProductProgrammeArgs = {
  where?: InputMaybe<ProductProgrammeWhereInput>;
};


export type MutationDeleteManyProductProgrammePrerequisiteArgs = {
  where?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
};


export type MutationDeleteManyProductSubscriptionTypeArgs = {
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};


export type MutationDeleteManyProgrammeArgs = {
  where?: InputMaybe<ProgrammeWhereInput>;
};


export type MutationDeleteManyProgrammeProgressionArgs = {
  where?: InputMaybe<ProgrammeProgressionWhereInput>;
};


export type MutationDeleteManyQuizArgs = {
  where?: InputMaybe<QuizWhereInput>;
};


export type MutationDeleteManyQuizCategoryArgs = {
  where?: InputMaybe<QuizCategoryWhereInput>;
};


export type MutationDeleteManyResourceArgs = {
  where?: InputMaybe<ResourceWhereInput>;
};


export type MutationDeleteManyResourceCategoryArgs = {
  where?: InputMaybe<ResourceCategoryWhereInput>;
};


export type MutationDeleteManyResourceTypeArgs = {
  where?: InputMaybe<ResourceTypeWhereInput>;
};


export type MutationDeleteManyStripeEventArgs = {
  where?: InputMaybe<StripeEventWhereInput>;
};


export type MutationDeleteManyStripePaymentIntentArgs = {
  where?: InputMaybe<StripePaymentIntentWhereInput>;
};


export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type MutationDeleteManyUserArticleArgs = {
  where?: InputMaybe<UserArticleWhereInput>;
};


export type MutationDeleteManyUserManualActivityArgs = {
  where?: InputMaybe<UserManualActivityWhereInput>;
};


export type MutationDeleteManyUserQuizArgs = {
  where?: InputMaybe<UserQuizWhereInput>;
};


export type MutationDeleteManyUserResourceArgs = {
  where?: InputMaybe<UserResourceWhereInput>;
};


export type MutationDeleteManyUserSubscriptionArgs = {
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type MutationDeleteOneArticleArgs = {
  where: ArticleWhereUniqueInput;
};


export type MutationDeleteOneArticleCategoryArgs = {
  where: ArticleCategoryWhereUniqueInput;
};


export type MutationDeleteOneCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type MutationDeleteOneCategoryPrerequisiteArgs = {
  where: CategoryPrerequisiteWhereUniqueInput;
};


export type MutationDeleteOneContentArgs = {
  where: ContentWhereUniqueInput;
};


export type MutationDeleteOneEventArgs = {
  where: EventWhereUniqueInput;
};


export type MutationDeleteOneEventAttendanceArgs = {
  where: EventAttendanceWhereUniqueInput;
};


export type MutationDeleteOneEventCategoryArgs = {
  where: EventCategoryWhereUniqueInput;
};


export type MutationDeleteOneEventOccurrenceArgs = {
  where: EventOccurrenceWhereUniqueInput;
};


export type MutationDeleteOneEventRegistrationArgs = {
  where: EventRegistrationWhereUniqueInput;
};


export type MutationDeleteOneGuestArgs = {
  where: GuestWhereUniqueInput;
};


export type MutationDeleteOnePaymentArgs = {
  where: PaymentWhereUniqueInput;
};


export type MutationDeleteOnePaymentItemArgs = {
  where: PaymentItemWhereUniqueInput;
};


export type MutationDeleteOneProductArgs = {
  where: ProductWhereUniqueInput;
};


export type MutationDeleteOneProductProgrammeArgs = {
  where: ProductProgrammeWhereUniqueInput;
};


export type MutationDeleteOneProductProgrammePrerequisiteArgs = {
  where: ProductProgrammePrerequisiteWhereUniqueInput;
};


export type MutationDeleteOneProductSubscriptionTypeArgs = {
  where: ProductSubscriptionTypeWhereUniqueInput;
};


export type MutationDeleteOneProgrammeArgs = {
  where: ProgrammeWhereUniqueInput;
};


export type MutationDeleteOneProgrammeProgressionArgs = {
  where: ProgrammeProgressionWhereUniqueInput;
};


export type MutationDeleteOneQuizArgs = {
  where: QuizWhereUniqueInput;
};


export type MutationDeleteOneQuizCategoryArgs = {
  where: QuizCategoryWhereUniqueInput;
};


export type MutationDeleteOneResourceArgs = {
  where: ResourceWhereUniqueInput;
};


export type MutationDeleteOneResourceCategoryArgs = {
  where: ResourceCategoryWhereUniqueInput;
};


export type MutationDeleteOneResourceTypeArgs = {
  where: ResourceTypeWhereUniqueInput;
};


export type MutationDeleteOneStripeEventArgs = {
  where: StripeEventWhereUniqueInput;
};


export type MutationDeleteOneStripePaymentIntentArgs = {
  where: StripePaymentIntentWhereUniqueInput;
};


export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteOneUserArticleArgs = {
  where: UserArticleWhereUniqueInput;
};


export type MutationDeleteOneUserManualActivityArgs = {
  where: UserManualActivityWhereUniqueInput;
};


export type MutationDeleteOneUserQuizArgs = {
  where: UserQuizWhereUniqueInput;
};


export type MutationDeleteOneUserResourceArgs = {
  where: UserResourceWhereUniqueInput;
};


export type MutationDeleteOneUserSubscriptionArgs = {
  where: UserSubscriptionWhereUniqueInput;
};


export type MutationUpdateManyArticleArgs = {
  data: ArticleUpdateManyMutationInput;
  where?: InputMaybe<ArticleWhereInput>;
};


export type MutationUpdateManyArticleCategoryArgs = {
  data: ArticleCategoryUpdateManyMutationInput;
  where?: InputMaybe<ArticleCategoryWhereInput>;
};


export type MutationUpdateManyCategoryArgs = {
  data: CategoryUpdateManyMutationInput;
  where?: InputMaybe<CategoryWhereInput>;
};


export type MutationUpdateManyCategoryPrerequisiteArgs = {
  data: CategoryPrerequisiteUpdateManyMutationInput;
  where?: InputMaybe<CategoryPrerequisiteWhereInput>;
};


export type MutationUpdateManyContentArgs = {
  data: ContentUpdateManyMutationInput;
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationUpdateManyEventArgs = {
  data: EventUpdateManyMutationInput;
  where?: InputMaybe<EventWhereInput>;
};


export type MutationUpdateManyEventAttendanceArgs = {
  data: EventAttendanceUpdateManyMutationInput;
  where?: InputMaybe<EventAttendanceWhereInput>;
};


export type MutationUpdateManyEventCategoryArgs = {
  data: EventCategoryUpdateManyMutationInput;
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type MutationUpdateManyEventOccurrenceArgs = {
  data: EventOccurrenceUpdateManyMutationInput;
  where?: InputMaybe<EventOccurrenceWhereInput>;
};


export type MutationUpdateManyEventRegistrationArgs = {
  data: EventRegistrationUpdateManyMutationInput;
  where?: InputMaybe<EventRegistrationWhereInput>;
};


export type MutationUpdateManyGuestArgs = {
  data: GuestUpdateManyMutationInput;
  where?: InputMaybe<GuestWhereInput>;
};


export type MutationUpdateManyPaymentArgs = {
  data: PaymentUpdateManyMutationInput;
  where?: InputMaybe<PaymentWhereInput>;
};


export type MutationUpdateManyPaymentItemArgs = {
  data: PaymentItemUpdateManyMutationInput;
  where?: InputMaybe<PaymentItemWhereInput>;
};


export type MutationUpdateManyProductArgs = {
  data: ProductUpdateManyMutationInput;
  where?: InputMaybe<ProductWhereInput>;
};


export type MutationUpdateManyProductProgrammeArgs = {
  data: ProductProgrammeUpdateManyMutationInput;
  where?: InputMaybe<ProductProgrammeWhereInput>;
};


export type MutationUpdateManyProductProgrammePrerequisiteArgs = {
  data: ProductProgrammePrerequisiteUpdateManyMutationInput;
  where?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
};


export type MutationUpdateManyProductSubscriptionTypeArgs = {
  data: ProductSubscriptionTypeUpdateManyMutationInput;
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};


export type MutationUpdateManyProgrammeArgs = {
  data: ProgrammeUpdateManyMutationInput;
  where?: InputMaybe<ProgrammeWhereInput>;
};


export type MutationUpdateManyProgrammeProgressionArgs = {
  data: ProgrammeProgressionUpdateManyMutationInput;
  where?: InputMaybe<ProgrammeProgressionWhereInput>;
};


export type MutationUpdateManyQuizArgs = {
  data: QuizUpdateManyMutationInput;
  where?: InputMaybe<QuizWhereInput>;
};


export type MutationUpdateManyQuizCategoryArgs = {
  data: QuizCategoryUpdateManyMutationInput;
  where?: InputMaybe<QuizCategoryWhereInput>;
};


export type MutationUpdateManyResourceArgs = {
  data: ResourceUpdateManyMutationInput;
  where?: InputMaybe<ResourceWhereInput>;
};


export type MutationUpdateManyResourceCategoryArgs = {
  data: ResourceCategoryUpdateManyMutationInput;
  where?: InputMaybe<ResourceCategoryWhereInput>;
};


export type MutationUpdateManyResourceTypeArgs = {
  data: ResourceTypeUpdateManyMutationInput;
  where?: InputMaybe<ResourceTypeWhereInput>;
};


export type MutationUpdateManyStripeEventArgs = {
  data: StripeEventUpdateManyMutationInput;
  where?: InputMaybe<StripeEventWhereInput>;
};


export type MutationUpdateManyStripePaymentIntentArgs = {
  data: StripePaymentIntentUpdateManyMutationInput;
  where?: InputMaybe<StripePaymentIntentWhereInput>;
};


export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};


export type MutationUpdateManyUserArticleArgs = {
  data: UserArticleUpdateManyMutationInput;
  where?: InputMaybe<UserArticleWhereInput>;
};


export type MutationUpdateManyUserManualActivityArgs = {
  data: UserManualActivityUpdateManyMutationInput;
  where?: InputMaybe<UserManualActivityWhereInput>;
};


export type MutationUpdateManyUserQuizArgs = {
  data: UserQuizUpdateManyMutationInput;
  where?: InputMaybe<UserQuizWhereInput>;
};


export type MutationUpdateManyUserResourceArgs = {
  data: UserResourceUpdateManyMutationInput;
  where?: InputMaybe<UserResourceWhereInput>;
};


export type MutationUpdateManyUserSubscriptionArgs = {
  data: UserSubscriptionUpdateManyMutationInput;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type MutationUpdateOneArticleArgs = {
  data: ArticleUpdateInput;
  where: ArticleWhereUniqueInput;
};


export type MutationUpdateOneArticleCategoryArgs = {
  data: ArticleCategoryUpdateInput;
  where: ArticleCategoryWhereUniqueInput;
};


export type MutationUpdateOneCategoryArgs = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};


export type MutationUpdateOneCategoryPrerequisiteArgs = {
  data: CategoryPrerequisiteUpdateInput;
  where: CategoryPrerequisiteWhereUniqueInput;
};


export type MutationUpdateOneContentArgs = {
  data: ContentUpdateInput;
  where: ContentWhereUniqueInput;
};


export type MutationUpdateOneEventArgs = {
  data: EventUpdateInput;
  where: EventWhereUniqueInput;
};


export type MutationUpdateOneEventAttendanceArgs = {
  data: EventAttendanceUpdateInput;
  where: EventAttendanceWhereUniqueInput;
};


export type MutationUpdateOneEventCategoryArgs = {
  data: EventCategoryUpdateInput;
  where: EventCategoryWhereUniqueInput;
};


export type MutationUpdateOneEventOccurrenceArgs = {
  data: EventOccurrenceUpdateInput;
  where: EventOccurrenceWhereUniqueInput;
};


export type MutationUpdateOneEventRegistrationArgs = {
  data: EventRegistrationUpdateInput;
  where: EventRegistrationWhereUniqueInput;
};


export type MutationUpdateOneGuestArgs = {
  data: GuestUpdateInput;
  where: GuestWhereUniqueInput;
};


export type MutationUpdateOnePaymentArgs = {
  data: PaymentUpdateInput;
  where: PaymentWhereUniqueInput;
};


export type MutationUpdateOnePaymentItemArgs = {
  data: PaymentItemUpdateInput;
  where: PaymentItemWhereUniqueInput;
};


export type MutationUpdateOneProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpdateOneProductProgrammeArgs = {
  data: ProductProgrammeUpdateInput;
  where: ProductProgrammeWhereUniqueInput;
};


export type MutationUpdateOneProductProgrammePrerequisiteArgs = {
  data: ProductProgrammePrerequisiteUpdateInput;
  where: ProductProgrammePrerequisiteWhereUniqueInput;
};


export type MutationUpdateOneProductSubscriptionTypeArgs = {
  data: ProductSubscriptionTypeUpdateInput;
  where: ProductSubscriptionTypeWhereUniqueInput;
};


export type MutationUpdateOneProgrammeArgs = {
  data: ProgrammeUpdateInput;
  where: ProgrammeWhereUniqueInput;
};


export type MutationUpdateOneProgrammeProgressionArgs = {
  data: ProgrammeProgressionUpdateInput;
  where: ProgrammeProgressionWhereUniqueInput;
};


export type MutationUpdateOneQuizArgs = {
  data: QuizUpdateInput;
  where: QuizWhereUniqueInput;
};


export type MutationUpdateOneQuizCategoryArgs = {
  data: QuizCategoryUpdateInput;
  where: QuizCategoryWhereUniqueInput;
};


export type MutationUpdateOneResourceArgs = {
  data: ResourceUpdateInput;
  where: ResourceWhereUniqueInput;
};


export type MutationUpdateOneResourceCategoryArgs = {
  data: ResourceCategoryUpdateInput;
  where: ResourceCategoryWhereUniqueInput;
};


export type MutationUpdateOneResourceTypeArgs = {
  data: ResourceTypeUpdateInput;
  where: ResourceTypeWhereUniqueInput;
};


export type MutationUpdateOneStripeEventArgs = {
  data: StripeEventUpdateInput;
  where: StripeEventWhereUniqueInput;
};


export type MutationUpdateOneStripePaymentIntentArgs = {
  data: StripePaymentIntentUpdateInput;
  where: StripePaymentIntentWhereUniqueInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateOneUserArticleArgs = {
  data: UserArticleUpdateInput;
  where: UserArticleWhereUniqueInput;
};


export type MutationUpdateOneUserManualActivityArgs = {
  data: UserManualActivityUpdateInput;
  where: UserManualActivityWhereUniqueInput;
};


export type MutationUpdateOneUserQuizArgs = {
  data: UserQuizUpdateInput;
  where: UserQuizWhereUniqueInput;
};


export type MutationUpdateOneUserResourceArgs = {
  data: UserResourceUpdateInput;
  where: UserResourceWhereUniqueInput;
};


export type MutationUpdateOneUserSubscriptionArgs = {
  data: UserSubscriptionUpdateInput;
  where: UserSubscriptionWhereUniqueInput;
};


export type MutationUpsertOneArticleArgs = {
  create: ArticleCreateInput;
  update: ArticleUpdateInput;
  where: ArticleWhereUniqueInput;
};


export type MutationUpsertOneArticleCategoryArgs = {
  create: ArticleCategoryCreateInput;
  update: ArticleCategoryUpdateInput;
  where: ArticleCategoryWhereUniqueInput;
};


export type MutationUpsertOneCategoryArgs = {
  create: CategoryCreateInput;
  update: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};


export type MutationUpsertOneCategoryPrerequisiteArgs = {
  create: CategoryPrerequisiteCreateInput;
  update: CategoryPrerequisiteUpdateInput;
  where: CategoryPrerequisiteWhereUniqueInput;
};


export type MutationUpsertOneContentArgs = {
  create: ContentCreateInput;
  update: ContentUpdateInput;
  where: ContentWhereUniqueInput;
};


export type MutationUpsertOneEventArgs = {
  create: EventCreateInput;
  update: EventUpdateInput;
  where: EventWhereUniqueInput;
};


export type MutationUpsertOneEventAttendanceArgs = {
  create: EventAttendanceCreateInput;
  update: EventAttendanceUpdateInput;
  where: EventAttendanceWhereUniqueInput;
};


export type MutationUpsertOneEventCategoryArgs = {
  create: EventCategoryCreateInput;
  update: EventCategoryUpdateInput;
  where: EventCategoryWhereUniqueInput;
};


export type MutationUpsertOneEventOccurrenceArgs = {
  create: EventOccurrenceCreateInput;
  update: EventOccurrenceUpdateInput;
  where: EventOccurrenceWhereUniqueInput;
};


export type MutationUpsertOneEventRegistrationArgs = {
  create: EventRegistrationCreateInput;
  update: EventRegistrationUpdateInput;
  where: EventRegistrationWhereUniqueInput;
};


export type MutationUpsertOneGuestArgs = {
  create: GuestCreateInput;
  update: GuestUpdateInput;
  where: GuestWhereUniqueInput;
};


export type MutationUpsertOnePaymentArgs = {
  create: PaymentCreateInput;
  update: PaymentUpdateInput;
  where: PaymentWhereUniqueInput;
};


export type MutationUpsertOnePaymentItemArgs = {
  create: PaymentItemCreateInput;
  update: PaymentItemUpdateInput;
  where: PaymentItemWhereUniqueInput;
};


export type MutationUpsertOneProductArgs = {
  create: ProductCreateInput;
  update: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpsertOneProductProgrammeArgs = {
  create: ProductProgrammeCreateInput;
  update: ProductProgrammeUpdateInput;
  where: ProductProgrammeWhereUniqueInput;
};


export type MutationUpsertOneProductProgrammePrerequisiteArgs = {
  create: ProductProgrammePrerequisiteCreateInput;
  update: ProductProgrammePrerequisiteUpdateInput;
  where: ProductProgrammePrerequisiteWhereUniqueInput;
};


export type MutationUpsertOneProductSubscriptionTypeArgs = {
  create: ProductSubscriptionTypeCreateInput;
  update: ProductSubscriptionTypeUpdateInput;
  where: ProductSubscriptionTypeWhereUniqueInput;
};


export type MutationUpsertOneProgrammeArgs = {
  create: ProgrammeCreateInput;
  update: ProgrammeUpdateInput;
  where: ProgrammeWhereUniqueInput;
};


export type MutationUpsertOneProgrammeProgressionArgs = {
  create: ProgrammeProgressionCreateInput;
  update: ProgrammeProgressionUpdateInput;
  where: ProgrammeProgressionWhereUniqueInput;
};


export type MutationUpsertOneQuizArgs = {
  create: QuizCreateInput;
  update: QuizUpdateInput;
  where: QuizWhereUniqueInput;
};


export type MutationUpsertOneQuizCategoryArgs = {
  create: QuizCategoryCreateInput;
  update: QuizCategoryUpdateInput;
  where: QuizCategoryWhereUniqueInput;
};


export type MutationUpsertOneResourceArgs = {
  create: ResourceCreateInput;
  update: ResourceUpdateInput;
  where: ResourceWhereUniqueInput;
};


export type MutationUpsertOneResourceCategoryArgs = {
  create: ResourceCategoryCreateInput;
  update: ResourceCategoryUpdateInput;
  where: ResourceCategoryWhereUniqueInput;
};


export type MutationUpsertOneResourceTypeArgs = {
  create: ResourceTypeCreateInput;
  update: ResourceTypeUpdateInput;
  where: ResourceTypeWhereUniqueInput;
};


export type MutationUpsertOneStripeEventArgs = {
  create: StripeEventCreateInput;
  update: StripeEventUpdateInput;
  where: StripeEventWhereUniqueInput;
};


export type MutationUpsertOneStripePaymentIntentArgs = {
  create: StripePaymentIntentCreateInput;
  update: StripePaymentIntentUpdateInput;
  where: StripePaymentIntentWhereUniqueInput;
};


export type MutationUpsertOneUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertOneUserArticleArgs = {
  create: UserArticleCreateInput;
  update: UserArticleUpdateInput;
  where: UserArticleWhereUniqueInput;
};


export type MutationUpsertOneUserManualActivityArgs = {
  create: UserManualActivityCreateInput;
  update: UserManualActivityUpdateInput;
  where: UserManualActivityWhereUniqueInput;
};


export type MutationUpsertOneUserQuizArgs = {
  create: UserQuizCreateInput;
  update: UserQuizUpdateInput;
  where: UserQuizWhereUniqueInput;
};


export type MutationUpsertOneUserResourceArgs = {
  create: UserResourceCreateInput;
  update: UserResourceUpdateInput;
  where: UserResourceWhereUniqueInput;
};


export type MutationUpsertOneUserSubscriptionArgs = {
  create: UserSubscriptionCreateInput;
  update: UserSubscriptionUpdateInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedEnumAcceptedCurrencyFilter = {
  equals?: InputMaybe<AcceptedCurrency>;
  in?: InputMaybe<Array<AcceptedCurrency>>;
  not?: InputMaybe<NestedEnumAcceptedCurrencyFilter>;
  notIn?: InputMaybe<Array<AcceptedCurrency>>;
};

export type NestedEnumAcceptedCurrencyWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAcceptedCurrencyFilter>;
  _min?: InputMaybe<NestedEnumAcceptedCurrencyFilter>;
  equals?: InputMaybe<AcceptedCurrency>;
  in?: InputMaybe<Array<AcceptedCurrency>>;
  not?: InputMaybe<NestedEnumAcceptedCurrencyWithAggregatesFilter>;
  notIn?: InputMaybe<Array<AcceptedCurrency>>;
};

export type NestedEnumBrandingTypeFilter = {
  equals?: InputMaybe<BrandingType>;
  in?: InputMaybe<Array<BrandingType>>;
  not?: InputMaybe<NestedEnumBrandingTypeFilter>;
  notIn?: InputMaybe<Array<BrandingType>>;
};

export type NestedEnumBrandingTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumBrandingTypeFilter>;
  _min?: InputMaybe<NestedEnumBrandingTypeFilter>;
  equals?: InputMaybe<BrandingType>;
  in?: InputMaybe<Array<BrandingType>>;
  not?: InputMaybe<NestedEnumBrandingTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<BrandingType>>;
};

export type NestedEnumLearningStyleFilter = {
  equals?: InputMaybe<LearningStyle>;
  in?: InputMaybe<Array<LearningStyle>>;
  not?: InputMaybe<NestedEnumLearningStyleFilter>;
  notIn?: InputMaybe<Array<LearningStyle>>;
};

export type NestedEnumLearningStyleWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumLearningStyleFilter>;
  _min?: InputMaybe<NestedEnumLearningStyleFilter>;
  equals?: InputMaybe<LearningStyle>;
  in?: InputMaybe<Array<LearningStyle>>;
  not?: InputMaybe<NestedEnumLearningStyleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<LearningStyle>>;
};

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumRoleWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRoleFilter>;
  _min?: InputMaybe<NestedEnumRoleFilter>;
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumStripePaymentIntentStatusFilter = {
  equals?: InputMaybe<StripePaymentIntentStatus>;
  in?: InputMaybe<Array<StripePaymentIntentStatus>>;
  not?: InputMaybe<NestedEnumStripePaymentIntentStatusFilter>;
  notIn?: InputMaybe<Array<StripePaymentIntentStatus>>;
};

export type NestedEnumStripePaymentIntentStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStripePaymentIntentStatusFilter>;
  _min?: InputMaybe<NestedEnumStripePaymentIntentStatusFilter>;
  equals?: InputMaybe<StripePaymentIntentStatus>;
  in?: InputMaybe<Array<StripePaymentIntentStatus>>;
  not?: InputMaybe<NestedEnumStripePaymentIntentStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StripePaymentIntentStatus>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedJsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type NestedJsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Payment = {
  _count?: Maybe<PaymentCount>;
  amountInCents: Scalars['Int']['output'];
  billingAddress: Scalars['JSON']['output'];
  billingCountryCode: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  currency: AcceptedCurrency;
  id: Scalars['String']['output'];
  isInvoiceSent: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  paymentItems: Array<PaymentItem>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  taxInCents: Scalars['Int']['output'];
  user: User;
  userId: Scalars['String']['output'];
};


export type PaymentPaymentItemsArgs = {
  cursor?: InputMaybe<PaymentItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentItemWhereInput>;
};


export type PaymentStripePaymentIntentsArgs = {
  cursor?: InputMaybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: InputMaybe<Array<StripePaymentIntentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StripePaymentIntentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StripePaymentIntentWhereInput>;
};

export type PaymentAvgAggregate = {
  amountInCents?: Maybe<Scalars['Float']['output']>;
  taxInCents?: Maybe<Scalars['Float']['output']>;
};

export type PaymentAvgOrderByAggregateInput = {
  amountInCents?: InputMaybe<SortOrder>;
  taxInCents?: InputMaybe<SortOrder>;
};

export type PaymentCount = {
  paymentItems: Scalars['Int']['output'];
  stripePaymentIntents: Scalars['Int']['output'];
};


export type PaymentCountPaymentItemsArgs = {
  where?: InputMaybe<PaymentItemWhereInput>;
};


export type PaymentCountStripePaymentIntentsArgs = {
  where?: InputMaybe<StripePaymentIntentWhereInput>;
};

export type PaymentCountAggregate = {
  _all: Scalars['Int']['output'];
  amountInCents: Scalars['Int']['output'];
  billingAddress: Scalars['Int']['output'];
  billingCountryCode: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  currency: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isInvoiceSent: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  taxInCents: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type PaymentCountOrderByAggregateInput = {
  amountInCents?: InputMaybe<SortOrder>;
  billingAddress?: InputMaybe<SortOrder>;
  billingCountryCode?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isInvoiceSent?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  taxInCents?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type PaymentCreateInput = {
  amountInCents: Scalars['Int']['input'];
  billingAddress: Scalars['JSON']['input'];
  billingCountryCode: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency?: InputMaybe<AcceptedCurrency>;
  id?: InputMaybe<Scalars['String']['input']>;
  isInvoiceSent?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  paymentItems?: InputMaybe<PaymentItemCreateNestedManyWithoutPaymentInput>;
  stripePaymentIntents?: InputMaybe<StripePaymentIntentCreateNestedManyWithoutPaymentInput>;
  taxInCents: Scalars['Int']['input'];
  user: UserCreateNestedOneWithoutPaymentsInput;
};

export type PaymentCreateManyInput = {
  amountInCents: Scalars['Int']['input'];
  billingAddress: Scalars['JSON']['input'];
  billingCountryCode: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency?: InputMaybe<AcceptedCurrency>;
  id?: InputMaybe<Scalars['String']['input']>;
  isInvoiceSent?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  taxInCents: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type PaymentCreateManyUserInput = {
  amountInCents: Scalars['Int']['input'];
  billingAddress: Scalars['JSON']['input'];
  billingCountryCode: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency?: InputMaybe<AcceptedCurrency>;
  id?: InputMaybe<Scalars['String']['input']>;
  isInvoiceSent?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  taxInCents: Scalars['Int']['input'];
};

export type PaymentCreateManyUserInputEnvelope = {
  data: Array<PaymentCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PaymentCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PaymentCreateWithoutUserInput>>;
  createMany?: InputMaybe<PaymentCreateManyUserInputEnvelope>;
};

export type PaymentCreateNestedOneWithoutPaymentItemsInput = {
  connect?: InputMaybe<PaymentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentCreateOrConnectWithoutPaymentItemsInput>;
  create?: InputMaybe<PaymentCreateWithoutPaymentItemsInput>;
};

export type PaymentCreateNestedOneWithoutStripePaymentIntentsInput = {
  connect?: InputMaybe<PaymentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentCreateOrConnectWithoutStripePaymentIntentsInput>;
  create?: InputMaybe<PaymentCreateWithoutStripePaymentIntentsInput>;
};

export type PaymentCreateOrConnectWithoutPaymentItemsInput = {
  create: PaymentCreateWithoutPaymentItemsInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutStripePaymentIntentsInput = {
  create: PaymentCreateWithoutStripePaymentIntentsInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutUserInput = {
  create: PaymentCreateWithoutUserInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateWithoutPaymentItemsInput = {
  amountInCents: Scalars['Int']['input'];
  billingAddress: Scalars['JSON']['input'];
  billingCountryCode: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency?: InputMaybe<AcceptedCurrency>;
  id?: InputMaybe<Scalars['String']['input']>;
  isInvoiceSent?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  stripePaymentIntents?: InputMaybe<StripePaymentIntentCreateNestedManyWithoutPaymentInput>;
  taxInCents: Scalars['Int']['input'];
  user: UserCreateNestedOneWithoutPaymentsInput;
};

export type PaymentCreateWithoutStripePaymentIntentsInput = {
  amountInCents: Scalars['Int']['input'];
  billingAddress: Scalars['JSON']['input'];
  billingCountryCode: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency?: InputMaybe<AcceptedCurrency>;
  id?: InputMaybe<Scalars['String']['input']>;
  isInvoiceSent?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  paymentItems?: InputMaybe<PaymentItemCreateNestedManyWithoutPaymentInput>;
  taxInCents: Scalars['Int']['input'];
  user: UserCreateNestedOneWithoutPaymentsInput;
};

export type PaymentCreateWithoutUserInput = {
  amountInCents: Scalars['Int']['input'];
  billingAddress: Scalars['JSON']['input'];
  billingCountryCode: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency?: InputMaybe<AcceptedCurrency>;
  id?: InputMaybe<Scalars['String']['input']>;
  isInvoiceSent?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  paymentItems?: InputMaybe<PaymentItemCreateNestedManyWithoutPaymentInput>;
  stripePaymentIntents?: InputMaybe<StripePaymentIntentCreateNestedManyWithoutPaymentInput>;
  taxInCents: Scalars['Int']['input'];
};

export type PaymentGroupBy = {
  _avg?: Maybe<PaymentAvgAggregate>;
  _count?: Maybe<PaymentCountAggregate>;
  _max?: Maybe<PaymentMaxAggregate>;
  _min?: Maybe<PaymentMinAggregate>;
  _sum?: Maybe<PaymentSumAggregate>;
  amountInCents: Scalars['Int']['output'];
  billingAddress: Scalars['JSON']['output'];
  billingCountryCode: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  currency: AcceptedCurrency;
  id: Scalars['String']['output'];
  isInvoiceSent: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  taxInCents: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
};

export type PaymentItem = {
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  payment: Payment;
  paymentId: Scalars['String']['output'];
  productSubscriptionType?: Maybe<ProductSubscriptionType>;
  productSubscriptionTypeId?: Maybe<Scalars['String']['output']>;
};


export type PaymentItemProductSubscriptionTypeArgs = {
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};

export type PaymentItemCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  paymentId: Scalars['Int']['output'];
  productSubscriptionTypeId: Scalars['Int']['output'];
};

export type PaymentItemCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  paymentId?: InputMaybe<SortOrder>;
  productSubscriptionTypeId?: InputMaybe<SortOrder>;
};

export type PaymentItemCreateInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  payment: PaymentCreateNestedOneWithoutPaymentItemsInput;
  productSubscriptionType?: InputMaybe<ProductSubscriptionTypeCreateNestedOneWithoutPaymentItemsInput>;
};

export type PaymentItemCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  paymentId: Scalars['String']['input'];
  productSubscriptionTypeId?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentItemCreateManyPaymentInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  productSubscriptionTypeId?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentItemCreateManyPaymentInputEnvelope = {
  data: Array<PaymentItemCreateManyPaymentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PaymentItemCreateManyProductSubscriptionTypeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  paymentId: Scalars['String']['input'];
};

export type PaymentItemCreateManyProductSubscriptionTypeInputEnvelope = {
  data: Array<PaymentItemCreateManyProductSubscriptionTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PaymentItemCreateNestedManyWithoutPaymentInput = {
  connect?: InputMaybe<Array<PaymentItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentItemCreateOrConnectWithoutPaymentInput>>;
  create?: InputMaybe<Array<PaymentItemCreateWithoutPaymentInput>>;
  createMany?: InputMaybe<PaymentItemCreateManyPaymentInputEnvelope>;
};

export type PaymentItemCreateNestedManyWithoutProductSubscriptionTypeInput = {
  connect?: InputMaybe<Array<PaymentItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentItemCreateOrConnectWithoutProductSubscriptionTypeInput>>;
  create?: InputMaybe<Array<PaymentItemCreateWithoutProductSubscriptionTypeInput>>;
  createMany?: InputMaybe<PaymentItemCreateManyProductSubscriptionTypeInputEnvelope>;
};

export type PaymentItemCreateOrConnectWithoutPaymentInput = {
  create: PaymentItemCreateWithoutPaymentInput;
  where: PaymentItemWhereUniqueInput;
};

export type PaymentItemCreateOrConnectWithoutProductSubscriptionTypeInput = {
  create: PaymentItemCreateWithoutProductSubscriptionTypeInput;
  where: PaymentItemWhereUniqueInput;
};

export type PaymentItemCreateWithoutPaymentInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  productSubscriptionType?: InputMaybe<ProductSubscriptionTypeCreateNestedOneWithoutPaymentItemsInput>;
};

export type PaymentItemCreateWithoutProductSubscriptionTypeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  payment: PaymentCreateNestedOneWithoutPaymentItemsInput;
};

export type PaymentItemGroupBy = {
  _count?: Maybe<PaymentItemCountAggregate>;
  _max?: Maybe<PaymentItemMaxAggregate>;
  _min?: Maybe<PaymentItemMinAggregate>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  paymentId: Scalars['String']['output'];
  productSubscriptionTypeId?: Maybe<Scalars['String']['output']>;
};

export type PaymentItemListRelationFilter = {
  every?: InputMaybe<PaymentItemWhereInput>;
  none?: InputMaybe<PaymentItemWhereInput>;
  some?: InputMaybe<PaymentItemWhereInput>;
};

export type PaymentItemMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  productSubscriptionTypeId?: Maybe<Scalars['String']['output']>;
};

export type PaymentItemMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  paymentId?: InputMaybe<SortOrder>;
  productSubscriptionTypeId?: InputMaybe<SortOrder>;
};

export type PaymentItemMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  productSubscriptionTypeId?: Maybe<Scalars['String']['output']>;
};

export type PaymentItemMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  paymentId?: InputMaybe<SortOrder>;
  productSubscriptionTypeId?: InputMaybe<SortOrder>;
};

export type PaymentItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PaymentItemOrderByWithAggregationInput = {
  _count?: InputMaybe<PaymentItemCountOrderByAggregateInput>;
  _max?: InputMaybe<PaymentItemMaxOrderByAggregateInput>;
  _min?: InputMaybe<PaymentItemMinOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  paymentId?: InputMaybe<SortOrder>;
  productSubscriptionTypeId?: InputMaybe<SortOrderInput>;
};

export type PaymentItemOrderByWithRelationInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  payment?: InputMaybe<PaymentOrderByWithRelationInput>;
  paymentId?: InputMaybe<SortOrder>;
  productSubscriptionType?: InputMaybe<ProductSubscriptionTypeOrderByWithRelationInput>;
  productSubscriptionTypeId?: InputMaybe<SortOrderInput>;
};

export enum PaymentItemScalarFieldEnum {
  Created = 'created',
  Id = 'id',
  Modified = 'modified',
  PaymentId = 'paymentId',
  ProductSubscriptionTypeId = 'productSubscriptionTypeId'
}

export type PaymentItemScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentItemScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentItemScalarWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  paymentId?: InputMaybe<StringFilter>;
  productSubscriptionTypeId?: InputMaybe<StringNullableFilter>;
};

export type PaymentItemScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PaymentItemScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PaymentItemScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PaymentItemScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  paymentId?: InputMaybe<StringWithAggregatesFilter>;
  productSubscriptionTypeId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type PaymentItemUpdateInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateOneRequiredWithoutPaymentItemsNestedInput>;
  productSubscriptionType?: InputMaybe<ProductSubscriptionTypeUpdateOneWithoutPaymentItemsNestedInput>;
};

export type PaymentItemUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PaymentItemUpdateManyWithWhereWithoutPaymentInput = {
  data: PaymentItemUpdateManyMutationInput;
  where: PaymentItemScalarWhereInput;
};

export type PaymentItemUpdateManyWithWhereWithoutProductSubscriptionTypeInput = {
  data: PaymentItemUpdateManyMutationInput;
  where: PaymentItemScalarWhereInput;
};

export type PaymentItemUpdateManyWithoutPaymentNestedInput = {
  connect?: InputMaybe<Array<PaymentItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentItemCreateOrConnectWithoutPaymentInput>>;
  create?: InputMaybe<Array<PaymentItemCreateWithoutPaymentInput>>;
  createMany?: InputMaybe<PaymentItemCreateManyPaymentInputEnvelope>;
  delete?: InputMaybe<Array<PaymentItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentItemWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentItemUpdateWithWhereUniqueWithoutPaymentInput>>;
  updateMany?: InputMaybe<Array<PaymentItemUpdateManyWithWhereWithoutPaymentInput>>;
  upsert?: InputMaybe<Array<PaymentItemUpsertWithWhereUniqueWithoutPaymentInput>>;
};

export type PaymentItemUpdateManyWithoutProductSubscriptionTypeNestedInput = {
  connect?: InputMaybe<Array<PaymentItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentItemCreateOrConnectWithoutProductSubscriptionTypeInput>>;
  create?: InputMaybe<Array<PaymentItemCreateWithoutProductSubscriptionTypeInput>>;
  createMany?: InputMaybe<PaymentItemCreateManyProductSubscriptionTypeInputEnvelope>;
  delete?: InputMaybe<Array<PaymentItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentItemWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentItemUpdateWithWhereUniqueWithoutProductSubscriptionTypeInput>>;
  updateMany?: InputMaybe<Array<PaymentItemUpdateManyWithWhereWithoutProductSubscriptionTypeInput>>;
  upsert?: InputMaybe<Array<PaymentItemUpsertWithWhereUniqueWithoutProductSubscriptionTypeInput>>;
};

export type PaymentItemUpdateWithWhereUniqueWithoutPaymentInput = {
  data: PaymentItemUpdateWithoutPaymentInput;
  where: PaymentItemWhereUniqueInput;
};

export type PaymentItemUpdateWithWhereUniqueWithoutProductSubscriptionTypeInput = {
  data: PaymentItemUpdateWithoutProductSubscriptionTypeInput;
  where: PaymentItemWhereUniqueInput;
};

export type PaymentItemUpdateWithoutPaymentInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  productSubscriptionType?: InputMaybe<ProductSubscriptionTypeUpdateOneWithoutPaymentItemsNestedInput>;
};

export type PaymentItemUpdateWithoutProductSubscriptionTypeInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateOneRequiredWithoutPaymentItemsNestedInput>;
};

export type PaymentItemUpsertWithWhereUniqueWithoutPaymentInput = {
  create: PaymentItemCreateWithoutPaymentInput;
  update: PaymentItemUpdateWithoutPaymentInput;
  where: PaymentItemWhereUniqueInput;
};

export type PaymentItemUpsertWithWhereUniqueWithoutProductSubscriptionTypeInput = {
  create: PaymentItemCreateWithoutProductSubscriptionTypeInput;
  update: PaymentItemUpdateWithoutProductSubscriptionTypeInput;
  where: PaymentItemWhereUniqueInput;
};

export type PaymentItemWhereInput = {
  AND?: InputMaybe<Array<PaymentItemWhereInput>>;
  NOT?: InputMaybe<Array<PaymentItemWhereInput>>;
  OR?: InputMaybe<Array<PaymentItemWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  payment?: InputMaybe<PaymentRelationFilter>;
  paymentId?: InputMaybe<StringFilter>;
  productSubscriptionType?: InputMaybe<ProductSubscriptionTypeNullableRelationFilter>;
  productSubscriptionTypeId?: InputMaybe<StringNullableFilter>;
};

export type PaymentItemWhereUniqueInput = {
  AND?: InputMaybe<Array<PaymentItemWhereInput>>;
  NOT?: InputMaybe<Array<PaymentItemWhereInput>>;
  OR?: InputMaybe<Array<PaymentItemWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  payment?: InputMaybe<PaymentRelationFilter>;
  paymentId?: InputMaybe<StringFilter>;
  productSubscriptionType?: InputMaybe<ProductSubscriptionTypeNullableRelationFilter>;
  productSubscriptionTypeId?: InputMaybe<StringNullableFilter>;
};

export type PaymentListRelationFilter = {
  every?: InputMaybe<PaymentWhereInput>;
  none?: InputMaybe<PaymentWhereInput>;
  some?: InputMaybe<PaymentWhereInput>;
};

export type PaymentMaxAggregate = {
  amountInCents?: Maybe<Scalars['Int']['output']>;
  billingCountryCode?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  currency?: Maybe<AcceptedCurrency>;
  id?: Maybe<Scalars['String']['output']>;
  isInvoiceSent?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  taxInCents?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type PaymentMaxOrderByAggregateInput = {
  amountInCents?: InputMaybe<SortOrder>;
  billingCountryCode?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isInvoiceSent?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  taxInCents?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type PaymentMinAggregate = {
  amountInCents?: Maybe<Scalars['Int']['output']>;
  billingCountryCode?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  currency?: Maybe<AcceptedCurrency>;
  id?: Maybe<Scalars['String']['output']>;
  isInvoiceSent?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  taxInCents?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type PaymentMinOrderByAggregateInput = {
  amountInCents?: InputMaybe<SortOrder>;
  billingCountryCode?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isInvoiceSent?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  taxInCents?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type PaymentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PaymentOrderByWithAggregationInput = {
  _avg?: InputMaybe<PaymentAvgOrderByAggregateInput>;
  _count?: InputMaybe<PaymentCountOrderByAggregateInput>;
  _max?: InputMaybe<PaymentMaxOrderByAggregateInput>;
  _min?: InputMaybe<PaymentMinOrderByAggregateInput>;
  _sum?: InputMaybe<PaymentSumOrderByAggregateInput>;
  amountInCents?: InputMaybe<SortOrder>;
  billingAddress?: InputMaybe<SortOrder>;
  billingCountryCode?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isInvoiceSent?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  taxInCents?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type PaymentOrderByWithRelationInput = {
  amountInCents?: InputMaybe<SortOrder>;
  billingAddress?: InputMaybe<SortOrder>;
  billingCountryCode?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isInvoiceSent?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  paymentItems?: InputMaybe<PaymentItemOrderByRelationAggregateInput>;
  stripePaymentIntents?: InputMaybe<StripePaymentIntentOrderByRelationAggregateInput>;
  taxInCents?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type PaymentRelationFilter = {
  is?: InputMaybe<PaymentWhereInput>;
  isNot?: InputMaybe<PaymentWhereInput>;
};

export enum PaymentScalarFieldEnum {
  AmountInCents = 'amountInCents',
  BillingAddress = 'billingAddress',
  BillingCountryCode = 'billingCountryCode',
  Created = 'created',
  Currency = 'currency',
  Id = 'id',
  IsInvoiceSent = 'isInvoiceSent',
  Modified = 'modified',
  TaxInCents = 'taxInCents',
  UserId = 'userId'
}

export type PaymentScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentScalarWhereInput>>;
  amountInCents?: InputMaybe<IntFilter>;
  billingAddress?: InputMaybe<JsonFilter>;
  billingCountryCode?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumAcceptedCurrencyFilter>;
  id?: InputMaybe<StringFilter>;
  isInvoiceSent?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  taxInCents?: InputMaybe<IntFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type PaymentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PaymentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PaymentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PaymentScalarWhereWithAggregatesInput>>;
  amountInCents?: InputMaybe<IntWithAggregatesFilter>;
  billingAddress?: InputMaybe<JsonWithAggregatesFilter>;
  billingCountryCode?: InputMaybe<StringWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  currency?: InputMaybe<EnumAcceptedCurrencyWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isInvoiceSent?: InputMaybe<BoolWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  taxInCents?: InputMaybe<IntWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type PaymentSumAggregate = {
  amountInCents?: Maybe<Scalars['Int']['output']>;
  taxInCents?: Maybe<Scalars['Int']['output']>;
};

export type PaymentSumOrderByAggregateInput = {
  amountInCents?: InputMaybe<SortOrder>;
  taxInCents?: InputMaybe<SortOrder>;
};

export type PaymentUpdateInput = {
  amountInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumAcceptedCurrencyFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isInvoiceSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  paymentItems?: InputMaybe<PaymentItemUpdateManyWithoutPaymentNestedInput>;
  stripePaymentIntents?: InputMaybe<StripePaymentIntentUpdateManyWithoutPaymentNestedInput>;
  taxInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPaymentsNestedInput>;
};

export type PaymentUpdateManyMutationInput = {
  amountInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumAcceptedCurrencyFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isInvoiceSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  taxInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type PaymentUpdateManyWithWhereWithoutUserInput = {
  data: PaymentUpdateManyMutationInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PaymentCreateWithoutUserInput>>;
  createMany?: InputMaybe<PaymentCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<PaymentUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<PaymentUpsertWithWhereUniqueWithoutUserInput>>;
};

export type PaymentUpdateOneRequiredWithoutPaymentItemsNestedInput = {
  connect?: InputMaybe<PaymentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentCreateOrConnectWithoutPaymentItemsInput>;
  create?: InputMaybe<PaymentCreateWithoutPaymentItemsInput>;
  update?: InputMaybe<PaymentUpdateToOneWithWhereWithoutPaymentItemsInput>;
  upsert?: InputMaybe<PaymentUpsertWithoutPaymentItemsInput>;
};

export type PaymentUpdateOneRequiredWithoutStripePaymentIntentsNestedInput = {
  connect?: InputMaybe<PaymentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentCreateOrConnectWithoutStripePaymentIntentsInput>;
  create?: InputMaybe<PaymentCreateWithoutStripePaymentIntentsInput>;
  update?: InputMaybe<PaymentUpdateToOneWithWhereWithoutStripePaymentIntentsInput>;
  upsert?: InputMaybe<PaymentUpsertWithoutStripePaymentIntentsInput>;
};

export type PaymentUpdateToOneWithWhereWithoutPaymentItemsInput = {
  data: PaymentUpdateWithoutPaymentItemsInput;
  where?: InputMaybe<PaymentWhereInput>;
};

export type PaymentUpdateToOneWithWhereWithoutStripePaymentIntentsInput = {
  data: PaymentUpdateWithoutStripePaymentIntentsInput;
  where?: InputMaybe<PaymentWhereInput>;
};

export type PaymentUpdateWithWhereUniqueWithoutUserInput = {
  data: PaymentUpdateWithoutUserInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpdateWithoutPaymentItemsInput = {
  amountInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumAcceptedCurrencyFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isInvoiceSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  stripePaymentIntents?: InputMaybe<StripePaymentIntentUpdateManyWithoutPaymentNestedInput>;
  taxInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPaymentsNestedInput>;
};

export type PaymentUpdateWithoutStripePaymentIntentsInput = {
  amountInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumAcceptedCurrencyFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isInvoiceSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  paymentItems?: InputMaybe<PaymentItemUpdateManyWithoutPaymentNestedInput>;
  taxInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPaymentsNestedInput>;
};

export type PaymentUpdateWithoutUserInput = {
  amountInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumAcceptedCurrencyFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isInvoiceSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  paymentItems?: InputMaybe<PaymentItemUpdateManyWithoutPaymentNestedInput>;
  stripePaymentIntents?: InputMaybe<StripePaymentIntentUpdateManyWithoutPaymentNestedInput>;
  taxInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type PaymentUpsertWithWhereUniqueWithoutUserInput = {
  create: PaymentCreateWithoutUserInput;
  update: PaymentUpdateWithoutUserInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpsertWithoutPaymentItemsInput = {
  create: PaymentCreateWithoutPaymentItemsInput;
  update: PaymentUpdateWithoutPaymentItemsInput;
  where?: InputMaybe<PaymentWhereInput>;
};

export type PaymentUpsertWithoutStripePaymentIntentsInput = {
  create: PaymentCreateWithoutStripePaymentIntentsInput;
  update: PaymentUpdateWithoutStripePaymentIntentsInput;
  where?: InputMaybe<PaymentWhereInput>;
};

export type PaymentWhereInput = {
  AND?: InputMaybe<Array<PaymentWhereInput>>;
  NOT?: InputMaybe<Array<PaymentWhereInput>>;
  OR?: InputMaybe<Array<PaymentWhereInput>>;
  amountInCents?: InputMaybe<IntFilter>;
  billingAddress?: InputMaybe<JsonFilter>;
  billingCountryCode?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumAcceptedCurrencyFilter>;
  id?: InputMaybe<StringFilter>;
  isInvoiceSent?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  paymentItems?: InputMaybe<PaymentItemListRelationFilter>;
  stripePaymentIntents?: InputMaybe<StripePaymentIntentListRelationFilter>;
  taxInCents?: InputMaybe<IntFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type PaymentWhereUniqueInput = {
  AND?: InputMaybe<Array<PaymentWhereInput>>;
  NOT?: InputMaybe<Array<PaymentWhereInput>>;
  OR?: InputMaybe<Array<PaymentWhereInput>>;
  amountInCents?: InputMaybe<IntFilter>;
  billingAddress?: InputMaybe<JsonFilter>;
  billingCountryCode?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumAcceptedCurrencyFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isInvoiceSent?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  paymentItems?: InputMaybe<PaymentItemListRelationFilter>;
  stripePaymentIntents?: InputMaybe<StripePaymentIntentListRelationFilter>;
  taxInCents?: InputMaybe<IntFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type Product = {
  _count?: Maybe<ProductCount>;
  brandingType: BrandingType;
  created: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  name: Scalars['String']['output'];
  prerequisites: Array<ProductProgrammePrerequisite>;
  productProgrammes: Array<ProductProgramme>;
  productSubscriptionTypes: Array<ProductSubscriptionType>;
  shortName: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};


export type ProductPrerequisitesArgs = {
  cursor?: InputMaybe<ProductProgrammePrerequisiteWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductProgrammePrerequisiteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductProgrammePrerequisiteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
};


export type ProductProductProgrammesArgs = {
  cursor?: InputMaybe<ProductProgrammeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductProgrammeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductProgrammeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammeWhereInput>;
};


export type ProductProductSubscriptionTypesArgs = {
  cursor?: InputMaybe<ProductSubscriptionTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductSubscriptionTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductSubscriptionTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};

export type ProductCount = {
  prerequisites: Scalars['Int']['output'];
  productProgrammes: Scalars['Int']['output'];
  productSubscriptionTypes: Scalars['Int']['output'];
};


export type ProductCountPrerequisitesArgs = {
  where?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
};


export type ProductCountProductProgrammesArgs = {
  where?: InputMaybe<ProductProgrammeWhereInput>;
};


export type ProductCountProductSubscriptionTypesArgs = {
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};

export type ProductCountAggregate = {
  _all: Scalars['Int']['output'];
  brandingType: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isPublished: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  shortName: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
};

export type ProductCountOrderByAggregateInput = {
  brandingType?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
};

export type ProductCreateInput = {
  brandingType?: InputMaybe<BrandingType>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  prerequisites?: InputMaybe<ProductProgrammePrerequisiteCreateNestedManyWithoutProductInput>;
  productProgrammes?: InputMaybe<ProductProgrammeCreateNestedManyWithoutProductInput>;
  productSubscriptionTypes?: InputMaybe<ProductSubscriptionTypeCreateNestedManyWithoutProductInput>;
  shortName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type ProductCreateManyInput = {
  brandingType?: InputMaybe<BrandingType>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  shortName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type ProductCreateNestedOneWithoutPrerequisitesInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutPrerequisitesInput>;
  create?: InputMaybe<ProductCreateWithoutPrerequisitesInput>;
};

export type ProductCreateNestedOneWithoutProductProgrammesInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductProgrammesInput>;
  create?: InputMaybe<ProductCreateWithoutProductProgrammesInput>;
};

export type ProductCreateNestedOneWithoutProductSubscriptionTypesInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductSubscriptionTypesInput>;
  create?: InputMaybe<ProductCreateWithoutProductSubscriptionTypesInput>;
};

export type ProductCreateOrConnectWithoutPrerequisitesInput = {
  create: ProductCreateWithoutPrerequisitesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutProductProgrammesInput = {
  create: ProductCreateWithoutProductProgrammesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutProductSubscriptionTypesInput = {
  create: ProductCreateWithoutProductSubscriptionTypesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutPrerequisitesInput = {
  brandingType?: InputMaybe<BrandingType>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  productProgrammes?: InputMaybe<ProductProgrammeCreateNestedManyWithoutProductInput>;
  productSubscriptionTypes?: InputMaybe<ProductSubscriptionTypeCreateNestedManyWithoutProductInput>;
  shortName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type ProductCreateWithoutProductProgrammesInput = {
  brandingType?: InputMaybe<BrandingType>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  prerequisites?: InputMaybe<ProductProgrammePrerequisiteCreateNestedManyWithoutProductInput>;
  productSubscriptionTypes?: InputMaybe<ProductSubscriptionTypeCreateNestedManyWithoutProductInput>;
  shortName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type ProductCreateWithoutProductSubscriptionTypesInput = {
  brandingType?: InputMaybe<BrandingType>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  prerequisites?: InputMaybe<ProductProgrammePrerequisiteCreateNestedManyWithoutProductInput>;
  productProgrammes?: InputMaybe<ProductProgrammeCreateNestedManyWithoutProductInput>;
  shortName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type ProductGroupBy = {
  _count?: Maybe<ProductCountAggregate>;
  _max?: Maybe<ProductMaxAggregate>;
  _min?: Maybe<ProductMinAggregate>;
  brandingType: BrandingType;
  created: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type ProductMaxAggregate = {
  brandingType?: Maybe<BrandingType>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type ProductMaxOrderByAggregateInput = {
  brandingType?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
};

export type ProductMinAggregate = {
  brandingType?: Maybe<BrandingType>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type ProductMinOrderByAggregateInput = {
  brandingType?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
};

export type ProductNullableRelationFilter = {
  is?: InputMaybe<ProductWhereInput>;
  isNot?: InputMaybe<ProductWhereInput>;
};

export type ProductOrderByWithAggregationInput = {
  _count?: InputMaybe<ProductCountOrderByAggregateInput>;
  _max?: InputMaybe<ProductMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProductMinOrderByAggregateInput>;
  brandingType?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
};

export type ProductOrderByWithRelationInput = {
  brandingType?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  prerequisites?: InputMaybe<ProductProgrammePrerequisiteOrderByRelationAggregateInput>;
  productProgrammes?: InputMaybe<ProductProgrammeOrderByRelationAggregateInput>;
  productSubscriptionTypes?: InputMaybe<ProductSubscriptionTypeOrderByRelationAggregateInput>;
  shortName?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
};

export type ProductProgramme = {
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  product: Product;
  productId: Scalars['String']['output'];
  programme: Programme;
  programmeId: Scalars['String']['output'];
};

export type ProductProgrammeCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  programmeId: Scalars['Int']['output'];
};

export type ProductProgrammeCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
};

export type ProductProgrammeCreateInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  product: ProductCreateNestedOneWithoutProductProgrammesInput;
  programme: ProgrammeCreateNestedOneWithoutProgrammeProductsInput;
};

export type ProductProgrammeCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  productId: Scalars['String']['input'];
  programmeId: Scalars['String']['input'];
};

export type ProductProgrammeCreateManyProductInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  programmeId: Scalars['String']['input'];
};

export type ProductProgrammeCreateManyProductInputEnvelope = {
  data: Array<ProductProgrammeCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductProgrammeCreateManyProgrammeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  productId: Scalars['String']['input'];
};

export type ProductProgrammeCreateManyProgrammeInputEnvelope = {
  data: Array<ProductProgrammeCreateManyProgrammeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductProgrammeCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<ProductProgrammeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductProgrammeCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductProgrammeCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductProgrammeCreateManyProductInputEnvelope>;
};

export type ProductProgrammeCreateNestedManyWithoutProgrammeInput = {
  connect?: InputMaybe<Array<ProductProgrammeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductProgrammeCreateOrConnectWithoutProgrammeInput>>;
  create?: InputMaybe<Array<ProductProgrammeCreateWithoutProgrammeInput>>;
  createMany?: InputMaybe<ProductProgrammeCreateManyProgrammeInputEnvelope>;
};

export type ProductProgrammeCreateOrConnectWithoutProductInput = {
  create: ProductProgrammeCreateWithoutProductInput;
  where: ProductProgrammeWhereUniqueInput;
};

export type ProductProgrammeCreateOrConnectWithoutProgrammeInput = {
  create: ProductProgrammeCreateWithoutProgrammeInput;
  where: ProductProgrammeWhereUniqueInput;
};

export type ProductProgrammeCreateWithoutProductInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  programme: ProgrammeCreateNestedOneWithoutProgrammeProductsInput;
};

export type ProductProgrammeCreateWithoutProgrammeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  product: ProductCreateNestedOneWithoutProductProgrammesInput;
};

export type ProductProgrammeGroupBy = {
  _count?: Maybe<ProductProgrammeCountAggregate>;
  _max?: Maybe<ProductProgrammeMaxAggregate>;
  _min?: Maybe<ProductProgrammeMinAggregate>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  productId: Scalars['String']['output'];
  programmeId: Scalars['String']['output'];
};

export type ProductProgrammeListRelationFilter = {
  every?: InputMaybe<ProductProgrammeWhereInput>;
  none?: InputMaybe<ProductProgrammeWhereInput>;
  some?: InputMaybe<ProductProgrammeWhereInput>;
};

export type ProductProgrammeMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  programmeId?: Maybe<Scalars['String']['output']>;
};

export type ProductProgrammeMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
};

export type ProductProgrammeMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  programmeId?: Maybe<Scalars['String']['output']>;
};

export type ProductProgrammeMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
};

export type ProductProgrammeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductProgrammeOrderByWithAggregationInput = {
  _count?: InputMaybe<ProductProgrammeCountOrderByAggregateInput>;
  _max?: InputMaybe<ProductProgrammeMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProductProgrammeMinOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
};

export type ProductProgrammeOrderByWithRelationInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrder>;
  programme?: InputMaybe<ProgrammeOrderByWithRelationInput>;
  programmeId?: InputMaybe<SortOrder>;
};

export type ProductProgrammePrerequisite = {
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  product: Product;
  productId: Scalars['String']['output'];
  programme: Programme;
  programmeId: Scalars['String']['output'];
};

export type ProductProgrammePrerequisiteCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  programmeId: Scalars['Int']['output'];
};

export type ProductProgrammePrerequisiteCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
};

export type ProductProgrammePrerequisiteCreateInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  product: ProductCreateNestedOneWithoutPrerequisitesInput;
  programme: ProgrammeCreateNestedOneWithoutPrerequisiteForInput;
};

export type ProductProgrammePrerequisiteCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  productId: Scalars['String']['input'];
  programmeId: Scalars['String']['input'];
};

export type ProductProgrammePrerequisiteCreateManyProductInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  programmeId: Scalars['String']['input'];
};

export type ProductProgrammePrerequisiteCreateManyProductInputEnvelope = {
  data: Array<ProductProgrammePrerequisiteCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductProgrammePrerequisiteCreateManyProgrammeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  productId: Scalars['String']['input'];
};

export type ProductProgrammePrerequisiteCreateManyProgrammeInputEnvelope = {
  data: Array<ProductProgrammePrerequisiteCreateManyProgrammeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductProgrammePrerequisiteCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<ProductProgrammePrerequisiteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductProgrammePrerequisiteCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductProgrammePrerequisiteCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductProgrammePrerequisiteCreateManyProductInputEnvelope>;
};

export type ProductProgrammePrerequisiteCreateNestedManyWithoutProgrammeInput = {
  connect?: InputMaybe<Array<ProductProgrammePrerequisiteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductProgrammePrerequisiteCreateOrConnectWithoutProgrammeInput>>;
  create?: InputMaybe<Array<ProductProgrammePrerequisiteCreateWithoutProgrammeInput>>;
  createMany?: InputMaybe<ProductProgrammePrerequisiteCreateManyProgrammeInputEnvelope>;
};

export type ProductProgrammePrerequisiteCreateOrConnectWithoutProductInput = {
  create: ProductProgrammePrerequisiteCreateWithoutProductInput;
  where: ProductProgrammePrerequisiteWhereUniqueInput;
};

export type ProductProgrammePrerequisiteCreateOrConnectWithoutProgrammeInput = {
  create: ProductProgrammePrerequisiteCreateWithoutProgrammeInput;
  where: ProductProgrammePrerequisiteWhereUniqueInput;
};

export type ProductProgrammePrerequisiteCreateWithoutProductInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  programme: ProgrammeCreateNestedOneWithoutPrerequisiteForInput;
};

export type ProductProgrammePrerequisiteCreateWithoutProgrammeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  product: ProductCreateNestedOneWithoutPrerequisitesInput;
};

export type ProductProgrammePrerequisiteGroupBy = {
  _count?: Maybe<ProductProgrammePrerequisiteCountAggregate>;
  _max?: Maybe<ProductProgrammePrerequisiteMaxAggregate>;
  _min?: Maybe<ProductProgrammePrerequisiteMinAggregate>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  productId: Scalars['String']['output'];
  programmeId: Scalars['String']['output'];
};

export type ProductProgrammePrerequisiteListRelationFilter = {
  every?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
  none?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
  some?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
};

export type ProductProgrammePrerequisiteMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  programmeId?: Maybe<Scalars['String']['output']>;
};

export type ProductProgrammePrerequisiteMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
};

export type ProductProgrammePrerequisiteMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  programmeId?: Maybe<Scalars['String']['output']>;
};

export type ProductProgrammePrerequisiteMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
};

export type ProductProgrammePrerequisiteOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductProgrammePrerequisiteOrderByWithAggregationInput = {
  _count?: InputMaybe<ProductProgrammePrerequisiteCountOrderByAggregateInput>;
  _max?: InputMaybe<ProductProgrammePrerequisiteMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProductProgrammePrerequisiteMinOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
};

export type ProductProgrammePrerequisiteOrderByWithRelationInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrder>;
  programme?: InputMaybe<ProgrammeOrderByWithRelationInput>;
  programmeId?: InputMaybe<SortOrder>;
};

export type ProductProgrammePrerequisiteProductProgrammePrerequisiteCompoundUniqueInput = {
  productId: Scalars['String']['input'];
  programmeId: Scalars['String']['input'];
};

export enum ProductProgrammePrerequisiteScalarFieldEnum {
  Created = 'created',
  Id = 'id',
  Modified = 'modified',
  ProductId = 'productId',
  ProgrammeId = 'programmeId'
}

export type ProductProgrammePrerequisiteScalarWhereInput = {
  AND?: InputMaybe<Array<ProductProgrammePrerequisiteScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductProgrammePrerequisiteScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductProgrammePrerequisiteScalarWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  productId?: InputMaybe<StringFilter>;
  programmeId?: InputMaybe<StringFilter>;
};

export type ProductProgrammePrerequisiteScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProductProgrammePrerequisiteScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProductProgrammePrerequisiteScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProductProgrammePrerequisiteScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  productId?: InputMaybe<StringWithAggregatesFilter>;
  programmeId?: InputMaybe<StringWithAggregatesFilter>;
};

export type ProductProgrammePrerequisiteUpdateInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutPrerequisitesNestedInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutPrerequisiteForNestedInput>;
};

export type ProductProgrammePrerequisiteUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductProgrammePrerequisiteUpdateManyWithWhereWithoutProductInput = {
  data: ProductProgrammePrerequisiteUpdateManyMutationInput;
  where: ProductProgrammePrerequisiteScalarWhereInput;
};

export type ProductProgrammePrerequisiteUpdateManyWithWhereWithoutProgrammeInput = {
  data: ProductProgrammePrerequisiteUpdateManyMutationInput;
  where: ProductProgrammePrerequisiteScalarWhereInput;
};

export type ProductProgrammePrerequisiteUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<ProductProgrammePrerequisiteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductProgrammePrerequisiteCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductProgrammePrerequisiteCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductProgrammePrerequisiteCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<ProductProgrammePrerequisiteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductProgrammePrerequisiteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductProgrammePrerequisiteWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductProgrammePrerequisiteWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductProgrammePrerequisiteUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<ProductProgrammePrerequisiteUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<ProductProgrammePrerequisiteUpsertWithWhereUniqueWithoutProductInput>>;
};

export type ProductProgrammePrerequisiteUpdateManyWithoutProgrammeNestedInput = {
  connect?: InputMaybe<Array<ProductProgrammePrerequisiteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductProgrammePrerequisiteCreateOrConnectWithoutProgrammeInput>>;
  create?: InputMaybe<Array<ProductProgrammePrerequisiteCreateWithoutProgrammeInput>>;
  createMany?: InputMaybe<ProductProgrammePrerequisiteCreateManyProgrammeInputEnvelope>;
  delete?: InputMaybe<Array<ProductProgrammePrerequisiteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductProgrammePrerequisiteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductProgrammePrerequisiteWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductProgrammePrerequisiteWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductProgrammePrerequisiteUpdateWithWhereUniqueWithoutProgrammeInput>>;
  updateMany?: InputMaybe<Array<ProductProgrammePrerequisiteUpdateManyWithWhereWithoutProgrammeInput>>;
  upsert?: InputMaybe<Array<ProductProgrammePrerequisiteUpsertWithWhereUniqueWithoutProgrammeInput>>;
};

export type ProductProgrammePrerequisiteUpdateWithWhereUniqueWithoutProductInput = {
  data: ProductProgrammePrerequisiteUpdateWithoutProductInput;
  where: ProductProgrammePrerequisiteWhereUniqueInput;
};

export type ProductProgrammePrerequisiteUpdateWithWhereUniqueWithoutProgrammeInput = {
  data: ProductProgrammePrerequisiteUpdateWithoutProgrammeInput;
  where: ProductProgrammePrerequisiteWhereUniqueInput;
};

export type ProductProgrammePrerequisiteUpdateWithoutProductInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutPrerequisiteForNestedInput>;
};

export type ProductProgrammePrerequisiteUpdateWithoutProgrammeInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutPrerequisitesNestedInput>;
};

export type ProductProgrammePrerequisiteUpsertWithWhereUniqueWithoutProductInput = {
  create: ProductProgrammePrerequisiteCreateWithoutProductInput;
  update: ProductProgrammePrerequisiteUpdateWithoutProductInput;
  where: ProductProgrammePrerequisiteWhereUniqueInput;
};

export type ProductProgrammePrerequisiteUpsertWithWhereUniqueWithoutProgrammeInput = {
  create: ProductProgrammePrerequisiteCreateWithoutProgrammeInput;
  update: ProductProgrammePrerequisiteUpdateWithoutProgrammeInput;
  where: ProductProgrammePrerequisiteWhereUniqueInput;
};

export type ProductProgrammePrerequisiteWhereInput = {
  AND?: InputMaybe<Array<ProductProgrammePrerequisiteWhereInput>>;
  NOT?: InputMaybe<Array<ProductProgrammePrerequisiteWhereInput>>;
  OR?: InputMaybe<Array<ProductProgrammePrerequisiteWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<StringFilter>;
  programme?: InputMaybe<ProgrammeRelationFilter>;
  programmeId?: InputMaybe<StringFilter>;
};

export type ProductProgrammePrerequisiteWhereUniqueInput = {
  AND?: InputMaybe<Array<ProductProgrammePrerequisiteWhereInput>>;
  NOT?: InputMaybe<Array<ProductProgrammePrerequisiteWhereInput>>;
  OR?: InputMaybe<Array<ProductProgrammePrerequisiteWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<StringFilter>;
  productProgrammePrerequisite?: InputMaybe<ProductProgrammePrerequisiteProductProgrammePrerequisiteCompoundUniqueInput>;
  programme?: InputMaybe<ProgrammeRelationFilter>;
  programmeId?: InputMaybe<StringFilter>;
};

export type ProductProgrammeProductProgrammeCompoundUniqueInput = {
  productId: Scalars['String']['input'];
  programmeId: Scalars['String']['input'];
};

export enum ProductProgrammeScalarFieldEnum {
  Created = 'created',
  Id = 'id',
  Modified = 'modified',
  ProductId = 'productId',
  ProgrammeId = 'programmeId'
}

export type ProductProgrammeScalarWhereInput = {
  AND?: InputMaybe<Array<ProductProgrammeScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductProgrammeScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductProgrammeScalarWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  productId?: InputMaybe<StringFilter>;
  programmeId?: InputMaybe<StringFilter>;
};

export type ProductProgrammeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProductProgrammeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProductProgrammeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProductProgrammeScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  productId?: InputMaybe<StringWithAggregatesFilter>;
  programmeId?: InputMaybe<StringWithAggregatesFilter>;
};

export type ProductProgrammeUpdateInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutProductProgrammesNestedInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutProgrammeProductsNestedInput>;
};

export type ProductProgrammeUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductProgrammeUpdateManyWithWhereWithoutProductInput = {
  data: ProductProgrammeUpdateManyMutationInput;
  where: ProductProgrammeScalarWhereInput;
};

export type ProductProgrammeUpdateManyWithWhereWithoutProgrammeInput = {
  data: ProductProgrammeUpdateManyMutationInput;
  where: ProductProgrammeScalarWhereInput;
};

export type ProductProgrammeUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<ProductProgrammeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductProgrammeCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductProgrammeCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductProgrammeCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<ProductProgrammeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductProgrammeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductProgrammeWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductProgrammeWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductProgrammeUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<ProductProgrammeUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<ProductProgrammeUpsertWithWhereUniqueWithoutProductInput>>;
};

export type ProductProgrammeUpdateManyWithoutProgrammeNestedInput = {
  connect?: InputMaybe<Array<ProductProgrammeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductProgrammeCreateOrConnectWithoutProgrammeInput>>;
  create?: InputMaybe<Array<ProductProgrammeCreateWithoutProgrammeInput>>;
  createMany?: InputMaybe<ProductProgrammeCreateManyProgrammeInputEnvelope>;
  delete?: InputMaybe<Array<ProductProgrammeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductProgrammeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductProgrammeWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductProgrammeWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductProgrammeUpdateWithWhereUniqueWithoutProgrammeInput>>;
  updateMany?: InputMaybe<Array<ProductProgrammeUpdateManyWithWhereWithoutProgrammeInput>>;
  upsert?: InputMaybe<Array<ProductProgrammeUpsertWithWhereUniqueWithoutProgrammeInput>>;
};

export type ProductProgrammeUpdateWithWhereUniqueWithoutProductInput = {
  data: ProductProgrammeUpdateWithoutProductInput;
  where: ProductProgrammeWhereUniqueInput;
};

export type ProductProgrammeUpdateWithWhereUniqueWithoutProgrammeInput = {
  data: ProductProgrammeUpdateWithoutProgrammeInput;
  where: ProductProgrammeWhereUniqueInput;
};

export type ProductProgrammeUpdateWithoutProductInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutProgrammeProductsNestedInput>;
};

export type ProductProgrammeUpdateWithoutProgrammeInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutProductProgrammesNestedInput>;
};

export type ProductProgrammeUpsertWithWhereUniqueWithoutProductInput = {
  create: ProductProgrammeCreateWithoutProductInput;
  update: ProductProgrammeUpdateWithoutProductInput;
  where: ProductProgrammeWhereUniqueInput;
};

export type ProductProgrammeUpsertWithWhereUniqueWithoutProgrammeInput = {
  create: ProductProgrammeCreateWithoutProgrammeInput;
  update: ProductProgrammeUpdateWithoutProgrammeInput;
  where: ProductProgrammeWhereUniqueInput;
};

export type ProductProgrammeWhereInput = {
  AND?: InputMaybe<Array<ProductProgrammeWhereInput>>;
  NOT?: InputMaybe<Array<ProductProgrammeWhereInput>>;
  OR?: InputMaybe<Array<ProductProgrammeWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<StringFilter>;
  programme?: InputMaybe<ProgrammeRelationFilter>;
  programmeId?: InputMaybe<StringFilter>;
};

export type ProductProgrammeWhereUniqueInput = {
  AND?: InputMaybe<Array<ProductProgrammeWhereInput>>;
  NOT?: InputMaybe<Array<ProductProgrammeWhereInput>>;
  OR?: InputMaybe<Array<ProductProgrammeWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<StringFilter>;
  productProgramme?: InputMaybe<ProductProgrammeProductProgrammeCompoundUniqueInput>;
  programme?: InputMaybe<ProgrammeRelationFilter>;
  programmeId?: InputMaybe<StringFilter>;
};

export type ProductRelationFilter = {
  is?: InputMaybe<ProductWhereInput>;
  isNot?: InputMaybe<ProductWhereInput>;
};

export enum ProductScalarFieldEnum {
  BrandingType = 'brandingType',
  Created = 'created',
  Description = 'description',
  Id = 'id',
  IsPublished = 'isPublished',
  Modified = 'modified',
  Name = 'name',
  ShortName = 'shortName',
  Slug = 'slug'
}

export type ProductScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProductScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProductScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProductScalarWhereWithAggregatesInput>>;
  brandingType?: InputMaybe<EnumBrandingTypeWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPublished?: InputMaybe<BoolWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  shortName?: InputMaybe<StringWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
};

export type ProductSubscriptionType = {
  _count?: Maybe<ProductSubscriptionTypeCount>;
  automaticRenew: Scalars['Boolean']['output'];
  created: Scalars['DateTimeISO']['output'];
  currency: AcceptedCurrency;
  description?: Maybe<Scalars['String']['output']>;
  durationInDays?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  name: Scalars['String']['output'];
  paymentItems: Array<PaymentItem>;
  priceInCents: Scalars['Int']['output'];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']['output']>;
  userSubscriptions: Array<UserSubscription>;
};


export type ProductSubscriptionTypePaymentItemsArgs = {
  cursor?: InputMaybe<PaymentItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentItemWhereInput>;
};


export type ProductSubscriptionTypeProductArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type ProductSubscriptionTypeUserSubscriptionsArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};

export type ProductSubscriptionTypeAvgAggregate = {
  durationInDays?: Maybe<Scalars['Float']['output']>;
  priceInCents?: Maybe<Scalars['Float']['output']>;
};

export type ProductSubscriptionTypeAvgOrderByAggregateInput = {
  durationInDays?: InputMaybe<SortOrder>;
  priceInCents?: InputMaybe<SortOrder>;
};

export type ProductSubscriptionTypeCount = {
  paymentItems: Scalars['Int']['output'];
  userSubscriptions: Scalars['Int']['output'];
};


export type ProductSubscriptionTypeCountPaymentItemsArgs = {
  where?: InputMaybe<PaymentItemWhereInput>;
};


export type ProductSubscriptionTypeCountUserSubscriptionsArgs = {
  where?: InputMaybe<UserSubscriptionWhereInput>;
};

export type ProductSubscriptionTypeCountAggregate = {
  _all: Scalars['Int']['output'];
  automaticRenew: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  currency: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  durationInDays: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isPublished: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  priceInCents: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
};

export type ProductSubscriptionTypeCountOrderByAggregateInput = {
  automaticRenew?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  durationInDays?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  priceInCents?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
};

export type ProductSubscriptionTypeCreateInput = {
  automaticRenew?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency?: InputMaybe<AcceptedCurrency>;
  description?: InputMaybe<Scalars['String']['input']>;
  durationInDays?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  paymentItems?: InputMaybe<PaymentItemCreateNestedManyWithoutProductSubscriptionTypeInput>;
  priceInCents: Scalars['Int']['input'];
  product?: InputMaybe<ProductCreateNestedOneWithoutProductSubscriptionTypesInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutProductSubscriptionTypeInput>;
};

export type ProductSubscriptionTypeCreateManyInput = {
  automaticRenew?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency?: InputMaybe<AcceptedCurrency>;
  description?: InputMaybe<Scalars['String']['input']>;
  durationInDays?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  priceInCents: Scalars['Int']['input'];
  productId?: InputMaybe<Scalars['String']['input']>;
};

export type ProductSubscriptionTypeCreateManyProductInput = {
  automaticRenew?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency?: InputMaybe<AcceptedCurrency>;
  description?: InputMaybe<Scalars['String']['input']>;
  durationInDays?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  priceInCents: Scalars['Int']['input'];
};

export type ProductSubscriptionTypeCreateManyProductInputEnvelope = {
  data: Array<ProductSubscriptionTypeCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductSubscriptionTypeCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<ProductSubscriptionTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductSubscriptionTypeCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductSubscriptionTypeCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductSubscriptionTypeCreateManyProductInputEnvelope>;
};

export type ProductSubscriptionTypeCreateNestedOneWithoutPaymentItemsInput = {
  connect?: InputMaybe<ProductSubscriptionTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductSubscriptionTypeCreateOrConnectWithoutPaymentItemsInput>;
  create?: InputMaybe<ProductSubscriptionTypeCreateWithoutPaymentItemsInput>;
};

export type ProductSubscriptionTypeCreateNestedOneWithoutUserSubscriptionsInput = {
  connect?: InputMaybe<ProductSubscriptionTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductSubscriptionTypeCreateOrConnectWithoutUserSubscriptionsInput>;
  create?: InputMaybe<ProductSubscriptionTypeCreateWithoutUserSubscriptionsInput>;
};

export type ProductSubscriptionTypeCreateOrConnectWithoutPaymentItemsInput = {
  create: ProductSubscriptionTypeCreateWithoutPaymentItemsInput;
  where: ProductSubscriptionTypeWhereUniqueInput;
};

export type ProductSubscriptionTypeCreateOrConnectWithoutProductInput = {
  create: ProductSubscriptionTypeCreateWithoutProductInput;
  where: ProductSubscriptionTypeWhereUniqueInput;
};

export type ProductSubscriptionTypeCreateOrConnectWithoutUserSubscriptionsInput = {
  create: ProductSubscriptionTypeCreateWithoutUserSubscriptionsInput;
  where: ProductSubscriptionTypeWhereUniqueInput;
};

export type ProductSubscriptionTypeCreateWithoutPaymentItemsInput = {
  automaticRenew?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency?: InputMaybe<AcceptedCurrency>;
  description?: InputMaybe<Scalars['String']['input']>;
  durationInDays?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  priceInCents: Scalars['Int']['input'];
  product?: InputMaybe<ProductCreateNestedOneWithoutProductSubscriptionTypesInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutProductSubscriptionTypeInput>;
};

export type ProductSubscriptionTypeCreateWithoutProductInput = {
  automaticRenew?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency?: InputMaybe<AcceptedCurrency>;
  description?: InputMaybe<Scalars['String']['input']>;
  durationInDays?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  paymentItems?: InputMaybe<PaymentItemCreateNestedManyWithoutProductSubscriptionTypeInput>;
  priceInCents: Scalars['Int']['input'];
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutProductSubscriptionTypeInput>;
};

export type ProductSubscriptionTypeCreateWithoutUserSubscriptionsInput = {
  automaticRenew?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency?: InputMaybe<AcceptedCurrency>;
  description?: InputMaybe<Scalars['String']['input']>;
  durationInDays?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  paymentItems?: InputMaybe<PaymentItemCreateNestedManyWithoutProductSubscriptionTypeInput>;
  priceInCents: Scalars['Int']['input'];
  product?: InputMaybe<ProductCreateNestedOneWithoutProductSubscriptionTypesInput>;
};

export type ProductSubscriptionTypeGroupBy = {
  _avg?: Maybe<ProductSubscriptionTypeAvgAggregate>;
  _count?: Maybe<ProductSubscriptionTypeCountAggregate>;
  _max?: Maybe<ProductSubscriptionTypeMaxAggregate>;
  _min?: Maybe<ProductSubscriptionTypeMinAggregate>;
  _sum?: Maybe<ProductSubscriptionTypeSumAggregate>;
  automaticRenew: Scalars['Boolean']['output'];
  created: Scalars['DateTimeISO']['output'];
  currency: AcceptedCurrency;
  description?: Maybe<Scalars['String']['output']>;
  durationInDays?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  name: Scalars['String']['output'];
  priceInCents: Scalars['Int']['output'];
  productId?: Maybe<Scalars['String']['output']>;
};

export type ProductSubscriptionTypeListRelationFilter = {
  every?: InputMaybe<ProductSubscriptionTypeWhereInput>;
  none?: InputMaybe<ProductSubscriptionTypeWhereInput>;
  some?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};

export type ProductSubscriptionTypeMaxAggregate = {
  automaticRenew?: Maybe<Scalars['Boolean']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  currency?: Maybe<AcceptedCurrency>;
  description?: Maybe<Scalars['String']['output']>;
  durationInDays?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priceInCents?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
};

export type ProductSubscriptionTypeMaxOrderByAggregateInput = {
  automaticRenew?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  durationInDays?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  priceInCents?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
};

export type ProductSubscriptionTypeMinAggregate = {
  automaticRenew?: Maybe<Scalars['Boolean']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  currency?: Maybe<AcceptedCurrency>;
  description?: Maybe<Scalars['String']['output']>;
  durationInDays?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priceInCents?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
};

export type ProductSubscriptionTypeMinOrderByAggregateInput = {
  automaticRenew?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  durationInDays?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  priceInCents?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
};

export type ProductSubscriptionTypeNullableRelationFilter = {
  is?: InputMaybe<ProductSubscriptionTypeWhereInput>;
  isNot?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};

export type ProductSubscriptionTypeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductSubscriptionTypeOrderByWithAggregationInput = {
  _avg?: InputMaybe<ProductSubscriptionTypeAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProductSubscriptionTypeCountOrderByAggregateInput>;
  _max?: InputMaybe<ProductSubscriptionTypeMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProductSubscriptionTypeMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProductSubscriptionTypeSumOrderByAggregateInput>;
  automaticRenew?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  durationInDays?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  priceInCents?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrderInput>;
};

export type ProductSubscriptionTypeOrderByWithRelationInput = {
  automaticRenew?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  durationInDays?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paymentItems?: InputMaybe<PaymentItemOrderByRelationAggregateInput>;
  priceInCents?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrderInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionOrderByRelationAggregateInput>;
};

export type ProductSubscriptionTypeRelationFilter = {
  is?: InputMaybe<ProductSubscriptionTypeWhereInput>;
  isNot?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};

export enum ProductSubscriptionTypeScalarFieldEnum {
  AutomaticRenew = 'automaticRenew',
  Created = 'created',
  Currency = 'currency',
  Description = 'description',
  DurationInDays = 'durationInDays',
  Id = 'id',
  IsPublished = 'isPublished',
  Modified = 'modified',
  Name = 'name',
  PriceInCents = 'priceInCents',
  ProductId = 'productId'
}

export type ProductSubscriptionTypeScalarWhereInput = {
  AND?: InputMaybe<Array<ProductSubscriptionTypeScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductSubscriptionTypeScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductSubscriptionTypeScalarWhereInput>>;
  automaticRenew?: InputMaybe<BoolFilter>;
  created?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumAcceptedCurrencyFilter>;
  description?: InputMaybe<StringNullableFilter>;
  durationInDays?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isPublished?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  priceInCents?: InputMaybe<IntFilter>;
  productId?: InputMaybe<StringNullableFilter>;
};

export type ProductSubscriptionTypeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProductSubscriptionTypeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProductSubscriptionTypeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProductSubscriptionTypeScalarWhereWithAggregatesInput>>;
  automaticRenew?: InputMaybe<BoolWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  currency?: InputMaybe<EnumAcceptedCurrencyWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  durationInDays?: InputMaybe<IntNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPublished?: InputMaybe<BoolWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  priceInCents?: InputMaybe<IntWithAggregatesFilter>;
  productId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type ProductSubscriptionTypeSumAggregate = {
  durationInDays?: Maybe<Scalars['Int']['output']>;
  priceInCents?: Maybe<Scalars['Int']['output']>;
};

export type ProductSubscriptionTypeSumOrderByAggregateInput = {
  durationInDays?: InputMaybe<SortOrder>;
  priceInCents?: InputMaybe<SortOrder>;
};

export type ProductSubscriptionTypeUpdateInput = {
  automaticRenew?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumAcceptedCurrencyFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  durationInDays?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  paymentItems?: InputMaybe<PaymentItemUpdateManyWithoutProductSubscriptionTypeNestedInput>;
  priceInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutProductSubscriptionTypesNestedInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutProductSubscriptionTypeNestedInput>;
};

export type ProductSubscriptionTypeUpdateManyMutationInput = {
  automaticRenew?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumAcceptedCurrencyFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  durationInDays?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  priceInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ProductSubscriptionTypeUpdateManyWithWhereWithoutProductInput = {
  data: ProductSubscriptionTypeUpdateManyMutationInput;
  where: ProductSubscriptionTypeScalarWhereInput;
};

export type ProductSubscriptionTypeUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<ProductSubscriptionTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductSubscriptionTypeCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductSubscriptionTypeCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductSubscriptionTypeCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<ProductSubscriptionTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductSubscriptionTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductSubscriptionTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductSubscriptionTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductSubscriptionTypeUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<ProductSubscriptionTypeUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<ProductSubscriptionTypeUpsertWithWhereUniqueWithoutProductInput>>;
};

export type ProductSubscriptionTypeUpdateOneRequiredWithoutUserSubscriptionsNestedInput = {
  connect?: InputMaybe<ProductSubscriptionTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductSubscriptionTypeCreateOrConnectWithoutUserSubscriptionsInput>;
  create?: InputMaybe<ProductSubscriptionTypeCreateWithoutUserSubscriptionsInput>;
  update?: InputMaybe<ProductSubscriptionTypeUpdateToOneWithWhereWithoutUserSubscriptionsInput>;
  upsert?: InputMaybe<ProductSubscriptionTypeUpsertWithoutUserSubscriptionsInput>;
};

export type ProductSubscriptionTypeUpdateOneWithoutPaymentItemsNestedInput = {
  connect?: InputMaybe<ProductSubscriptionTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductSubscriptionTypeCreateOrConnectWithoutPaymentItemsInput>;
  create?: InputMaybe<ProductSubscriptionTypeCreateWithoutPaymentItemsInput>;
  delete?: InputMaybe<ProductSubscriptionTypeWhereInput>;
  disconnect?: InputMaybe<ProductSubscriptionTypeWhereInput>;
  update?: InputMaybe<ProductSubscriptionTypeUpdateToOneWithWhereWithoutPaymentItemsInput>;
  upsert?: InputMaybe<ProductSubscriptionTypeUpsertWithoutPaymentItemsInput>;
};

export type ProductSubscriptionTypeUpdateToOneWithWhereWithoutPaymentItemsInput = {
  data: ProductSubscriptionTypeUpdateWithoutPaymentItemsInput;
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};

export type ProductSubscriptionTypeUpdateToOneWithWhereWithoutUserSubscriptionsInput = {
  data: ProductSubscriptionTypeUpdateWithoutUserSubscriptionsInput;
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};

export type ProductSubscriptionTypeUpdateWithWhereUniqueWithoutProductInput = {
  data: ProductSubscriptionTypeUpdateWithoutProductInput;
  where: ProductSubscriptionTypeWhereUniqueInput;
};

export type ProductSubscriptionTypeUpdateWithoutPaymentItemsInput = {
  automaticRenew?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumAcceptedCurrencyFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  durationInDays?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  priceInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutProductSubscriptionTypesNestedInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutProductSubscriptionTypeNestedInput>;
};

export type ProductSubscriptionTypeUpdateWithoutProductInput = {
  automaticRenew?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumAcceptedCurrencyFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  durationInDays?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  paymentItems?: InputMaybe<PaymentItemUpdateManyWithoutProductSubscriptionTypeNestedInput>;
  priceInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutProductSubscriptionTypeNestedInput>;
};

export type ProductSubscriptionTypeUpdateWithoutUserSubscriptionsInput = {
  automaticRenew?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumAcceptedCurrencyFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  durationInDays?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  paymentItems?: InputMaybe<PaymentItemUpdateManyWithoutProductSubscriptionTypeNestedInput>;
  priceInCents?: InputMaybe<IntFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutProductSubscriptionTypesNestedInput>;
};

export type ProductSubscriptionTypeUpsertWithWhereUniqueWithoutProductInput = {
  create: ProductSubscriptionTypeCreateWithoutProductInput;
  update: ProductSubscriptionTypeUpdateWithoutProductInput;
  where: ProductSubscriptionTypeWhereUniqueInput;
};

export type ProductSubscriptionTypeUpsertWithoutPaymentItemsInput = {
  create: ProductSubscriptionTypeCreateWithoutPaymentItemsInput;
  update: ProductSubscriptionTypeUpdateWithoutPaymentItemsInput;
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};

export type ProductSubscriptionTypeUpsertWithoutUserSubscriptionsInput = {
  create: ProductSubscriptionTypeCreateWithoutUserSubscriptionsInput;
  update: ProductSubscriptionTypeUpdateWithoutUserSubscriptionsInput;
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};

export type ProductSubscriptionTypeWhereInput = {
  AND?: InputMaybe<Array<ProductSubscriptionTypeWhereInput>>;
  NOT?: InputMaybe<Array<ProductSubscriptionTypeWhereInput>>;
  OR?: InputMaybe<Array<ProductSubscriptionTypeWhereInput>>;
  automaticRenew?: InputMaybe<BoolFilter>;
  created?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumAcceptedCurrencyFilter>;
  description?: InputMaybe<StringNullableFilter>;
  durationInDays?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isPublished?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  paymentItems?: InputMaybe<PaymentItemListRelationFilter>;
  priceInCents?: InputMaybe<IntFilter>;
  product?: InputMaybe<ProductNullableRelationFilter>;
  productId?: InputMaybe<StringNullableFilter>;
  userSubscriptions?: InputMaybe<UserSubscriptionListRelationFilter>;
};

export type ProductSubscriptionTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<ProductSubscriptionTypeWhereInput>>;
  NOT?: InputMaybe<Array<ProductSubscriptionTypeWhereInput>>;
  OR?: InputMaybe<Array<ProductSubscriptionTypeWhereInput>>;
  automaticRenew?: InputMaybe<BoolFilter>;
  created?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumAcceptedCurrencyFilter>;
  description?: InputMaybe<StringNullableFilter>;
  durationInDays?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  paymentItems?: InputMaybe<PaymentItemListRelationFilter>;
  priceInCents?: InputMaybe<IntFilter>;
  product?: InputMaybe<ProductNullableRelationFilter>;
  productId?: InputMaybe<StringNullableFilter>;
  userSubscriptions?: InputMaybe<UserSubscriptionListRelationFilter>;
};

export type ProductUpdateInput = {
  brandingType?: InputMaybe<EnumBrandingTypeFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  prerequisites?: InputMaybe<ProductProgrammePrerequisiteUpdateManyWithoutProductNestedInput>;
  productProgrammes?: InputMaybe<ProductProgrammeUpdateManyWithoutProductNestedInput>;
  productSubscriptionTypes?: InputMaybe<ProductSubscriptionTypeUpdateManyWithoutProductNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductUpdateManyMutationInput = {
  brandingType?: InputMaybe<EnumBrandingTypeFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductUpdateOneRequiredWithoutPrerequisitesNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutPrerequisitesInput>;
  create?: InputMaybe<ProductCreateWithoutPrerequisitesInput>;
  update?: InputMaybe<ProductUpdateToOneWithWhereWithoutPrerequisitesInput>;
  upsert?: InputMaybe<ProductUpsertWithoutPrerequisitesInput>;
};

export type ProductUpdateOneRequiredWithoutProductProgrammesNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductProgrammesInput>;
  create?: InputMaybe<ProductCreateWithoutProductProgrammesInput>;
  update?: InputMaybe<ProductUpdateToOneWithWhereWithoutProductProgrammesInput>;
  upsert?: InputMaybe<ProductUpsertWithoutProductProgrammesInput>;
};

export type ProductUpdateOneWithoutProductSubscriptionTypesNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductSubscriptionTypesInput>;
  create?: InputMaybe<ProductCreateWithoutProductSubscriptionTypesInput>;
  delete?: InputMaybe<ProductWhereInput>;
  disconnect?: InputMaybe<ProductWhereInput>;
  update?: InputMaybe<ProductUpdateToOneWithWhereWithoutProductSubscriptionTypesInput>;
  upsert?: InputMaybe<ProductUpsertWithoutProductSubscriptionTypesInput>;
};

export type ProductUpdateToOneWithWhereWithoutPrerequisitesInput = {
  data: ProductUpdateWithoutPrerequisitesInput;
  where?: InputMaybe<ProductWhereInput>;
};

export type ProductUpdateToOneWithWhereWithoutProductProgrammesInput = {
  data: ProductUpdateWithoutProductProgrammesInput;
  where?: InputMaybe<ProductWhereInput>;
};

export type ProductUpdateToOneWithWhereWithoutProductSubscriptionTypesInput = {
  data: ProductUpdateWithoutProductSubscriptionTypesInput;
  where?: InputMaybe<ProductWhereInput>;
};

export type ProductUpdateWithoutPrerequisitesInput = {
  brandingType?: InputMaybe<EnumBrandingTypeFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productProgrammes?: InputMaybe<ProductProgrammeUpdateManyWithoutProductNestedInput>;
  productSubscriptionTypes?: InputMaybe<ProductSubscriptionTypeUpdateManyWithoutProductNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutProductProgrammesInput = {
  brandingType?: InputMaybe<EnumBrandingTypeFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  prerequisites?: InputMaybe<ProductProgrammePrerequisiteUpdateManyWithoutProductNestedInput>;
  productSubscriptionTypes?: InputMaybe<ProductSubscriptionTypeUpdateManyWithoutProductNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutProductSubscriptionTypesInput = {
  brandingType?: InputMaybe<EnumBrandingTypeFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  prerequisites?: InputMaybe<ProductProgrammePrerequisiteUpdateManyWithoutProductNestedInput>;
  productProgrammes?: InputMaybe<ProductProgrammeUpdateManyWithoutProductNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductUpsertWithoutPrerequisitesInput = {
  create: ProductCreateWithoutPrerequisitesInput;
  update: ProductUpdateWithoutPrerequisitesInput;
  where?: InputMaybe<ProductWhereInput>;
};

export type ProductUpsertWithoutProductProgrammesInput = {
  create: ProductCreateWithoutProductProgrammesInput;
  update: ProductUpdateWithoutProductProgrammesInput;
  where?: InputMaybe<ProductWhereInput>;
};

export type ProductUpsertWithoutProductSubscriptionTypesInput = {
  create: ProductCreateWithoutProductSubscriptionTypesInput;
  update: ProductUpdateWithoutProductSubscriptionTypesInput;
  where?: InputMaybe<ProductWhereInput>;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  brandingType?: InputMaybe<EnumBrandingTypeFilter>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPublished?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  prerequisites?: InputMaybe<ProductProgrammePrerequisiteListRelationFilter>;
  productProgrammes?: InputMaybe<ProductProgrammeListRelationFilter>;
  productSubscriptionTypes?: InputMaybe<ProductSubscriptionTypeListRelationFilter>;
  shortName?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
};

export type ProductWhereUniqueInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  brandingType?: InputMaybe<EnumBrandingTypeFilter>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  prerequisites?: InputMaybe<ProductProgrammePrerequisiteListRelationFilter>;
  productProgrammes?: InputMaybe<ProductProgrammeListRelationFilter>;
  productSubscriptionTypes?: InputMaybe<ProductSubscriptionTypeListRelationFilter>;
  shortName?: InputMaybe<StringFilter>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type Programme = {
  _count?: Maybe<ProgrammeCount>;
  categories: Array<Category>;
  created: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isHidden: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  learningStyle: LearningStyle;
  modified: Scalars['DateTimeISO']['output'];
  name: Scalars['String']['output'];
  prerequisiteFor: Array<ProductProgrammePrerequisite>;
  programmeProducts: Array<ProductProgramme>;
  shortName: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  userProgressions: Array<ProgrammeProgression>;
};


export type ProgrammeCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type ProgrammePrerequisiteForArgs = {
  cursor?: InputMaybe<ProductProgrammePrerequisiteWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductProgrammePrerequisiteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductProgrammePrerequisiteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
};


export type ProgrammeProgrammeProductsArgs = {
  cursor?: InputMaybe<ProductProgrammeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductProgrammeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductProgrammeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammeWhereInput>;
};


export type ProgrammeUserProgressionsArgs = {
  cursor?: InputMaybe<ProgrammeProgressionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgrammeProgressionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgrammeProgressionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgrammeProgressionWhereInput>;
};

export type ProgrammeAvgAggregate = {
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

export type ProgrammeAvgOrderByAggregateInput = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type ProgrammeCount = {
  categories: Scalars['Int']['output'];
  prerequisiteFor: Scalars['Int']['output'];
  programmeProducts: Scalars['Int']['output'];
  userProgressions: Scalars['Int']['output'];
};


export type ProgrammeCountCategoriesArgs = {
  where?: InputMaybe<CategoryWhereInput>;
};


export type ProgrammeCountPrerequisiteForArgs = {
  where?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
};


export type ProgrammeCountProgrammeProductsArgs = {
  where?: InputMaybe<ProductProgrammeWhereInput>;
};


export type ProgrammeCountUserProgressionsArgs = {
  where?: InputMaybe<ProgrammeProgressionWhereInput>;
};

export type ProgrammeCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isHidden: Scalars['Int']['output'];
  isPublished: Scalars['Int']['output'];
  learningStyle: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  shortName: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type ProgrammeCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isHidden?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  learningStyle?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ProgrammeCreateInput = {
  categories?: InputMaybe<CategoryCreateNestedManyWithoutProgrammeInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  learningStyle?: InputMaybe<LearningStyle>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  prerequisiteFor?: InputMaybe<ProductProgrammePrerequisiteCreateNestedManyWithoutProgrammeInput>;
  programmeProducts?: InputMaybe<ProductProgrammeCreateNestedManyWithoutProgrammeInput>;
  shortName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  userProgressions?: InputMaybe<ProgrammeProgressionCreateNestedManyWithoutProgrammeInput>;
};

export type ProgrammeCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  learningStyle?: InputMaybe<LearningStyle>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  shortName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ProgrammeCreateNestedOneWithoutCategoriesInput = {
  connect?: InputMaybe<ProgrammeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProgrammeCreateOrConnectWithoutCategoriesInput>;
  create?: InputMaybe<ProgrammeCreateWithoutCategoriesInput>;
};

export type ProgrammeCreateNestedOneWithoutPrerequisiteForInput = {
  connect?: InputMaybe<ProgrammeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProgrammeCreateOrConnectWithoutPrerequisiteForInput>;
  create?: InputMaybe<ProgrammeCreateWithoutPrerequisiteForInput>;
};

export type ProgrammeCreateNestedOneWithoutProgrammeProductsInput = {
  connect?: InputMaybe<ProgrammeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProgrammeCreateOrConnectWithoutProgrammeProductsInput>;
  create?: InputMaybe<ProgrammeCreateWithoutProgrammeProductsInput>;
};

export type ProgrammeCreateNestedOneWithoutUserProgressionsInput = {
  connect?: InputMaybe<ProgrammeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProgrammeCreateOrConnectWithoutUserProgressionsInput>;
  create?: InputMaybe<ProgrammeCreateWithoutUserProgressionsInput>;
};

export type ProgrammeCreateOrConnectWithoutCategoriesInput = {
  create: ProgrammeCreateWithoutCategoriesInput;
  where: ProgrammeWhereUniqueInput;
};

export type ProgrammeCreateOrConnectWithoutPrerequisiteForInput = {
  create: ProgrammeCreateWithoutPrerequisiteForInput;
  where: ProgrammeWhereUniqueInput;
};

export type ProgrammeCreateOrConnectWithoutProgrammeProductsInput = {
  create: ProgrammeCreateWithoutProgrammeProductsInput;
  where: ProgrammeWhereUniqueInput;
};

export type ProgrammeCreateOrConnectWithoutUserProgressionsInput = {
  create: ProgrammeCreateWithoutUserProgressionsInput;
  where: ProgrammeWhereUniqueInput;
};

export type ProgrammeCreateWithoutCategoriesInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  learningStyle?: InputMaybe<LearningStyle>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  prerequisiteFor?: InputMaybe<ProductProgrammePrerequisiteCreateNestedManyWithoutProgrammeInput>;
  programmeProducts?: InputMaybe<ProductProgrammeCreateNestedManyWithoutProgrammeInput>;
  shortName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  userProgressions?: InputMaybe<ProgrammeProgressionCreateNestedManyWithoutProgrammeInput>;
};

export type ProgrammeCreateWithoutPrerequisiteForInput = {
  categories?: InputMaybe<CategoryCreateNestedManyWithoutProgrammeInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  learningStyle?: InputMaybe<LearningStyle>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  programmeProducts?: InputMaybe<ProductProgrammeCreateNestedManyWithoutProgrammeInput>;
  shortName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  userProgressions?: InputMaybe<ProgrammeProgressionCreateNestedManyWithoutProgrammeInput>;
};

export type ProgrammeCreateWithoutProgrammeProductsInput = {
  categories?: InputMaybe<CategoryCreateNestedManyWithoutProgrammeInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  learningStyle?: InputMaybe<LearningStyle>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  prerequisiteFor?: InputMaybe<ProductProgrammePrerequisiteCreateNestedManyWithoutProgrammeInput>;
  shortName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  userProgressions?: InputMaybe<ProgrammeProgressionCreateNestedManyWithoutProgrammeInput>;
};

export type ProgrammeCreateWithoutUserProgressionsInput = {
  categories?: InputMaybe<CategoryCreateNestedManyWithoutProgrammeInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  learningStyle?: InputMaybe<LearningStyle>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  prerequisiteFor?: InputMaybe<ProductProgrammePrerequisiteCreateNestedManyWithoutProgrammeInput>;
  programmeProducts?: InputMaybe<ProductProgrammeCreateNestedManyWithoutProgrammeInput>;
  shortName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ProgrammeGroupBy = {
  _avg?: Maybe<ProgrammeAvgAggregate>;
  _count?: Maybe<ProgrammeCountAggregate>;
  _max?: Maybe<ProgrammeMaxAggregate>;
  _min?: Maybe<ProgrammeMinAggregate>;
  _sum?: Maybe<ProgrammeSumAggregate>;
  created: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isHidden: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  learningStyle: LearningStyle;
  modified: Scalars['DateTimeISO']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type ProgrammeMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  learningStyle?: Maybe<LearningStyle>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type ProgrammeMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isHidden?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  learningStyle?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ProgrammeMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  learningStyle?: Maybe<LearningStyle>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type ProgrammeMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isHidden?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  learningStyle?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ProgrammeOrderByWithAggregationInput = {
  _avg?: InputMaybe<ProgrammeAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProgrammeCountOrderByAggregateInput>;
  _max?: InputMaybe<ProgrammeMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProgrammeMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProgrammeSumOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isHidden?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  learningStyle?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ProgrammeOrderByWithRelationInput = {
  categories?: InputMaybe<CategoryOrderByRelationAggregateInput>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isHidden?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  learningStyle?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  prerequisiteFor?: InputMaybe<ProductProgrammePrerequisiteOrderByRelationAggregateInput>;
  programmeProducts?: InputMaybe<ProductProgrammeOrderByRelationAggregateInput>;
  shortName?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
  userProgressions?: InputMaybe<ProgrammeProgressionOrderByRelationAggregateInput>;
};

export type ProgrammeProgression = {
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isCompleted: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  percentage?: Maybe<Scalars['Float']['output']>;
  programme: Programme;
  programmeId: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type ProgrammeProgressionAvgAggregate = {
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type ProgrammeProgressionAvgOrderByAggregateInput = {
  percentage?: InputMaybe<SortOrder>;
};

export type ProgrammeProgressionCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isCompleted: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  percentage: Scalars['Int']['output'];
  programmeId: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type ProgrammeProgressionCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ProgrammeProgressionCreateInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
  programme: ProgrammeCreateNestedOneWithoutUserProgressionsInput;
  user: UserCreateNestedOneWithoutProgrammeProgressionsInput;
};

export type ProgrammeProgressionCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
  programmeId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type ProgrammeProgressionCreateManyProgrammeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
  userId: Scalars['String']['input'];
};

export type ProgrammeProgressionCreateManyProgrammeInputEnvelope = {
  data: Array<ProgrammeProgressionCreateManyProgrammeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProgrammeProgressionCreateManyUserInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
  programmeId: Scalars['String']['input'];
};

export type ProgrammeProgressionCreateManyUserInputEnvelope = {
  data: Array<ProgrammeProgressionCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProgrammeProgressionCreateNestedManyWithoutProgrammeInput = {
  connect?: InputMaybe<Array<ProgrammeProgressionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProgrammeProgressionCreateOrConnectWithoutProgrammeInput>>;
  create?: InputMaybe<Array<ProgrammeProgressionCreateWithoutProgrammeInput>>;
  createMany?: InputMaybe<ProgrammeProgressionCreateManyProgrammeInputEnvelope>;
};

export type ProgrammeProgressionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ProgrammeProgressionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProgrammeProgressionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ProgrammeProgressionCreateWithoutUserInput>>;
  createMany?: InputMaybe<ProgrammeProgressionCreateManyUserInputEnvelope>;
};

export type ProgrammeProgressionCreateOrConnectWithoutProgrammeInput = {
  create: ProgrammeProgressionCreateWithoutProgrammeInput;
  where: ProgrammeProgressionWhereUniqueInput;
};

export type ProgrammeProgressionCreateOrConnectWithoutUserInput = {
  create: ProgrammeProgressionCreateWithoutUserInput;
  where: ProgrammeProgressionWhereUniqueInput;
};

export type ProgrammeProgressionCreateWithoutProgrammeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
  user: UserCreateNestedOneWithoutProgrammeProgressionsInput;
};

export type ProgrammeProgressionCreateWithoutUserInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
  programme: ProgrammeCreateNestedOneWithoutUserProgressionsInput;
};

export type ProgrammeProgressionGroupBy = {
  _avg?: Maybe<ProgrammeProgressionAvgAggregate>;
  _count?: Maybe<ProgrammeProgressionCountAggregate>;
  _max?: Maybe<ProgrammeProgressionMaxAggregate>;
  _min?: Maybe<ProgrammeProgressionMinAggregate>;
  _sum?: Maybe<ProgrammeProgressionSumAggregate>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isCompleted: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  percentage?: Maybe<Scalars['Float']['output']>;
  programmeId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type ProgrammeProgressionListRelationFilter = {
  every?: InputMaybe<ProgrammeProgressionWhereInput>;
  none?: InputMaybe<ProgrammeProgressionWhereInput>;
  some?: InputMaybe<ProgrammeProgressionWhereInput>;
};

export type ProgrammeProgressionMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  programmeId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type ProgrammeProgressionMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ProgrammeProgressionMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  programmeId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type ProgrammeProgressionMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  programmeId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ProgrammeProgressionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProgrammeProgressionOrderByWithAggregationInput = {
  _avg?: InputMaybe<ProgrammeProgressionAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProgrammeProgressionCountOrderByAggregateInput>;
  _max?: InputMaybe<ProgrammeProgressionMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProgrammeProgressionMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProgrammeProgressionSumOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrderInput>;
  programmeId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ProgrammeProgressionOrderByWithRelationInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrderInput>;
  programme?: InputMaybe<ProgrammeOrderByWithRelationInput>;
  programmeId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type ProgrammeProgressionProgrammeProgressionCompoundUniqueInput = {
  programmeId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export enum ProgrammeProgressionScalarFieldEnum {
  Created = 'created',
  Id = 'id',
  IsCompleted = 'isCompleted',
  Modified = 'modified',
  Percentage = 'percentage',
  ProgrammeId = 'programmeId',
  UserId = 'userId'
}

export type ProgrammeProgressionScalarWhereInput = {
  AND?: InputMaybe<Array<ProgrammeProgressionScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProgrammeProgressionScalarWhereInput>>;
  OR?: InputMaybe<Array<ProgrammeProgressionScalarWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isCompleted?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  percentage?: InputMaybe<FloatNullableFilter>;
  programmeId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ProgrammeProgressionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProgrammeProgressionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProgrammeProgressionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProgrammeProgressionScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isCompleted?: InputMaybe<BoolWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  percentage?: InputMaybe<FloatNullableWithAggregatesFilter>;
  programmeId?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type ProgrammeProgressionSumAggregate = {
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type ProgrammeProgressionSumOrderByAggregateInput = {
  percentage?: InputMaybe<SortOrder>;
};

export type ProgrammeProgressionUpdateInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  percentage?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutUserProgressionsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProgrammeProgressionsNestedInput>;
};

export type ProgrammeProgressionUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  percentage?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type ProgrammeProgressionUpdateManyWithWhereWithoutProgrammeInput = {
  data: ProgrammeProgressionUpdateManyMutationInput;
  where: ProgrammeProgressionScalarWhereInput;
};

export type ProgrammeProgressionUpdateManyWithWhereWithoutUserInput = {
  data: ProgrammeProgressionUpdateManyMutationInput;
  where: ProgrammeProgressionScalarWhereInput;
};

export type ProgrammeProgressionUpdateManyWithoutProgrammeNestedInput = {
  connect?: InputMaybe<Array<ProgrammeProgressionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProgrammeProgressionCreateOrConnectWithoutProgrammeInput>>;
  create?: InputMaybe<Array<ProgrammeProgressionCreateWithoutProgrammeInput>>;
  createMany?: InputMaybe<ProgrammeProgressionCreateManyProgrammeInputEnvelope>;
  delete?: InputMaybe<Array<ProgrammeProgressionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProgrammeProgressionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProgrammeProgressionWhereUniqueInput>>;
  set?: InputMaybe<Array<ProgrammeProgressionWhereUniqueInput>>;
  update?: InputMaybe<Array<ProgrammeProgressionUpdateWithWhereUniqueWithoutProgrammeInput>>;
  updateMany?: InputMaybe<Array<ProgrammeProgressionUpdateManyWithWhereWithoutProgrammeInput>>;
  upsert?: InputMaybe<Array<ProgrammeProgressionUpsertWithWhereUniqueWithoutProgrammeInput>>;
};

export type ProgrammeProgressionUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ProgrammeProgressionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProgrammeProgressionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ProgrammeProgressionCreateWithoutUserInput>>;
  createMany?: InputMaybe<ProgrammeProgressionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ProgrammeProgressionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProgrammeProgressionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProgrammeProgressionWhereUniqueInput>>;
  set?: InputMaybe<Array<ProgrammeProgressionWhereUniqueInput>>;
  update?: InputMaybe<Array<ProgrammeProgressionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ProgrammeProgressionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ProgrammeProgressionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ProgrammeProgressionUpdateWithWhereUniqueWithoutProgrammeInput = {
  data: ProgrammeProgressionUpdateWithoutProgrammeInput;
  where: ProgrammeProgressionWhereUniqueInput;
};

export type ProgrammeProgressionUpdateWithWhereUniqueWithoutUserInput = {
  data: ProgrammeProgressionUpdateWithoutUserInput;
  where: ProgrammeProgressionWhereUniqueInput;
};

export type ProgrammeProgressionUpdateWithoutProgrammeInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  percentage?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProgrammeProgressionsNestedInput>;
};

export type ProgrammeProgressionUpdateWithoutUserInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  percentage?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  programme?: InputMaybe<ProgrammeUpdateOneRequiredWithoutUserProgressionsNestedInput>;
};

export type ProgrammeProgressionUpsertWithWhereUniqueWithoutProgrammeInput = {
  create: ProgrammeProgressionCreateWithoutProgrammeInput;
  update: ProgrammeProgressionUpdateWithoutProgrammeInput;
  where: ProgrammeProgressionWhereUniqueInput;
};

export type ProgrammeProgressionUpsertWithWhereUniqueWithoutUserInput = {
  create: ProgrammeProgressionCreateWithoutUserInput;
  update: ProgrammeProgressionUpdateWithoutUserInput;
  where: ProgrammeProgressionWhereUniqueInput;
};

export type ProgrammeProgressionWhereInput = {
  AND?: InputMaybe<Array<ProgrammeProgressionWhereInput>>;
  NOT?: InputMaybe<Array<ProgrammeProgressionWhereInput>>;
  OR?: InputMaybe<Array<ProgrammeProgressionWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isCompleted?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  percentage?: InputMaybe<FloatNullableFilter>;
  programme?: InputMaybe<ProgrammeRelationFilter>;
  programmeId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ProgrammeProgressionWhereUniqueInput = {
  AND?: InputMaybe<Array<ProgrammeProgressionWhereInput>>;
  NOT?: InputMaybe<Array<ProgrammeProgressionWhereInput>>;
  OR?: InputMaybe<Array<ProgrammeProgressionWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  percentage?: InputMaybe<FloatNullableFilter>;
  programme?: InputMaybe<ProgrammeRelationFilter>;
  programmeId?: InputMaybe<StringFilter>;
  programmeProgression?: InputMaybe<ProgrammeProgressionProgrammeProgressionCompoundUniqueInput>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ProgrammeRelationFilter = {
  is?: InputMaybe<ProgrammeWhereInput>;
  isNot?: InputMaybe<ProgrammeWhereInput>;
};

export enum ProgrammeScalarFieldEnum {
  Created = 'created',
  Description = 'description',
  Id = 'id',
  IsHidden = 'isHidden',
  IsPublished = 'isPublished',
  LearningStyle = 'learningStyle',
  Modified = 'modified',
  Name = 'name',
  ShortName = 'shortName',
  Slug = 'slug',
  SortOrder = 'sortOrder'
}

export type ProgrammeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProgrammeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProgrammeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProgrammeScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isHidden?: InputMaybe<BoolWithAggregatesFilter>;
  isPublished?: InputMaybe<BoolWithAggregatesFilter>;
  learningStyle?: InputMaybe<EnumLearningStyleWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  shortName?: InputMaybe<StringWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  sortOrder?: InputMaybe<IntWithAggregatesFilter>;
};

export type ProgrammeSumAggregate = {
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type ProgrammeSumOrderByAggregateInput = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type ProgrammeUpdateInput = {
  categories?: InputMaybe<CategoryUpdateManyWithoutProgrammeNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isHidden?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  learningStyle?: InputMaybe<EnumLearningStyleFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  prerequisiteFor?: InputMaybe<ProductProgrammePrerequisiteUpdateManyWithoutProgrammeNestedInput>;
  programmeProducts?: InputMaybe<ProductProgrammeUpdateManyWithoutProgrammeNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  userProgressions?: InputMaybe<ProgrammeProgressionUpdateManyWithoutProgrammeNestedInput>;
};

export type ProgrammeUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isHidden?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  learningStyle?: InputMaybe<EnumLearningStyleFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ProgrammeUpdateOneRequiredWithoutCategoriesNestedInput = {
  connect?: InputMaybe<ProgrammeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProgrammeCreateOrConnectWithoutCategoriesInput>;
  create?: InputMaybe<ProgrammeCreateWithoutCategoriesInput>;
  update?: InputMaybe<ProgrammeUpdateToOneWithWhereWithoutCategoriesInput>;
  upsert?: InputMaybe<ProgrammeUpsertWithoutCategoriesInput>;
};

export type ProgrammeUpdateOneRequiredWithoutPrerequisiteForNestedInput = {
  connect?: InputMaybe<ProgrammeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProgrammeCreateOrConnectWithoutPrerequisiteForInput>;
  create?: InputMaybe<ProgrammeCreateWithoutPrerequisiteForInput>;
  update?: InputMaybe<ProgrammeUpdateToOneWithWhereWithoutPrerequisiteForInput>;
  upsert?: InputMaybe<ProgrammeUpsertWithoutPrerequisiteForInput>;
};

export type ProgrammeUpdateOneRequiredWithoutProgrammeProductsNestedInput = {
  connect?: InputMaybe<ProgrammeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProgrammeCreateOrConnectWithoutProgrammeProductsInput>;
  create?: InputMaybe<ProgrammeCreateWithoutProgrammeProductsInput>;
  update?: InputMaybe<ProgrammeUpdateToOneWithWhereWithoutProgrammeProductsInput>;
  upsert?: InputMaybe<ProgrammeUpsertWithoutProgrammeProductsInput>;
};

export type ProgrammeUpdateOneRequiredWithoutUserProgressionsNestedInput = {
  connect?: InputMaybe<ProgrammeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProgrammeCreateOrConnectWithoutUserProgressionsInput>;
  create?: InputMaybe<ProgrammeCreateWithoutUserProgressionsInput>;
  update?: InputMaybe<ProgrammeUpdateToOneWithWhereWithoutUserProgressionsInput>;
  upsert?: InputMaybe<ProgrammeUpsertWithoutUserProgressionsInput>;
};

export type ProgrammeUpdateToOneWithWhereWithoutCategoriesInput = {
  data: ProgrammeUpdateWithoutCategoriesInput;
  where?: InputMaybe<ProgrammeWhereInput>;
};

export type ProgrammeUpdateToOneWithWhereWithoutPrerequisiteForInput = {
  data: ProgrammeUpdateWithoutPrerequisiteForInput;
  where?: InputMaybe<ProgrammeWhereInput>;
};

export type ProgrammeUpdateToOneWithWhereWithoutProgrammeProductsInput = {
  data: ProgrammeUpdateWithoutProgrammeProductsInput;
  where?: InputMaybe<ProgrammeWhereInput>;
};

export type ProgrammeUpdateToOneWithWhereWithoutUserProgressionsInput = {
  data: ProgrammeUpdateWithoutUserProgressionsInput;
  where?: InputMaybe<ProgrammeWhereInput>;
};

export type ProgrammeUpdateWithoutCategoriesInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isHidden?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  learningStyle?: InputMaybe<EnumLearningStyleFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  prerequisiteFor?: InputMaybe<ProductProgrammePrerequisiteUpdateManyWithoutProgrammeNestedInput>;
  programmeProducts?: InputMaybe<ProductProgrammeUpdateManyWithoutProgrammeNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  userProgressions?: InputMaybe<ProgrammeProgressionUpdateManyWithoutProgrammeNestedInput>;
};

export type ProgrammeUpdateWithoutPrerequisiteForInput = {
  categories?: InputMaybe<CategoryUpdateManyWithoutProgrammeNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isHidden?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  learningStyle?: InputMaybe<EnumLearningStyleFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  programmeProducts?: InputMaybe<ProductProgrammeUpdateManyWithoutProgrammeNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  userProgressions?: InputMaybe<ProgrammeProgressionUpdateManyWithoutProgrammeNestedInput>;
};

export type ProgrammeUpdateWithoutProgrammeProductsInput = {
  categories?: InputMaybe<CategoryUpdateManyWithoutProgrammeNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isHidden?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  learningStyle?: InputMaybe<EnumLearningStyleFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  prerequisiteFor?: InputMaybe<ProductProgrammePrerequisiteUpdateManyWithoutProgrammeNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  userProgressions?: InputMaybe<ProgrammeProgressionUpdateManyWithoutProgrammeNestedInput>;
};

export type ProgrammeUpdateWithoutUserProgressionsInput = {
  categories?: InputMaybe<CategoryUpdateManyWithoutProgrammeNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isHidden?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  learningStyle?: InputMaybe<EnumLearningStyleFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  prerequisiteFor?: InputMaybe<ProductProgrammePrerequisiteUpdateManyWithoutProgrammeNestedInput>;
  programmeProducts?: InputMaybe<ProductProgrammeUpdateManyWithoutProgrammeNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ProgrammeUpsertWithoutCategoriesInput = {
  create: ProgrammeCreateWithoutCategoriesInput;
  update: ProgrammeUpdateWithoutCategoriesInput;
  where?: InputMaybe<ProgrammeWhereInput>;
};

export type ProgrammeUpsertWithoutPrerequisiteForInput = {
  create: ProgrammeCreateWithoutPrerequisiteForInput;
  update: ProgrammeUpdateWithoutPrerequisiteForInput;
  where?: InputMaybe<ProgrammeWhereInput>;
};

export type ProgrammeUpsertWithoutProgrammeProductsInput = {
  create: ProgrammeCreateWithoutProgrammeProductsInput;
  update: ProgrammeUpdateWithoutProgrammeProductsInput;
  where?: InputMaybe<ProgrammeWhereInput>;
};

export type ProgrammeUpsertWithoutUserProgressionsInput = {
  create: ProgrammeCreateWithoutUserProgressionsInput;
  update: ProgrammeUpdateWithoutUserProgressionsInput;
  where?: InputMaybe<ProgrammeWhereInput>;
};

export type ProgrammeWhereInput = {
  AND?: InputMaybe<Array<ProgrammeWhereInput>>;
  NOT?: InputMaybe<Array<ProgrammeWhereInput>>;
  OR?: InputMaybe<Array<ProgrammeWhereInput>>;
  categories?: InputMaybe<CategoryListRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isHidden?: InputMaybe<BoolFilter>;
  isPublished?: InputMaybe<BoolFilter>;
  learningStyle?: InputMaybe<EnumLearningStyleFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  prerequisiteFor?: InputMaybe<ProductProgrammePrerequisiteListRelationFilter>;
  programmeProducts?: InputMaybe<ProductProgrammeListRelationFilter>;
  shortName?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  sortOrder?: InputMaybe<IntFilter>;
  userProgressions?: InputMaybe<ProgrammeProgressionListRelationFilter>;
};

export type ProgrammeWhereUniqueInput = {
  AND?: InputMaybe<Array<ProgrammeWhereInput>>;
  NOT?: InputMaybe<Array<ProgrammeWhereInput>>;
  OR?: InputMaybe<Array<ProgrammeWhereInput>>;
  categories?: InputMaybe<CategoryListRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<BoolFilter>;
  isPublished?: InputMaybe<BoolFilter>;
  learningStyle?: InputMaybe<EnumLearningStyleFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  prerequisiteFor?: InputMaybe<ProductProgrammePrerequisiteListRelationFilter>;
  programmeProducts?: InputMaybe<ProductProgrammeListRelationFilter>;
  shortName?: InputMaybe<StringFilter>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<IntFilter>;
  userProgressions?: InputMaybe<ProgrammeProgressionListRelationFilter>;
};

export type Query = {
  aggregateArticle: AggregateArticle;
  aggregateArticleCategory: AggregateArticleCategory;
  aggregateCategory: AggregateCategory;
  aggregateCategoryPrerequisite: AggregateCategoryPrerequisite;
  aggregateContent: AggregateContent;
  aggregateEvent: AggregateEvent;
  aggregateEventAttendance: AggregateEventAttendance;
  aggregateEventCategory: AggregateEventCategory;
  aggregateEventOccurrence: AggregateEventOccurrence;
  aggregateEventRegistration: AggregateEventRegistration;
  aggregateGuest: AggregateGuest;
  aggregatePayment: AggregatePayment;
  aggregatePaymentItem: AggregatePaymentItem;
  aggregateProduct: AggregateProduct;
  aggregateProductProgramme: AggregateProductProgramme;
  aggregateProductProgrammePrerequisite: AggregateProductProgrammePrerequisite;
  aggregateProductSubscriptionType: AggregateProductSubscriptionType;
  aggregateProgramme: AggregateProgramme;
  aggregateProgrammeProgression: AggregateProgrammeProgression;
  aggregateQuiz: AggregateQuiz;
  aggregateQuizCategory: AggregateQuizCategory;
  aggregateResource: AggregateResource;
  aggregateResourceCategory: AggregateResourceCategory;
  aggregateResourceType: AggregateResourceType;
  aggregateStripeEvent: AggregateStripeEvent;
  aggregateStripePaymentIntent: AggregateStripePaymentIntent;
  aggregateUser: AggregateUser;
  aggregateUserArticle: AggregateUserArticle;
  aggregateUserManualActivity: AggregateUserManualActivity;
  aggregateUserQuiz: AggregateUserQuiz;
  aggregateUserResource: AggregateUserResource;
  aggregateUserSubscription: AggregateUserSubscription;
  article?: Maybe<Article>;
  articleCategories: Array<ArticleCategory>;
  articleCategory?: Maybe<ArticleCategory>;
  articles: Array<Article>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  categoryPrerequisite?: Maybe<CategoryPrerequisite>;
  categoryPrerequisites: Array<CategoryPrerequisite>;
  content?: Maybe<Content>;
  contents: Array<Content>;
  event?: Maybe<Event>;
  eventAttendance?: Maybe<EventAttendance>;
  eventAttendances: Array<EventAttendance>;
  eventCategories: Array<EventCategory>;
  eventCategory?: Maybe<EventCategory>;
  eventOccurrence?: Maybe<EventOccurrence>;
  eventOccurrences: Array<EventOccurrence>;
  eventRegistration?: Maybe<EventRegistration>;
  eventRegistrations: Array<EventRegistration>;
  events: Array<Event>;
  findFirstArticle?: Maybe<Article>;
  findFirstArticleCategory?: Maybe<ArticleCategory>;
  findFirstArticleCategoryOrThrow?: Maybe<ArticleCategory>;
  findFirstArticleOrThrow?: Maybe<Article>;
  findFirstCategory?: Maybe<Category>;
  findFirstCategoryOrThrow?: Maybe<Category>;
  findFirstCategoryPrerequisite?: Maybe<CategoryPrerequisite>;
  findFirstCategoryPrerequisiteOrThrow?: Maybe<CategoryPrerequisite>;
  findFirstContent?: Maybe<Content>;
  findFirstContentOrThrow?: Maybe<Content>;
  findFirstEvent?: Maybe<Event>;
  findFirstEventAttendance?: Maybe<EventAttendance>;
  findFirstEventAttendanceOrThrow?: Maybe<EventAttendance>;
  findFirstEventCategory?: Maybe<EventCategory>;
  findFirstEventCategoryOrThrow?: Maybe<EventCategory>;
  findFirstEventOccurrence?: Maybe<EventOccurrence>;
  findFirstEventOccurrenceOrThrow?: Maybe<EventOccurrence>;
  findFirstEventOrThrow?: Maybe<Event>;
  findFirstEventRegistration?: Maybe<EventRegistration>;
  findFirstEventRegistrationOrThrow?: Maybe<EventRegistration>;
  findFirstGuest?: Maybe<Guest>;
  findFirstGuestOrThrow?: Maybe<Guest>;
  findFirstPayment?: Maybe<Payment>;
  findFirstPaymentItem?: Maybe<PaymentItem>;
  findFirstPaymentItemOrThrow?: Maybe<PaymentItem>;
  findFirstPaymentOrThrow?: Maybe<Payment>;
  findFirstProduct?: Maybe<Product>;
  findFirstProductOrThrow?: Maybe<Product>;
  findFirstProductProgramme?: Maybe<ProductProgramme>;
  findFirstProductProgrammeOrThrow?: Maybe<ProductProgramme>;
  findFirstProductProgrammePrerequisite?: Maybe<ProductProgrammePrerequisite>;
  findFirstProductProgrammePrerequisiteOrThrow?: Maybe<ProductProgrammePrerequisite>;
  findFirstProductSubscriptionType?: Maybe<ProductSubscriptionType>;
  findFirstProductSubscriptionTypeOrThrow?: Maybe<ProductSubscriptionType>;
  findFirstProgramme?: Maybe<Programme>;
  findFirstProgrammeOrThrow?: Maybe<Programme>;
  findFirstProgrammeProgression?: Maybe<ProgrammeProgression>;
  findFirstProgrammeProgressionOrThrow?: Maybe<ProgrammeProgression>;
  findFirstQuiz?: Maybe<Quiz>;
  findFirstQuizCategory?: Maybe<QuizCategory>;
  findFirstQuizCategoryOrThrow?: Maybe<QuizCategory>;
  findFirstQuizOrThrow?: Maybe<Quiz>;
  findFirstResource?: Maybe<Resource>;
  findFirstResourceCategory?: Maybe<ResourceCategory>;
  findFirstResourceCategoryOrThrow?: Maybe<ResourceCategory>;
  findFirstResourceOrThrow?: Maybe<Resource>;
  findFirstResourceType?: Maybe<ResourceType>;
  findFirstResourceTypeOrThrow?: Maybe<ResourceType>;
  findFirstStripeEvent?: Maybe<StripeEvent>;
  findFirstStripeEventOrThrow?: Maybe<StripeEvent>;
  findFirstStripePaymentIntent?: Maybe<StripePaymentIntent>;
  findFirstStripePaymentIntentOrThrow?: Maybe<StripePaymentIntent>;
  findFirstUser?: Maybe<User>;
  findFirstUserArticle?: Maybe<UserArticle>;
  findFirstUserArticleOrThrow?: Maybe<UserArticle>;
  findFirstUserManualActivity?: Maybe<UserManualActivity>;
  findFirstUserManualActivityOrThrow?: Maybe<UserManualActivity>;
  findFirstUserOrThrow?: Maybe<User>;
  findFirstUserQuiz?: Maybe<UserQuiz>;
  findFirstUserQuizOrThrow?: Maybe<UserQuiz>;
  findFirstUserResource?: Maybe<UserResource>;
  findFirstUserResourceOrThrow?: Maybe<UserResource>;
  findFirstUserSubscription?: Maybe<UserSubscription>;
  findFirstUserSubscriptionOrThrow?: Maybe<UserSubscription>;
  getArticle?: Maybe<Article>;
  getArticleCategory?: Maybe<ArticleCategory>;
  getCategory?: Maybe<Category>;
  getCategoryPrerequisite?: Maybe<CategoryPrerequisite>;
  getContent?: Maybe<Content>;
  getEvent?: Maybe<Event>;
  getEventAttendance?: Maybe<EventAttendance>;
  getEventCategory?: Maybe<EventCategory>;
  getEventOccurrence?: Maybe<EventOccurrence>;
  getEventRegistration?: Maybe<EventRegistration>;
  getGuest?: Maybe<Guest>;
  getPayment?: Maybe<Payment>;
  getPaymentItem?: Maybe<PaymentItem>;
  getProduct?: Maybe<Product>;
  getProductProgramme?: Maybe<ProductProgramme>;
  getProductProgrammePrerequisite?: Maybe<ProductProgrammePrerequisite>;
  getProductSubscriptionType?: Maybe<ProductSubscriptionType>;
  getProgramme?: Maybe<Programme>;
  getProgrammeProgression?: Maybe<ProgrammeProgression>;
  getQuiz?: Maybe<Quiz>;
  getQuizCategory?: Maybe<QuizCategory>;
  getResource?: Maybe<Resource>;
  getResourceCategory?: Maybe<ResourceCategory>;
  getResourceType?: Maybe<ResourceType>;
  getStripeEvent?: Maybe<StripeEvent>;
  getStripePaymentIntent?: Maybe<StripePaymentIntent>;
  getUser?: Maybe<User>;
  getUserArticle?: Maybe<UserArticle>;
  getUserManualActivity?: Maybe<UserManualActivity>;
  getUserQuiz?: Maybe<UserQuiz>;
  getUserResource?: Maybe<UserResource>;
  getUserSubscription?: Maybe<UserSubscription>;
  groupByArticle: Array<ArticleGroupBy>;
  groupByArticleCategory: Array<ArticleCategoryGroupBy>;
  groupByCategory: Array<CategoryGroupBy>;
  groupByCategoryPrerequisite: Array<CategoryPrerequisiteGroupBy>;
  groupByContent: Array<ContentGroupBy>;
  groupByEvent: Array<EventGroupBy>;
  groupByEventAttendance: Array<EventAttendanceGroupBy>;
  groupByEventCategory: Array<EventCategoryGroupBy>;
  groupByEventOccurrence: Array<EventOccurrenceGroupBy>;
  groupByEventRegistration: Array<EventRegistrationGroupBy>;
  groupByGuest: Array<GuestGroupBy>;
  groupByPayment: Array<PaymentGroupBy>;
  groupByPaymentItem: Array<PaymentItemGroupBy>;
  groupByProduct: Array<ProductGroupBy>;
  groupByProductProgramme: Array<ProductProgrammeGroupBy>;
  groupByProductProgrammePrerequisite: Array<ProductProgrammePrerequisiteGroupBy>;
  groupByProductSubscriptionType: Array<ProductSubscriptionTypeGroupBy>;
  groupByProgramme: Array<ProgrammeGroupBy>;
  groupByProgrammeProgression: Array<ProgrammeProgressionGroupBy>;
  groupByQuiz: Array<QuizGroupBy>;
  groupByQuizCategory: Array<QuizCategoryGroupBy>;
  groupByResource: Array<ResourceGroupBy>;
  groupByResourceCategory: Array<ResourceCategoryGroupBy>;
  groupByResourceType: Array<ResourceTypeGroupBy>;
  groupByStripeEvent: Array<StripeEventGroupBy>;
  groupByStripePaymentIntent: Array<StripePaymentIntentGroupBy>;
  groupByUser: Array<UserGroupBy>;
  groupByUserArticle: Array<UserArticleGroupBy>;
  groupByUserManualActivity: Array<UserManualActivityGroupBy>;
  groupByUserQuiz: Array<UserQuizGroupBy>;
  groupByUserResource: Array<UserResourceGroupBy>;
  groupByUserSubscription: Array<UserSubscriptionGroupBy>;
  guest?: Maybe<Guest>;
  guests: Array<Guest>;
  payment?: Maybe<Payment>;
  paymentItem?: Maybe<PaymentItem>;
  paymentItems: Array<PaymentItem>;
  payments: Array<Payment>;
  product?: Maybe<Product>;
  productProgramme?: Maybe<ProductProgramme>;
  productProgrammePrerequisite?: Maybe<ProductProgrammePrerequisite>;
  productProgrammePrerequisites: Array<ProductProgrammePrerequisite>;
  productProgrammes: Array<ProductProgramme>;
  productSubscriptionType?: Maybe<ProductSubscriptionType>;
  productSubscriptionTypes: Array<ProductSubscriptionType>;
  products: Array<Product>;
  programme?: Maybe<Programme>;
  programmeProgression?: Maybe<ProgrammeProgression>;
  programmeProgressions: Array<ProgrammeProgression>;
  programmes: Array<Programme>;
  quiz?: Maybe<Quiz>;
  quizCategories: Array<QuizCategory>;
  quizCategory?: Maybe<QuizCategory>;
  quizzes: Array<Quiz>;
  resource?: Maybe<Resource>;
  resourceCategories: Array<ResourceCategory>;
  resourceCategory?: Maybe<ResourceCategory>;
  resourceType?: Maybe<ResourceType>;
  resourceTypes: Array<ResourceType>;
  resources: Array<Resource>;
  stripeEvent?: Maybe<StripeEvent>;
  stripeEvents: Array<StripeEvent>;
  stripePaymentIntent?: Maybe<StripePaymentIntent>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  user?: Maybe<User>;
  userArticle?: Maybe<UserArticle>;
  userArticles: Array<UserArticle>;
  userManualActivities: Array<UserManualActivity>;
  userManualActivity?: Maybe<UserManualActivity>;
  userQuiz?: Maybe<UserQuiz>;
  userQuizs: Array<UserQuiz>;
  userResource?: Maybe<UserResource>;
  userResources: Array<UserResource>;
  userSubscription?: Maybe<UserSubscription>;
  userSubscriptions: Array<UserSubscription>;
  users: Array<User>;
};


export type QueryAggregateArticleArgs = {
  cursor?: InputMaybe<ArticleWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ArticleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleWhereInput>;
};


export type QueryAggregateArticleCategoryArgs = {
  cursor?: InputMaybe<ArticleCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ArticleCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleCategoryWhereInput>;
};


export type QueryAggregateCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryAggregateCategoryPrerequisiteArgs = {
  cursor?: InputMaybe<CategoryPrerequisiteWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CategoryPrerequisiteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryPrerequisiteWhereInput>;
};


export type QueryAggregateContentArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryAggregateEventArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryAggregateEventAttendanceArgs = {
  cursor?: InputMaybe<EventAttendanceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EventAttendanceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventAttendanceWhereInput>;
};


export type QueryAggregateEventCategoryArgs = {
  cursor?: InputMaybe<EventCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EventCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type QueryAggregateEventOccurrenceArgs = {
  cursor?: InputMaybe<EventOccurrenceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EventOccurrenceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventOccurrenceWhereInput>;
};


export type QueryAggregateEventRegistrationArgs = {
  cursor?: InputMaybe<EventRegistrationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EventRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventRegistrationWhereInput>;
};


export type QueryAggregateGuestArgs = {
  cursor?: InputMaybe<GuestWhereUniqueInput>;
  orderBy?: InputMaybe<Array<GuestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GuestWhereInput>;
};


export type QueryAggregatePaymentArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type QueryAggregatePaymentItemArgs = {
  cursor?: InputMaybe<PaymentItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PaymentItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentItemWhereInput>;
};


export type QueryAggregateProductArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryAggregateProductProgrammeArgs = {
  cursor?: InputMaybe<ProductProgrammeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductProgrammeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammeWhereInput>;
};


export type QueryAggregateProductProgrammePrerequisiteArgs = {
  cursor?: InputMaybe<ProductProgrammePrerequisiteWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductProgrammePrerequisiteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
};


export type QueryAggregateProductSubscriptionTypeArgs = {
  cursor?: InputMaybe<ProductSubscriptionTypeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductSubscriptionTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};


export type QueryAggregateProgrammeArgs = {
  cursor?: InputMaybe<ProgrammeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProgrammeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgrammeWhereInput>;
};


export type QueryAggregateProgrammeProgressionArgs = {
  cursor?: InputMaybe<ProgrammeProgressionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProgrammeProgressionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgrammeProgressionWhereInput>;
};


export type QueryAggregateQuizArgs = {
  cursor?: InputMaybe<QuizWhereUniqueInput>;
  orderBy?: InputMaybe<Array<QuizOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuizWhereInput>;
};


export type QueryAggregateQuizCategoryArgs = {
  cursor?: InputMaybe<QuizCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<QuizCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuizCategoryWhereInput>;
};


export type QueryAggregateResourceArgs = {
  cursor?: InputMaybe<ResourceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ResourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceWhereInput>;
};


export type QueryAggregateResourceCategoryArgs = {
  cursor?: InputMaybe<ResourceCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ResourceCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceCategoryWhereInput>;
};


export type QueryAggregateResourceTypeArgs = {
  cursor?: InputMaybe<ResourceTypeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ResourceTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceTypeWhereInput>;
};


export type QueryAggregateStripeEventArgs = {
  cursor?: InputMaybe<StripeEventWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StripeEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StripeEventWhereInput>;
};


export type QueryAggregateStripePaymentIntentArgs = {
  cursor?: InputMaybe<StripePaymentIntentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StripePaymentIntentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StripePaymentIntentWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAggregateUserArticleArgs = {
  cursor?: InputMaybe<UserArticleWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserArticleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserArticleWhereInput>;
};


export type QueryAggregateUserManualActivityArgs = {
  cursor?: InputMaybe<UserManualActivityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserManualActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserManualActivityWhereInput>;
};


export type QueryAggregateUserQuizArgs = {
  cursor?: InputMaybe<UserQuizWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserQuizOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserQuizWhereInput>;
};


export type QueryAggregateUserResourceArgs = {
  cursor?: InputMaybe<UserResourceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserResourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserResourceWhereInput>;
};


export type QueryAggregateUserSubscriptionArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryArticleArgs = {
  where: ArticleWhereUniqueInput;
};


export type QueryArticleCategoriesArgs = {
  cursor?: InputMaybe<ArticleCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArticleCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArticleCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleCategoryWhereInput>;
};


export type QueryArticleCategoryArgs = {
  where: ArticleCategoryWhereUniqueInput;
};


export type QueryArticlesArgs = {
  cursor?: InputMaybe<ArticleWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArticleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArticleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleWhereInput>;
};


export type QueryCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type QueryCategoryPrerequisiteArgs = {
  where: CategoryPrerequisiteWhereUniqueInput;
};


export type QueryCategoryPrerequisitesArgs = {
  cursor?: InputMaybe<CategoryPrerequisiteWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryPrerequisiteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryPrerequisiteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryPrerequisiteWhereInput>;
};


export type QueryContentArgs = {
  where: ContentWhereUniqueInput;
};


export type QueryContentsArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryEventArgs = {
  where: EventWhereUniqueInput;
};


export type QueryEventAttendanceArgs = {
  where: EventAttendanceWhereUniqueInput;
};


export type QueryEventAttendancesArgs = {
  cursor?: InputMaybe<EventAttendanceWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventAttendanceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventAttendanceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventAttendanceWhereInput>;
};


export type QueryEventCategoriesArgs = {
  cursor?: InputMaybe<EventCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type QueryEventCategoryArgs = {
  where: EventCategoryWhereUniqueInput;
};


export type QueryEventOccurrenceArgs = {
  where: EventOccurrenceWhereUniqueInput;
};


export type QueryEventOccurrencesArgs = {
  cursor?: InputMaybe<EventOccurrenceWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventOccurrenceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventOccurrenceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventOccurrenceWhereInput>;
};


export type QueryEventRegistrationArgs = {
  where: EventRegistrationWhereUniqueInput;
};


export type QueryEventRegistrationsArgs = {
  cursor?: InputMaybe<EventRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventRegistrationWhereInput>;
};


export type QueryEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryFindFirstArticleArgs = {
  cursor?: InputMaybe<ArticleWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArticleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArticleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleWhereInput>;
};


export type QueryFindFirstArticleCategoryArgs = {
  cursor?: InputMaybe<ArticleCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArticleCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArticleCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleCategoryWhereInput>;
};


export type QueryFindFirstArticleCategoryOrThrowArgs = {
  cursor?: InputMaybe<ArticleCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArticleCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArticleCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleCategoryWhereInput>;
};


export type QueryFindFirstArticleOrThrowArgs = {
  cursor?: InputMaybe<ArticleWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArticleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArticleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleWhereInput>;
};


export type QueryFindFirstCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryFindFirstCategoryOrThrowArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryFindFirstCategoryPrerequisiteArgs = {
  cursor?: InputMaybe<CategoryPrerequisiteWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryPrerequisiteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryPrerequisiteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryPrerequisiteWhereInput>;
};


export type QueryFindFirstCategoryPrerequisiteOrThrowArgs = {
  cursor?: InputMaybe<CategoryPrerequisiteWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryPrerequisiteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryPrerequisiteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryPrerequisiteWhereInput>;
};


export type QueryFindFirstContentArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryFindFirstContentOrThrowArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryFindFirstEventArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryFindFirstEventAttendanceArgs = {
  cursor?: InputMaybe<EventAttendanceWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventAttendanceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventAttendanceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventAttendanceWhereInput>;
};


export type QueryFindFirstEventAttendanceOrThrowArgs = {
  cursor?: InputMaybe<EventAttendanceWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventAttendanceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventAttendanceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventAttendanceWhereInput>;
};


export type QueryFindFirstEventCategoryArgs = {
  cursor?: InputMaybe<EventCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type QueryFindFirstEventCategoryOrThrowArgs = {
  cursor?: InputMaybe<EventCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type QueryFindFirstEventOccurrenceArgs = {
  cursor?: InputMaybe<EventOccurrenceWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventOccurrenceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventOccurrenceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventOccurrenceWhereInput>;
};


export type QueryFindFirstEventOccurrenceOrThrowArgs = {
  cursor?: InputMaybe<EventOccurrenceWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventOccurrenceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventOccurrenceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventOccurrenceWhereInput>;
};


export type QueryFindFirstEventOrThrowArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryFindFirstEventRegistrationArgs = {
  cursor?: InputMaybe<EventRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventRegistrationWhereInput>;
};


export type QueryFindFirstEventRegistrationOrThrowArgs = {
  cursor?: InputMaybe<EventRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventRegistrationWhereInput>;
};


export type QueryFindFirstGuestArgs = {
  cursor?: InputMaybe<GuestWhereUniqueInput>;
  distinct?: InputMaybe<Array<GuestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GuestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GuestWhereInput>;
};


export type QueryFindFirstGuestOrThrowArgs = {
  cursor?: InputMaybe<GuestWhereUniqueInput>;
  distinct?: InputMaybe<Array<GuestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GuestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GuestWhereInput>;
};


export type QueryFindFirstPaymentArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type QueryFindFirstPaymentItemArgs = {
  cursor?: InputMaybe<PaymentItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentItemWhereInput>;
};


export type QueryFindFirstPaymentItemOrThrowArgs = {
  cursor?: InputMaybe<PaymentItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentItemWhereInput>;
};


export type QueryFindFirstPaymentOrThrowArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type QueryFindFirstProductArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryFindFirstProductOrThrowArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryFindFirstProductProgrammeArgs = {
  cursor?: InputMaybe<ProductProgrammeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductProgrammeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductProgrammeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammeWhereInput>;
};


export type QueryFindFirstProductProgrammeOrThrowArgs = {
  cursor?: InputMaybe<ProductProgrammeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductProgrammeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductProgrammeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammeWhereInput>;
};


export type QueryFindFirstProductProgrammePrerequisiteArgs = {
  cursor?: InputMaybe<ProductProgrammePrerequisiteWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductProgrammePrerequisiteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductProgrammePrerequisiteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
};


export type QueryFindFirstProductProgrammePrerequisiteOrThrowArgs = {
  cursor?: InputMaybe<ProductProgrammePrerequisiteWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductProgrammePrerequisiteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductProgrammePrerequisiteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
};


export type QueryFindFirstProductSubscriptionTypeArgs = {
  cursor?: InputMaybe<ProductSubscriptionTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductSubscriptionTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductSubscriptionTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};


export type QueryFindFirstProductSubscriptionTypeOrThrowArgs = {
  cursor?: InputMaybe<ProductSubscriptionTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductSubscriptionTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductSubscriptionTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};


export type QueryFindFirstProgrammeArgs = {
  cursor?: InputMaybe<ProgrammeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgrammeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgrammeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgrammeWhereInput>;
};


export type QueryFindFirstProgrammeOrThrowArgs = {
  cursor?: InputMaybe<ProgrammeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgrammeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgrammeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgrammeWhereInput>;
};


export type QueryFindFirstProgrammeProgressionArgs = {
  cursor?: InputMaybe<ProgrammeProgressionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgrammeProgressionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgrammeProgressionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgrammeProgressionWhereInput>;
};


export type QueryFindFirstProgrammeProgressionOrThrowArgs = {
  cursor?: InputMaybe<ProgrammeProgressionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgrammeProgressionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgrammeProgressionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgrammeProgressionWhereInput>;
};


export type QueryFindFirstQuizArgs = {
  cursor?: InputMaybe<QuizWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuizScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuizOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuizWhereInput>;
};


export type QueryFindFirstQuizCategoryArgs = {
  cursor?: InputMaybe<QuizCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuizCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuizCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuizCategoryWhereInput>;
};


export type QueryFindFirstQuizCategoryOrThrowArgs = {
  cursor?: InputMaybe<QuizCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuizCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuizCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuizCategoryWhereInput>;
};


export type QueryFindFirstQuizOrThrowArgs = {
  cursor?: InputMaybe<QuizWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuizScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuizOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuizWhereInput>;
};


export type QueryFindFirstResourceArgs = {
  cursor?: InputMaybe<ResourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceWhereInput>;
};


export type QueryFindFirstResourceCategoryArgs = {
  cursor?: InputMaybe<ResourceCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResourceCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResourceCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceCategoryWhereInput>;
};


export type QueryFindFirstResourceCategoryOrThrowArgs = {
  cursor?: InputMaybe<ResourceCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResourceCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResourceCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceCategoryWhereInput>;
};


export type QueryFindFirstResourceOrThrowArgs = {
  cursor?: InputMaybe<ResourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceWhereInput>;
};


export type QueryFindFirstResourceTypeArgs = {
  cursor?: InputMaybe<ResourceTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResourceTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResourceTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceTypeWhereInput>;
};


export type QueryFindFirstResourceTypeOrThrowArgs = {
  cursor?: InputMaybe<ResourceTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResourceTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResourceTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceTypeWhereInput>;
};


export type QueryFindFirstStripeEventArgs = {
  cursor?: InputMaybe<StripeEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<StripeEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StripeEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StripeEventWhereInput>;
};


export type QueryFindFirstStripeEventOrThrowArgs = {
  cursor?: InputMaybe<StripeEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<StripeEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StripeEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StripeEventWhereInput>;
};


export type QueryFindFirstStripePaymentIntentArgs = {
  cursor?: InputMaybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: InputMaybe<Array<StripePaymentIntentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StripePaymentIntentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StripePaymentIntentWhereInput>;
};


export type QueryFindFirstStripePaymentIntentOrThrowArgs = {
  cursor?: InputMaybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: InputMaybe<Array<StripePaymentIntentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StripePaymentIntentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StripePaymentIntentWhereInput>;
};


export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindFirstUserArticleArgs = {
  cursor?: InputMaybe<UserArticleWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserArticleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserArticleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserArticleWhereInput>;
};


export type QueryFindFirstUserArticleOrThrowArgs = {
  cursor?: InputMaybe<UserArticleWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserArticleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserArticleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserArticleWhereInput>;
};


export type QueryFindFirstUserManualActivityArgs = {
  cursor?: InputMaybe<UserManualActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserManualActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserManualActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserManualActivityWhereInput>;
};


export type QueryFindFirstUserManualActivityOrThrowArgs = {
  cursor?: InputMaybe<UserManualActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserManualActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserManualActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserManualActivityWhereInput>;
};


export type QueryFindFirstUserOrThrowArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindFirstUserQuizArgs = {
  cursor?: InputMaybe<UserQuizWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserQuizScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserQuizOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserQuizWhereInput>;
};


export type QueryFindFirstUserQuizOrThrowArgs = {
  cursor?: InputMaybe<UserQuizWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserQuizScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserQuizOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserQuizWhereInput>;
};


export type QueryFindFirstUserResourceArgs = {
  cursor?: InputMaybe<UserResourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserResourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserResourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserResourceWhereInput>;
};


export type QueryFindFirstUserResourceOrThrowArgs = {
  cursor?: InputMaybe<UserResourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserResourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserResourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserResourceWhereInput>;
};


export type QueryFindFirstUserSubscriptionArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryFindFirstUserSubscriptionOrThrowArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryGetArticleArgs = {
  where: ArticleWhereUniqueInput;
};


export type QueryGetArticleCategoryArgs = {
  where: ArticleCategoryWhereUniqueInput;
};


export type QueryGetCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type QueryGetCategoryPrerequisiteArgs = {
  where: CategoryPrerequisiteWhereUniqueInput;
};


export type QueryGetContentArgs = {
  where: ContentWhereUniqueInput;
};


export type QueryGetEventArgs = {
  where: EventWhereUniqueInput;
};


export type QueryGetEventAttendanceArgs = {
  where: EventAttendanceWhereUniqueInput;
};


export type QueryGetEventCategoryArgs = {
  where: EventCategoryWhereUniqueInput;
};


export type QueryGetEventOccurrenceArgs = {
  where: EventOccurrenceWhereUniqueInput;
};


export type QueryGetEventRegistrationArgs = {
  where: EventRegistrationWhereUniqueInput;
};


export type QueryGetGuestArgs = {
  where: GuestWhereUniqueInput;
};


export type QueryGetPaymentArgs = {
  where: PaymentWhereUniqueInput;
};


export type QueryGetPaymentItemArgs = {
  where: PaymentItemWhereUniqueInput;
};


export type QueryGetProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryGetProductProgrammeArgs = {
  where: ProductProgrammeWhereUniqueInput;
};


export type QueryGetProductProgrammePrerequisiteArgs = {
  where: ProductProgrammePrerequisiteWhereUniqueInput;
};


export type QueryGetProductSubscriptionTypeArgs = {
  where: ProductSubscriptionTypeWhereUniqueInput;
};


export type QueryGetProgrammeArgs = {
  where: ProgrammeWhereUniqueInput;
};


export type QueryGetProgrammeProgressionArgs = {
  where: ProgrammeProgressionWhereUniqueInput;
};


export type QueryGetQuizArgs = {
  where: QuizWhereUniqueInput;
};


export type QueryGetQuizCategoryArgs = {
  where: QuizCategoryWhereUniqueInput;
};


export type QueryGetResourceArgs = {
  where: ResourceWhereUniqueInput;
};


export type QueryGetResourceCategoryArgs = {
  where: ResourceCategoryWhereUniqueInput;
};


export type QueryGetResourceTypeArgs = {
  where: ResourceTypeWhereUniqueInput;
};


export type QueryGetStripeEventArgs = {
  where: StripeEventWhereUniqueInput;
};


export type QueryGetStripePaymentIntentArgs = {
  where: StripePaymentIntentWhereUniqueInput;
};


export type QueryGetUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryGetUserArticleArgs = {
  where: UserArticleWhereUniqueInput;
};


export type QueryGetUserManualActivityArgs = {
  where: UserManualActivityWhereUniqueInput;
};


export type QueryGetUserQuizArgs = {
  where: UserQuizWhereUniqueInput;
};


export type QueryGetUserResourceArgs = {
  where: UserResourceWhereUniqueInput;
};


export type QueryGetUserSubscriptionArgs = {
  where: UserSubscriptionWhereUniqueInput;
};


export type QueryGroupByArticleArgs = {
  by: Array<ArticleScalarFieldEnum>;
  having?: InputMaybe<ArticleScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ArticleOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleWhereInput>;
};


export type QueryGroupByArticleCategoryArgs = {
  by: Array<ArticleCategoryScalarFieldEnum>;
  having?: InputMaybe<ArticleCategoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ArticleCategoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleCategoryWhereInput>;
};


export type QueryGroupByCategoryArgs = {
  by: Array<CategoryScalarFieldEnum>;
  having?: InputMaybe<CategoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryGroupByCategoryPrerequisiteArgs = {
  by: Array<CategoryPrerequisiteScalarFieldEnum>;
  having?: InputMaybe<CategoryPrerequisiteScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CategoryPrerequisiteOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryPrerequisiteWhereInput>;
};


export type QueryGroupByContentArgs = {
  by: Array<ContentScalarFieldEnum>;
  having?: InputMaybe<ContentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ContentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryGroupByEventArgs = {
  by: Array<EventScalarFieldEnum>;
  having?: InputMaybe<EventScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EventOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryGroupByEventAttendanceArgs = {
  by: Array<EventAttendanceScalarFieldEnum>;
  having?: InputMaybe<EventAttendanceScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EventAttendanceOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventAttendanceWhereInput>;
};


export type QueryGroupByEventCategoryArgs = {
  by: Array<EventCategoryScalarFieldEnum>;
  having?: InputMaybe<EventCategoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EventCategoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type QueryGroupByEventOccurrenceArgs = {
  by: Array<EventOccurrenceScalarFieldEnum>;
  having?: InputMaybe<EventOccurrenceScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EventOccurrenceOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventOccurrenceWhereInput>;
};


export type QueryGroupByEventRegistrationArgs = {
  by: Array<EventRegistrationScalarFieldEnum>;
  having?: InputMaybe<EventRegistrationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EventRegistrationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventRegistrationWhereInput>;
};


export type QueryGroupByGuestArgs = {
  by: Array<GuestScalarFieldEnum>;
  having?: InputMaybe<GuestScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<GuestOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GuestWhereInput>;
};


export type QueryGroupByPaymentArgs = {
  by: Array<PaymentScalarFieldEnum>;
  having?: InputMaybe<PaymentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type QueryGroupByPaymentItemArgs = {
  by: Array<PaymentItemScalarFieldEnum>;
  having?: InputMaybe<PaymentItemScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PaymentItemOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentItemWhereInput>;
};


export type QueryGroupByProductArgs = {
  by: Array<ProductScalarFieldEnum>;
  having?: InputMaybe<ProductScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProductOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryGroupByProductProgrammeArgs = {
  by: Array<ProductProgrammeScalarFieldEnum>;
  having?: InputMaybe<ProductProgrammeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProductProgrammeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammeWhereInput>;
};


export type QueryGroupByProductProgrammePrerequisiteArgs = {
  by: Array<ProductProgrammePrerequisiteScalarFieldEnum>;
  having?: InputMaybe<ProductProgrammePrerequisiteScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProductProgrammePrerequisiteOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
};


export type QueryGroupByProductSubscriptionTypeArgs = {
  by: Array<ProductSubscriptionTypeScalarFieldEnum>;
  having?: InputMaybe<ProductSubscriptionTypeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProductSubscriptionTypeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};


export type QueryGroupByProgrammeArgs = {
  by: Array<ProgrammeScalarFieldEnum>;
  having?: InputMaybe<ProgrammeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProgrammeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgrammeWhereInput>;
};


export type QueryGroupByProgrammeProgressionArgs = {
  by: Array<ProgrammeProgressionScalarFieldEnum>;
  having?: InputMaybe<ProgrammeProgressionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProgrammeProgressionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgrammeProgressionWhereInput>;
};


export type QueryGroupByQuizArgs = {
  by: Array<QuizScalarFieldEnum>;
  having?: InputMaybe<QuizScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<QuizOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuizWhereInput>;
};


export type QueryGroupByQuizCategoryArgs = {
  by: Array<QuizCategoryScalarFieldEnum>;
  having?: InputMaybe<QuizCategoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<QuizCategoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuizCategoryWhereInput>;
};


export type QueryGroupByResourceArgs = {
  by: Array<ResourceScalarFieldEnum>;
  having?: InputMaybe<ResourceScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ResourceOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceWhereInput>;
};


export type QueryGroupByResourceCategoryArgs = {
  by: Array<ResourceCategoryScalarFieldEnum>;
  having?: InputMaybe<ResourceCategoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ResourceCategoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceCategoryWhereInput>;
};


export type QueryGroupByResourceTypeArgs = {
  by: Array<ResourceTypeScalarFieldEnum>;
  having?: InputMaybe<ResourceTypeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ResourceTypeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceTypeWhereInput>;
};


export type QueryGroupByStripeEventArgs = {
  by: Array<StripeEventScalarFieldEnum>;
  having?: InputMaybe<StripeEventScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StripeEventOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StripeEventWhereInput>;
};


export type QueryGroupByStripePaymentIntentArgs = {
  by: Array<StripePaymentIntentScalarFieldEnum>;
  having?: InputMaybe<StripePaymentIntentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StripePaymentIntentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StripePaymentIntentWhereInput>;
};


export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryGroupByUserArticleArgs = {
  by: Array<UserArticleScalarFieldEnum>;
  having?: InputMaybe<UserArticleScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserArticleOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserArticleWhereInput>;
};


export type QueryGroupByUserManualActivityArgs = {
  by: Array<UserManualActivityScalarFieldEnum>;
  having?: InputMaybe<UserManualActivityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserManualActivityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserManualActivityWhereInput>;
};


export type QueryGroupByUserQuizArgs = {
  by: Array<UserQuizScalarFieldEnum>;
  having?: InputMaybe<UserQuizScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserQuizOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserQuizWhereInput>;
};


export type QueryGroupByUserResourceArgs = {
  by: Array<UserResourceScalarFieldEnum>;
  having?: InputMaybe<UserResourceScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserResourceOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserResourceWhereInput>;
};


export type QueryGroupByUserSubscriptionArgs = {
  by: Array<UserSubscriptionScalarFieldEnum>;
  having?: InputMaybe<UserSubscriptionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryGuestArgs = {
  where: GuestWhereUniqueInput;
};


export type QueryGuestsArgs = {
  cursor?: InputMaybe<GuestWhereUniqueInput>;
  distinct?: InputMaybe<Array<GuestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GuestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GuestWhereInput>;
};


export type QueryPaymentArgs = {
  where: PaymentWhereUniqueInput;
};


export type QueryPaymentItemArgs = {
  where: PaymentItemWhereUniqueInput;
};


export type QueryPaymentItemsArgs = {
  cursor?: InputMaybe<PaymentItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentItemWhereInput>;
};


export type QueryPaymentsArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type QueryProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryProductProgrammeArgs = {
  where: ProductProgrammeWhereUniqueInput;
};


export type QueryProductProgrammePrerequisiteArgs = {
  where: ProductProgrammePrerequisiteWhereUniqueInput;
};


export type QueryProductProgrammePrerequisitesArgs = {
  cursor?: InputMaybe<ProductProgrammePrerequisiteWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductProgrammePrerequisiteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductProgrammePrerequisiteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammePrerequisiteWhereInput>;
};


export type QueryProductProgrammesArgs = {
  cursor?: InputMaybe<ProductProgrammeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductProgrammeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductProgrammeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductProgrammeWhereInput>;
};


export type QueryProductSubscriptionTypeArgs = {
  where: ProductSubscriptionTypeWhereUniqueInput;
};


export type QueryProductSubscriptionTypesArgs = {
  cursor?: InputMaybe<ProductSubscriptionTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductSubscriptionTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductSubscriptionTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductSubscriptionTypeWhereInput>;
};


export type QueryProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryProgrammeArgs = {
  where: ProgrammeWhereUniqueInput;
};


export type QueryProgrammeProgressionArgs = {
  where: ProgrammeProgressionWhereUniqueInput;
};


export type QueryProgrammeProgressionsArgs = {
  cursor?: InputMaybe<ProgrammeProgressionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgrammeProgressionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgrammeProgressionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgrammeProgressionWhereInput>;
};


export type QueryProgrammesArgs = {
  cursor?: InputMaybe<ProgrammeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgrammeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgrammeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgrammeWhereInput>;
};


export type QueryQuizArgs = {
  where: QuizWhereUniqueInput;
};


export type QueryQuizCategoriesArgs = {
  cursor?: InputMaybe<QuizCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuizCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuizCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuizCategoryWhereInput>;
};


export type QueryQuizCategoryArgs = {
  where: QuizCategoryWhereUniqueInput;
};


export type QueryQuizzesArgs = {
  cursor?: InputMaybe<QuizWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuizScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuizOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuizWhereInput>;
};


export type QueryResourceArgs = {
  where: ResourceWhereUniqueInput;
};


export type QueryResourceCategoriesArgs = {
  cursor?: InputMaybe<ResourceCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResourceCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResourceCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceCategoryWhereInput>;
};


export type QueryResourceCategoryArgs = {
  where: ResourceCategoryWhereUniqueInput;
};


export type QueryResourceTypeArgs = {
  where: ResourceTypeWhereUniqueInput;
};


export type QueryResourceTypesArgs = {
  cursor?: InputMaybe<ResourceTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResourceTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResourceTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceTypeWhereInput>;
};


export type QueryResourcesArgs = {
  cursor?: InputMaybe<ResourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceWhereInput>;
};


export type QueryStripeEventArgs = {
  where: StripeEventWhereUniqueInput;
};


export type QueryStripeEventsArgs = {
  cursor?: InputMaybe<StripeEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<StripeEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StripeEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StripeEventWhereInput>;
};


export type QueryStripePaymentIntentArgs = {
  where: StripePaymentIntentWhereUniqueInput;
};


export type QueryStripePaymentIntentsArgs = {
  cursor?: InputMaybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: InputMaybe<Array<StripePaymentIntentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StripePaymentIntentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StripePaymentIntentWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUserArticleArgs = {
  where: UserArticleWhereUniqueInput;
};


export type QueryUserArticlesArgs = {
  cursor?: InputMaybe<UserArticleWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserArticleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserArticleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserArticleWhereInput>;
};


export type QueryUserManualActivitiesArgs = {
  cursor?: InputMaybe<UserManualActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserManualActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserManualActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserManualActivityWhereInput>;
};


export type QueryUserManualActivityArgs = {
  where: UserManualActivityWhereUniqueInput;
};


export type QueryUserQuizArgs = {
  where: UserQuizWhereUniqueInput;
};


export type QueryUserQuizsArgs = {
  cursor?: InputMaybe<UserQuizWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserQuizScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserQuizOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserQuizWhereInput>;
};


export type QueryUserResourceArgs = {
  where: UserResourceWhereUniqueInput;
};


export type QueryUserResourcesArgs = {
  cursor?: InputMaybe<UserResourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserResourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserResourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserResourceWhereInput>;
};


export type QueryUserSubscriptionArgs = {
  where: UserSubscriptionWhereUniqueInput;
};


export type QueryUserSubscriptionsArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type Quiz = {
  _count?: Maybe<QuizCount>;
  attemptedBy: Array<UserQuiz>;
  content?: Maybe<Content>;
  created: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  quizCategories: Array<QuizCategory>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


export type QuizAttemptedByArgs = {
  cursor?: InputMaybe<UserQuizWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserQuizScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserQuizOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserQuizWhereInput>;
};


export type QuizContentArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type QuizQuizCategoriesArgs = {
  cursor?: InputMaybe<QuizCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuizCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuizCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuizCategoryWhereInput>;
};

export type QuizCategory = {
  category: Category;
  categoryId: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isPinned: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  minScore?: Maybe<Scalars['Float']['output']>;
  modified: Scalars['DateTimeISO']['output'];
  quiz: Quiz;
  quizId: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type QuizCategoryAvgAggregate = {
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  minScore?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

export type QuizCategoryAvgOrderByAggregateInput = {
  maxAttempts?: InputMaybe<SortOrder>;
  minScore?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QuizCategoryCountAggregate = {
  _all: Scalars['Int']['output'];
  categoryId: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isPinned: Scalars['Int']['output'];
  isRequired: Scalars['Int']['output'];
  maxAttempts: Scalars['Int']['output'];
  minScore: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  quizId: Scalars['Int']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type QuizCategoryCountOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  maxAttempts?: InputMaybe<SortOrder>;
  minScore?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  quizId?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QuizCategoryCreateInput = {
  category: CategoryCreateNestedOneWithoutCategoryQuizzesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quiz: QuizCreateNestedOneWithoutQuizCategoriesInput;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type QuizCategoryCreateManyCategoryInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quizId: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type QuizCategoryCreateManyCategoryInputEnvelope = {
  data: Array<QuizCategoryCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuizCategoryCreateManyInput = {
  categoryId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quizId: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type QuizCategoryCreateManyQuizInput = {
  categoryId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type QuizCategoryCreateManyQuizInputEnvelope = {
  data: Array<QuizCategoryCreateManyQuizInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuizCategoryCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<QuizCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuizCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<QuizCategoryCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<QuizCategoryCreateManyCategoryInputEnvelope>;
};

export type QuizCategoryCreateNestedManyWithoutQuizInput = {
  connect?: InputMaybe<Array<QuizCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuizCategoryCreateOrConnectWithoutQuizInput>>;
  create?: InputMaybe<Array<QuizCategoryCreateWithoutQuizInput>>;
  createMany?: InputMaybe<QuizCategoryCreateManyQuizInputEnvelope>;
};

export type QuizCategoryCreateOrConnectWithoutCategoryInput = {
  create: QuizCategoryCreateWithoutCategoryInput;
  where: QuizCategoryWhereUniqueInput;
};

export type QuizCategoryCreateOrConnectWithoutQuizInput = {
  create: QuizCategoryCreateWithoutQuizInput;
  where: QuizCategoryWhereUniqueInput;
};

export type QuizCategoryCreateWithoutCategoryInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quiz: QuizCreateNestedOneWithoutQuizCategoriesInput;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type QuizCategoryCreateWithoutQuizInput = {
  category: CategoryCreateNestedOneWithoutCategoryQuizzesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type QuizCategoryGroupBy = {
  _avg?: Maybe<QuizCategoryAvgAggregate>;
  _count?: Maybe<QuizCategoryCountAggregate>;
  _max?: Maybe<QuizCategoryMaxAggregate>;
  _min?: Maybe<QuizCategoryMinAggregate>;
  _sum?: Maybe<QuizCategorySumAggregate>;
  categoryId: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isPinned: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  minScore?: Maybe<Scalars['Float']['output']>;
  modified: Scalars['DateTimeISO']['output'];
  quizId: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type QuizCategoryListRelationFilter = {
  every?: InputMaybe<QuizCategoryWhereInput>;
  none?: InputMaybe<QuizCategoryWhereInput>;
  some?: InputMaybe<QuizCategoryWhereInput>;
};

export type QuizCategoryMaxAggregate = {
  categoryId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  minScore?: Maybe<Scalars['Float']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  quizId?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type QuizCategoryMaxOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  maxAttempts?: InputMaybe<SortOrder>;
  minScore?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  quizId?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QuizCategoryMinAggregate = {
  categoryId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  minScore?: Maybe<Scalars['Float']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  quizId?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type QuizCategoryMinOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  maxAttempts?: InputMaybe<SortOrder>;
  minScore?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  quizId?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QuizCategoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type QuizCategoryOrderByWithAggregationInput = {
  _avg?: InputMaybe<QuizCategoryAvgOrderByAggregateInput>;
  _count?: InputMaybe<QuizCategoryCountOrderByAggregateInput>;
  _max?: InputMaybe<QuizCategoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<QuizCategoryMinOrderByAggregateInput>;
  _sum?: InputMaybe<QuizCategorySumOrderByAggregateInput>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  maxAttempts?: InputMaybe<SortOrderInput>;
  minScore?: InputMaybe<SortOrderInput>;
  modified?: InputMaybe<SortOrder>;
  quizId?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QuizCategoryOrderByWithRelationInput = {
  category?: InputMaybe<CategoryOrderByWithRelationInput>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  maxAttempts?: InputMaybe<SortOrderInput>;
  minScore?: InputMaybe<SortOrderInput>;
  modified?: InputMaybe<SortOrder>;
  quiz?: InputMaybe<QuizOrderByWithRelationInput>;
  quizId?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QuizCategoryQuizCategoryCompoundUniqueInput = {
  categoryId: Scalars['String']['input'];
  quizId: Scalars['String']['input'];
};

export enum QuizCategoryScalarFieldEnum {
  CategoryId = 'categoryId',
  Created = 'created',
  Id = 'id',
  IsPinned = 'isPinned',
  IsRequired = 'isRequired',
  MaxAttempts = 'maxAttempts',
  MinScore = 'minScore',
  Modified = 'modified',
  QuizId = 'quizId',
  SortOrder = 'sortOrder'
}

export type QuizCategoryScalarWhereInput = {
  AND?: InputMaybe<Array<QuizCategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<QuizCategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<QuizCategoryScalarWhereInput>>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isPinned?: InputMaybe<BoolFilter>;
  isRequired?: InputMaybe<BoolFilter>;
  maxAttempts?: InputMaybe<IntNullableFilter>;
  minScore?: InputMaybe<FloatNullableFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  quizId?: InputMaybe<StringFilter>;
  sortOrder?: InputMaybe<IntFilter>;
};

export type QuizCategoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<QuizCategoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<QuizCategoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<QuizCategoryScalarWhereWithAggregatesInput>>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPinned?: InputMaybe<BoolWithAggregatesFilter>;
  isRequired?: InputMaybe<BoolWithAggregatesFilter>;
  maxAttempts?: InputMaybe<IntNullableWithAggregatesFilter>;
  minScore?: InputMaybe<FloatNullableWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  quizId?: InputMaybe<StringWithAggregatesFilter>;
  sortOrder?: InputMaybe<IntWithAggregatesFilter>;
};

export type QuizCategorySumAggregate = {
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  minScore?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type QuizCategorySumOrderByAggregateInput = {
  maxAttempts?: InputMaybe<SortOrder>;
  minScore?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QuizCategoryUpdateInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutCategoryQuizzesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxAttempts?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minScore?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  quiz?: InputMaybe<QuizUpdateOneRequiredWithoutQuizCategoriesNestedInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type QuizCategoryUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxAttempts?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minScore?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type QuizCategoryUpdateManyWithWhereWithoutCategoryInput = {
  data: QuizCategoryUpdateManyMutationInput;
  where: QuizCategoryScalarWhereInput;
};

export type QuizCategoryUpdateManyWithWhereWithoutQuizInput = {
  data: QuizCategoryUpdateManyMutationInput;
  where: QuizCategoryScalarWhereInput;
};

export type QuizCategoryUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<QuizCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuizCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<QuizCategoryCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<QuizCategoryCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<QuizCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<QuizCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<QuizCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<QuizCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<QuizCategoryUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<QuizCategoryUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<QuizCategoryUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type QuizCategoryUpdateManyWithoutQuizNestedInput = {
  connect?: InputMaybe<Array<QuizCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuizCategoryCreateOrConnectWithoutQuizInput>>;
  create?: InputMaybe<Array<QuizCategoryCreateWithoutQuizInput>>;
  createMany?: InputMaybe<QuizCategoryCreateManyQuizInputEnvelope>;
  delete?: InputMaybe<Array<QuizCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<QuizCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<QuizCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<QuizCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<QuizCategoryUpdateWithWhereUniqueWithoutQuizInput>>;
  updateMany?: InputMaybe<Array<QuizCategoryUpdateManyWithWhereWithoutQuizInput>>;
  upsert?: InputMaybe<Array<QuizCategoryUpsertWithWhereUniqueWithoutQuizInput>>;
};

export type QuizCategoryUpdateWithWhereUniqueWithoutCategoryInput = {
  data: QuizCategoryUpdateWithoutCategoryInput;
  where: QuizCategoryWhereUniqueInput;
};

export type QuizCategoryUpdateWithWhereUniqueWithoutQuizInput = {
  data: QuizCategoryUpdateWithoutQuizInput;
  where: QuizCategoryWhereUniqueInput;
};

export type QuizCategoryUpdateWithoutCategoryInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxAttempts?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minScore?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  quiz?: InputMaybe<QuizUpdateOneRequiredWithoutQuizCategoriesNestedInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type QuizCategoryUpdateWithoutQuizInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutCategoryQuizzesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxAttempts?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minScore?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type QuizCategoryUpsertWithWhereUniqueWithoutCategoryInput = {
  create: QuizCategoryCreateWithoutCategoryInput;
  update: QuizCategoryUpdateWithoutCategoryInput;
  where: QuizCategoryWhereUniqueInput;
};

export type QuizCategoryUpsertWithWhereUniqueWithoutQuizInput = {
  create: QuizCategoryCreateWithoutQuizInput;
  update: QuizCategoryUpdateWithoutQuizInput;
  where: QuizCategoryWhereUniqueInput;
};

export type QuizCategoryWhereInput = {
  AND?: InputMaybe<Array<QuizCategoryWhereInput>>;
  NOT?: InputMaybe<Array<QuizCategoryWhereInput>>;
  OR?: InputMaybe<Array<QuizCategoryWhereInput>>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isPinned?: InputMaybe<BoolFilter>;
  isRequired?: InputMaybe<BoolFilter>;
  maxAttempts?: InputMaybe<IntNullableFilter>;
  minScore?: InputMaybe<FloatNullableFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  quiz?: InputMaybe<QuizRelationFilter>;
  quizId?: InputMaybe<StringFilter>;
  sortOrder?: InputMaybe<IntFilter>;
};

export type QuizCategoryWhereUniqueInput = {
  AND?: InputMaybe<Array<QuizCategoryWhereInput>>;
  NOT?: InputMaybe<Array<QuizCategoryWhereInput>>;
  OR?: InputMaybe<Array<QuizCategoryWhereInput>>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<BoolFilter>;
  isRequired?: InputMaybe<BoolFilter>;
  maxAttempts?: InputMaybe<IntNullableFilter>;
  minScore?: InputMaybe<FloatNullableFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  quiz?: InputMaybe<QuizRelationFilter>;
  quizCategory?: InputMaybe<QuizCategoryQuizCategoryCompoundUniqueInput>;
  quizId?: InputMaybe<StringFilter>;
  sortOrder?: InputMaybe<IntFilter>;
};

export type QuizCount = {
  attemptedBy: Scalars['Int']['output'];
  quizCategories: Scalars['Int']['output'];
};


export type QuizCountAttemptedByArgs = {
  where?: InputMaybe<UserQuizWhereInput>;
};


export type QuizCountQuizCategoriesArgs = {
  where?: InputMaybe<QuizCategoryWhereInput>;
};

export type QuizCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isPublished: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
};

export type QuizCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type QuizCreateInput = {
  attemptedBy?: InputMaybe<UserQuizCreateNestedManyWithoutQuizInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutQuizInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quizCategories?: InputMaybe<QuizCategoryCreateNestedManyWithoutQuizInput>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type QuizCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type QuizCreateNestedOneWithoutAttemptedByInput = {
  connect?: InputMaybe<QuizWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuizCreateOrConnectWithoutAttemptedByInput>;
  create?: InputMaybe<QuizCreateWithoutAttemptedByInput>;
};

export type QuizCreateNestedOneWithoutContentInput = {
  connect?: InputMaybe<QuizWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuizCreateOrConnectWithoutContentInput>;
  create?: InputMaybe<QuizCreateWithoutContentInput>;
};

export type QuizCreateNestedOneWithoutQuizCategoriesInput = {
  connect?: InputMaybe<QuizWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuizCreateOrConnectWithoutQuizCategoriesInput>;
  create?: InputMaybe<QuizCreateWithoutQuizCategoriesInput>;
};

export type QuizCreateOrConnectWithoutAttemptedByInput = {
  create: QuizCreateWithoutAttemptedByInput;
  where: QuizWhereUniqueInput;
};

export type QuizCreateOrConnectWithoutContentInput = {
  create: QuizCreateWithoutContentInput;
  where: QuizWhereUniqueInput;
};

export type QuizCreateOrConnectWithoutQuizCategoriesInput = {
  create: QuizCreateWithoutQuizCategoriesInput;
  where: QuizWhereUniqueInput;
};

export type QuizCreateWithoutAttemptedByInput = {
  content?: InputMaybe<ContentCreateNestedOneWithoutQuizInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quizCategories?: InputMaybe<QuizCategoryCreateNestedManyWithoutQuizInput>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type QuizCreateWithoutContentInput = {
  attemptedBy?: InputMaybe<UserQuizCreateNestedManyWithoutQuizInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quizCategories?: InputMaybe<QuizCategoryCreateNestedManyWithoutQuizInput>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type QuizCreateWithoutQuizCategoriesInput = {
  attemptedBy?: InputMaybe<UserQuizCreateNestedManyWithoutQuizInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutQuizInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type QuizGroupBy = {
  _count?: Maybe<QuizCountAggregate>;
  _max?: Maybe<QuizMaxAggregate>;
  _min?: Maybe<QuizMinAggregate>;
  created: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type QuizMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type QuizMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type QuizMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type QuizMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type QuizNullableRelationFilter = {
  is?: InputMaybe<QuizWhereInput>;
  isNot?: InputMaybe<QuizWhereInput>;
};

export type QuizOrderByWithAggregationInput = {
  _count?: InputMaybe<QuizCountOrderByAggregateInput>;
  _max?: InputMaybe<QuizMaxOrderByAggregateInput>;
  _min?: InputMaybe<QuizMinOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type QuizOrderByWithRelationInput = {
  attemptedBy?: InputMaybe<UserQuizOrderByRelationAggregateInput>;
  content?: InputMaybe<ContentOrderByWithRelationInput>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  quizCategories?: InputMaybe<QuizCategoryOrderByRelationAggregateInput>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type QuizRelationFilter = {
  is?: InputMaybe<QuizWhereInput>;
  isNot?: InputMaybe<QuizWhereInput>;
};

export enum QuizScalarFieldEnum {
  Created = 'created',
  Description = 'description',
  Id = 'id',
  IsPublished = 'isPublished',
  Modified = 'modified',
  Slug = 'slug',
  Title = 'title'
}

export type QuizScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<QuizScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<QuizScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<QuizScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPublished?: InputMaybe<BoolWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
};

export type QuizUpdateInput = {
  attemptedBy?: InputMaybe<UserQuizUpdateManyWithoutQuizNestedInput>;
  content?: InputMaybe<ContentUpdateOneWithoutQuizNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  quizCategories?: InputMaybe<QuizCategoryUpdateManyWithoutQuizNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type QuizUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type QuizUpdateOneRequiredWithoutAttemptedByNestedInput = {
  connect?: InputMaybe<QuizWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuizCreateOrConnectWithoutAttemptedByInput>;
  create?: InputMaybe<QuizCreateWithoutAttemptedByInput>;
  update?: InputMaybe<QuizUpdateToOneWithWhereWithoutAttemptedByInput>;
  upsert?: InputMaybe<QuizUpsertWithoutAttemptedByInput>;
};

export type QuizUpdateOneRequiredWithoutQuizCategoriesNestedInput = {
  connect?: InputMaybe<QuizWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuizCreateOrConnectWithoutQuizCategoriesInput>;
  create?: InputMaybe<QuizCreateWithoutQuizCategoriesInput>;
  update?: InputMaybe<QuizUpdateToOneWithWhereWithoutQuizCategoriesInput>;
  upsert?: InputMaybe<QuizUpsertWithoutQuizCategoriesInput>;
};

export type QuizUpdateOneWithoutContentNestedInput = {
  connect?: InputMaybe<QuizWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuizCreateOrConnectWithoutContentInput>;
  create?: InputMaybe<QuizCreateWithoutContentInput>;
  delete?: InputMaybe<QuizWhereInput>;
  disconnect?: InputMaybe<QuizWhereInput>;
  update?: InputMaybe<QuizUpdateToOneWithWhereWithoutContentInput>;
  upsert?: InputMaybe<QuizUpsertWithoutContentInput>;
};

export type QuizUpdateToOneWithWhereWithoutAttemptedByInput = {
  data: QuizUpdateWithoutAttemptedByInput;
  where?: InputMaybe<QuizWhereInput>;
};

export type QuizUpdateToOneWithWhereWithoutContentInput = {
  data: QuizUpdateWithoutContentInput;
  where?: InputMaybe<QuizWhereInput>;
};

export type QuizUpdateToOneWithWhereWithoutQuizCategoriesInput = {
  data: QuizUpdateWithoutQuizCategoriesInput;
  where?: InputMaybe<QuizWhereInput>;
};

export type QuizUpdateWithoutAttemptedByInput = {
  content?: InputMaybe<ContentUpdateOneWithoutQuizNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  quizCategories?: InputMaybe<QuizCategoryUpdateManyWithoutQuizNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type QuizUpdateWithoutContentInput = {
  attemptedBy?: InputMaybe<UserQuizUpdateManyWithoutQuizNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  quizCategories?: InputMaybe<QuizCategoryUpdateManyWithoutQuizNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type QuizUpdateWithoutQuizCategoriesInput = {
  attemptedBy?: InputMaybe<UserQuizUpdateManyWithoutQuizNestedInput>;
  content?: InputMaybe<ContentUpdateOneWithoutQuizNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type QuizUpsertWithoutAttemptedByInput = {
  create: QuizCreateWithoutAttemptedByInput;
  update: QuizUpdateWithoutAttemptedByInput;
  where?: InputMaybe<QuizWhereInput>;
};

export type QuizUpsertWithoutContentInput = {
  create: QuizCreateWithoutContentInput;
  update: QuizUpdateWithoutContentInput;
  where?: InputMaybe<QuizWhereInput>;
};

export type QuizUpsertWithoutQuizCategoriesInput = {
  create: QuizCreateWithoutQuizCategoriesInput;
  update: QuizUpdateWithoutQuizCategoriesInput;
  where?: InputMaybe<QuizWhereInput>;
};

export type QuizWhereInput = {
  AND?: InputMaybe<Array<QuizWhereInput>>;
  NOT?: InputMaybe<Array<QuizWhereInput>>;
  OR?: InputMaybe<Array<QuizWhereInput>>;
  attemptedBy?: InputMaybe<UserQuizListRelationFilter>;
  content?: InputMaybe<ContentNullableRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isPublished?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  quizCategories?: InputMaybe<QuizCategoryListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type QuizWhereUniqueInput = {
  AND?: InputMaybe<Array<QuizWhereInput>>;
  NOT?: InputMaybe<Array<QuizWhereInput>>;
  OR?: InputMaybe<Array<QuizWhereInput>>;
  attemptedBy?: InputMaybe<UserQuizListRelationFilter>;
  content?: InputMaybe<ContentNullableRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  quizCategories?: InputMaybe<QuizCategoryListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type Resource = {
  _count?: Maybe<ResourceCount>;
  created: Scalars['DateTimeISO']['output'];
  downloadedBy: Array<UserResource>;
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  resourceCategories: Array<ResourceCategory>;
  resourceTypeId?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type?: Maybe<ResourceType>;
  url: Scalars['String']['output'];
};


export type ResourceDownloadedByArgs = {
  cursor?: InputMaybe<UserResourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserResourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserResourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserResourceWhereInput>;
};


export type ResourceResourceCategoriesArgs = {
  cursor?: InputMaybe<ResourceCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResourceCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResourceCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceCategoryWhereInput>;
};


export type ResourceTypeArgs = {
  where?: InputMaybe<ResourceTypeWhereInput>;
};

export type ResourceCategory = {
  category: Category;
  categoryId: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isPinned: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  resource: Resource;
  resourceId: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type ResourceCategoryAvgAggregate = {
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

export type ResourceCategoryAvgOrderByAggregateInput = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type ResourceCategoryCountAggregate = {
  _all: Scalars['Int']['output'];
  categoryId: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isPinned: Scalars['Int']['output'];
  isRequired: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  resourceId: Scalars['Int']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type ResourceCategoryCountOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceId?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ResourceCategoryCreateInput = {
  category: CategoryCreateNestedOneWithoutCategoryResourcesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  resource: ResourceCreateNestedOneWithoutResourceCategoriesInput;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ResourceCategoryCreateManyCategoryInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  resourceId: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ResourceCategoryCreateManyCategoryInputEnvelope = {
  data: Array<ResourceCategoryCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ResourceCategoryCreateManyInput = {
  categoryId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  resourceId: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ResourceCategoryCreateManyResourceInput = {
  categoryId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ResourceCategoryCreateManyResourceInputEnvelope = {
  data: Array<ResourceCategoryCreateManyResourceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ResourceCategoryCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<ResourceCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResourceCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<ResourceCategoryCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<ResourceCategoryCreateManyCategoryInputEnvelope>;
};

export type ResourceCategoryCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResourceCategoryCreateOrConnectWithoutResourceInput>>;
  create?: InputMaybe<Array<ResourceCategoryCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceCategoryCreateManyResourceInputEnvelope>;
};

export type ResourceCategoryCreateOrConnectWithoutCategoryInput = {
  create: ResourceCategoryCreateWithoutCategoryInput;
  where: ResourceCategoryWhereUniqueInput;
};

export type ResourceCategoryCreateOrConnectWithoutResourceInput = {
  create: ResourceCategoryCreateWithoutResourceInput;
  where: ResourceCategoryWhereUniqueInput;
};

export type ResourceCategoryCreateWithoutCategoryInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  resource: ResourceCreateNestedOneWithoutResourceCategoriesInput;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ResourceCategoryCreateWithoutResourceInput = {
  category: CategoryCreateNestedOneWithoutCategoryResourcesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ResourceCategoryGroupBy = {
  _avg?: Maybe<ResourceCategoryAvgAggregate>;
  _count?: Maybe<ResourceCategoryCountAggregate>;
  _max?: Maybe<ResourceCategoryMaxAggregate>;
  _min?: Maybe<ResourceCategoryMinAggregate>;
  _sum?: Maybe<ResourceCategorySumAggregate>;
  categoryId: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isPinned: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  resourceId: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type ResourceCategoryListRelationFilter = {
  every?: InputMaybe<ResourceCategoryWhereInput>;
  none?: InputMaybe<ResourceCategoryWhereInput>;
  some?: InputMaybe<ResourceCategoryWhereInput>;
};

export type ResourceCategoryMaxAggregate = {
  categoryId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type ResourceCategoryMaxOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceId?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ResourceCategoryMinAggregate = {
  categoryId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type ResourceCategoryMinOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceId?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ResourceCategoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ResourceCategoryOrderByWithAggregationInput = {
  _avg?: InputMaybe<ResourceCategoryAvgOrderByAggregateInput>;
  _count?: InputMaybe<ResourceCategoryCountOrderByAggregateInput>;
  _max?: InputMaybe<ResourceCategoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<ResourceCategoryMinOrderByAggregateInput>;
  _sum?: InputMaybe<ResourceCategorySumOrderByAggregateInput>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceId?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ResourceCategoryOrderByWithRelationInput = {
  category?: InputMaybe<CategoryOrderByWithRelationInput>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPinned?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resource?: InputMaybe<ResourceOrderByWithRelationInput>;
  resourceId?: InputMaybe<SortOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ResourceCategoryResourceCategoryCompoundUniqueInput = {
  categoryId: Scalars['String']['input'];
  resourceId: Scalars['String']['input'];
};

export enum ResourceCategoryScalarFieldEnum {
  CategoryId = 'categoryId',
  Created = 'created',
  Id = 'id',
  IsPinned = 'isPinned',
  IsRequired = 'isRequired',
  Modified = 'modified',
  ResourceId = 'resourceId',
  SortOrder = 'sortOrder'
}

export type ResourceCategoryScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceCategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceCategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceCategoryScalarWhereInput>>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isPinned?: InputMaybe<BoolFilter>;
  isRequired?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  resourceId?: InputMaybe<StringFilter>;
  sortOrder?: InputMaybe<IntFilter>;
};

export type ResourceCategoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ResourceCategoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ResourceCategoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ResourceCategoryScalarWhereWithAggregatesInput>>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPinned?: InputMaybe<BoolWithAggregatesFilter>;
  isRequired?: InputMaybe<BoolWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  resourceId?: InputMaybe<StringWithAggregatesFilter>;
  sortOrder?: InputMaybe<IntWithAggregatesFilter>;
};

export type ResourceCategorySumAggregate = {
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type ResourceCategorySumOrderByAggregateInput = {
  sortOrder?: InputMaybe<SortOrder>;
};

export type ResourceCategoryUpdateInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutCategoryResourcesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutResourceCategoriesNestedInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ResourceCategoryUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ResourceCategoryUpdateManyWithWhereWithoutCategoryInput = {
  data: ResourceCategoryUpdateManyMutationInput;
  where: ResourceCategoryScalarWhereInput;
};

export type ResourceCategoryUpdateManyWithWhereWithoutResourceInput = {
  data: ResourceCategoryUpdateManyMutationInput;
  where: ResourceCategoryScalarWhereInput;
};

export type ResourceCategoryUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<ResourceCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResourceCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<ResourceCategoryCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<ResourceCategoryCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<ResourceCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<ResourceCategoryUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<ResourceCategoryUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<ResourceCategoryUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type ResourceCategoryUpdateManyWithoutResourceNestedInput = {
  connect?: InputMaybe<Array<ResourceCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResourceCategoryCreateOrConnectWithoutResourceInput>>;
  create?: InputMaybe<Array<ResourceCategoryCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceCategoryCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<ResourceCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<ResourceCategoryUpdateWithWhereUniqueWithoutResourceInput>>;
  updateMany?: InputMaybe<Array<ResourceCategoryUpdateManyWithWhereWithoutResourceInput>>;
  upsert?: InputMaybe<Array<ResourceCategoryUpsertWithWhereUniqueWithoutResourceInput>>;
};

export type ResourceCategoryUpdateWithWhereUniqueWithoutCategoryInput = {
  data: ResourceCategoryUpdateWithoutCategoryInput;
  where: ResourceCategoryWhereUniqueInput;
};

export type ResourceCategoryUpdateWithWhereUniqueWithoutResourceInput = {
  data: ResourceCategoryUpdateWithoutResourceInput;
  where: ResourceCategoryWhereUniqueInput;
};

export type ResourceCategoryUpdateWithoutCategoryInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutResourceCategoriesNestedInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ResourceCategoryUpdateWithoutResourceInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutCategoryResourcesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPinned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sortOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ResourceCategoryUpsertWithWhereUniqueWithoutCategoryInput = {
  create: ResourceCategoryCreateWithoutCategoryInput;
  update: ResourceCategoryUpdateWithoutCategoryInput;
  where: ResourceCategoryWhereUniqueInput;
};

export type ResourceCategoryUpsertWithWhereUniqueWithoutResourceInput = {
  create: ResourceCategoryCreateWithoutResourceInput;
  update: ResourceCategoryUpdateWithoutResourceInput;
  where: ResourceCategoryWhereUniqueInput;
};

export type ResourceCategoryWhereInput = {
  AND?: InputMaybe<Array<ResourceCategoryWhereInput>>;
  NOT?: InputMaybe<Array<ResourceCategoryWhereInput>>;
  OR?: InputMaybe<Array<ResourceCategoryWhereInput>>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isPinned?: InputMaybe<BoolFilter>;
  isRequired?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  resource?: InputMaybe<ResourceRelationFilter>;
  resourceId?: InputMaybe<StringFilter>;
  sortOrder?: InputMaybe<IntFilter>;
};

export type ResourceCategoryWhereUniqueInput = {
  AND?: InputMaybe<Array<ResourceCategoryWhereInput>>;
  NOT?: InputMaybe<Array<ResourceCategoryWhereInput>>;
  OR?: InputMaybe<Array<ResourceCategoryWhereInput>>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<BoolFilter>;
  isRequired?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  resource?: InputMaybe<ResourceRelationFilter>;
  resourceCategory?: InputMaybe<ResourceCategoryResourceCategoryCompoundUniqueInput>;
  resourceId?: InputMaybe<StringFilter>;
  sortOrder?: InputMaybe<IntFilter>;
};

export type ResourceCount = {
  downloadedBy: Scalars['Int']['output'];
  resourceCategories: Scalars['Int']['output'];
};


export type ResourceCountDownloadedByArgs = {
  where?: InputMaybe<UserResourceWhereInput>;
};


export type ResourceCountResourceCategoriesArgs = {
  where?: InputMaybe<ResourceCategoryWhereInput>;
};

export type ResourceCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  filename: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isPublished: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  resourceTypeId: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type ResourceCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceTypeId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type ResourceCreateInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  downloadedBy?: InputMaybe<UserResourceCreateNestedManyWithoutResourceInput>;
  filename: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  resourceCategories?: InputMaybe<ResourceCategoryCreateNestedManyWithoutResourceInput>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type?: InputMaybe<ResourceTypeCreateNestedOneWithoutResourcesInput>;
  url: Scalars['String']['input'];
};

export type ResourceCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  filename: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  resourceTypeId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ResourceCreateManyTypeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  filename: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ResourceCreateManyTypeInputEnvelope = {
  data: Array<ResourceCreateManyTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ResourceCreateNestedManyWithoutTypeInput = {
  connect?: InputMaybe<Array<ResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResourceCreateOrConnectWithoutTypeInput>>;
  create?: InputMaybe<Array<ResourceCreateWithoutTypeInput>>;
  createMany?: InputMaybe<ResourceCreateManyTypeInputEnvelope>;
};

export type ResourceCreateNestedOneWithoutDownloadedByInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutDownloadedByInput>;
  create?: InputMaybe<ResourceCreateWithoutDownloadedByInput>;
};

export type ResourceCreateNestedOneWithoutResourceCategoriesInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutResourceCategoriesInput>;
  create?: InputMaybe<ResourceCreateWithoutResourceCategoriesInput>;
};

export type ResourceCreateOrConnectWithoutDownloadedByInput = {
  create: ResourceCreateWithoutDownloadedByInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutResourceCategoriesInput = {
  create: ResourceCreateWithoutResourceCategoriesInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutTypeInput = {
  create: ResourceCreateWithoutTypeInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateWithoutDownloadedByInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  filename: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  resourceCategories?: InputMaybe<ResourceCategoryCreateNestedManyWithoutResourceInput>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type?: InputMaybe<ResourceTypeCreateNestedOneWithoutResourcesInput>;
  url: Scalars['String']['input'];
};

export type ResourceCreateWithoutResourceCategoriesInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  downloadedBy?: InputMaybe<UserResourceCreateNestedManyWithoutResourceInput>;
  filename: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type?: InputMaybe<ResourceTypeCreateNestedOneWithoutResourcesInput>;
  url: Scalars['String']['input'];
};

export type ResourceCreateWithoutTypeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  downloadedBy?: InputMaybe<UserResourceCreateNestedManyWithoutResourceInput>;
  filename: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  resourceCategories?: InputMaybe<ResourceCategoryCreateNestedManyWithoutResourceInput>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ResourceGroupBy = {
  _count?: Maybe<ResourceCountAggregate>;
  _max?: Maybe<ResourceMaxAggregate>;
  _min?: Maybe<ResourceMinAggregate>;
  created: Scalars['DateTimeISO']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  resourceTypeId?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ResourceListRelationFilter = {
  every?: InputMaybe<ResourceWhereInput>;
  none?: InputMaybe<ResourceWhereInput>;
  some?: InputMaybe<ResourceWhereInput>;
};

export type ResourceMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  resourceTypeId?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ResourceMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceTypeId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type ResourceMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  resourceTypeId?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ResourceMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceTypeId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type ResourceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ResourceOrderByWithAggregationInput = {
  _count?: InputMaybe<ResourceCountOrderByAggregateInput>;
  _max?: InputMaybe<ResourceMaxOrderByAggregateInput>;
  _min?: InputMaybe<ResourceMinOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceTypeId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type ResourceOrderByWithRelationInput = {
  created?: InputMaybe<SortOrder>;
  downloadedBy?: InputMaybe<UserResourceOrderByRelationAggregateInput>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceCategories?: InputMaybe<ResourceCategoryOrderByRelationAggregateInput>;
  resourceTypeId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<ResourceTypeOrderByWithRelationInput>;
  url?: InputMaybe<SortOrder>;
};

export type ResourceRelationFilter = {
  is?: InputMaybe<ResourceWhereInput>;
  isNot?: InputMaybe<ResourceWhereInput>;
};

export enum ResourceScalarFieldEnum {
  Created = 'created',
  Filename = 'filename',
  Id = 'id',
  IsPublished = 'isPublished',
  Modified = 'modified',
  ResourceTypeId = 'resourceTypeId',
  Slug = 'slug',
  Title = 'title',
  Url = 'url'
}

export type ResourceScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceScalarWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  filename?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPublished?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  resourceTypeId?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type ResourceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ResourceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ResourceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ResourceScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  filename?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPublished?: InputMaybe<BoolWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  resourceTypeId?: InputMaybe<StringNullableWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  url?: InputMaybe<StringWithAggregatesFilter>;
};

export type ResourceType = {
  _count?: Maybe<ResourceTypeCount>;
  articles: Array<Article>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  name: Scalars['String']['output'];
  resources: Array<Resource>;
};


export type ResourceTypeArticlesArgs = {
  cursor?: InputMaybe<ArticleWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArticleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArticleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleWhereInput>;
};


export type ResourceTypeResourcesArgs = {
  cursor?: InputMaybe<ResourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceWhereInput>;
};

export type ResourceTypeCount = {
  articles: Scalars['Int']['output'];
  resources: Scalars['Int']['output'];
};


export type ResourceTypeCountArticlesArgs = {
  where?: InputMaybe<ArticleWhereInput>;
};


export type ResourceTypeCountResourcesArgs = {
  where?: InputMaybe<ResourceWhereInput>;
};

export type ResourceTypeCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
};

export type ResourceTypeCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type ResourceTypeCreateInput = {
  articles?: InputMaybe<ArticleCreateNestedManyWithoutTypeInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  resources?: InputMaybe<ResourceCreateNestedManyWithoutTypeInput>;
};

export type ResourceTypeCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
};

export type ResourceTypeCreateNestedOneWithoutArticlesInput = {
  connect?: InputMaybe<ResourceTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceTypeCreateOrConnectWithoutArticlesInput>;
  create?: InputMaybe<ResourceTypeCreateWithoutArticlesInput>;
};

export type ResourceTypeCreateNestedOneWithoutResourcesInput = {
  connect?: InputMaybe<ResourceTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceTypeCreateOrConnectWithoutResourcesInput>;
  create?: InputMaybe<ResourceTypeCreateWithoutResourcesInput>;
};

export type ResourceTypeCreateOrConnectWithoutArticlesInput = {
  create: ResourceTypeCreateWithoutArticlesInput;
  where: ResourceTypeWhereUniqueInput;
};

export type ResourceTypeCreateOrConnectWithoutResourcesInput = {
  create: ResourceTypeCreateWithoutResourcesInput;
  where: ResourceTypeWhereUniqueInput;
};

export type ResourceTypeCreateWithoutArticlesInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  resources?: InputMaybe<ResourceCreateNestedManyWithoutTypeInput>;
};

export type ResourceTypeCreateWithoutResourcesInput = {
  articles?: InputMaybe<ArticleCreateNestedManyWithoutTypeInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
};

export type ResourceTypeGroupBy = {
  _count?: Maybe<ResourceTypeCountAggregate>;
  _max?: Maybe<ResourceTypeMaxAggregate>;
  _min?: Maybe<ResourceTypeMinAggregate>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  name: Scalars['String']['output'];
};

export type ResourceTypeMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ResourceTypeMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type ResourceTypeMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ResourceTypeMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type ResourceTypeNullableRelationFilter = {
  is?: InputMaybe<ResourceTypeWhereInput>;
  isNot?: InputMaybe<ResourceTypeWhereInput>;
};

export type ResourceTypeOrderByWithAggregationInput = {
  _count?: InputMaybe<ResourceTypeCountOrderByAggregateInput>;
  _max?: InputMaybe<ResourceTypeMaxOrderByAggregateInput>;
  _min?: InputMaybe<ResourceTypeMinOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type ResourceTypeOrderByWithRelationInput = {
  articles?: InputMaybe<ArticleOrderByRelationAggregateInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  resources?: InputMaybe<ResourceOrderByRelationAggregateInput>;
};

export enum ResourceTypeScalarFieldEnum {
  Created = 'created',
  Id = 'id',
  Modified = 'modified',
  Name = 'name'
}

export type ResourceTypeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ResourceTypeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ResourceTypeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ResourceTypeScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
};

export type ResourceTypeUpdateInput = {
  articles?: InputMaybe<ArticleUpdateManyWithoutTypeNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  resources?: InputMaybe<ResourceUpdateManyWithoutTypeNestedInput>;
};

export type ResourceTypeUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ResourceTypeUpdateOneWithoutArticlesNestedInput = {
  connect?: InputMaybe<ResourceTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceTypeCreateOrConnectWithoutArticlesInput>;
  create?: InputMaybe<ResourceTypeCreateWithoutArticlesInput>;
  delete?: InputMaybe<ResourceTypeWhereInput>;
  disconnect?: InputMaybe<ResourceTypeWhereInput>;
  update?: InputMaybe<ResourceTypeUpdateToOneWithWhereWithoutArticlesInput>;
  upsert?: InputMaybe<ResourceTypeUpsertWithoutArticlesInput>;
};

export type ResourceTypeUpdateOneWithoutResourcesNestedInput = {
  connect?: InputMaybe<ResourceTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceTypeCreateOrConnectWithoutResourcesInput>;
  create?: InputMaybe<ResourceTypeCreateWithoutResourcesInput>;
  delete?: InputMaybe<ResourceTypeWhereInput>;
  disconnect?: InputMaybe<ResourceTypeWhereInput>;
  update?: InputMaybe<ResourceTypeUpdateToOneWithWhereWithoutResourcesInput>;
  upsert?: InputMaybe<ResourceTypeUpsertWithoutResourcesInput>;
};

export type ResourceTypeUpdateToOneWithWhereWithoutArticlesInput = {
  data: ResourceTypeUpdateWithoutArticlesInput;
  where?: InputMaybe<ResourceTypeWhereInput>;
};

export type ResourceTypeUpdateToOneWithWhereWithoutResourcesInput = {
  data: ResourceTypeUpdateWithoutResourcesInput;
  where?: InputMaybe<ResourceTypeWhereInput>;
};

export type ResourceTypeUpdateWithoutArticlesInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  resources?: InputMaybe<ResourceUpdateManyWithoutTypeNestedInput>;
};

export type ResourceTypeUpdateWithoutResourcesInput = {
  articles?: InputMaybe<ArticleUpdateManyWithoutTypeNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ResourceTypeUpsertWithoutArticlesInput = {
  create: ResourceTypeCreateWithoutArticlesInput;
  update: ResourceTypeUpdateWithoutArticlesInput;
  where?: InputMaybe<ResourceTypeWhereInput>;
};

export type ResourceTypeUpsertWithoutResourcesInput = {
  create: ResourceTypeCreateWithoutResourcesInput;
  update: ResourceTypeUpdateWithoutResourcesInput;
  where?: InputMaybe<ResourceTypeWhereInput>;
};

export type ResourceTypeWhereInput = {
  AND?: InputMaybe<Array<ResourceTypeWhereInput>>;
  NOT?: InputMaybe<Array<ResourceTypeWhereInput>>;
  OR?: InputMaybe<Array<ResourceTypeWhereInput>>;
  articles?: InputMaybe<ArticleListRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  resources?: InputMaybe<ResourceListRelationFilter>;
};

export type ResourceTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<ResourceTypeWhereInput>>;
  NOT?: InputMaybe<Array<ResourceTypeWhereInput>>;
  OR?: InputMaybe<Array<ResourceTypeWhereInput>>;
  articles?: InputMaybe<ArticleListRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<ResourceListRelationFilter>;
};

export type ResourceUpdateInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  downloadedBy?: InputMaybe<UserResourceUpdateManyWithoutResourceNestedInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  resourceCategories?: InputMaybe<ResourceCategoryUpdateManyWithoutResourceNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<ResourceTypeUpdateOneWithoutResourcesNestedInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ResourceUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ResourceUpdateManyWithWhereWithoutTypeInput = {
  data: ResourceUpdateManyMutationInput;
  where: ResourceScalarWhereInput;
};

export type ResourceUpdateManyWithoutTypeNestedInput = {
  connect?: InputMaybe<Array<ResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResourceCreateOrConnectWithoutTypeInput>>;
  create?: InputMaybe<Array<ResourceCreateWithoutTypeInput>>;
  createMany?: InputMaybe<ResourceCreateManyTypeInputEnvelope>;
  delete?: InputMaybe<Array<ResourceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceWhereUniqueInput>>;
  update?: InputMaybe<Array<ResourceUpdateWithWhereUniqueWithoutTypeInput>>;
  updateMany?: InputMaybe<Array<ResourceUpdateManyWithWhereWithoutTypeInput>>;
  upsert?: InputMaybe<Array<ResourceUpsertWithWhereUniqueWithoutTypeInput>>;
};

export type ResourceUpdateOneRequiredWithoutDownloadedByNestedInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutDownloadedByInput>;
  create?: InputMaybe<ResourceCreateWithoutDownloadedByInput>;
  update?: InputMaybe<ResourceUpdateToOneWithWhereWithoutDownloadedByInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutDownloadedByInput>;
};

export type ResourceUpdateOneRequiredWithoutResourceCategoriesNestedInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutResourceCategoriesInput>;
  create?: InputMaybe<ResourceCreateWithoutResourceCategoriesInput>;
  update?: InputMaybe<ResourceUpdateToOneWithWhereWithoutResourceCategoriesInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutResourceCategoriesInput>;
};

export type ResourceUpdateToOneWithWhereWithoutDownloadedByInput = {
  data: ResourceUpdateWithoutDownloadedByInput;
  where?: InputMaybe<ResourceWhereInput>;
};

export type ResourceUpdateToOneWithWhereWithoutResourceCategoriesInput = {
  data: ResourceUpdateWithoutResourceCategoriesInput;
  where?: InputMaybe<ResourceWhereInput>;
};

export type ResourceUpdateWithWhereUniqueWithoutTypeInput = {
  data: ResourceUpdateWithoutTypeInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceUpdateWithoutDownloadedByInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  resourceCategories?: InputMaybe<ResourceCategoryUpdateManyWithoutResourceNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<ResourceTypeUpdateOneWithoutResourcesNestedInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ResourceUpdateWithoutResourceCategoriesInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  downloadedBy?: InputMaybe<UserResourceUpdateManyWithoutResourceNestedInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<ResourceTypeUpdateOneWithoutResourcesNestedInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ResourceUpdateWithoutTypeInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  downloadedBy?: InputMaybe<UserResourceUpdateManyWithoutResourceNestedInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  resourceCategories?: InputMaybe<ResourceCategoryUpdateManyWithoutResourceNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ResourceUpsertWithWhereUniqueWithoutTypeInput = {
  create: ResourceCreateWithoutTypeInput;
  update: ResourceUpdateWithoutTypeInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceUpsertWithoutDownloadedByInput = {
  create: ResourceCreateWithoutDownloadedByInput;
  update: ResourceUpdateWithoutDownloadedByInput;
  where?: InputMaybe<ResourceWhereInput>;
};

export type ResourceUpsertWithoutResourceCategoriesInput = {
  create: ResourceCreateWithoutResourceCategoriesInput;
  update: ResourceUpdateWithoutResourceCategoriesInput;
  where?: InputMaybe<ResourceWhereInput>;
};

export type ResourceWhereInput = {
  AND?: InputMaybe<Array<ResourceWhereInput>>;
  NOT?: InputMaybe<Array<ResourceWhereInput>>;
  OR?: InputMaybe<Array<ResourceWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  downloadedBy?: InputMaybe<UserResourceListRelationFilter>;
  filename?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPublished?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  resourceCategories?: InputMaybe<ResourceCategoryListRelationFilter>;
  resourceTypeId?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<ResourceTypeNullableRelationFilter>;
  url?: InputMaybe<StringFilter>;
};

export type ResourceWhereUniqueInput = {
  AND?: InputMaybe<Array<ResourceWhereInput>>;
  NOT?: InputMaybe<Array<ResourceWhereInput>>;
  OR?: InputMaybe<Array<ResourceWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  downloadedBy?: InputMaybe<UserResourceListRelationFilter>;
  filename?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  resourceCategories?: InputMaybe<ResourceCategoryListRelationFilter>;
  resourceTypeId?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<ResourceTypeNullableRelationFilter>;
  url?: InputMaybe<StringFilter>;
};

export enum Role {
  Admin = 'ADMIN',
  User = 'USER'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StripeEvent = {
  created: Scalars['DateTimeISO']['output'];
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActioned: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  payload: Scalars['JSON']['output'];
  type: Scalars['String']['output'];
};

export type StripeEventCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  eventId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isActioned: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  payload: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
};

export type StripeEventCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActioned?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  payload?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type StripeEventCreateInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isActioned?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  payload: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};

export type StripeEventCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isActioned?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  payload: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};

export type StripeEventGroupBy = {
  _count?: Maybe<StripeEventCountAggregate>;
  _max?: Maybe<StripeEventMaxAggregate>;
  _min?: Maybe<StripeEventMinAggregate>;
  created: Scalars['DateTimeISO']['output'];
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActioned: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  payload: Scalars['JSON']['output'];
  type: Scalars['String']['output'];
};

export type StripeEventMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActioned?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type StripeEventMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActioned?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type StripeEventMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActioned?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type StripeEventMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActioned?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type StripeEventOrderByWithAggregationInput = {
  _count?: InputMaybe<StripeEventCountOrderByAggregateInput>;
  _max?: InputMaybe<StripeEventMaxOrderByAggregateInput>;
  _min?: InputMaybe<StripeEventMinOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActioned?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  payload?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type StripeEventOrderByWithRelationInput = {
  created?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActioned?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  payload?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export enum StripeEventScalarFieldEnum {
  Created = 'created',
  EventId = 'eventId',
  Id = 'id',
  IsActioned = 'isActioned',
  Modified = 'modified',
  Payload = 'payload',
  Type = 'type'
}

export type StripeEventScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StripeEventScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StripeEventScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StripeEventScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  eventId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isActioned?: InputMaybe<BoolWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  payload?: InputMaybe<JsonWithAggregatesFilter>;
  type?: InputMaybe<StringWithAggregatesFilter>;
};

export type StripeEventUpdateInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventId?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActioned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StripeEventUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventId?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActioned?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StripeEventWhereInput = {
  AND?: InputMaybe<Array<StripeEventWhereInput>>;
  NOT?: InputMaybe<Array<StripeEventWhereInput>>;
  OR?: InputMaybe<Array<StripeEventWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isActioned?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  payload?: InputMaybe<JsonFilter>;
  type?: InputMaybe<StringFilter>;
};

export type StripeEventWhereUniqueInput = {
  AND?: InputMaybe<Array<StripeEventWhereInput>>;
  NOT?: InputMaybe<Array<StripeEventWhereInput>>;
  OR?: InputMaybe<Array<StripeEventWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActioned?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  payload?: InputMaybe<JsonFilter>;
  type?: InputMaybe<StringFilter>;
};

export type StripePaymentIntent = {
  chargedInCents?: Maybe<Scalars['Int']['output']>;
  clientSecret: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  feesPaidInCents?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  netInCents?: Maybe<Scalars['Int']['output']>;
  payment: Payment;
  paymentId: Scalars['String']['output'];
  paymentIntentId: Scalars['String']['output'];
  rawResponse: Scalars['JSON']['output'];
  status: StripePaymentIntentStatus;
  taxPaidInCents?: Maybe<Scalars['Int']['output']>;
};

export type StripePaymentIntentAvgAggregate = {
  chargedInCents?: Maybe<Scalars['Float']['output']>;
  feesPaidInCents?: Maybe<Scalars['Float']['output']>;
  netInCents?: Maybe<Scalars['Float']['output']>;
  taxPaidInCents?: Maybe<Scalars['Float']['output']>;
};

export type StripePaymentIntentAvgOrderByAggregateInput = {
  chargedInCents?: InputMaybe<SortOrder>;
  feesPaidInCents?: InputMaybe<SortOrder>;
  netInCents?: InputMaybe<SortOrder>;
  taxPaidInCents?: InputMaybe<SortOrder>;
};

export type StripePaymentIntentCountAggregate = {
  _all: Scalars['Int']['output'];
  chargedInCents: Scalars['Int']['output'];
  clientSecret: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  feesPaidInCents: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  netInCents: Scalars['Int']['output'];
  paymentId: Scalars['Int']['output'];
  paymentIntentId: Scalars['Int']['output'];
  rawResponse: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  taxPaidInCents: Scalars['Int']['output'];
};

export type StripePaymentIntentCountOrderByAggregateInput = {
  chargedInCents?: InputMaybe<SortOrder>;
  clientSecret?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  feesPaidInCents?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  netInCents?: InputMaybe<SortOrder>;
  paymentId?: InputMaybe<SortOrder>;
  paymentIntentId?: InputMaybe<SortOrder>;
  rawResponse?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  taxPaidInCents?: InputMaybe<SortOrder>;
};

export type StripePaymentIntentCreateInput = {
  chargedInCents?: InputMaybe<Scalars['Int']['input']>;
  clientSecret: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  feesPaidInCents?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  netInCents?: InputMaybe<Scalars['Int']['input']>;
  payment: PaymentCreateNestedOneWithoutStripePaymentIntentsInput;
  paymentIntentId: Scalars['String']['input'];
  rawResponse: Scalars['JSON']['input'];
  status?: InputMaybe<StripePaymentIntentStatus>;
  taxPaidInCents?: InputMaybe<Scalars['Int']['input']>;
};

export type StripePaymentIntentCreateManyInput = {
  chargedInCents?: InputMaybe<Scalars['Int']['input']>;
  clientSecret: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  feesPaidInCents?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  netInCents?: InputMaybe<Scalars['Int']['input']>;
  paymentId: Scalars['String']['input'];
  paymentIntentId: Scalars['String']['input'];
  rawResponse: Scalars['JSON']['input'];
  status?: InputMaybe<StripePaymentIntentStatus>;
  taxPaidInCents?: InputMaybe<Scalars['Int']['input']>;
};

export type StripePaymentIntentCreateManyPaymentInput = {
  chargedInCents?: InputMaybe<Scalars['Int']['input']>;
  clientSecret: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  feesPaidInCents?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  netInCents?: InputMaybe<Scalars['Int']['input']>;
  paymentIntentId: Scalars['String']['input'];
  rawResponse: Scalars['JSON']['input'];
  status?: InputMaybe<StripePaymentIntentStatus>;
  taxPaidInCents?: InputMaybe<Scalars['Int']['input']>;
};

export type StripePaymentIntentCreateManyPaymentInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyPaymentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StripePaymentIntentCreateNestedManyWithoutPaymentInput = {
  connect?: InputMaybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StripePaymentIntentCreateOrConnectWithoutPaymentInput>>;
  create?: InputMaybe<Array<StripePaymentIntentCreateWithoutPaymentInput>>;
  createMany?: InputMaybe<StripePaymentIntentCreateManyPaymentInputEnvelope>;
};

export type StripePaymentIntentCreateOrConnectWithoutPaymentInput = {
  create: StripePaymentIntentCreateWithoutPaymentInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateWithoutPaymentInput = {
  chargedInCents?: InputMaybe<Scalars['Int']['input']>;
  clientSecret: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  feesPaidInCents?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  netInCents?: InputMaybe<Scalars['Int']['input']>;
  paymentIntentId: Scalars['String']['input'];
  rawResponse: Scalars['JSON']['input'];
  status?: InputMaybe<StripePaymentIntentStatus>;
  taxPaidInCents?: InputMaybe<Scalars['Int']['input']>;
};

export type StripePaymentIntentGroupBy = {
  _avg?: Maybe<StripePaymentIntentAvgAggregate>;
  _count?: Maybe<StripePaymentIntentCountAggregate>;
  _max?: Maybe<StripePaymentIntentMaxAggregate>;
  _min?: Maybe<StripePaymentIntentMinAggregate>;
  _sum?: Maybe<StripePaymentIntentSumAggregate>;
  chargedInCents?: Maybe<Scalars['Int']['output']>;
  clientSecret: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  feesPaidInCents?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  netInCents?: Maybe<Scalars['Int']['output']>;
  paymentId: Scalars['String']['output'];
  paymentIntentId: Scalars['String']['output'];
  rawResponse: Scalars['JSON']['output'];
  status: StripePaymentIntentStatus;
  taxPaidInCents?: Maybe<Scalars['Int']['output']>;
};

export type StripePaymentIntentListRelationFilter = {
  every?: InputMaybe<StripePaymentIntentWhereInput>;
  none?: InputMaybe<StripePaymentIntentWhereInput>;
  some?: InputMaybe<StripePaymentIntentWhereInput>;
};

export type StripePaymentIntentMaxAggregate = {
  chargedInCents?: Maybe<Scalars['Int']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  feesPaidInCents?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  netInCents?: Maybe<Scalars['Int']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  paymentIntentId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<StripePaymentIntentStatus>;
  taxPaidInCents?: Maybe<Scalars['Int']['output']>;
};

export type StripePaymentIntentMaxOrderByAggregateInput = {
  chargedInCents?: InputMaybe<SortOrder>;
  clientSecret?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  feesPaidInCents?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  netInCents?: InputMaybe<SortOrder>;
  paymentId?: InputMaybe<SortOrder>;
  paymentIntentId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  taxPaidInCents?: InputMaybe<SortOrder>;
};

export type StripePaymentIntentMinAggregate = {
  chargedInCents?: Maybe<Scalars['Int']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  feesPaidInCents?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  netInCents?: Maybe<Scalars['Int']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  paymentIntentId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<StripePaymentIntentStatus>;
  taxPaidInCents?: Maybe<Scalars['Int']['output']>;
};

export type StripePaymentIntentMinOrderByAggregateInput = {
  chargedInCents?: InputMaybe<SortOrder>;
  clientSecret?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  feesPaidInCents?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  netInCents?: InputMaybe<SortOrder>;
  paymentId?: InputMaybe<SortOrder>;
  paymentIntentId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  taxPaidInCents?: InputMaybe<SortOrder>;
};

export type StripePaymentIntentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StripePaymentIntentOrderByWithAggregationInput = {
  _avg?: InputMaybe<StripePaymentIntentAvgOrderByAggregateInput>;
  _count?: InputMaybe<StripePaymentIntentCountOrderByAggregateInput>;
  _max?: InputMaybe<StripePaymentIntentMaxOrderByAggregateInput>;
  _min?: InputMaybe<StripePaymentIntentMinOrderByAggregateInput>;
  _sum?: InputMaybe<StripePaymentIntentSumOrderByAggregateInput>;
  chargedInCents?: InputMaybe<SortOrderInput>;
  clientSecret?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  feesPaidInCents?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  netInCents?: InputMaybe<SortOrderInput>;
  paymentId?: InputMaybe<SortOrder>;
  paymentIntentId?: InputMaybe<SortOrder>;
  rawResponse?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  taxPaidInCents?: InputMaybe<SortOrderInput>;
};

export type StripePaymentIntentOrderByWithRelationInput = {
  chargedInCents?: InputMaybe<SortOrderInput>;
  clientSecret?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  feesPaidInCents?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  netInCents?: InputMaybe<SortOrderInput>;
  payment?: InputMaybe<PaymentOrderByWithRelationInput>;
  paymentId?: InputMaybe<SortOrder>;
  paymentIntentId?: InputMaybe<SortOrder>;
  rawResponse?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  taxPaidInCents?: InputMaybe<SortOrderInput>;
};

export enum StripePaymentIntentScalarFieldEnum {
  ChargedInCents = 'chargedInCents',
  ClientSecret = 'clientSecret',
  Created = 'created',
  FeesPaidInCents = 'feesPaidInCents',
  Id = 'id',
  Modified = 'modified',
  NetInCents = 'netInCents',
  PaymentId = 'paymentId',
  PaymentIntentId = 'paymentIntentId',
  RawResponse = 'rawResponse',
  Status = 'status',
  TaxPaidInCents = 'taxPaidInCents'
}

export type StripePaymentIntentScalarWhereInput = {
  AND?: InputMaybe<Array<StripePaymentIntentScalarWhereInput>>;
  NOT?: InputMaybe<Array<StripePaymentIntentScalarWhereInput>>;
  OR?: InputMaybe<Array<StripePaymentIntentScalarWhereInput>>;
  chargedInCents?: InputMaybe<IntNullableFilter>;
  clientSecret?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  feesPaidInCents?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  netInCents?: InputMaybe<IntNullableFilter>;
  paymentId?: InputMaybe<StringFilter>;
  paymentIntentId?: InputMaybe<StringFilter>;
  rawResponse?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumStripePaymentIntentStatusFilter>;
  taxPaidInCents?: InputMaybe<IntNullableFilter>;
};

export type StripePaymentIntentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StripePaymentIntentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StripePaymentIntentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StripePaymentIntentScalarWhereWithAggregatesInput>>;
  chargedInCents?: InputMaybe<IntNullableWithAggregatesFilter>;
  clientSecret?: InputMaybe<StringWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  feesPaidInCents?: InputMaybe<IntNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  netInCents?: InputMaybe<IntNullableWithAggregatesFilter>;
  paymentId?: InputMaybe<StringWithAggregatesFilter>;
  paymentIntentId?: InputMaybe<StringWithAggregatesFilter>;
  rawResponse?: InputMaybe<JsonWithAggregatesFilter>;
  status?: InputMaybe<EnumStripePaymentIntentStatusWithAggregatesFilter>;
  taxPaidInCents?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export enum StripePaymentIntentStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export type StripePaymentIntentSumAggregate = {
  chargedInCents?: Maybe<Scalars['Int']['output']>;
  feesPaidInCents?: Maybe<Scalars['Int']['output']>;
  netInCents?: Maybe<Scalars['Int']['output']>;
  taxPaidInCents?: Maybe<Scalars['Int']['output']>;
};

export type StripePaymentIntentSumOrderByAggregateInput = {
  chargedInCents?: InputMaybe<SortOrder>;
  feesPaidInCents?: InputMaybe<SortOrder>;
  netInCents?: InputMaybe<SortOrder>;
  taxPaidInCents?: InputMaybe<SortOrder>;
};

export type StripePaymentIntentUpdateInput = {
  chargedInCents?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  clientSecret?: InputMaybe<StringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feesPaidInCents?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  netInCents?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateOneRequiredWithoutStripePaymentIntentsNestedInput>;
  paymentIntentId?: InputMaybe<StringFieldUpdateOperationsInput>;
  rawResponse?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<EnumStripePaymentIntentStatusFieldUpdateOperationsInput>;
  taxPaidInCents?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type StripePaymentIntentUpdateManyMutationInput = {
  chargedInCents?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  clientSecret?: InputMaybe<StringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feesPaidInCents?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  netInCents?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentIntentId?: InputMaybe<StringFieldUpdateOperationsInput>;
  rawResponse?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<EnumStripePaymentIntentStatusFieldUpdateOperationsInput>;
  taxPaidInCents?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutPaymentInput = {
  data: StripePaymentIntentUpdateManyMutationInput;
  where: StripePaymentIntentScalarWhereInput;
};

export type StripePaymentIntentUpdateManyWithoutPaymentNestedInput = {
  connect?: InputMaybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StripePaymentIntentCreateOrConnectWithoutPaymentInput>>;
  create?: InputMaybe<Array<StripePaymentIntentCreateWithoutPaymentInput>>;
  createMany?: InputMaybe<StripePaymentIntentCreateManyPaymentInputEnvelope>;
  delete?: InputMaybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: InputMaybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: InputMaybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutPaymentInput>>;
  updateMany?: InputMaybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutPaymentInput>>;
  upsert?: InputMaybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutPaymentInput>>;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutPaymentInput = {
  data: StripePaymentIntentUpdateWithoutPaymentInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpdateWithoutPaymentInput = {
  chargedInCents?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  clientSecret?: InputMaybe<StringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feesPaidInCents?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  netInCents?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentIntentId?: InputMaybe<StringFieldUpdateOperationsInput>;
  rawResponse?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<EnumStripePaymentIntentStatusFieldUpdateOperationsInput>;
  taxPaidInCents?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutPaymentInput = {
  create: StripePaymentIntentCreateWithoutPaymentInput;
  update: StripePaymentIntentUpdateWithoutPaymentInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentWhereInput = {
  AND?: InputMaybe<Array<StripePaymentIntentWhereInput>>;
  NOT?: InputMaybe<Array<StripePaymentIntentWhereInput>>;
  OR?: InputMaybe<Array<StripePaymentIntentWhereInput>>;
  chargedInCents?: InputMaybe<IntNullableFilter>;
  clientSecret?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  feesPaidInCents?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  netInCents?: InputMaybe<IntNullableFilter>;
  payment?: InputMaybe<PaymentRelationFilter>;
  paymentId?: InputMaybe<StringFilter>;
  paymentIntentId?: InputMaybe<StringFilter>;
  rawResponse?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumStripePaymentIntentStatusFilter>;
  taxPaidInCents?: InputMaybe<IntNullableFilter>;
};

export type StripePaymentIntentWhereUniqueInput = {
  AND?: InputMaybe<Array<StripePaymentIntentWhereInput>>;
  NOT?: InputMaybe<Array<StripePaymentIntentWhereInput>>;
  OR?: InputMaybe<Array<StripePaymentIntentWhereInput>>;
  chargedInCents?: InputMaybe<IntNullableFilter>;
  clientSecret?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  feesPaidInCents?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  netInCents?: InputMaybe<IntNullableFilter>;
  payment?: InputMaybe<PaymentRelationFilter>;
  paymentId?: InputMaybe<StringFilter>;
  paymentIntentId?: InputMaybe<StringFilter>;
  rawResponse?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumStripePaymentIntentStatusFilter>;
  taxPaidInCents?: InputMaybe<IntNullableFilter>;
};

export type User = {
  _count?: Maybe<UserCount>;
  attemptedQuizzes: Array<UserQuiz>;
  billingAddress?: Maybe<Scalars['JSON']['output']>;
  billingCountryCode?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  downloadedResources: Array<UserResource>;
  email: Scalars['String']['output'];
  eventAttendances: Array<EventAttendance>;
  eventRegistrations: Array<EventRegistration>;
  externalId: Scalars['String']['output'];
  healthDiscipline?: Maybe<Scalars['String']['output']>;
  howDidYouHear?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isEnabled: Scalars['Boolean']['output'];
  isPublicEnabled: Scalars['Boolean']['output'];
  manualActivities: Array<UserManualActivity>;
  modified: Scalars['DateTimeISO']['output'];
  payments: Array<Payment>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  programmeProgressions: Array<ProgrammeProgression>;
  publicAddresses?: Maybe<Scalars['JSON']['output']>;
  publicDiscipline?: Maybe<Scalars['String']['output']>;
  publicName?: Maybe<Scalars['String']['output']>;
  readArticles: Array<UserArticle>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  regulatoryBody?: Maybe<Scalars['String']['output']>;
  role: Role;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  userSubscriptions: Array<UserSubscription>;
};


export type UserAttemptedQuizzesArgs = {
  cursor?: InputMaybe<UserQuizWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserQuizScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserQuizOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserQuizWhereInput>;
};


export type UserDownloadedResourcesArgs = {
  cursor?: InputMaybe<UserResourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserResourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserResourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserResourceWhereInput>;
};


export type UserEventAttendancesArgs = {
  cursor?: InputMaybe<EventAttendanceWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventAttendanceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventAttendanceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventAttendanceWhereInput>;
};


export type UserEventRegistrationsArgs = {
  cursor?: InputMaybe<EventRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventRegistrationWhereInput>;
};


export type UserManualActivitiesArgs = {
  cursor?: InputMaybe<UserManualActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserManualActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserManualActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserManualActivityWhereInput>;
};


export type UserPaymentsArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type UserProgrammeProgressionsArgs = {
  cursor?: InputMaybe<ProgrammeProgressionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgrammeProgressionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgrammeProgressionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgrammeProgressionWhereInput>;
};


export type UserReadArticlesArgs = {
  cursor?: InputMaybe<UserArticleWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserArticleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserArticleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserArticleWhereInput>;
};


export type UserUserSubscriptionsArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};

export type UserArticle = {
  article: Article;
  articleId: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserArticleCountAggregate = {
  _all: Scalars['Int']['output'];
  articleId: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserArticleCountOrderByAggregateInput = {
  articleId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserArticleCreateInput = {
  article: ArticleCreateNestedOneWithoutReadByInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  user: UserCreateNestedOneWithoutReadArticlesInput;
};

export type UserArticleCreateManyArticleInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  userId: Scalars['String']['input'];
};

export type UserArticleCreateManyArticleInputEnvelope = {
  data: Array<UserArticleCreateManyArticleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserArticleCreateManyInput = {
  articleId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  userId: Scalars['String']['input'];
};

export type UserArticleCreateManyUserInput = {
  articleId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type UserArticleCreateManyUserInputEnvelope = {
  data: Array<UserArticleCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserArticleCreateNestedManyWithoutArticleInput = {
  connect?: InputMaybe<Array<UserArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserArticleCreateOrConnectWithoutArticleInput>>;
  create?: InputMaybe<Array<UserArticleCreateWithoutArticleInput>>;
  createMany?: InputMaybe<UserArticleCreateManyArticleInputEnvelope>;
};

export type UserArticleCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserArticleCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserArticleCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserArticleCreateManyUserInputEnvelope>;
};

export type UserArticleCreateOrConnectWithoutArticleInput = {
  create: UserArticleCreateWithoutArticleInput;
  where: UserArticleWhereUniqueInput;
};

export type UserArticleCreateOrConnectWithoutUserInput = {
  create: UserArticleCreateWithoutUserInput;
  where: UserArticleWhereUniqueInput;
};

export type UserArticleCreateWithoutArticleInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  user: UserCreateNestedOneWithoutReadArticlesInput;
};

export type UserArticleCreateWithoutUserInput = {
  article: ArticleCreateNestedOneWithoutReadByInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type UserArticleGroupBy = {
  _count?: Maybe<UserArticleCountAggregate>;
  _max?: Maybe<UserArticleMaxAggregate>;
  _min?: Maybe<UserArticleMinAggregate>;
  articleId: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type UserArticleListRelationFilter = {
  every?: InputMaybe<UserArticleWhereInput>;
  none?: InputMaybe<UserArticleWhereInput>;
  some?: InputMaybe<UserArticleWhereInput>;
};

export type UserArticleMaxAggregate = {
  articleId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserArticleMaxOrderByAggregateInput = {
  articleId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserArticleMinAggregate = {
  articleId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserArticleMinOrderByAggregateInput = {
  articleId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserArticleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserArticleOrderByWithAggregationInput = {
  _count?: InputMaybe<UserArticleCountOrderByAggregateInput>;
  _max?: InputMaybe<UserArticleMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserArticleMinOrderByAggregateInput>;
  articleId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserArticleOrderByWithRelationInput = {
  article?: InputMaybe<ArticleOrderByWithRelationInput>;
  articleId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserArticleScalarFieldEnum {
  ArticleId = 'articleId',
  Created = 'created',
  Id = 'id',
  Modified = 'modified',
  UserId = 'userId'
}

export type UserArticleScalarWhereInput = {
  AND?: InputMaybe<Array<UserArticleScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserArticleScalarWhereInput>>;
  OR?: InputMaybe<Array<UserArticleScalarWhereInput>>;
  articleId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserArticleScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserArticleScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserArticleScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserArticleScalarWhereWithAggregatesInput>>;
  articleId?: InputMaybe<StringWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserArticleUpdateInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutReadByNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutReadArticlesNestedInput>;
};

export type UserArticleUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserArticleUpdateManyWithWhereWithoutArticleInput = {
  data: UserArticleUpdateManyMutationInput;
  where: UserArticleScalarWhereInput;
};

export type UserArticleUpdateManyWithWhereWithoutUserInput = {
  data: UserArticleUpdateManyMutationInput;
  where: UserArticleScalarWhereInput;
};

export type UserArticleUpdateManyWithoutArticleNestedInput = {
  connect?: InputMaybe<Array<UserArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserArticleCreateOrConnectWithoutArticleInput>>;
  create?: InputMaybe<Array<UserArticleCreateWithoutArticleInput>>;
  createMany?: InputMaybe<UserArticleCreateManyArticleInputEnvelope>;
  delete?: InputMaybe<Array<UserArticleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserArticleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserArticleWhereUniqueInput>>;
  set?: InputMaybe<Array<UserArticleWhereUniqueInput>>;
  update?: InputMaybe<Array<UserArticleUpdateWithWhereUniqueWithoutArticleInput>>;
  updateMany?: InputMaybe<Array<UserArticleUpdateManyWithWhereWithoutArticleInput>>;
  upsert?: InputMaybe<Array<UserArticleUpsertWithWhereUniqueWithoutArticleInput>>;
};

export type UserArticleUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserArticleCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserArticleCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserArticleCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserArticleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserArticleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserArticleWhereUniqueInput>>;
  set?: InputMaybe<Array<UserArticleWhereUniqueInput>>;
  update?: InputMaybe<Array<UserArticleUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserArticleUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserArticleUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserArticleUpdateWithWhereUniqueWithoutArticleInput = {
  data: UserArticleUpdateWithoutArticleInput;
  where: UserArticleWhereUniqueInput;
};

export type UserArticleUpdateWithWhereUniqueWithoutUserInput = {
  data: UserArticleUpdateWithoutUserInput;
  where: UserArticleWhereUniqueInput;
};

export type UserArticleUpdateWithoutArticleInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutReadArticlesNestedInput>;
};

export type UserArticleUpdateWithoutUserInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutReadByNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserArticleUpsertWithWhereUniqueWithoutArticleInput = {
  create: UserArticleCreateWithoutArticleInput;
  update: UserArticleUpdateWithoutArticleInput;
  where: UserArticleWhereUniqueInput;
};

export type UserArticleUpsertWithWhereUniqueWithoutUserInput = {
  create: UserArticleCreateWithoutUserInput;
  update: UserArticleUpdateWithoutUserInput;
  where: UserArticleWhereUniqueInput;
};

export type UserArticleUserArticleCompoundUniqueInput = {
  articleId: Scalars['String']['input'];
  created: Scalars['DateTimeISO']['input'];
  userId: Scalars['String']['input'];
};

export type UserArticleWhereInput = {
  AND?: InputMaybe<Array<UserArticleWhereInput>>;
  NOT?: InputMaybe<Array<UserArticleWhereInput>>;
  OR?: InputMaybe<Array<UserArticleWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  articleId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserArticleWhereUniqueInput = {
  AND?: InputMaybe<Array<UserArticleWhereInput>>;
  NOT?: InputMaybe<Array<UserArticleWhereInput>>;
  OR?: InputMaybe<Array<UserArticleWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  articleId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userArticle?: InputMaybe<UserArticleUserArticleCompoundUniqueInput>;
  userId?: InputMaybe<StringFilter>;
};

export type UserCount = {
  attemptedQuizzes: Scalars['Int']['output'];
  downloadedResources: Scalars['Int']['output'];
  eventAttendances: Scalars['Int']['output'];
  eventRegistrations: Scalars['Int']['output'];
  manualActivities: Scalars['Int']['output'];
  payments: Scalars['Int']['output'];
  programmeProgressions: Scalars['Int']['output'];
  readArticles: Scalars['Int']['output'];
  userSubscriptions: Scalars['Int']['output'];
};


export type UserCountAttemptedQuizzesArgs = {
  where?: InputMaybe<UserQuizWhereInput>;
};


export type UserCountDownloadedResourcesArgs = {
  where?: InputMaybe<UserResourceWhereInput>;
};


export type UserCountEventAttendancesArgs = {
  where?: InputMaybe<EventAttendanceWhereInput>;
};


export type UserCountEventRegistrationsArgs = {
  where?: InputMaybe<EventRegistrationWhereInput>;
};


export type UserCountManualActivitiesArgs = {
  where?: InputMaybe<UserManualActivityWhereInput>;
};


export type UserCountPaymentsArgs = {
  where?: InputMaybe<PaymentWhereInput>;
};


export type UserCountProgrammeProgressionsArgs = {
  where?: InputMaybe<ProgrammeProgressionWhereInput>;
};


export type UserCountReadArticlesArgs = {
  where?: InputMaybe<UserArticleWhereInput>;
};


export type UserCountUserSubscriptionsArgs = {
  where?: InputMaybe<UserSubscriptionWhereInput>;
};

export type UserCountAggregate = {
  _all: Scalars['Int']['output'];
  billingAddress: Scalars['Int']['output'];
  billingCountryCode: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  displayName: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  externalId: Scalars['Int']['output'];
  healthDiscipline: Scalars['Int']['output'];
  howDidYouHear: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isEnabled: Scalars['Int']['output'];
  isPublicEnabled: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  photoUrl: Scalars['Int']['output'];
  publicAddresses: Scalars['Int']['output'];
  publicDiscipline: Scalars['Int']['output'];
  publicName: Scalars['Int']['output'];
  registrationNumber: Scalars['Int']['output'];
  regulatoryBody: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  stripeCustomerId: Scalars['Int']['output'];
};

export type UserCountOrderByAggregateInput = {
  billingAddress?: InputMaybe<SortOrder>;
  billingCountryCode?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  healthDiscipline?: InputMaybe<SortOrder>;
  howDidYouHear?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isEnabled?: InputMaybe<SortOrder>;
  isPublicEnabled?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  photoUrl?: InputMaybe<SortOrder>;
  publicAddresses?: InputMaybe<SortOrder>;
  publicDiscipline?: InputMaybe<SortOrder>;
  publicName?: InputMaybe<SortOrder>;
  registrationNumber?: InputMaybe<SortOrder>;
  regulatoryBody?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  stripeCustomerId?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  attemptedQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutUserInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  downloadedResources?: InputMaybe<UserResourceCreateNestedManyWithoutUserInput>;
  email: Scalars['String']['input'];
  eventAttendances?: InputMaybe<EventAttendanceCreateNestedManyWithoutUserInput>;
  eventRegistrations?: InputMaybe<EventRegistrationCreateNestedManyWithoutUserInput>;
  externalId: Scalars['String']['input'];
  healthDiscipline?: InputMaybe<Scalars['String']['input']>;
  howDidYouHear?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  manualActivities?: InputMaybe<UserManualActivityCreateNestedManyWithoutUserInput>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  payments?: InputMaybe<PaymentCreateNestedManyWithoutUserInput>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionCreateNestedManyWithoutUserInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  readArticles?: InputMaybe<UserArticleCreateNestedManyWithoutUserInput>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  regulatoryBody?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutUserInput>;
};

export type UserCreateManyInput = {
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  healthDiscipline?: InputMaybe<Scalars['String']['input']>;
  howDidYouHear?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  regulatoryBody?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateNestedOneWithoutAttemptedQuizzesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAttemptedQuizzesInput>;
  create?: InputMaybe<UserCreateWithoutAttemptedQuizzesInput>;
};

export type UserCreateNestedOneWithoutDownloadedResourcesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDownloadedResourcesInput>;
  create?: InputMaybe<UserCreateWithoutDownloadedResourcesInput>;
};

export type UserCreateNestedOneWithoutEventAttendancesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEventAttendancesInput>;
  create?: InputMaybe<UserCreateWithoutEventAttendancesInput>;
};

export type UserCreateNestedOneWithoutEventRegistrationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEventRegistrationsInput>;
  create?: InputMaybe<UserCreateWithoutEventRegistrationsInput>;
};

export type UserCreateNestedOneWithoutManualActivitiesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutManualActivitiesInput>;
  create?: InputMaybe<UserCreateWithoutManualActivitiesInput>;
};

export type UserCreateNestedOneWithoutPaymentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPaymentsInput>;
  create?: InputMaybe<UserCreateWithoutPaymentsInput>;
};

export type UserCreateNestedOneWithoutProgrammeProgressionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProgrammeProgressionsInput>;
  create?: InputMaybe<UserCreateWithoutProgrammeProgressionsInput>;
};

export type UserCreateNestedOneWithoutReadArticlesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReadArticlesInput>;
  create?: InputMaybe<UserCreateWithoutReadArticlesInput>;
};

export type UserCreateNestedOneWithoutUserSubscriptionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserSubscriptionsInput>;
  create?: InputMaybe<UserCreateWithoutUserSubscriptionsInput>;
};

export type UserCreateOrConnectWithoutAttemptedQuizzesInput = {
  create: UserCreateWithoutAttemptedQuizzesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDownloadedResourcesInput = {
  create: UserCreateWithoutDownloadedResourcesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEventAttendancesInput = {
  create: UserCreateWithoutEventAttendancesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEventRegistrationsInput = {
  create: UserCreateWithoutEventRegistrationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutManualActivitiesInput = {
  create: UserCreateWithoutManualActivitiesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPaymentsInput = {
  create: UserCreateWithoutPaymentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutProgrammeProgressionsInput = {
  create: UserCreateWithoutProgrammeProgressionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReadArticlesInput = {
  create: UserCreateWithoutReadArticlesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserSubscriptionsInput = {
  create: UserCreateWithoutUserSubscriptionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutAttemptedQuizzesInput = {
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  downloadedResources?: InputMaybe<UserResourceCreateNestedManyWithoutUserInput>;
  email: Scalars['String']['input'];
  eventAttendances?: InputMaybe<EventAttendanceCreateNestedManyWithoutUserInput>;
  eventRegistrations?: InputMaybe<EventRegistrationCreateNestedManyWithoutUserInput>;
  externalId: Scalars['String']['input'];
  healthDiscipline?: InputMaybe<Scalars['String']['input']>;
  howDidYouHear?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  manualActivities?: InputMaybe<UserManualActivityCreateNestedManyWithoutUserInput>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  payments?: InputMaybe<PaymentCreateNestedManyWithoutUserInput>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionCreateNestedManyWithoutUserInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  readArticles?: InputMaybe<UserArticleCreateNestedManyWithoutUserInput>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  regulatoryBody?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutDownloadedResourcesInput = {
  attemptedQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutUserInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  eventAttendances?: InputMaybe<EventAttendanceCreateNestedManyWithoutUserInput>;
  eventRegistrations?: InputMaybe<EventRegistrationCreateNestedManyWithoutUserInput>;
  externalId: Scalars['String']['input'];
  healthDiscipline?: InputMaybe<Scalars['String']['input']>;
  howDidYouHear?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  manualActivities?: InputMaybe<UserManualActivityCreateNestedManyWithoutUserInput>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  payments?: InputMaybe<PaymentCreateNestedManyWithoutUserInput>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionCreateNestedManyWithoutUserInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  readArticles?: InputMaybe<UserArticleCreateNestedManyWithoutUserInput>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  regulatoryBody?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutEventAttendancesInput = {
  attemptedQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutUserInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  downloadedResources?: InputMaybe<UserResourceCreateNestedManyWithoutUserInput>;
  email: Scalars['String']['input'];
  eventRegistrations?: InputMaybe<EventRegistrationCreateNestedManyWithoutUserInput>;
  externalId: Scalars['String']['input'];
  healthDiscipline?: InputMaybe<Scalars['String']['input']>;
  howDidYouHear?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  manualActivities?: InputMaybe<UserManualActivityCreateNestedManyWithoutUserInput>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  payments?: InputMaybe<PaymentCreateNestedManyWithoutUserInput>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionCreateNestedManyWithoutUserInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  readArticles?: InputMaybe<UserArticleCreateNestedManyWithoutUserInput>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  regulatoryBody?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutEventRegistrationsInput = {
  attemptedQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutUserInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  downloadedResources?: InputMaybe<UserResourceCreateNestedManyWithoutUserInput>;
  email: Scalars['String']['input'];
  eventAttendances?: InputMaybe<EventAttendanceCreateNestedManyWithoutUserInput>;
  externalId: Scalars['String']['input'];
  healthDiscipline?: InputMaybe<Scalars['String']['input']>;
  howDidYouHear?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  manualActivities?: InputMaybe<UserManualActivityCreateNestedManyWithoutUserInput>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  payments?: InputMaybe<PaymentCreateNestedManyWithoutUserInput>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionCreateNestedManyWithoutUserInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  readArticles?: InputMaybe<UserArticleCreateNestedManyWithoutUserInput>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  regulatoryBody?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutManualActivitiesInput = {
  attemptedQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutUserInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  downloadedResources?: InputMaybe<UserResourceCreateNestedManyWithoutUserInput>;
  email: Scalars['String']['input'];
  eventAttendances?: InputMaybe<EventAttendanceCreateNestedManyWithoutUserInput>;
  eventRegistrations?: InputMaybe<EventRegistrationCreateNestedManyWithoutUserInput>;
  externalId: Scalars['String']['input'];
  healthDiscipline?: InputMaybe<Scalars['String']['input']>;
  howDidYouHear?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  payments?: InputMaybe<PaymentCreateNestedManyWithoutUserInput>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionCreateNestedManyWithoutUserInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  readArticles?: InputMaybe<UserArticleCreateNestedManyWithoutUserInput>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  regulatoryBody?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutPaymentsInput = {
  attemptedQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutUserInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  downloadedResources?: InputMaybe<UserResourceCreateNestedManyWithoutUserInput>;
  email: Scalars['String']['input'];
  eventAttendances?: InputMaybe<EventAttendanceCreateNestedManyWithoutUserInput>;
  eventRegistrations?: InputMaybe<EventRegistrationCreateNestedManyWithoutUserInput>;
  externalId: Scalars['String']['input'];
  healthDiscipline?: InputMaybe<Scalars['String']['input']>;
  howDidYouHear?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  manualActivities?: InputMaybe<UserManualActivityCreateNestedManyWithoutUserInput>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionCreateNestedManyWithoutUserInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  readArticles?: InputMaybe<UserArticleCreateNestedManyWithoutUserInput>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  regulatoryBody?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutProgrammeProgressionsInput = {
  attemptedQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutUserInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  downloadedResources?: InputMaybe<UserResourceCreateNestedManyWithoutUserInput>;
  email: Scalars['String']['input'];
  eventAttendances?: InputMaybe<EventAttendanceCreateNestedManyWithoutUserInput>;
  eventRegistrations?: InputMaybe<EventRegistrationCreateNestedManyWithoutUserInput>;
  externalId: Scalars['String']['input'];
  healthDiscipline?: InputMaybe<Scalars['String']['input']>;
  howDidYouHear?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  manualActivities?: InputMaybe<UserManualActivityCreateNestedManyWithoutUserInput>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  payments?: InputMaybe<PaymentCreateNestedManyWithoutUserInput>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  readArticles?: InputMaybe<UserArticleCreateNestedManyWithoutUserInput>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  regulatoryBody?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutReadArticlesInput = {
  attemptedQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutUserInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  downloadedResources?: InputMaybe<UserResourceCreateNestedManyWithoutUserInput>;
  email: Scalars['String']['input'];
  eventAttendances?: InputMaybe<EventAttendanceCreateNestedManyWithoutUserInput>;
  eventRegistrations?: InputMaybe<EventRegistrationCreateNestedManyWithoutUserInput>;
  externalId: Scalars['String']['input'];
  healthDiscipline?: InputMaybe<Scalars['String']['input']>;
  howDidYouHear?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  manualActivities?: InputMaybe<UserManualActivityCreateNestedManyWithoutUserInput>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  payments?: InputMaybe<PaymentCreateNestedManyWithoutUserInput>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionCreateNestedManyWithoutUserInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  regulatoryBody?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutUserSubscriptionsInput = {
  attemptedQuizzes?: InputMaybe<UserQuizCreateNestedManyWithoutUserInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  downloadedResources?: InputMaybe<UserResourceCreateNestedManyWithoutUserInput>;
  email: Scalars['String']['input'];
  eventAttendances?: InputMaybe<EventAttendanceCreateNestedManyWithoutUserInput>;
  eventRegistrations?: InputMaybe<EventRegistrationCreateNestedManyWithoutUserInput>;
  externalId: Scalars['String']['input'];
  healthDiscipline?: InputMaybe<Scalars['String']['input']>;
  howDidYouHear?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  manualActivities?: InputMaybe<UserManualActivityCreateNestedManyWithoutUserInput>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  payments?: InputMaybe<PaymentCreateNestedManyWithoutUserInput>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionCreateNestedManyWithoutUserInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  readArticles?: InputMaybe<UserArticleCreateNestedManyWithoutUserInput>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  regulatoryBody?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
};

export type UserGroupBy = {
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  billingAddress?: Maybe<Scalars['JSON']['output']>;
  billingCountryCode?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  healthDiscipline?: Maybe<Scalars['String']['output']>;
  howDidYouHear?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isEnabled: Scalars['Boolean']['output'];
  isPublicEnabled: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  photoUrl?: Maybe<Scalars['String']['output']>;
  publicAddresses?: Maybe<Scalars['JSON']['output']>;
  publicDiscipline?: Maybe<Scalars['String']['output']>;
  publicName?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  regulatoryBody?: Maybe<Scalars['String']['output']>;
  role: Role;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
};

export type UserManualActivity = {
  created: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserManualActivityAvgAggregate = {
  duration?: Maybe<Scalars['Float']['output']>;
};

export type UserManualActivityAvgOrderByAggregateInput = {
  duration?: InputMaybe<SortOrder>;
};

export type UserManualActivityCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserManualActivityCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserManualActivityCreateInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  user: UserCreateNestedOneWithoutManualActivitiesInput;
};

export type UserManualActivityCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  userId: Scalars['String']['input'];
};

export type UserManualActivityCreateManyUserInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type UserManualActivityCreateManyUserInputEnvelope = {
  data: Array<UserManualActivityCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserManualActivityCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserManualActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserManualActivityCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserManualActivityCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserManualActivityCreateManyUserInputEnvelope>;
};

export type UserManualActivityCreateOrConnectWithoutUserInput = {
  create: UserManualActivityCreateWithoutUserInput;
  where: UserManualActivityWhereUniqueInput;
};

export type UserManualActivityCreateWithoutUserInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type UserManualActivityGroupBy = {
  _avg?: Maybe<UserManualActivityAvgAggregate>;
  _count?: Maybe<UserManualActivityCountAggregate>;
  _max?: Maybe<UserManualActivityMaxAggregate>;
  _min?: Maybe<UserManualActivityMinAggregate>;
  _sum?: Maybe<UserManualActivitySumAggregate>;
  created: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type UserManualActivityListRelationFilter = {
  every?: InputMaybe<UserManualActivityWhereInput>;
  none?: InputMaybe<UserManualActivityWhereInput>;
  some?: InputMaybe<UserManualActivityWhereInput>;
};

export type UserManualActivityMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserManualActivityMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserManualActivityMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserManualActivityMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserManualActivityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserManualActivityOrderByWithAggregationInput = {
  _avg?: InputMaybe<UserManualActivityAvgOrderByAggregateInput>;
  _count?: InputMaybe<UserManualActivityCountOrderByAggregateInput>;
  _max?: InputMaybe<UserManualActivityMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserManualActivityMinOrderByAggregateInput>;
  _sum?: InputMaybe<UserManualActivitySumOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserManualActivityOrderByWithRelationInput = {
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserManualActivityScalarFieldEnum {
  Created = 'created',
  Description = 'description',
  Duration = 'duration',
  Id = 'id',
  Modified = 'modified',
  UserId = 'userId'
}

export type UserManualActivityScalarWhereInput = {
  AND?: InputMaybe<Array<UserManualActivityScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserManualActivityScalarWhereInput>>;
  OR?: InputMaybe<Array<UserManualActivityScalarWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserManualActivityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserManualActivityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserManualActivityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserManualActivityScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  duration?: InputMaybe<IntNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserManualActivitySumAggregate = {
  duration?: Maybe<Scalars['Int']['output']>;
};

export type UserManualActivitySumOrderByAggregateInput = {
  duration?: InputMaybe<SortOrder>;
};

export type UserManualActivityUpdateInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutManualActivitiesNestedInput>;
};

export type UserManualActivityUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserManualActivityUpdateManyWithWhereWithoutUserInput = {
  data: UserManualActivityUpdateManyMutationInput;
  where: UserManualActivityScalarWhereInput;
};

export type UserManualActivityUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserManualActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserManualActivityCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserManualActivityCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserManualActivityCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserManualActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserManualActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserManualActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<UserManualActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<UserManualActivityUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserManualActivityUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserManualActivityUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserManualActivityUpdateWithWhereUniqueWithoutUserInput = {
  data: UserManualActivityUpdateWithoutUserInput;
  where: UserManualActivityWhereUniqueInput;
};

export type UserManualActivityUpdateWithoutUserInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserManualActivityUpsertWithWhereUniqueWithoutUserInput = {
  create: UserManualActivityCreateWithoutUserInput;
  update: UserManualActivityUpdateWithoutUserInput;
  where: UserManualActivityWhereUniqueInput;
};

export type UserManualActivityWhereInput = {
  AND?: InputMaybe<Array<UserManualActivityWhereInput>>;
  NOT?: InputMaybe<Array<UserManualActivityWhereInput>>;
  OR?: InputMaybe<Array<UserManualActivityWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserManualActivityWhereUniqueInput = {
  AND?: InputMaybe<Array<UserManualActivityWhereInput>>;
  NOT?: InputMaybe<Array<UserManualActivityWhereInput>>;
  OR?: InputMaybe<Array<UserManualActivityWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserMaxAggregate = {
  billingCountryCode?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  healthDiscipline?: Maybe<Scalars['String']['output']>;
  howDidYouHear?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  isPublicEnabled?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  publicDiscipline?: Maybe<Scalars['String']['output']>;
  publicName?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  regulatoryBody?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
};

export type UserMaxOrderByAggregateInput = {
  billingCountryCode?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  healthDiscipline?: InputMaybe<SortOrder>;
  howDidYouHear?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isEnabled?: InputMaybe<SortOrder>;
  isPublicEnabled?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  photoUrl?: InputMaybe<SortOrder>;
  publicDiscipline?: InputMaybe<SortOrder>;
  publicName?: InputMaybe<SortOrder>;
  registrationNumber?: InputMaybe<SortOrder>;
  regulatoryBody?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  stripeCustomerId?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  billingCountryCode?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  healthDiscipline?: Maybe<Scalars['String']['output']>;
  howDidYouHear?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  isPublicEnabled?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  publicDiscipline?: Maybe<Scalars['String']['output']>;
  publicName?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  regulatoryBody?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
};

export type UserMinOrderByAggregateInput = {
  billingCountryCode?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  healthDiscipline?: InputMaybe<SortOrder>;
  howDidYouHear?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isEnabled?: InputMaybe<SortOrder>;
  isPublicEnabled?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  photoUrl?: InputMaybe<SortOrder>;
  publicDiscipline?: InputMaybe<SortOrder>;
  publicName?: InputMaybe<SortOrder>;
  registrationNumber?: InputMaybe<SortOrder>;
  regulatoryBody?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  stripeCustomerId?: InputMaybe<SortOrder>;
};

export type UserOrderByWithAggregationInput = {
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  billingAddress?: InputMaybe<SortOrderInput>;
  billingCountryCode?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  healthDiscipline?: InputMaybe<SortOrderInput>;
  howDidYouHear?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isEnabled?: InputMaybe<SortOrder>;
  isPublicEnabled?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  photoUrl?: InputMaybe<SortOrderInput>;
  publicAddresses?: InputMaybe<SortOrderInput>;
  publicDiscipline?: InputMaybe<SortOrderInput>;
  publicName?: InputMaybe<SortOrderInput>;
  registrationNumber?: InputMaybe<SortOrderInput>;
  regulatoryBody?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  stripeCustomerId?: InputMaybe<SortOrderInput>;
};

export type UserOrderByWithRelationInput = {
  attemptedQuizzes?: InputMaybe<UserQuizOrderByRelationAggregateInput>;
  billingAddress?: InputMaybe<SortOrderInput>;
  billingCountryCode?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrderInput>;
  downloadedResources?: InputMaybe<UserResourceOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  eventAttendances?: InputMaybe<EventAttendanceOrderByRelationAggregateInput>;
  eventRegistrations?: InputMaybe<EventRegistrationOrderByRelationAggregateInput>;
  externalId?: InputMaybe<SortOrder>;
  healthDiscipline?: InputMaybe<SortOrderInput>;
  howDidYouHear?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isEnabled?: InputMaybe<SortOrder>;
  isPublicEnabled?: InputMaybe<SortOrder>;
  manualActivities?: InputMaybe<UserManualActivityOrderByRelationAggregateInput>;
  modified?: InputMaybe<SortOrder>;
  payments?: InputMaybe<PaymentOrderByRelationAggregateInput>;
  photoUrl?: InputMaybe<SortOrderInput>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionOrderByRelationAggregateInput>;
  publicAddresses?: InputMaybe<SortOrderInput>;
  publicDiscipline?: InputMaybe<SortOrderInput>;
  publicName?: InputMaybe<SortOrderInput>;
  readArticles?: InputMaybe<UserArticleOrderByRelationAggregateInput>;
  registrationNumber?: InputMaybe<SortOrderInput>;
  regulatoryBody?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  stripeCustomerId?: InputMaybe<SortOrderInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionOrderByRelationAggregateInput>;
};

export type UserQuiz = {
  answers?: Maybe<Scalars['JSON']['output']>;
  category: Category;
  categoryId: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isCompleted: Scalars['Boolean']['output'];
  isPass: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  quiz: Quiz;
  quizId: Scalars['String']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  sourceMarkdown?: Maybe<Scalars['String']['output']>;
  user: User;
  userId: Scalars['String']['output'];
};

export type UserQuizAvgAggregate = {
  score?: Maybe<Scalars['Float']['output']>;
};

export type UserQuizAvgOrderByAggregateInput = {
  score?: InputMaybe<SortOrder>;
};

export type UserQuizCountAggregate = {
  _all: Scalars['Int']['output'];
  answers: Scalars['Int']['output'];
  categoryId: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isCompleted: Scalars['Int']['output'];
  isPass: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  quizId: Scalars['Int']['output'];
  score: Scalars['Int']['output'];
  sourceMarkdown: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserQuizCountOrderByAggregateInput = {
  answers?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  isPass?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  quizId?: InputMaybe<SortOrder>;
  score?: InputMaybe<SortOrder>;
  sourceMarkdown?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserQuizCreateInput = {
  answers?: InputMaybe<Scalars['JSON']['input']>;
  category: CategoryCreateNestedOneWithoutUserQuizzesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPass?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quiz: QuizCreateNestedOneWithoutAttemptedByInput;
  score?: InputMaybe<Scalars['Float']['input']>;
  sourceMarkdown?: InputMaybe<Scalars['String']['input']>;
  user: UserCreateNestedOneWithoutAttemptedQuizzesInput;
};

export type UserQuizCreateManyCategoryInput = {
  answers?: InputMaybe<Scalars['JSON']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPass?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quizId: Scalars['String']['input'];
  score?: InputMaybe<Scalars['Float']['input']>;
  sourceMarkdown?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserQuizCreateManyCategoryInputEnvelope = {
  data: Array<UserQuizCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserQuizCreateManyInput = {
  answers?: InputMaybe<Scalars['JSON']['input']>;
  categoryId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPass?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quizId: Scalars['String']['input'];
  score?: InputMaybe<Scalars['Float']['input']>;
  sourceMarkdown?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserQuizCreateManyQuizInput = {
  answers?: InputMaybe<Scalars['JSON']['input']>;
  categoryId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPass?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  sourceMarkdown?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserQuizCreateManyQuizInputEnvelope = {
  data: Array<UserQuizCreateManyQuizInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserQuizCreateManyUserInput = {
  answers?: InputMaybe<Scalars['JSON']['input']>;
  categoryId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPass?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quizId: Scalars['String']['input'];
  score?: InputMaybe<Scalars['Float']['input']>;
  sourceMarkdown?: InputMaybe<Scalars['String']['input']>;
};

export type UserQuizCreateManyUserInputEnvelope = {
  data: Array<UserQuizCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserQuizCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserQuizCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<UserQuizCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<UserQuizCreateManyCategoryInputEnvelope>;
};

export type UserQuizCreateNestedManyWithoutQuizInput = {
  connect?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserQuizCreateOrConnectWithoutQuizInput>>;
  create?: InputMaybe<Array<UserQuizCreateWithoutQuizInput>>;
  createMany?: InputMaybe<UserQuizCreateManyQuizInputEnvelope>;
};

export type UserQuizCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserQuizCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserQuizCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserQuizCreateManyUserInputEnvelope>;
};

export type UserQuizCreateOrConnectWithoutCategoryInput = {
  create: UserQuizCreateWithoutCategoryInput;
  where: UserQuizWhereUniqueInput;
};

export type UserQuizCreateOrConnectWithoutQuizInput = {
  create: UserQuizCreateWithoutQuizInput;
  where: UserQuizWhereUniqueInput;
};

export type UserQuizCreateOrConnectWithoutUserInput = {
  create: UserQuizCreateWithoutUserInput;
  where: UserQuizWhereUniqueInput;
};

export type UserQuizCreateWithoutCategoryInput = {
  answers?: InputMaybe<Scalars['JSON']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPass?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quiz: QuizCreateNestedOneWithoutAttemptedByInput;
  score?: InputMaybe<Scalars['Float']['input']>;
  sourceMarkdown?: InputMaybe<Scalars['String']['input']>;
  user: UserCreateNestedOneWithoutAttemptedQuizzesInput;
};

export type UserQuizCreateWithoutQuizInput = {
  answers?: InputMaybe<Scalars['JSON']['input']>;
  category: CategoryCreateNestedOneWithoutUserQuizzesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPass?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  sourceMarkdown?: InputMaybe<Scalars['String']['input']>;
  user: UserCreateNestedOneWithoutAttemptedQuizzesInput;
};

export type UserQuizCreateWithoutUserInput = {
  answers?: InputMaybe<Scalars['JSON']['input']>;
  category: CategoryCreateNestedOneWithoutUserQuizzesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPass?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quiz: QuizCreateNestedOneWithoutAttemptedByInput;
  score?: InputMaybe<Scalars['Float']['input']>;
  sourceMarkdown?: InputMaybe<Scalars['String']['input']>;
};

export type UserQuizGroupBy = {
  _avg?: Maybe<UserQuizAvgAggregate>;
  _count?: Maybe<UserQuizCountAggregate>;
  _max?: Maybe<UserQuizMaxAggregate>;
  _min?: Maybe<UserQuizMinAggregate>;
  _sum?: Maybe<UserQuizSumAggregate>;
  answers?: Maybe<Scalars['JSON']['output']>;
  categoryId: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isCompleted: Scalars['Boolean']['output'];
  isPass: Scalars['Boolean']['output'];
  modified: Scalars['DateTimeISO']['output'];
  quizId: Scalars['String']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  sourceMarkdown?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type UserQuizListRelationFilter = {
  every?: InputMaybe<UserQuizWhereInput>;
  none?: InputMaybe<UserQuizWhereInput>;
  some?: InputMaybe<UserQuizWhereInput>;
};

export type UserQuizMaxAggregate = {
  categoryId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  isPass?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  quizId?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  sourceMarkdown?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserQuizMaxOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  isPass?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  quizId?: InputMaybe<SortOrder>;
  score?: InputMaybe<SortOrder>;
  sourceMarkdown?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserQuizMinAggregate = {
  categoryId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  isPass?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  quizId?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  sourceMarkdown?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserQuizMinOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  isPass?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  quizId?: InputMaybe<SortOrder>;
  score?: InputMaybe<SortOrder>;
  sourceMarkdown?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserQuizOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserQuizOrderByWithAggregationInput = {
  _avg?: InputMaybe<UserQuizAvgOrderByAggregateInput>;
  _count?: InputMaybe<UserQuizCountOrderByAggregateInput>;
  _max?: InputMaybe<UserQuizMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserQuizMinOrderByAggregateInput>;
  _sum?: InputMaybe<UserQuizSumOrderByAggregateInput>;
  answers?: InputMaybe<SortOrderInput>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  isPass?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  quizId?: InputMaybe<SortOrder>;
  score?: InputMaybe<SortOrderInput>;
  sourceMarkdown?: InputMaybe<SortOrderInput>;
  userId?: InputMaybe<SortOrder>;
};

export type UserQuizOrderByWithRelationInput = {
  answers?: InputMaybe<SortOrderInput>;
  category?: InputMaybe<CategoryOrderByWithRelationInput>;
  categoryId?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  isPass?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  quiz?: InputMaybe<QuizOrderByWithRelationInput>;
  quizId?: InputMaybe<SortOrder>;
  score?: InputMaybe<SortOrderInput>;
  sourceMarkdown?: InputMaybe<SortOrderInput>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserQuizScalarFieldEnum {
  Answers = 'answers',
  CategoryId = 'categoryId',
  Created = 'created',
  Id = 'id',
  IsCompleted = 'isCompleted',
  IsPass = 'isPass',
  Modified = 'modified',
  QuizId = 'quizId',
  Score = 'score',
  SourceMarkdown = 'sourceMarkdown',
  UserId = 'userId'
}

export type UserQuizScalarWhereInput = {
  AND?: InputMaybe<Array<UserQuizScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserQuizScalarWhereInput>>;
  OR?: InputMaybe<Array<UserQuizScalarWhereInput>>;
  answers?: InputMaybe<JsonNullableFilter>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isCompleted?: InputMaybe<BoolFilter>;
  isPass?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  quizId?: InputMaybe<StringFilter>;
  score?: InputMaybe<FloatNullableFilter>;
  sourceMarkdown?: InputMaybe<StringNullableFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserQuizScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserQuizScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserQuizScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserQuizScalarWhereWithAggregatesInput>>;
  answers?: InputMaybe<JsonNullableWithAggregatesFilter>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isCompleted?: InputMaybe<BoolWithAggregatesFilter>;
  isPass?: InputMaybe<BoolWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  quizId?: InputMaybe<StringWithAggregatesFilter>;
  score?: InputMaybe<FloatNullableWithAggregatesFilter>;
  sourceMarkdown?: InputMaybe<StringNullableWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserQuizSumAggregate = {
  score?: Maybe<Scalars['Float']['output']>;
};

export type UserQuizSumOrderByAggregateInput = {
  score?: InputMaybe<SortOrder>;
};

export type UserQuizUpdateInput = {
  answers?: InputMaybe<Scalars['JSON']['input']>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutUserQuizzesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPass?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  quiz?: InputMaybe<QuizUpdateOneRequiredWithoutAttemptedByNestedInput>;
  score?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  sourceMarkdown?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAttemptedQuizzesNestedInput>;
};

export type UserQuizUpdateManyMutationInput = {
  answers?: InputMaybe<Scalars['JSON']['input']>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPass?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  score?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  sourceMarkdown?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserQuizUpdateManyWithWhereWithoutCategoryInput = {
  data: UserQuizUpdateManyMutationInput;
  where: UserQuizScalarWhereInput;
};

export type UserQuizUpdateManyWithWhereWithoutQuizInput = {
  data: UserQuizUpdateManyMutationInput;
  where: UserQuizScalarWhereInput;
};

export type UserQuizUpdateManyWithWhereWithoutUserInput = {
  data: UserQuizUpdateManyMutationInput;
  where: UserQuizScalarWhereInput;
};

export type UserQuizUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserQuizCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<UserQuizCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<UserQuizCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserQuizScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  set?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  update?: InputMaybe<Array<UserQuizUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<UserQuizUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<UserQuizUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type UserQuizUpdateManyWithoutQuizNestedInput = {
  connect?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserQuizCreateOrConnectWithoutQuizInput>>;
  create?: InputMaybe<Array<UserQuizCreateWithoutQuizInput>>;
  createMany?: InputMaybe<UserQuizCreateManyQuizInputEnvelope>;
  delete?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserQuizScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  set?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  update?: InputMaybe<Array<UserQuizUpdateWithWhereUniqueWithoutQuizInput>>;
  updateMany?: InputMaybe<Array<UserQuizUpdateManyWithWhereWithoutQuizInput>>;
  upsert?: InputMaybe<Array<UserQuizUpsertWithWhereUniqueWithoutQuizInput>>;
};

export type UserQuizUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserQuizCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserQuizCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserQuizCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserQuizScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  set?: InputMaybe<Array<UserQuizWhereUniqueInput>>;
  update?: InputMaybe<Array<UserQuizUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserQuizUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserQuizUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserQuizUpdateWithWhereUniqueWithoutCategoryInput = {
  data: UserQuizUpdateWithoutCategoryInput;
  where: UserQuizWhereUniqueInput;
};

export type UserQuizUpdateWithWhereUniqueWithoutQuizInput = {
  data: UserQuizUpdateWithoutQuizInput;
  where: UserQuizWhereUniqueInput;
};

export type UserQuizUpdateWithWhereUniqueWithoutUserInput = {
  data: UserQuizUpdateWithoutUserInput;
  where: UserQuizWhereUniqueInput;
};

export type UserQuizUpdateWithoutCategoryInput = {
  answers?: InputMaybe<Scalars['JSON']['input']>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPass?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  quiz?: InputMaybe<QuizUpdateOneRequiredWithoutAttemptedByNestedInput>;
  score?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  sourceMarkdown?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAttemptedQuizzesNestedInput>;
};

export type UserQuizUpdateWithoutQuizInput = {
  answers?: InputMaybe<Scalars['JSON']['input']>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutUserQuizzesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPass?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  score?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  sourceMarkdown?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAttemptedQuizzesNestedInput>;
};

export type UserQuizUpdateWithoutUserInput = {
  answers?: InputMaybe<Scalars['JSON']['input']>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutUserQuizzesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPass?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  quiz?: InputMaybe<QuizUpdateOneRequiredWithoutAttemptedByNestedInput>;
  score?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  sourceMarkdown?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserQuizUpsertWithWhereUniqueWithoutCategoryInput = {
  create: UserQuizCreateWithoutCategoryInput;
  update: UserQuizUpdateWithoutCategoryInput;
  where: UserQuizWhereUniqueInput;
};

export type UserQuizUpsertWithWhereUniqueWithoutQuizInput = {
  create: UserQuizCreateWithoutQuizInput;
  update: UserQuizUpdateWithoutQuizInput;
  where: UserQuizWhereUniqueInput;
};

export type UserQuizUpsertWithWhereUniqueWithoutUserInput = {
  create: UserQuizCreateWithoutUserInput;
  update: UserQuizUpdateWithoutUserInput;
  where: UserQuizWhereUniqueInput;
};

export type UserQuizWhereInput = {
  AND?: InputMaybe<Array<UserQuizWhereInput>>;
  NOT?: InputMaybe<Array<UserQuizWhereInput>>;
  OR?: InputMaybe<Array<UserQuizWhereInput>>;
  answers?: InputMaybe<JsonNullableFilter>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isCompleted?: InputMaybe<BoolFilter>;
  isPass?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  quiz?: InputMaybe<QuizRelationFilter>;
  quizId?: InputMaybe<StringFilter>;
  score?: InputMaybe<FloatNullableFilter>;
  sourceMarkdown?: InputMaybe<StringNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserQuizWhereUniqueInput = {
  AND?: InputMaybe<Array<UserQuizWhereInput>>;
  NOT?: InputMaybe<Array<UserQuizWhereInput>>;
  OR?: InputMaybe<Array<UserQuizWhereInput>>;
  answers?: InputMaybe<JsonNullableFilter>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<BoolFilter>;
  isPass?: InputMaybe<BoolFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  quiz?: InputMaybe<QuizRelationFilter>;
  quizId?: InputMaybe<StringFilter>;
  score?: InputMaybe<FloatNullableFilter>;
  sourceMarkdown?: InputMaybe<StringNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserResource = {
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  resource: Resource;
  resourceId: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserResourceCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  resourceId: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserResourceCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserResourceCreateInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  resource: ResourceCreateNestedOneWithoutDownloadedByInput;
  user: UserCreateNestedOneWithoutDownloadedResourcesInput;
};

export type UserResourceCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  resourceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserResourceCreateManyResourceInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  userId: Scalars['String']['input'];
};

export type UserResourceCreateManyResourceInputEnvelope = {
  data: Array<UserResourceCreateManyResourceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserResourceCreateManyUserInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  resourceId: Scalars['String']['input'];
};

export type UserResourceCreateManyUserInputEnvelope = {
  data: Array<UserResourceCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserResourceCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<UserResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserResourceCreateOrConnectWithoutResourceInput>>;
  create?: InputMaybe<Array<UserResourceCreateWithoutResourceInput>>;
  createMany?: InputMaybe<UserResourceCreateManyResourceInputEnvelope>;
};

export type UserResourceCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserResourceCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserResourceCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserResourceCreateManyUserInputEnvelope>;
};

export type UserResourceCreateOrConnectWithoutResourceInput = {
  create: UserResourceCreateWithoutResourceInput;
  where: UserResourceWhereUniqueInput;
};

export type UserResourceCreateOrConnectWithoutUserInput = {
  create: UserResourceCreateWithoutUserInput;
  where: UserResourceWhereUniqueInput;
};

export type UserResourceCreateWithoutResourceInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  user: UserCreateNestedOneWithoutDownloadedResourcesInput;
};

export type UserResourceCreateWithoutUserInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  resource: ResourceCreateNestedOneWithoutDownloadedByInput;
};

export type UserResourceGroupBy = {
  _count?: Maybe<UserResourceCountAggregate>;
  _max?: Maybe<UserResourceMaxAggregate>;
  _min?: Maybe<UserResourceMinAggregate>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  resourceId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type UserResourceListRelationFilter = {
  every?: InputMaybe<UserResourceWhereInput>;
  none?: InputMaybe<UserResourceWhereInput>;
  some?: InputMaybe<UserResourceWhereInput>;
};

export type UserResourceMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserResourceMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserResourceMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserResourceMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserResourceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserResourceOrderByWithAggregationInput = {
  _count?: InputMaybe<UserResourceCountOrderByAggregateInput>;
  _max?: InputMaybe<UserResourceMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserResourceMinOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resourceId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserResourceOrderByWithRelationInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  resource?: InputMaybe<ResourceOrderByWithRelationInput>;
  resourceId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserResourceScalarFieldEnum {
  Created = 'created',
  Id = 'id',
  Modified = 'modified',
  ResourceId = 'resourceId',
  UserId = 'userId'
}

export type UserResourceScalarWhereInput = {
  AND?: InputMaybe<Array<UserResourceScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserResourceScalarWhereInput>>;
  OR?: InputMaybe<Array<UserResourceScalarWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  resourceId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserResourceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserResourceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserResourceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserResourceScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  resourceId?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserResourceUpdateInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutDownloadedByNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDownloadedResourcesNestedInput>;
};

export type UserResourceUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserResourceUpdateManyWithWhereWithoutResourceInput = {
  data: UserResourceUpdateManyMutationInput;
  where: UserResourceScalarWhereInput;
};

export type UserResourceUpdateManyWithWhereWithoutUserInput = {
  data: UserResourceUpdateManyMutationInput;
  where: UserResourceScalarWhereInput;
};

export type UserResourceUpdateManyWithoutResourceNestedInput = {
  connect?: InputMaybe<Array<UserResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserResourceCreateOrConnectWithoutResourceInput>>;
  create?: InputMaybe<Array<UserResourceCreateWithoutResourceInput>>;
  createMany?: InputMaybe<UserResourceCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<UserResourceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserResourceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserResourceWhereUniqueInput>>;
  set?: InputMaybe<Array<UserResourceWhereUniqueInput>>;
  update?: InputMaybe<Array<UserResourceUpdateWithWhereUniqueWithoutResourceInput>>;
  updateMany?: InputMaybe<Array<UserResourceUpdateManyWithWhereWithoutResourceInput>>;
  upsert?: InputMaybe<Array<UserResourceUpsertWithWhereUniqueWithoutResourceInput>>;
};

export type UserResourceUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserResourceCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserResourceCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserResourceCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserResourceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserResourceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserResourceWhereUniqueInput>>;
  set?: InputMaybe<Array<UserResourceWhereUniqueInput>>;
  update?: InputMaybe<Array<UserResourceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserResourceUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserResourceUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserResourceUpdateWithWhereUniqueWithoutResourceInput = {
  data: UserResourceUpdateWithoutResourceInput;
  where: UserResourceWhereUniqueInput;
};

export type UserResourceUpdateWithWhereUniqueWithoutUserInput = {
  data: UserResourceUpdateWithoutUserInput;
  where: UserResourceWhereUniqueInput;
};

export type UserResourceUpdateWithoutResourceInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDownloadedResourcesNestedInput>;
};

export type UserResourceUpdateWithoutUserInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutDownloadedByNestedInput>;
};

export type UserResourceUpsertWithWhereUniqueWithoutResourceInput = {
  create: UserResourceCreateWithoutResourceInput;
  update: UserResourceUpdateWithoutResourceInput;
  where: UserResourceWhereUniqueInput;
};

export type UserResourceUpsertWithWhereUniqueWithoutUserInput = {
  create: UserResourceCreateWithoutUserInput;
  update: UserResourceUpdateWithoutUserInput;
  where: UserResourceWhereUniqueInput;
};

export type UserResourceUserResourceCompoundUniqueInput = {
  created: Scalars['DateTimeISO']['input'];
  resourceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserResourceWhereInput = {
  AND?: InputMaybe<Array<UserResourceWhereInput>>;
  NOT?: InputMaybe<Array<UserResourceWhereInput>>;
  OR?: InputMaybe<Array<UserResourceWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  resource?: InputMaybe<ResourceRelationFilter>;
  resourceId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserResourceWhereUniqueInput = {
  AND?: InputMaybe<Array<UserResourceWhereInput>>;
  NOT?: InputMaybe<Array<UserResourceWhereInput>>;
  OR?: InputMaybe<Array<UserResourceWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  resource?: InputMaybe<ResourceRelationFilter>;
  resourceId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  userResource?: InputMaybe<UserResourceUserResourceCompoundUniqueInput>;
};

export enum UserScalarFieldEnum {
  BillingAddress = 'billingAddress',
  BillingCountryCode = 'billingCountryCode',
  Created = 'created',
  DisplayName = 'displayName',
  Email = 'email',
  ExternalId = 'externalId',
  HealthDiscipline = 'healthDiscipline',
  HowDidYouHear = 'howDidYouHear',
  Id = 'id',
  IsEnabled = 'isEnabled',
  IsPublicEnabled = 'isPublicEnabled',
  Modified = 'modified',
  PhotoUrl = 'photoUrl',
  PublicAddresses = 'publicAddresses',
  PublicDiscipline = 'publicDiscipline',
  PublicName = 'publicName',
  RegistrationNumber = 'registrationNumber',
  RegulatoryBody = 'regulatoryBody',
  Role = 'role',
  StripeCustomerId = 'stripeCustomerId'
}

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  billingAddress?: InputMaybe<JsonNullableWithAggregatesFilter>;
  billingCountryCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  displayName?: InputMaybe<StringNullableWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  externalId?: InputMaybe<StringWithAggregatesFilter>;
  healthDiscipline?: InputMaybe<StringNullableWithAggregatesFilter>;
  howDidYouHear?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isEnabled?: InputMaybe<BoolWithAggregatesFilter>;
  isPublicEnabled?: InputMaybe<BoolWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  photoUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  publicAddresses?: InputMaybe<JsonNullableWithAggregatesFilter>;
  publicDiscipline?: InputMaybe<StringNullableWithAggregatesFilter>;
  publicName?: InputMaybe<StringNullableWithAggregatesFilter>;
  registrationNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  regulatoryBody?: InputMaybe<StringNullableWithAggregatesFilter>;
  role?: InputMaybe<EnumRoleWithAggregatesFilter>;
  stripeCustomerId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type UserSubscription = {
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  notifiedExpired: Scalars['Boolean']['output'];
  notifiedExpiringSoon: Scalars['Boolean']['output'];
  notifiedRenewalFailed: Scalars['Boolean']['output'];
  notifiedRenewed: Scalars['Boolean']['output'];
  productSubscriptionType: ProductSubscriptionType;
  productSubscriptionTypeId: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
  validFrom: Scalars['DateTimeISO']['output'];
  validTo?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type UserSubscriptionCountAggregate = {
  _all: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modified: Scalars['Int']['output'];
  notifiedExpired: Scalars['Int']['output'];
  notifiedExpiringSoon: Scalars['Int']['output'];
  notifiedRenewalFailed: Scalars['Int']['output'];
  notifiedRenewed: Scalars['Int']['output'];
  productSubscriptionTypeId: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
  validFrom: Scalars['Int']['output'];
  validTo: Scalars['Int']['output'];
};

export type UserSubscriptionCountOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  notifiedExpired?: InputMaybe<SortOrder>;
  notifiedExpiringSoon?: InputMaybe<SortOrder>;
  notifiedRenewalFailed?: InputMaybe<SortOrder>;
  notifiedRenewed?: InputMaybe<SortOrder>;
  productSubscriptionTypeId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  validFrom?: InputMaybe<SortOrder>;
  validTo?: InputMaybe<SortOrder>;
};

export type UserSubscriptionCreateInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  notifiedExpired?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedExpiringSoon?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedRenewalFailed?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedRenewed?: InputMaybe<Scalars['Boolean']['input']>;
  productSubscriptionType: ProductSubscriptionTypeCreateNestedOneWithoutUserSubscriptionsInput;
  user: UserCreateNestedOneWithoutUserSubscriptionsInput;
  validFrom?: InputMaybe<Scalars['DateTimeISO']['input']>;
  validTo?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type UserSubscriptionCreateManyInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  notifiedExpired?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedExpiringSoon?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedRenewalFailed?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedRenewed?: InputMaybe<Scalars['Boolean']['input']>;
  productSubscriptionTypeId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  validFrom?: InputMaybe<Scalars['DateTimeISO']['input']>;
  validTo?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type UserSubscriptionCreateManyProductSubscriptionTypeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  notifiedExpired?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedExpiringSoon?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedRenewalFailed?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedRenewed?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['String']['input'];
  validFrom?: InputMaybe<Scalars['DateTimeISO']['input']>;
  validTo?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type UserSubscriptionCreateManyProductSubscriptionTypeInputEnvelope = {
  data: Array<UserSubscriptionCreateManyProductSubscriptionTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSubscriptionCreateManyUserInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  notifiedExpired?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedExpiringSoon?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedRenewalFailed?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedRenewed?: InputMaybe<Scalars['Boolean']['input']>;
  productSubscriptionTypeId: Scalars['String']['input'];
  validFrom?: InputMaybe<Scalars['DateTimeISO']['input']>;
  validTo?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type UserSubscriptionCreateManyUserInputEnvelope = {
  data: Array<UserSubscriptionCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSubscriptionCreateNestedManyWithoutProductSubscriptionTypeInput = {
  connect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSubscriptionCreateOrConnectWithoutProductSubscriptionTypeInput>>;
  create?: InputMaybe<Array<UserSubscriptionCreateWithoutProductSubscriptionTypeInput>>;
  createMany?: InputMaybe<UserSubscriptionCreateManyProductSubscriptionTypeInputEnvelope>;
};

export type UserSubscriptionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSubscriptionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserSubscriptionCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserSubscriptionCreateManyUserInputEnvelope>;
};

export type UserSubscriptionCreateOrConnectWithoutProductSubscriptionTypeInput = {
  create: UserSubscriptionCreateWithoutProductSubscriptionTypeInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionCreateOrConnectWithoutUserInput = {
  create: UserSubscriptionCreateWithoutUserInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionCreateWithoutProductSubscriptionTypeInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  notifiedExpired?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedExpiringSoon?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedRenewalFailed?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedRenewed?: InputMaybe<Scalars['Boolean']['input']>;
  user: UserCreateNestedOneWithoutUserSubscriptionsInput;
  validFrom?: InputMaybe<Scalars['DateTimeISO']['input']>;
  validTo?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type UserSubscriptionCreateWithoutUserInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTimeISO']['input']>;
  notifiedExpired?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedExpiringSoon?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedRenewalFailed?: InputMaybe<Scalars['Boolean']['input']>;
  notifiedRenewed?: InputMaybe<Scalars['Boolean']['input']>;
  productSubscriptionType: ProductSubscriptionTypeCreateNestedOneWithoutUserSubscriptionsInput;
  validFrom?: InputMaybe<Scalars['DateTimeISO']['input']>;
  validTo?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type UserSubscriptionGroupBy = {
  _count?: Maybe<UserSubscriptionCountAggregate>;
  _max?: Maybe<UserSubscriptionMaxAggregate>;
  _min?: Maybe<UserSubscriptionMinAggregate>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  modified: Scalars['DateTimeISO']['output'];
  notifiedExpired: Scalars['Boolean']['output'];
  notifiedExpiringSoon: Scalars['Boolean']['output'];
  notifiedRenewalFailed: Scalars['Boolean']['output'];
  notifiedRenewed: Scalars['Boolean']['output'];
  productSubscriptionTypeId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  validFrom: Scalars['DateTimeISO']['output'];
  validTo?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type UserSubscriptionListRelationFilter = {
  every?: InputMaybe<UserSubscriptionWhereInput>;
  none?: InputMaybe<UserSubscriptionWhereInput>;
  some?: InputMaybe<UserSubscriptionWhereInput>;
};

export type UserSubscriptionMaxAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  notifiedExpired?: Maybe<Scalars['Boolean']['output']>;
  notifiedExpiringSoon?: Maybe<Scalars['Boolean']['output']>;
  notifiedRenewalFailed?: Maybe<Scalars['Boolean']['output']>;
  notifiedRenewed?: Maybe<Scalars['Boolean']['output']>;
  productSubscriptionTypeId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['DateTimeISO']['output']>;
  validTo?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type UserSubscriptionMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  notifiedExpired?: InputMaybe<SortOrder>;
  notifiedExpiringSoon?: InputMaybe<SortOrder>;
  notifiedRenewalFailed?: InputMaybe<SortOrder>;
  notifiedRenewed?: InputMaybe<SortOrder>;
  productSubscriptionTypeId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  validFrom?: InputMaybe<SortOrder>;
  validTo?: InputMaybe<SortOrder>;
};

export type UserSubscriptionMinAggregate = {
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTimeISO']['output']>;
  notifiedExpired?: Maybe<Scalars['Boolean']['output']>;
  notifiedExpiringSoon?: Maybe<Scalars['Boolean']['output']>;
  notifiedRenewalFailed?: Maybe<Scalars['Boolean']['output']>;
  notifiedRenewed?: Maybe<Scalars['Boolean']['output']>;
  productSubscriptionTypeId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['DateTimeISO']['output']>;
  validTo?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type UserSubscriptionMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  notifiedExpired?: InputMaybe<SortOrder>;
  notifiedExpiringSoon?: InputMaybe<SortOrder>;
  notifiedRenewalFailed?: InputMaybe<SortOrder>;
  notifiedRenewed?: InputMaybe<SortOrder>;
  productSubscriptionTypeId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  validFrom?: InputMaybe<SortOrder>;
  validTo?: InputMaybe<SortOrder>;
};

export type UserSubscriptionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserSubscriptionOrderByWithAggregationInput = {
  _count?: InputMaybe<UserSubscriptionCountOrderByAggregateInput>;
  _max?: InputMaybe<UserSubscriptionMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserSubscriptionMinOrderByAggregateInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  notifiedExpired?: InputMaybe<SortOrder>;
  notifiedExpiringSoon?: InputMaybe<SortOrder>;
  notifiedRenewalFailed?: InputMaybe<SortOrder>;
  notifiedRenewed?: InputMaybe<SortOrder>;
  productSubscriptionTypeId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  validFrom?: InputMaybe<SortOrder>;
  validTo?: InputMaybe<SortOrderInput>;
};

export type UserSubscriptionOrderByWithRelationInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modified?: InputMaybe<SortOrder>;
  notifiedExpired?: InputMaybe<SortOrder>;
  notifiedExpiringSoon?: InputMaybe<SortOrder>;
  notifiedRenewalFailed?: InputMaybe<SortOrder>;
  notifiedRenewed?: InputMaybe<SortOrder>;
  productSubscriptionType?: InputMaybe<ProductSubscriptionTypeOrderByWithRelationInput>;
  productSubscriptionTypeId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  validFrom?: InputMaybe<SortOrder>;
  validTo?: InputMaybe<SortOrderInput>;
};

export enum UserSubscriptionScalarFieldEnum {
  Created = 'created',
  Id = 'id',
  Modified = 'modified',
  NotifiedExpired = 'notifiedExpired',
  NotifiedExpiringSoon = 'notifiedExpiringSoon',
  NotifiedRenewalFailed = 'notifiedRenewalFailed',
  NotifiedRenewed = 'notifiedRenewed',
  ProductSubscriptionTypeId = 'productSubscriptionTypeId',
  UserId = 'userId',
  ValidFrom = 'validFrom',
  ValidTo = 'validTo'
}

export type UserSubscriptionScalarWhereInput = {
  AND?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  OR?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  notifiedExpired?: InputMaybe<BoolFilter>;
  notifiedExpiringSoon?: InputMaybe<BoolFilter>;
  notifiedRenewalFailed?: InputMaybe<BoolFilter>;
  notifiedRenewed?: InputMaybe<BoolFilter>;
  productSubscriptionTypeId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
  validFrom?: InputMaybe<DateTimeFilter>;
  validTo?: InputMaybe<DateTimeNullableFilter>;
};

export type UserSubscriptionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserSubscriptionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserSubscriptionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserSubscriptionScalarWhereWithAggregatesInput>>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  modified?: InputMaybe<DateTimeWithAggregatesFilter>;
  notifiedExpired?: InputMaybe<BoolWithAggregatesFilter>;
  notifiedExpiringSoon?: InputMaybe<BoolWithAggregatesFilter>;
  notifiedRenewalFailed?: InputMaybe<BoolWithAggregatesFilter>;
  notifiedRenewed?: InputMaybe<BoolWithAggregatesFilter>;
  productSubscriptionTypeId?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
  validFrom?: InputMaybe<DateTimeWithAggregatesFilter>;
  validTo?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type UserSubscriptionUpdateInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  notifiedExpired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifiedExpiringSoon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifiedRenewalFailed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifiedRenewed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  productSubscriptionType?: InputMaybe<ProductSubscriptionTypeUpdateOneRequiredWithoutUserSubscriptionsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserSubscriptionsNestedInput>;
  validFrom?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  validTo?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserSubscriptionUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  notifiedExpired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifiedExpiringSoon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifiedRenewalFailed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifiedRenewed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  validFrom?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  validTo?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserSubscriptionUpdateManyWithWhereWithoutProductSubscriptionTypeInput = {
  data: UserSubscriptionUpdateManyMutationInput;
  where: UserSubscriptionScalarWhereInput;
};

export type UserSubscriptionUpdateManyWithWhereWithoutUserInput = {
  data: UserSubscriptionUpdateManyMutationInput;
  where: UserSubscriptionScalarWhereInput;
};

export type UserSubscriptionUpdateManyWithoutProductSubscriptionTypeNestedInput = {
  connect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSubscriptionCreateOrConnectWithoutProductSubscriptionTypeInput>>;
  create?: InputMaybe<Array<UserSubscriptionCreateWithoutProductSubscriptionTypeInput>>;
  createMany?: InputMaybe<UserSubscriptionCreateManyProductSubscriptionTypeInputEnvelope>;
  delete?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSubscriptionUpdateWithWhereUniqueWithoutProductSubscriptionTypeInput>>;
  updateMany?: InputMaybe<Array<UserSubscriptionUpdateManyWithWhereWithoutProductSubscriptionTypeInput>>;
  upsert?: InputMaybe<Array<UserSubscriptionUpsertWithWhereUniqueWithoutProductSubscriptionTypeInput>>;
};

export type UserSubscriptionUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSubscriptionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserSubscriptionCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserSubscriptionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSubscriptionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserSubscriptionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserSubscriptionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserSubscriptionUpdateWithWhereUniqueWithoutProductSubscriptionTypeInput = {
  data: UserSubscriptionUpdateWithoutProductSubscriptionTypeInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionUpdateWithWhereUniqueWithoutUserInput = {
  data: UserSubscriptionUpdateWithoutUserInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionUpdateWithoutProductSubscriptionTypeInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  notifiedExpired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifiedExpiringSoon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifiedRenewalFailed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifiedRenewed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserSubscriptionsNestedInput>;
  validFrom?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  validTo?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserSubscriptionUpdateWithoutUserInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  notifiedExpired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifiedExpiringSoon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifiedRenewalFailed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifiedRenewed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  productSubscriptionType?: InputMaybe<ProductSubscriptionTypeUpdateOneRequiredWithoutUserSubscriptionsNestedInput>;
  validFrom?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  validTo?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserSubscriptionUpsertWithWhereUniqueWithoutProductSubscriptionTypeInput = {
  create: UserSubscriptionCreateWithoutProductSubscriptionTypeInput;
  update: UserSubscriptionUpdateWithoutProductSubscriptionTypeInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionUpsertWithWhereUniqueWithoutUserInput = {
  create: UserSubscriptionCreateWithoutUserInput;
  update: UserSubscriptionUpdateWithoutUserInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionWhereInput = {
  AND?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  NOT?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  OR?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  notifiedExpired?: InputMaybe<BoolFilter>;
  notifiedExpiringSoon?: InputMaybe<BoolFilter>;
  notifiedRenewalFailed?: InputMaybe<BoolFilter>;
  notifiedRenewed?: InputMaybe<BoolFilter>;
  productSubscriptionType?: InputMaybe<ProductSubscriptionTypeRelationFilter>;
  productSubscriptionTypeId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  validFrom?: InputMaybe<DateTimeFilter>;
  validTo?: InputMaybe<DateTimeNullableFilter>;
};

export type UserSubscriptionWhereUniqueInput = {
  AND?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  NOT?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  OR?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateTimeFilter>;
  notifiedExpired?: InputMaybe<BoolFilter>;
  notifiedExpiringSoon?: InputMaybe<BoolFilter>;
  notifiedRenewalFailed?: InputMaybe<BoolFilter>;
  notifiedRenewed?: InputMaybe<BoolFilter>;
  productSubscriptionType?: InputMaybe<ProductSubscriptionTypeRelationFilter>;
  productSubscriptionTypeId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  validFrom?: InputMaybe<DateTimeFilter>;
  validTo?: InputMaybe<DateTimeNullableFilter>;
};

export type UserUpdateInput = {
  attemptedQuizzes?: InputMaybe<UserQuizUpdateManyWithoutUserNestedInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  downloadedResources?: InputMaybe<UserResourceUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventAttendances?: InputMaybe<EventAttendanceUpdateManyWithoutUserNestedInput>;
  eventRegistrations?: InputMaybe<EventRegistrationUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  healthDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  howDidYouHear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublicEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  manualActivities?: InputMaybe<UserManualActivityUpdateManyWithoutUserNestedInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payments?: InputMaybe<PaymentUpdateManyWithoutUserNestedInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionUpdateManyWithoutUserNestedInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  readArticles?: InputMaybe<UserArticleUpdateManyWithoutUserNestedInput>;
  registrationNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  regulatoryBody?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  stripeCustomerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateManyMutationInput = {
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  healthDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  howDidYouHear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublicEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  regulatoryBody?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  stripeCustomerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateOneRequiredWithoutAttemptedQuizzesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAttemptedQuizzesInput>;
  create?: InputMaybe<UserCreateWithoutAttemptedQuizzesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutAttemptedQuizzesInput>;
  upsert?: InputMaybe<UserUpsertWithoutAttemptedQuizzesInput>;
};

export type UserUpdateOneRequiredWithoutDownloadedResourcesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDownloadedResourcesInput>;
  create?: InputMaybe<UserCreateWithoutDownloadedResourcesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutDownloadedResourcesInput>;
  upsert?: InputMaybe<UserUpsertWithoutDownloadedResourcesInput>;
};

export type UserUpdateOneRequiredWithoutEventAttendancesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEventAttendancesInput>;
  create?: InputMaybe<UserCreateWithoutEventAttendancesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutEventAttendancesInput>;
  upsert?: InputMaybe<UserUpsertWithoutEventAttendancesInput>;
};

export type UserUpdateOneRequiredWithoutEventRegistrationsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEventRegistrationsInput>;
  create?: InputMaybe<UserCreateWithoutEventRegistrationsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutEventRegistrationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutEventRegistrationsInput>;
};

export type UserUpdateOneRequiredWithoutManualActivitiesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutManualActivitiesInput>;
  create?: InputMaybe<UserCreateWithoutManualActivitiesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutManualActivitiesInput>;
  upsert?: InputMaybe<UserUpsertWithoutManualActivitiesInput>;
};

export type UserUpdateOneRequiredWithoutPaymentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPaymentsInput>;
  create?: InputMaybe<UserCreateWithoutPaymentsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutPaymentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutPaymentsInput>;
};

export type UserUpdateOneRequiredWithoutProgrammeProgressionsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProgrammeProgressionsInput>;
  create?: InputMaybe<UserCreateWithoutProgrammeProgressionsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutProgrammeProgressionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutProgrammeProgressionsInput>;
};

export type UserUpdateOneRequiredWithoutReadArticlesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReadArticlesInput>;
  create?: InputMaybe<UserCreateWithoutReadArticlesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutReadArticlesInput>;
  upsert?: InputMaybe<UserUpsertWithoutReadArticlesInput>;
};

export type UserUpdateOneRequiredWithoutUserSubscriptionsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserSubscriptionsInput>;
  create?: InputMaybe<UserCreateWithoutUserSubscriptionsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutUserSubscriptionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserSubscriptionsInput>;
};

export type UserUpdateToOneWithWhereWithoutAttemptedQuizzesInput = {
  data: UserUpdateWithoutAttemptedQuizzesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutDownloadedResourcesInput = {
  data: UserUpdateWithoutDownloadedResourcesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutEventAttendancesInput = {
  data: UserUpdateWithoutEventAttendancesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutEventRegistrationsInput = {
  data: UserUpdateWithoutEventRegistrationsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutManualActivitiesInput = {
  data: UserUpdateWithoutManualActivitiesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutPaymentsInput = {
  data: UserUpdateWithoutPaymentsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutProgrammeProgressionsInput = {
  data: UserUpdateWithoutProgrammeProgressionsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutReadArticlesInput = {
  data: UserUpdateWithoutReadArticlesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutUserSubscriptionsInput = {
  data: UserUpdateWithoutUserSubscriptionsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateWithoutAttemptedQuizzesInput = {
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  downloadedResources?: InputMaybe<UserResourceUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventAttendances?: InputMaybe<EventAttendanceUpdateManyWithoutUserNestedInput>;
  eventRegistrations?: InputMaybe<EventRegistrationUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  healthDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  howDidYouHear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublicEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  manualActivities?: InputMaybe<UserManualActivityUpdateManyWithoutUserNestedInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payments?: InputMaybe<PaymentUpdateManyWithoutUserNestedInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionUpdateManyWithoutUserNestedInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  readArticles?: InputMaybe<UserArticleUpdateManyWithoutUserNestedInput>;
  registrationNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  regulatoryBody?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  stripeCustomerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutDownloadedResourcesInput = {
  attemptedQuizzes?: InputMaybe<UserQuizUpdateManyWithoutUserNestedInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventAttendances?: InputMaybe<EventAttendanceUpdateManyWithoutUserNestedInput>;
  eventRegistrations?: InputMaybe<EventRegistrationUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  healthDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  howDidYouHear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublicEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  manualActivities?: InputMaybe<UserManualActivityUpdateManyWithoutUserNestedInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payments?: InputMaybe<PaymentUpdateManyWithoutUserNestedInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionUpdateManyWithoutUserNestedInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  readArticles?: InputMaybe<UserArticleUpdateManyWithoutUserNestedInput>;
  registrationNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  regulatoryBody?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  stripeCustomerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutEventAttendancesInput = {
  attemptedQuizzes?: InputMaybe<UserQuizUpdateManyWithoutUserNestedInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  downloadedResources?: InputMaybe<UserResourceUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventRegistrations?: InputMaybe<EventRegistrationUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  healthDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  howDidYouHear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublicEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  manualActivities?: InputMaybe<UserManualActivityUpdateManyWithoutUserNestedInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payments?: InputMaybe<PaymentUpdateManyWithoutUserNestedInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionUpdateManyWithoutUserNestedInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  readArticles?: InputMaybe<UserArticleUpdateManyWithoutUserNestedInput>;
  registrationNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  regulatoryBody?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  stripeCustomerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutEventRegistrationsInput = {
  attemptedQuizzes?: InputMaybe<UserQuizUpdateManyWithoutUserNestedInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  downloadedResources?: InputMaybe<UserResourceUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventAttendances?: InputMaybe<EventAttendanceUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  healthDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  howDidYouHear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublicEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  manualActivities?: InputMaybe<UserManualActivityUpdateManyWithoutUserNestedInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payments?: InputMaybe<PaymentUpdateManyWithoutUserNestedInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionUpdateManyWithoutUserNestedInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  readArticles?: InputMaybe<UserArticleUpdateManyWithoutUserNestedInput>;
  registrationNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  regulatoryBody?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  stripeCustomerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutManualActivitiesInput = {
  attemptedQuizzes?: InputMaybe<UserQuizUpdateManyWithoutUserNestedInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  downloadedResources?: InputMaybe<UserResourceUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventAttendances?: InputMaybe<EventAttendanceUpdateManyWithoutUserNestedInput>;
  eventRegistrations?: InputMaybe<EventRegistrationUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  healthDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  howDidYouHear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublicEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payments?: InputMaybe<PaymentUpdateManyWithoutUserNestedInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionUpdateManyWithoutUserNestedInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  readArticles?: InputMaybe<UserArticleUpdateManyWithoutUserNestedInput>;
  registrationNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  regulatoryBody?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  stripeCustomerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutPaymentsInput = {
  attemptedQuizzes?: InputMaybe<UserQuizUpdateManyWithoutUserNestedInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  downloadedResources?: InputMaybe<UserResourceUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventAttendances?: InputMaybe<EventAttendanceUpdateManyWithoutUserNestedInput>;
  eventRegistrations?: InputMaybe<EventRegistrationUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  healthDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  howDidYouHear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublicEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  manualActivities?: InputMaybe<UserManualActivityUpdateManyWithoutUserNestedInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionUpdateManyWithoutUserNestedInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  readArticles?: InputMaybe<UserArticleUpdateManyWithoutUserNestedInput>;
  registrationNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  regulatoryBody?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  stripeCustomerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutProgrammeProgressionsInput = {
  attemptedQuizzes?: InputMaybe<UserQuizUpdateManyWithoutUserNestedInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  downloadedResources?: InputMaybe<UserResourceUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventAttendances?: InputMaybe<EventAttendanceUpdateManyWithoutUserNestedInput>;
  eventRegistrations?: InputMaybe<EventRegistrationUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  healthDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  howDidYouHear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublicEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  manualActivities?: InputMaybe<UserManualActivityUpdateManyWithoutUserNestedInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payments?: InputMaybe<PaymentUpdateManyWithoutUserNestedInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  readArticles?: InputMaybe<UserArticleUpdateManyWithoutUserNestedInput>;
  registrationNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  regulatoryBody?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  stripeCustomerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutReadArticlesInput = {
  attemptedQuizzes?: InputMaybe<UserQuizUpdateManyWithoutUserNestedInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  downloadedResources?: InputMaybe<UserResourceUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventAttendances?: InputMaybe<EventAttendanceUpdateManyWithoutUserNestedInput>;
  eventRegistrations?: InputMaybe<EventRegistrationUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  healthDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  howDidYouHear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublicEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  manualActivities?: InputMaybe<UserManualActivityUpdateManyWithoutUserNestedInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payments?: InputMaybe<PaymentUpdateManyWithoutUserNestedInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionUpdateManyWithoutUserNestedInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  regulatoryBody?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  stripeCustomerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutUserSubscriptionsInput = {
  attemptedQuizzes?: InputMaybe<UserQuizUpdateManyWithoutUserNestedInput>;
  billingAddress?: InputMaybe<Scalars['JSON']['input']>;
  billingCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  downloadedResources?: InputMaybe<UserResourceUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventAttendances?: InputMaybe<EventAttendanceUpdateManyWithoutUserNestedInput>;
  eventRegistrations?: InputMaybe<EventRegistrationUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  healthDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  howDidYouHear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublicEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  manualActivities?: InputMaybe<UserManualActivityUpdateManyWithoutUserNestedInput>;
  modified?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payments?: InputMaybe<PaymentUpdateManyWithoutUserNestedInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionUpdateManyWithoutUserNestedInput>;
  publicAddresses?: InputMaybe<Scalars['JSON']['input']>;
  publicDiscipline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  readArticles?: InputMaybe<UserArticleUpdateManyWithoutUserNestedInput>;
  registrationNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  regulatoryBody?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  stripeCustomerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpsertWithoutAttemptedQuizzesInput = {
  create: UserCreateWithoutAttemptedQuizzesInput;
  update: UserUpdateWithoutAttemptedQuizzesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutDownloadedResourcesInput = {
  create: UserCreateWithoutDownloadedResourcesInput;
  update: UserUpdateWithoutDownloadedResourcesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutEventAttendancesInput = {
  create: UserCreateWithoutEventAttendancesInput;
  update: UserUpdateWithoutEventAttendancesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutEventRegistrationsInput = {
  create: UserCreateWithoutEventRegistrationsInput;
  update: UserUpdateWithoutEventRegistrationsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutManualActivitiesInput = {
  create: UserCreateWithoutManualActivitiesInput;
  update: UserUpdateWithoutManualActivitiesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutPaymentsInput = {
  create: UserCreateWithoutPaymentsInput;
  update: UserUpdateWithoutPaymentsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutProgrammeProgressionsInput = {
  create: UserCreateWithoutProgrammeProgressionsInput;
  update: UserUpdateWithoutProgrammeProgressionsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutReadArticlesInput = {
  create: UserCreateWithoutReadArticlesInput;
  update: UserUpdateWithoutReadArticlesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutUserSubscriptionsInput = {
  create: UserCreateWithoutUserSubscriptionsInput;
  update: UserUpdateWithoutUserSubscriptionsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  attemptedQuizzes?: InputMaybe<UserQuizListRelationFilter>;
  billingAddress?: InputMaybe<JsonNullableFilter>;
  billingCountryCode?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringNullableFilter>;
  downloadedResources?: InputMaybe<UserResourceListRelationFilter>;
  email?: InputMaybe<StringFilter>;
  eventAttendances?: InputMaybe<EventAttendanceListRelationFilter>;
  eventRegistrations?: InputMaybe<EventRegistrationListRelationFilter>;
  externalId?: InputMaybe<StringFilter>;
  healthDiscipline?: InputMaybe<StringNullableFilter>;
  howDidYouHear?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isEnabled?: InputMaybe<BoolFilter>;
  isPublicEnabled?: InputMaybe<BoolFilter>;
  manualActivities?: InputMaybe<UserManualActivityListRelationFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  payments?: InputMaybe<PaymentListRelationFilter>;
  photoUrl?: InputMaybe<StringNullableFilter>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionListRelationFilter>;
  publicAddresses?: InputMaybe<JsonNullableFilter>;
  publicDiscipline?: InputMaybe<StringNullableFilter>;
  publicName?: InputMaybe<StringNullableFilter>;
  readArticles?: InputMaybe<UserArticleListRelationFilter>;
  registrationNumber?: InputMaybe<StringNullableFilter>;
  regulatoryBody?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  stripeCustomerId?: InputMaybe<StringNullableFilter>;
  userSubscriptions?: InputMaybe<UserSubscriptionListRelationFilter>;
};

export type UserWhereUniqueInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  attemptedQuizzes?: InputMaybe<UserQuizListRelationFilter>;
  billingAddress?: InputMaybe<JsonNullableFilter>;
  billingCountryCode?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringNullableFilter>;
  downloadedResources?: InputMaybe<UserResourceListRelationFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  eventAttendances?: InputMaybe<EventAttendanceListRelationFilter>;
  eventRegistrations?: InputMaybe<EventRegistrationListRelationFilter>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  healthDiscipline?: InputMaybe<StringNullableFilter>;
  howDidYouHear?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<BoolFilter>;
  isPublicEnabled?: InputMaybe<BoolFilter>;
  manualActivities?: InputMaybe<UserManualActivityListRelationFilter>;
  modified?: InputMaybe<DateTimeFilter>;
  payments?: InputMaybe<PaymentListRelationFilter>;
  photoUrl?: InputMaybe<StringNullableFilter>;
  programmeProgressions?: InputMaybe<ProgrammeProgressionListRelationFilter>;
  publicAddresses?: InputMaybe<JsonNullableFilter>;
  publicDiscipline?: InputMaybe<StringNullableFilter>;
  publicName?: InputMaybe<StringNullableFilter>;
  readArticles?: InputMaybe<UserArticleListRelationFilter>;
  registrationNumber?: InputMaybe<StringNullableFilter>;
  regulatoryBody?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  stripeCustomerId?: InputMaybe<StringNullableFilter>;
  userSubscriptions?: InputMaybe<UserSubscriptionListRelationFilter>;
};
