// import '@mantine/core/styles.css';
// import '@mantine/notifications/styles.css';
import '../styles/main.scss';
import 'tippy.js/dist/tippy.css';
import React, { useMemo } from 'react';
import FirebaseProvider from '@dr-pam/common-components/Components/Firebase/FirebaseProvider';
import AuthProvider from '@dr-pam/common-components/Components/Auth/AuthProvider';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import { MantineProvider } from '@mantine/core';
import ModalProvider from '@dr-pam/common-components/Components/Modal/ModalProvider';
import ToastProvider from '@dr-pam/common-components/Components/Toast/ToastProvider';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import StripeProvider from '@dr-pam/common-components/Components/Payment/StripeProvider';
import RuntimeConfigProvider from '@dr-pam/common-components/Config/RuntimeConfigProvider';
import ProgrammeProvider from './programme/ProgrammeProvider';
import RuntimeGoogleTagManager from '@dr-pam/common-components/Components/Page/RuntimeGoogleTagManager';
import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';

// Pages that do NOT use the PageWrapper should be statically imported
import LoginPage from '../pages/LoginPage';
import LogoutPage from '../pages/LogoutPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import PageWrapper from './layout/PageWrapper';
import ErrorHandler from './misc/ErrorHandler';
import { SessionAuthService } from '@dr-pam/common-components/Services/SessionAuthService';

// Pages that DO use the PageWrapper should be dynamically imported
const HomePage = React.lazy(() => import('../pages/HomePage'));
const ResourceHubPage = React.lazy(() => import('../pages/ResourceHubPage'));
const ActivityLogPage = React.lazy(() => import('../pages/ActivityLogPage'));
const ProfilePage = React.lazy(() => import('../pages/ProfilePage'));
const ArticlePage = React.lazy(() => import('../pages/ArticlePage'));
const ResourcePage = React.lazy(() => import('../pages/ResourcePage'));
const EventPage = React.lazy(() => import('../pages/EventPage'));
const QuizPage = React.lazy(() => import('../pages/QuizPage'));
const ProductPage = React.lazy(() => import('../pages/ProductPage'));
const PurchaseCompletePage = React.lazy(() => import('../pages/PurchaseCompletePage'));
const ProgrammePage = React.lazy(() => import('../pages/ProgrammePage'));
const CategoryPage = React.lazy(() => import('../pages/CategoryPage'));

export type PageRouteObject = Pick<RouteObject, 'path' | 'element' | 'ErrorBoundary' | 'errorElement'> & {
	className?: string;
	children?: PageRouteObject[];
};

export const routes: PageRouteObject[] = [
	{
		path: '/logout',
		element: <LogoutPage />,
		className: 'LogoutPage',
	},
	{
		path: '/login',
		element: <LoginPage />,
		className: 'LoginPage',
	},
	{
		path: '/forgot-password',
		element: <ForgotPasswordPage />,
		className: 'ForgotPasswordPage',
	},
	{
		path: '/reset-password',
		element: <ResetPasswordPage />,
		className: 'ResetPasswordPage',
	},
	{
		path: '/',
		element: <PageWrapper routes={() => routes} />,
		errorElement: <ErrorHandler />,
		children: [
			{
				path: '',
				element: <HomePage />,
				className: 'HomePage',
			},
			{
				path: 'resource-hub',
				element: <ResourceHubPage />,
				className: 'ResourceHubPage',
			},
			{
				path: 'article/:id',
				element: <ArticlePage />,
				className: 'ArticlePage',
			},
			{
				path: 'article/:id/:slug?',
				element: <ArticlePage />,
				className: 'ArticlePage',
			},
			{
				path: 'resource/:id',
				element: <ResourcePage />,
				className: 'ResourcePage',
			},
			{
				path: 'resource/:id/:slug?',
				element: <ResourcePage />,
				className: 'ResourcePage',
			},
			{
				path: 'event/:id',
				element: <EventPage />,
				className: 'EventPage',
			},
			{
				path: 'event/:id/:slug?',
				element: <EventPage />,
				className: 'EventPage',
			},
			{
				path: 'quiz/:id',
				element: <QuizPage />,
				className: 'QuizPage',
			},
			{
				path: 'quiz/:id/:attemptId/attempt',
				element: <QuizPage />,
				className: 'QuizPage',
			},
			{
				path: 'quiz/:id/:resultsId/results',
				element: <QuizPage />,
				className: 'QuizPage',
			},
			{
				path: 'quiz/:id/:slug?',
				element: <QuizPage />,
				className: 'QuizPage',
			},
			{
				path: 'activity-log',
				element: <ActivityLogPage />,
				className: 'ActivityLogPage',
			},
			{
				path: 'profile',
				element: <ProfilePage />,
				className: 'ProfilePage',
			},
			{
				path: 'programme/:id',
				element: <ProgrammePage />,
				className: 'ProgrammePage',
			},
			{
				path: 'programme/:programmeId/:id',
				element: <CategoryPage />,
				className: 'CategoryPage',
			},
			{
				path: 'product/:id',
				element: <ProductPage />,
				className: 'ProductPage',
			},
			{
				path: 'purchase/complete',
				element: <PurchaseCompletePage />,
				className: 'PurchaseCompletePage',
			},
		],
	},
];

const router = createBrowserRouter(routes);
const authService = new SessionAuthService();

export default function App() {
	const apolloClient = useMemo(() => ApolloUtils.createApolloClient('/api/graphql'), []);

	return (
		<RuntimeConfigProvider>
			<RuntimeGoogleTagManager />
			<FirebaseProvider>
				<StripeProvider>
					<AuthProvider authService={authService} autoFetchUser>
						<ApolloProvider client={apolloClient}>
							<HelmetProvider>
								<MantineProvider>
									<ModalProvider>
										<ToastProvider>
											<ProgrammeProvider>
												<RouterProvider router={router} />
											</ProgrammeProvider>
										</ToastProvider>
									</ModalProvider>
								</MantineProvider>
							</HelmetProvider>
						</ApolloProvider>
					</AuthProvider>
				</StripeProvider>
			</FirebaseProvider>
		</RuntimeConfigProvider>
	);
}
