import React, { useEffect } from 'react';
import Center from '@dr-pam/common-components/Components/Misc/Center';
import Loading from '@dr-pam/common-components/Components/Misc/Loading';
import { ToastProviderValue, useToastProvider } from '@dr-pam/common-components/Components/Toast/ToastProvider';
import debounce from 'lodash-es/debounce';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAuthService } from '@dr-pam/common-components/Components/Auth/AuthProvider';

export type LogoutPageProps = {
	className?: string;
};

export default function LogoutPage(props: LogoutPageProps) {
	const { className } = props;

	const navigate = useNavigate();
	const authService = useAuthService();
	const toastProvider = useToastProvider();

	useEffect(() => {
		authService.signOut().then(() => afterLogout(toastProvider, navigate));
	}, [authService, navigate, toastProvider]);

	return (
		<Center className={`LogoutPage h100 w100 ${className ?? ''}`}>
			<Loading>
				<p>Signing you out...</p>
			</Loading>
		</Center>
	);
}

const afterLogout = debounce(
	(toastProvider: ToastProviderValue, navigate: NavigateFunction) => {
		toastProvider.showToast({
			children: 'Successfully logged out',
			color: 'success',
			autoCloseMs: 5000,
		});
		setTimeout(() => {
			navigate('/', {
				replace: true,
			});
		}, 100);
	},
	500,
	{
		leading: true,
		trailing: false,
	},
);
