import { ApolloClient, useApolloClient } from '@apollo/client';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import { useMemo } from 'react';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';
import {
	ProgrammeWithTopLevelCategories,
	PurchasableProgramme,
	PurchasedProgramme,
} from '@dr-pam/common-components/Models/NdcProgramme';
import { CategoryWithActivities, PurchasableProduct } from '@dr-pam/common-components/Models/NdcProduct';
import { CategoryProgression, ProgrammeProgression } from '@dr-pam/common-components/Models/NdcProgression';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';

export default class ProgrammeService {
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public get(id: string) {
		const request = FetchUtils.getJson<ProgrammeWithTopLevelCategories>(`/api/ndc/programme/${id}`);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getPurchasedProgrammes() {
		const request = FetchUtils.getJson<PurchasedProgramme[]>('/api/ndc/programme/purchased');
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getPurchasableProgrammes() {
		const request = FetchUtils.getJson<PurchasableProgramme[]>('/api/ndc/programme/purchasable');
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getProductsContainingProgramme(programmeId: string) {
		const request = FetchUtils.getJson<PurchasableProduct[]>('/api/ndc/product?programmeId=' + programmeId);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getProgrammeCategoryActivities(programmeId: string, categoryId: string) {
		const request = FetchUtils.getJson<CategoryWithActivities>(`/api/ndc/programme/${programmeId}/${categoryId}`);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getCategoryProgressionByProgrammeId(programmeId: string) {
		const request = FetchUtils.getJson<CategoryProgression[]>(`/api/ndc/progression/programme/${programmeId}`);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getTotalCategoryProgressionByProgrammeId(programmeId: string) {
		const request = FetchUtils.getJson<CategoryProgression>(`/api/ndc/progression/programme/${programmeId}/total`);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getProgrammeProgression(programmeIds: string[]) {
		const request = FetchUtils.postJson<ProgrammeProgression[], string[]>(
			`/api/ndc/progression/programme`,
			programmeIds,
		);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getTotalProgrammeProgression(programmeIds: string[]) {
		const request = FetchUtils.postJson<ProgrammeProgression, string[]>(
			`/api/ndc/progression/programme/total`,
			programmeIds,
		);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}
	public getProgrammeProgressionsForPublicProfile() {
		const request = FetchUtils.getJson<ProgrammeProgression[]>(`/api/ndc/progression/public-profile`);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}
}

export function useProgrammeService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new ProgrammeService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}
